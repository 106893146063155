<template>
  <div div class="mx-auto">
    <div
      v-if="mapStyle == 1"
      id="mappp"
      class="h-80 w-full rounded-lg drop-shadow-lg "
    ></div>
      <!-- above class was"  class="absolute mx-auto h-80 w-[96%] rounded-none border-r-0 border-white bg-indigo-50 pl-2 pr-4 drop-shadow-lg sm:w-[93%] sm:pl-5 sm:pr-10 lg:pr-0 md:w-full md:border-r-[40px] lg:w-[980px] lg:rounded-lg lg:border-2 lg:border-indigo-100 lg:border-opacity-50 xl:w-[1020px]" -->
    <div
      v-if="mapStyle == 2"
      id="mappp"
      class="h-96 w-full rounded-lg drop-shadow-lg sm:h-[687px]"
    ></div>
    <!-- <div v-else 
      id="mappp" 
      class="h-80 w-full rounded-lg drop-shadow-lg"
      ></div> -->
    <!-- w-[335px] sm:w-[600px] md:w-[728px] -->
  </div>
</template>

<script>
export default {
  props: {
    latNumber: Number, // passed-in from PropertyLanding.vue
    lngNumber: Number,
    mapStyle: String,
  },

  setup(props, context) {
    const mapStyle = props.mapStyle;
    return { mapStyle };
  },

  data() {
    return {
      // lattt: 40.796160,
      // lnggg: -74.480540
    };
  },

  methods: {
    renderMap() {
      const map = new google.maps.Map(document.getElementById("mappp"), {
        center: { lat: this.latNumber, lng: this.lngNumber },
        // center: {lat: this.lattt, lng: this.lnggg},
        zoom: 14,
        maxZoom: 18,
        minZoom: 8,
        streetViewControl: false,
        // marker: marker
      });

      const marker = new google.maps.Marker({
        position: { lat: this.latNumber, lng: this.lngNumber },
        map,
      });
    },
  },

  mounted() {
    this.renderMap();
  },
};
</script>

<style></style>
