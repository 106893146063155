<template>
  <div
    class="bg-rounded mx-auto max-w-screen-md bg-white px-0 pt-4 pb-4 sm:bg-slate-50 sm:px-0 md:px-4"
  >
    <div>
      <h1 class="mt-6 mb-12 text-center text-5xl font-bold">F.A.Q.</h1>
      <div class="row">
        <div class="col-xl-8 mx-auto">
          <p class="my-4 text-center">
            <strong>"<u>F</u>requently"</strong
            >&nbsp;&nbsp;&nbsp;<i>ˈfrē-kwənt-lē</i>&nbsp;&nbsp;-&nbsp;&nbsp;at
            frequent or short intervals
          </p>
          <p class="my-4 text-center">
            <strong>"<u>A</u>sked"</strong>&nbsp;&nbsp;&nbsp;<i>ˈas(k)t </i
            >&nbsp;&nbsp;-&nbsp;&nbsp;to make a request
            of&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p class="my-4 text-center">
            <strong>"<u>Q</u>uestions"</strong
            >&nbsp;&nbsp;&nbsp;<i>ˈkwes-chənz</i>&nbsp;&nbsp;-&nbsp;&nbsp;acts
            or instances of asking
          </p>
          <p class="my-6 text-center text-sm font-light italic">
            courtesy:
            <a
              href="https://www.merriam-webster.com"
              target="_blank"
              class="text-sm underline hover:text-slate-500"
              >Merriam-Webster</a
            >
          </p>
        </div>
      </div>
    </div>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question">
          Why are reviews allowed from people who have never visited the
          property?
        </span>
        <p>
          <span
            class="my-2 ml-8 rounded-lg bg-indigo-50 px-1 text-sm italic text-slate-700"
            >- Click to see answer -</span
          >
        </p>
      </summary>
      While it's important for a reviewer to disclose if they haven't been to a
      property, they can still have useful observations and insights based on
      what they've seen in maps, photos, or their knowledge about the
      neighborhood. <br /><br />For example, think of someone who lived on that
      street up until 2 years ago, but never actually visited the specific house
      being reviewed. That person's feedback about noise, traffic, restaurants,
      etc. could be helpful for prospective buyers.
    </details>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question">
          Why do some properties have fewer ratings than reviews? For example,
          why would a property have 3 reviews, but only two kitchen ratings?
        </span>
      </summary>
      Like most fields on the "Write a Review" page, the star ratings (<fa icon="star" id="rating_star" size="md" class="mx-0.5" />) are optional. In the example above, one of the three reviewers chose
      not to give a star rating for the kitchen.
    </details>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question"> Question goes here </span>
      </summary>
      Answer goes here
    </details>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question"> Question goes here </span>
      </summary>
      Answer goes here
    </details>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question"> Question goes here </span>
      </summary>
      Answer goes here
    </details>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question"> Question goes here </span>
      </summary>
      Answer goes here
    </details>

    <details class="my-10 rounded-lg p-2 open:bg-indigo-50">
      <summary class="text-zagblue mb-4">
        <span class="question"> Question goes here </span>
      </summary>
      Answer goes here
    </details>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
  },
};
</script>

<style></style>
