<template>
    <div class="text-zaggray hover:text-zaggray/70 col-span-12 rounded-xl border-2 border-gray-200 border-opacity-50 p-2.5 drop-shadow-lg odd:bg-slate-50 even:bg-indigo-50/60 hover:border-indigo-900/20 hover:bg-white sm:col-span-6 lg:col-span-4"
    v-if="nearByLocation"
    >
        <router-link
        :to="{
            name: 'PropertyLanding',
            params: { id: nearByLocation.doc_id },
        }"
        >
            <ul
                class="relative w-full space-y-1.5 text-center text-sm font-medium"
            >
                <li class="text-base font-semibold">{{ nearByLocation.address }}</li>
                <li class="text-sm" v-if="nearByLocation.extended_address">{{ nearByLocation.extended_address }}</li>
                <!-- BEGIN test display 1st image from 1st review -->
                <li class="text-sm" v-if="fetchedImages.length > 0">
                    <img   
                    :src="fetchedImages[0]"
                    alt=""
                    class="w-full rounded-md ring-1 ring-indigo-900/20 sm:h-[5.5rem] h-auto"
                /></li>
                <li v-else class="text-sm">
                    <img   
                    src="@/assets/images/review-has-no-photos-w-camera.png"
                    alt=""
                    class="rounded-xl ring-1 ring-indigo-900/10"
                /></li>
                
                <!-- END test display 1st image from 1st review -->  
                <!-- property type-->
                <li class="text-sm" v-if="nearByLocation.property_type === '1_family'">Single Family</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'condo'">Condominium</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'townhouse'">Townhouse</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'coop'">Cooperative</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'mobilehome'">Mobile home</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'multi_family'">Multi-family (whole building)</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'commercial'">Commercial</li>
                <li class="text-sm" v-else-if="nearByLocation.property_type === 'rentalapartment'">Apartment for rent</li>
                <li class="text-sm" v-else>Raw land</li>
                <!-- property type-->
                <li class="text-sm">{{ (nearByLocation.numberOfReviews === 1) ? '1 Review' : `${nearByLocation.numberOfReviews} Reviews` }}</li>
                <li class="text-sm" v-if="nearByLocation.qAnda" >{{ nearByLocation.qAnda.length }} Questions</li>
                <li class="text-xs" v-if="nearByLocation.answers_length">{{ (nearByLocation.answers_length === 1) ? '1 Answer' : `${nearByLocation.answers_length} Answers` }} </li>
                <li class="text-xs italic">Created {{ formatDistanceToNow(new Date(nearByLocation.createdAt.seconds * 1000)) }} ago</li>
            </ul>
        </router-link>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { formatDistanceToNow } from "date-fns";
import useStorage from "@/composables/useStorage";

export default {
  props: {
    singleLocation: Object,
  },

  components: {},

  setup(props, context) {
    const router = useRouter();
    const { getImages, fetchedImages } = useStorage();
    const nearByLocation = ref(props.singleLocation);


    onMounted(() => {
     if(nearByLocation.value.allReviews.length > 0) {
      const totalImageCount = nearByLocation.value.allReviews[0].filePath.length;
      const randomNumber = Math.floor(Math.random() * (totalImageCount - 1)) + 1;
      const randomImage = [nearByLocation.value.allReviews[0].filePath[randomNumber]];
      getImages(randomImage);
     }
      
    
    });
    
    return { 
      nearByLocation,
      formatDistanceToNow,
      fetchedImages, 
      };
  },

   data() {
    return {};
  },

};
</script>
