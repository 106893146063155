import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { projectAuth } from "./firebase/config";
import { onAuthStateChanged } from "firebase/auth"; // ### new for Firebase 8 to 9 refactoring
// import { VueReCaptcha } from "vue-recaptcha-v3";

// styles
// import './assets/main.css'
import "./assets/tailwind.css";

// per tailwind documents at https://tailwindcss.com/docs/installation#post-css-7-compatibility-build
// import "tailwindcss/tailwind.css"

// BEGIN font awesome icons, per https://github.com/FortAwesome/vue-fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon, fontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons"; // OPTIMIZE THIS LATER
import { fab } from "@fortawesome/free-brands-svg-icons"; // OPTIMIZE THIS LATER
import { far } from "@fortawesome/free-regular-svg-icons"; // OPTIMIZE THIS LATER

import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

library.add(fas, fab, far);

// END font awesome icons

let app;

onAuthStateChanged(projectAuth, () => {
  // ### before Firebase 8 to 9 refactoring, was: projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .component("fa", FontAwesomeIcon) // for FontAwesome, per https://youtu.be/MoDIpTuRWfM?t=440
      .use(router)
      // .use(VueReCaptcha, {
      //   siteKey: "6LcZudwfAAAAAF44uL0Jtr_4qR5zQHmJx6YHzXSe",
      // })
      .mount("#app");
  }
});
