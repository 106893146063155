<template v-if="propId">
  <div
    class="qacard"
    v-for="(quiz, quizIndex) in qAndA"
    :key="`qandA${quizIndex}`"
    :id="`quiz-${quizIndex}`"
  >
    <!--BEGIN question 1 -->

    <div class="md:gap-.5 mt-6 grid grid-cols-12 gap-3 px-0.5 pt-11 pb-5">
      <div
        class="col-span-1 -mt-1.5 ml-0.5 mr-2 -space-y-1.5 sm:ml-0 md:mr-16 lg:mr-12"
      >
        <!-- BEGIN upvote / downvote this item -->

        <div class="flex items-center justify-center">
          <button
            class="place-self-center text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
            @click='upvoteQuestion(`quiz_${quiz.userId}_${quizIndex}`, quizIndex, quiz.upvoteCount, qAndA, true)'
          >
            <i class="fas fa-caret-up"></i>
          </button>
        </div>
        <div class="flex items-center justify-center">
          <p class="text-center text-sm text-slate-500 sm:text-base">{{ (typeof quiz.upvoteCount !== 'undefined' && !isNaN(quiz.upvoteCount)) ? quiz.upvoteCount : 0 }}</p>
        </div>
        <div class="flex items-center justify-center">
          <button
            class="text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
            @click='upvoteQuestion(`quiz_${quiz.userId}_${quizIndex}`, quizIndex, quiz.upvoteCount, qAndA, false)'
          >
            <i class="fas fa-caret-down"></i>
          </button>
        </div>

        <!-- END upvote / downvote this item -->
      </div>

      <div class="col-span-10 grid md:col-span-10">
        <div
          class="bg-zagblue row-span-1 -ml-1 inline-flex rounded-lg p-2.5 text-white sm:-ml-2 md:-ml-8"
        >
          <p class="mr-2.5 -mt-0.5 font-semibold sm:mr-3">Q:</p>
          <p id="first" class="qatext scroll-mt-12 text-left text-white">
            {{ quiz.questionText }}
          </p>
        </div>

        <div
          class="space-between-96 row-span-1 -ml-0.5 mt-1.5 inline-flex items-start px-0.5 sm:ml-1.5 sm:px-1.5"
        >
          <div
            class="inline-flex w-2/5 space-x-5 text-slate-500 sm:w-1/2 sm:space-x-5 md:-ml-10 md:w-3/5 lg:space-x-8"
          >
            <!-- BEGIN "Answer a Question" button beneath a question, with v-if logged in or "Whoops" 8.5 -->

            <div v-if="user">
              <!-- <Whoops whoopsButtonVifTextAndIcon="8" /> -->
              <AskAnswerQuestion
                v-if="propId"
                AskAnswerQuestionButtonVifTextAndIcon="1"
                :qIndex="quizIndex"
                :qText="quiz.questionText"
                :qAndAArray="qAndA"
                :propertyId="propId"
              />
            </div>
            <div v-else><Whoops whoopsButtonVifTextAndIcon="8.5" /></div>

            <!-- END "Answer a Question" button beneath a question, with v-if logged in or "Whoops" 8.5 -->

            <ShareButtonDD shareButtonDDStyle="2.2" />
            <button class="text-xs sm:text-base">
              <fa :icon="['far', 'flag']" />&nbsp;<span
                class="hidden text-xs md:inline"
                >Flag</span
              >
            </button>
          </div>

          <div
            class="inline-block w-3/5 text-slate-500 sm:w-1/2 md:ml-10 md:w-2/5"
          >
            <p
              class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              v-if="quiz.createdAt"
            >
              {{
                formatDistanceToNow(new Date(quiz.createdAt.seconds * 1000))
              }}
              ago
            </p>
            <p
              class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              v-if="quiz.askedBy"
            >
              by <span class="text-sm italic">{{ quiz.askedBy }}</span>
            </p>
            <p
              class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              v-else
            >
              by <span class="zagnetic text-sm">zagnetic</span>
            </p>
            <!-- <p class="text-xs sm:text-sm leading-5 md:leading-normal text-right italic">standard question<Whoops whoopsButtonVifTextAndIcon=6 /></p> -->
            <div
              class="float-right inline-flex"
              v-if="quiz.askedBy === 'zagnetic'"
            >
              <p
                class="mr-0.5 text-right text-xs italic leading-5 md:text-sm md:leading-normal"
              >
                standard question
              </p>
              <button class="mb-0.5 ml-0">
                <Whoops whoopsButtonVifTextAndIcon="6" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-1 md:col-span-1"></div>
    </div>
    <!--END question 1 -->

    <!-- BEGIN answer 1 -->
    <div v-if="quiz.answers.length > 0" :key="quiz.answers.length">
      <div
        class="mt-1 grid grid-cols-12 gap-3 py-1 px-0.5 sm:py-2 sm:px-0.5 md:gap-0.5 md:px-0"
        v-for="(answerItem, answerIndex) in quiz.answers"
        :key="`answer-${answerIndex}`"
      >
        <div class="col-span-1 md:col-span-1"></div>

        <!-- <div class="col-span-1 -space-y-1.5 -mt-1.5 mr-2 md:-mr-6"> -->
        <div class="col-span-1 -mt-1.5 ml-0.5 -space-y-1.5 sm:mr-1 lg:-mr-2">
          <!-- BEGIN upvote / downvote this item -->

          <div class="flex items-center justify-center">
            <button
              class="place-self-center text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
              @click='upvoteAnswer(`${answerItem.userId}_${quizIndex}_${answerIndex}`, quizIndex, answerIndex, answerItem.upvoteCount, qAndA, true)'
            >
              <i class="fas fa-caret-up"></i>
            </button>
          </div>
          <div class="flex items-center justify-center">
            <p class="text-center text-sm text-slate-500 sm:text-base" :id="`${answerItem.userId}_${quizIndex}_${answerIndex}`">
              {{ answerItem.upvoteCount }}
            </p>
          </div>
          <div class="flex items-center justify-center">
            <button
              class="text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
              @click='upvoteAnswer(`${answerItem.userId}_${quizIndex}_${answerIndex}`, quizIndex, answerIndex, answerItem.upvoteCount, qAndA, false)'
            >
              <i class="fas fa-caret-down"></i>
            </button>
          </div>

          <!-- END upvote / downvote this item -->
        </div>

        <div class="col-span-10 grid md:col-span-10 md:mr-2.5">
          <div class="row-span-1 rounded-lg bg-slate-200 p-2 sm:p-4">
            <p class="qatext text-zagslate text-left">
              {{ answerItem.answerText }}
            </p>
          </div>

          <div
            class="space-between-96 row-span-1 ml-2.5 mt-1.5 inline-flex items-start px-0.5 sm:px-1.5"
          >
            <div
              class="inline-flex w-2/5 space-x-5 text-slate-500 sm:w-1/2 md:w-3/5 lg:space-x-8"
            >
              <ShareButtonDD shareButtonDDStyle="3" />
              <button class="text-sm md:text-base">
                <fa :icon="['far', 'flag']" />
              </button>
            </div>

            <div class="inline-block w-3/5 text-slate-500 sm:w-1/2 md:w-2/5">
              <p
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                {{
                  formatDistanceToNow(
                    new Date(answerItem.createdAt.seconds * 1000)
                  )
                }}
                ago
              </p>
              <p
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                by
                <a href="#" class="text-xs font-medium italic md:text-sm"
                  >{{ answerItem.answeredBy }}
                </a>
              </p>
              <p
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                <span
                  class="text-zagblue ml-0 text-xs leading-5 sm:text-sm md:leading-normal"
                  v-if="answerItem.isVerified"
                  >verified<sup>&thinsp;<i class="fas fa-check-circle"></i></sup
                ></span>
                <span v-if="answerItem.isRealEstateAgent"
                  >real estate agent</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="pb-4 text-center text-sm italic text-zagblue">
        -&nbsp;no answers yet&nbsp;-
      </div>
      <!-- <div v-if="user">
        <AskAnswerQuestion
          v-if="propId"
          AskAnswerQuestionButtonVifTextAndIcon="1.5"
          :qIndex="index"
          :qText="quiz.questionText"
          :qAndAArray="qAndA"
          :propertyId="propId"
        />
      </div> -->
    </div>

    <!-- END answer 1 -->
  </div>
</template>

<script>
import { ref } from "vue";
import ShareButtonDD from "@/components/ShareButtonDD.vue";
import Whoops from "@/components/Whoops.vue";
import AskAnswerQuestion from "@/components/AskAnswerQuestion.vue";
import getUser from "../composables/getUser";
import { formatDistanceToNow, format } from "date-fns";

import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";

export default {
  props: {
    // propertyId data is from PropertyLanding.vue (hard-coded real propertyId), being passed-into IndivQandAnswer.vue component
    propertyId: {
      type: String,
      required: true,
    },
    propertyQandA: {
      type: Array,
      required: true,
    },
  },
  
  setup(props, context) {   // accepting props as an argument, so props (propertyId) can be used below in setup()
    const { updateQAndAItems, error } = useDocument('properties', props.propertyId);
    const { user } = getUser();
    const propId = ref(props.propertyId);
    const qAndA = ref(props.propertyQandA);

    const upvoteAnswer = async (id, quizIndex, answerIndex, previousCount, oldQandA, isIncrement) => {
      
      const updatedQandA = [... oldQandA];
      // console.log(updatedQandA);
      let newCount = +previousCount;
      if(isIncrement) {
        newCount = newCount + 1;
      } else {
        newCount = newCount - 1;
      }
      updatedQandA[quizIndex].answers[answerIndex].upvoteCount = newCount;
      console.log(updatedQandA[quizIndex].answers[answerIndex].upvoteCount);
      const updateUpvoteCount= await updateQAndAItems(updatedQandA);
      if(error.value === null) {
        qAndA.value = updatedQandA;
      }
      
    }

    const upvoteQuestion = async (id, quizIndex, previousCount, oldQandA, isIncrement) => {
        
        const updatedQandA = [... oldQandA];
        let newCount = (typeof previousCount !== 'undefined' && !isNaN(previousCount)) ? +previousCount : 0;
        if(isIncrement) {
          newCount = newCount + 1;
        } else {
          newCount = newCount - 1;
        }
        updatedQandA[quizIndex].upvoteCount = newCount;
        console.log(updatedQandA[quizIndex].upvoteCount);
        const updateUpvoteCount= await updateQAndAItems(updatedQandA);
        if(error.value === null) {
          // document.getElementById(id).innerHTML = newCount;
          qAndA.value = updatedQandA;
        }

    }

    return {
      user,
      qAndA,
      propId,
      formatDistanceToNow,
      format,
      upvoteAnswer,
      upvoteQuestion,
    };
  },

  components: {
    ShareButtonDD,
    Whoops,
    AskAnswerQuestion,
  },

  data() {
    return {};
  },
};
</script>

<style></style>
