// useCollection.js is to add a new document to a collection

import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { collection, doc, setDoc, query, where, getDocs, getDoc, updateDoc  } from "firebase/firestore"; // ### new for Firebase 8 to 9 refactoring

const useCollection = (collectionName) => {
  const error = ref(null);
  const isPending = ref(false);
  const getDocError = ref(null);
  const document = ref(null);

  // add a new document
  const addDoc = async (docObj) => {
    error.value = null;
    isPending.value = true;
    console.log(docObj);
    try {
      const newCollectionRef = doc(
        collection(projectFirestore, collectionName)
      );
      const res = await setDoc(newCollectionRef, docObj);
      console.log("response", newCollectionRef);
      return newCollectionRef;
    } catch (err) {
      console.log(err.message);
      error.value = "Sorry, could not add to the database";
      isPending.value = false;
    }
  };

  const addDocWithCustomId = async (docObj) => {
    error.value = null;
    isPending.value = true;
    console.log(docObj);
    try {
      const newCollectionRef = doc( projectFirestore, collectionName, docObj.userId);
      const res = await setDoc(newCollectionRef, docObj);
      console.log("response", newCollectionRef);
      return newCollectionRef;
    } catch (err) {
      console.log(err.message);
      error.value = "Sorry, could not add to the database";
      isPending.value = false;
    }
  };

  const getDocsByQuery = async (indexName, campareOperator, campareValue) => {
    
    let collectionRef = collection(projectFirestore, collectionName);
    let results = [];
    const q = await query(
      collectionRef,
      where(indexName, campareOperator, campareValue),
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      return doc.id && results.push({ ...doc.data(), id: doc.id });
    });

    if (results.length > 0) {
      error.value = null;
      return results;
    } else {
      getDocError.value = "Document not found";
      return false;
    }
  }

  const getDocsByCompoundQuery = async (compoundQuery) => {
    // console.log(compoundQuery);
    // return false;
    getDocError.value = null;
    let collectionRef = collection(projectFirestore, collectionName);
    let results = [];
    let q;
    if(compoundQuery.length == 1) {
      q = await query(
        collectionRef,
        where(...compoundQuery[0]),
      );
    } else if(compoundQuery.length == 2) {
      q = await query(
        collectionRef,
        where(...compoundQuery[0]),
        where(...compoundQuery[1]),
      );
    } else {
      q = await query(
        collectionRef,
        where(...compoundQuery[0]),
        where(...compoundQuery[1]),
        where(...compoundQuery[2]),
      );
    }
    
    const querySnapshot = await getDocs(q);

    if(querySnapshot.empty) {
      getDocError.value = "No Document found";
      return [];
    } else {
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data());
        return doc.id && results.push({ ...doc.data(), id: doc.id });
      })
    }

    if (results.length > 0) {
      getDocError.value = null;
      return results;
    } else {
      getDocError.value = "Document not found";
      return false;
    }
  }

  const getDocById = async (docId) => {
    error.value = null;
    const docRef = doc(projectFirestore, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      document.value = docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      error.value = "No such document!";
    }
  }
  
  const updateDocById = async(docId, updates) => {
    isPending.value = true;
    error.value = null;
    let docRef = doc(projectFirestore, collectionName, docId);  
    try {
        // ??? not sure line below is correct
        const res = await updateDoc(docRef, updates); // ### before Firebase 8 to 9 refactoring, was: const res = await docRef.update(updates)
        isPending.value = false;
        return res;
      } catch (err) {
        console.log(err.message);
        isPending.value = false;
        error.value = "could not update the document";
      }
  }

  return { error, addDoc, isPending, getDocsByQuery, getDocsByCompoundQuery, getDocError, addDocWithCustomId, getDocById, document, updateDocById };
};

export default useCollection;
