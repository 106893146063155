import { ref } from "vue";
import { projectAuth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth"; // ### new for Firebase 8 to 9 refactoring

// refs
const user = ref(projectAuth.currentUser);


// auth changes
onAuthStateChanged(projectAuth, (_user) => {
  // ### before Firebase 8 to 9 refactoring, was:  projectAuth.onAuthStateChanged(_user => {
  console.log("User state change. Current user is: ", _user);
  // was if(_user !== null && _user.emailVerified) {
  if (_user !== null && _user.emailVerified) {
    user.value = _user;
  }
});

const getUser = () => {
  return { user };
};

export default getUser;
