<template>
  <div>
    <!-- BEGIN table of all properties -->
    <details class="mb-6 -mt-4 rounded-lg p-2 text-center open:bg-slate-50">
      <summary
        class="mb-6 text-2xl font-bold text-indigo-900/90 underline decoration-indigo-500/20 underline-offset-4"
      >
        List of Properties on <span class="zagnetic text-2xl">zagnetic</span>
        <span
          class="ml-1.5 align-middle text-sm font-light italic !no-underline"
          >- click -</span
        >
        <!-- <p>
          <span class="text-center text-xs font-light italic opacity-5"
            >07/19/2022</span
          >
        </p> -->
      </summary>

      <p class="py-6 text-center text-sm italic">- click to close -</p>
      <table class="mx-auto mb-8 table-auto border border-slate-400 pb-4">
        <thead>
          <tr class="border border-slate-300">
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              Street address
            </th>
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              -
            </th>
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              City / town
            </th>
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              State
            </th>
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              Zip code
            </th>
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              <span class="inline-block text-base sm:hidden">Type</span
              ><span class="hidden text-base sm:inline-block"
                >Property type</span
              >
            </th>
            <th
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              <span class="inline-block text-base sm:hidden">Go</span
              ><span class="hidden text-base sm:inline-block"
                >Landing page</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <!-- <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">zzzz</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/zzzz
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr> -->

          <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">169 Green Village Rd</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/8Ki9sMm3cQWv1pB7NDGD
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">40 Prospect Street</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/property/7LgZn1QFf9qHVnTiFaFu
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base ">2 Toothe Pl</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center"></td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">Madison</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">NJ</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">07940</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">1 Family</td>
            <td class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base text-center">
              <a
                class="text-muted text-slate-700 hover:text-slate-500 visited:text-pink-900 text-sm underline lg:text-base"
                href="https://zagnetic.com/review/JI9UA6WlQHn3qTN0a28B"
                target="_parent"
                title="link to this property landing page"
                >Go<span class="hidden lg:inline-block underline text-sm lg:text-base">&nbsp;to property</span><fa
                  :icon="['fas', 'arrow-alt-circle-right']"
                  class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              60 Chestnut St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Unit 1
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/bYCieffTG3T4vl2bHuu9
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              87 Independence Way
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Czsgi9tw8TuG7JM4Xdew
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              52 Keats Way
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/epS98zDPVPXjND3y8W8R
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              84 Skyline Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/FMHiKmMHN0nM6LbJnngH
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              6 Sommer Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/MMEpWtSrF0NfqXDKNXw2
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              2 Wentworth Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/WxuPFoxTPjC4o2MpWw3h
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              61 Woodland Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ZYJCSuHbUREwgpc0PdIJ
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              12 Independence Ct
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/VLKW3fXmd0oxFYSra5Jn
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              60 Green Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/lheygPxM7pHt37feKj82
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              32 Village Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              New Vernon
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07976
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/KE7Q9hw6KYaofs2ZRFGO
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              2 Lovell Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/a5CiTzdwzEYspr2SRCCl
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              208 Loantaka Way
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/6zhmNYqZLuR38HvWpaMX
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              61 Union Hill Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/3UHtVc94wzRLwhN36qK2
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              23 Elmwood Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/TOjPfeYaCHla2O3108Hb
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              66 New England Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/r8ShqSrYJ73Ehg6wrHH3
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Euclid Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/CTeEufu7fXQBouY2ioLC
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              44 Birch Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08542
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/1tIv821YkjUEuiytdTZB
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              21 Lytle St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08542
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/KKUdFwLasFMjozivpLYL
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              175 Hamilton Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08540
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/baUFd4jObWDH59p5PimW
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              20 Maple St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08542
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/QdL0re9zPPODYRhmFV9u
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              110 Harrison Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07042
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/review/SNk3Pu3gtVQWcgNqRzrl
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              31 Grenada Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07942
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/APiyCHaCGu8DFTbdb1jG
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              69 Mission St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07042
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Multi-Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/B4qPBunYk5rpvt9jKHxt
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              14 Fairmount Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07043
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/X6kNEGVHMFN5VKZJA2DQ
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              51 Grandview Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07043
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/xerjKh8bcJw2SjiiPa5f
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              7 Patton Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07043
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/GkRMrRDd5ObLrKLRVgkk
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              67 Heller Way
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Montclair
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07043
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/gXmwgI149EuFbPpvWu8A
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              3 Prospect St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Bernardsville
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07924
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Atsky6sNTi6KuqU8q5Yo
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              80 Hidden Valley Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Far Hills
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07931
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/DKEq4ySBLMQ2nj84u2GO
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              9 Montview Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ObXk4xaugUdUs7Zg5ExZ
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              25 Plymouth Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/0J9G34qYMd6LtbU0sDrr
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              3 Edison Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/NM6VndRo5KHQ39zIYXJU
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              35 North Crescent
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/tz8OATz6z7tjdFK0NRwY
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              7 Madison Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/rGJ2cNEIh44FNx4yNvwP
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              9 Norwood Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/qVFI1NYpHqiqpsIZI2Gh
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              1 Somerset Hills Village Ct
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1C
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Bernardsville
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07924
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/0r033VTYbLNjZbO2Xdld"
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              40 Loantaka Way
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              C
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/qQX75YiBIRKla60hBckr
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              43 Midwood Terrace
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/HJFqzgFnELvO3wOxHpT5
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              42 Elm St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              38-A
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/BRKaRlG0MBu2GORW6PRX
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              42 Elm St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              38-B
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/LddVhK8tMU3NO6AJ8Zax
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              61 Oak Ridge Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/XsdkifUeJqvG1cbTyx0o
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              6 Blue Mill Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/KUq9eV8XI7q5FrfGjPT5
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              2 Collins Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/m5K91xotlPkIsDpmwkZ2
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              215 Greenwood Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/FqQ85vVAfiBmxIKYbu98
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              60 Valley Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/odLWwtF4fgglBFRwR7Ah
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              58 Lathrop Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/S7pUNHE2Vd9Ikt3MWBpX
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              21 Morven Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08540
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/OB9x6GfiLiSodwS7cYMe
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              62 Hodge Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08540
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/PZhbOfVi2mIepZGQNaUI
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              43 Leigh Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Princeton
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08540
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Multi-Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/4IcptZbS4EMGws2RToMf
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              17 Franklin St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/RdsoHD6ZuBYzThJAynYx
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Dorado Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              10 E
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/khFMW75BmOP3ieVbSsRG
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              37 Fairwood Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/N9izrnUaHbPlFTOEyfX7
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Brooklake Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/XWsDPYJreg8lj6wC8Q0d
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              62 Pine Grove Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/poB8HB2iPmjjyRUMd86H
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              36 Fairview Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/LsMyvypvbsukhRGiQtSI
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              18 Barnsdale Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/0um6yfWHd3yCzIAF3vhK
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              151 Warren Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Bridgewater Township
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08807
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/1aselqY7QRkYTrVK9RBp
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              8 Beverly Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/3kzM4b8ubi0PtKWE4w0L
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              407 Verbank Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Millbrook
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NY
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              12545
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/3YtmNoRaYsFpf1N2k3TD
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 E Coleman Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/4rEr5xHpco7GbeijsRlB
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              33 Green Village Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              4404
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/6BZk5aTpTIfzsPHa1xQo
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              29 Valley Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/6o5jSY5sIb0gt716fTTv
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              16 Ward Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/755EMRuB73ph9kczQc78
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              32 Woodland Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07040
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/91yBMNj3GfGKB8G4JKFv
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              9 Highview Terrace
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/9BGqwB99eI0hLF13Xv9w
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Lake Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham Township
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/aasFowVBzJWorFSeHwpN
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              35 King St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ABKgGbCKlZE8xU2bmIrA
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              5 Stonehenge Ln
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Bridgewater Township
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08807
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/aDPy3L5j2ZQL5rUvCtZC
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Kennaday Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Mendham
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07945
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ae3uJbVKtx5DUarD8XrE
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              57 Western Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/aEjRg5nYwO08A0FLeooD
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              32 Niles Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Avxw11eJRMPw0SKfY6E4
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              16 Midland Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/B4ap7xW1iWgfSV0pw8uU
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              13 Franklin Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              8a
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/B8978hj4dqCuSz0M5bFf
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              29 East Ln
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/PcfzoDXUWXPLHCLy78PV
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              51 Mt Kemble Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              103
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/claHzSrSZoWIrCjMPCGD
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              54 Midwood Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Edison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              08820
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/cs0azblG506DUwJO04u3
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              142 James St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/cz71sHYGnLaOVZNbuZTi
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              37 Georgian Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/dLxND3uMBLOaMg9dej4S
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              25 Holden Ln
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/eHCn4K88DYOKsvThLpHv
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              20 Franklin St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/GyR6Wf0y1u2xNGUfYSAK
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              369 Shunpike Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham Township
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/gyxQTtvZfvhRRmTHpr2W
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              90 Kent Pl Blvd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/hDDlXrW68fHhnJTguwmb
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              28 Prospect St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Multi-Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/HmQYFnOfobw6yUPWsFtx
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              83 Madison Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Multi-Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/iVfSxRN2he7Q8GKkQgFh
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              21 Primrose Trail
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ICp8EVj0NF1N7m3IBzLO
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              320 South St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              18 M
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Coop
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Ii2jVYoJUsp06Jzd19Mn
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              3 Wilson Ln
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/iVX6JiIvKwCjCYMGifEX
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              73 Brittin St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/iYDx8pAh4cYQqSolwN91
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              13 Union Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/kvSmEqw1D8OPJozskRNr
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              7 Beechwood Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/kytJsE0fACHLASThu8OO
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              64 Independence Way
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/LExckpXRJESdbga8ouSt
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              123 Chatham St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/lFqv4p0Q55WWOIY2bl3F
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              190 Brooklake Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Florham Park
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07932
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/lMwM0MjtAGEB8FADuL52
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              129 Treadwell Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/lxlGFMxkihcKkYtpxgkB
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              29 Center Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/mfFlP16rRlbu0B9dJ4Fm
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              283 Wyoming Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/mLOSeWQPTDpzWkg05gXe
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              17 Skyline Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Colonia
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07067
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/N1XfI9rDWPHsKpgUSR6p
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              14 Ardsleigh Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/n2gzHncUmPE0OfZWLoxv
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              14 Rosedale Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/NGIaGDpkPtB6jDyi5TSf
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              17 Normandy Blvd E
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Nr7fE9JBcfmEQucg9IC4
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              27 Pine Grove Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/NuuHjD1AWzqDiUz3uveQ
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              7 Townsend Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Florham Park
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07932
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/nzuRKqyOHmiTL5ceMAt9
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              18 Noe Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/o03ZfOlK8WKEAhqq4Kd3
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              38 Walnut St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/oOIdWJYptZEyCfrub49K
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              9 Overhill Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/pdLfQsLAG9FqAXV92ur6
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              49 Fernwood Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/pMq3jN3Tku657YvW3a1I
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              13 Harvey Ct
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/pqiz9VY9siUDNG0rEJdV
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              9 Fairview Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/PsIIsfi0j7yThNXkXhm7
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              40 W Park Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              507
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Condo
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/QaAappOccHFd4hnMXaqG
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              20 Highland Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/qEBsuMJ2nbK8TebLEDPZ
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              196 Park Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Qw4he8kv4EJjQj0uy1RS
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              50 Knollwood Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/QZDBfgfRcGPv8GaBVvj1
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Cory Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/s1xkvJxBhW9d8ZoOCvbP
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              185 Ridgedale Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/TuJhTm8y1hzDi97hDyx2
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              24 Cross Gates Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/uvTILKcc2WxCI1gCjbaQ
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              23 Spring Garden Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/uZAWx6HTYqkSOXISNOA1
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              19 Lees Hill Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Basking Ridge
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07920
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/v0HvrKHaDu7heSgnIMpE
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              41 Broom Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Florham Park
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07932
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/vu1RF1yv0cj5gMYgt2jR
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              2 Loantaka Terrace
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/w31Gujbznsbdp7OeJJWp
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              251 Plymouth Ct
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/wjvr4NcqxY6O6dlim7lc
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              32 Hickory Dr
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Maplewood
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Wlg1AkLcAj6neX4SXEIP
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              178 James St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/wnSwXwBR45MazY4FociW
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              30 Station Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/WYNpKlIgrUJopsp3pG3l
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              22 Arcadia Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              B
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Vauxhall
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07088
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/WzW5wi4IQ0teMFL5apCx
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              195 Franklin St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/XDEyXw9amqsO5njofrrv
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              11 Poplar Ln
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Chatham Township
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07928
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Townhouse
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/XIsA04RjexBp4g6k4c88
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              100 Shunpike Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/XPj7LfYIfLvqYG2SE5hf
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              50 Sherman Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/xZ9HHf0hKw1DCU5dmlJ7
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              10 Montrose Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Y4p1DHNCoHFGtY9bh69O
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              85 Fairmount Ave
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/YdmBoeoHeerOEoJenFof
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              49 Tulip St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Summit
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07901
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/yfwhphLUMvNyhiwmb3qk
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              14 Drew Pl
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/YmZelOnYCCtWMsMP3XA1
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              52 46th St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Weehawken
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07086
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Multi-Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/Z0Ks9Z7Wc6STstAxNgYh
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              91 Brittin St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Madison
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07940
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ZBd1espzcNeT3JncP6jU
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              15 Prospect St
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Morristown
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NJ
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              07960
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              Apartment
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/ZHIPB8payQpuBRo3NIun
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>

          <tr class="bg-white odd:bg-indigo-50">
            <td
              class="border border-slate-300 px-2 py-1.5 text-sm lg:text-base"
            >
              1175 Old Ford Rd
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            ></td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              New Paltz
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              NY
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              12561
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              1 Family
            </td>
            <td
              class="border border-slate-300 px-2 py-1.5 text-center text-sm lg:text-base"
            >
              <a
                class="text-muted text-sm text-slate-700 underline visited:text-pink-900 hover:text-slate-500 lg:text-base"
                href="https://zagnetic.com/property/zQtXFvtq4vMtzMlboNEv
            "
                target="_parent"
                title="link to this property landing page"
                >Go<span
                  class="hidden text-sm underline lg:inline-block lg:text-base"
                  >&nbsp;to property</span
                ><fa :icon="['fas', 'arrow-alt-circle-right']" class="ml-1.5"
              /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </details>
    <!-- END table of all properties -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
