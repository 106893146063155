<template>
  <div>
    <!-- Below, each AskAnswerQuestionButtonVifTextAndIcon number(1, 2, 3, 4 etc.) corresponds to a different pop-up alert button and message (see "BEGIN v-if case 1, 2 or 3" a few dozen lines below) -->

    <!-- BEGIN case 1 (answer a Q&A question) -->
    <div v-if="AskAnswerQuestionButtonVifTextAndIcon == 1">
      <div @click="openModal" class="">
        <button class="-mr-3 text-xs sm:mr-0 sm:text-base">
          <fa :icon="['far', 'comment']" /><span class="ml-0.5 text-xs sm:ml-1"
            >Answer</span
          >
        </button>
      </div>
    </div>
    
    <!-- END case 1 (answer a Q&A question) -->

     <!-- BEGIN case 1.5 (be the first to answer a Q&A question) -->
    <!-- <div v-if="AskAnswerQuestionButtonVifTextAndIcon == 1.5">
      <div @click="openModal" class="">
        <button class="text-sm sm:text-base">
          <fa :icon="['far', 'comment']" /><span class="ml-0.5 text-sm sm:ml-1"
            >Can you answer this?</span>
        </button>
      </div>
    </div> -->
    <!-- END case 1 (answer a Q&A question) -->

    <!-- BEGIN case 2 (ask a Q&A question) -->
    <div v-else-if="AskAnswerQuestionButtonVifTextAndIcon == 2">
      <div @click="openModal">
        <span class="underline decoration-dotted">Ask a Question</span
        ><fa
          :icon="['fas', 'comments']"
          class="text-zagblue/70 animate-pulse-slow ml-2.5 text-lg hover:animate-none"
        />
      </div>
    </div>
    <!-- END case 2 (ask a Q&A question) -->

    <!-- BEGIN HeadlessUI Dialogue code -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-1 text-center sm:px-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="via ingigo-50 my-8 inline-block w-full max-w-[18rem] transform overflow-hidden rounded-2xl bg-gradient-to-br from-white to-slate-100 px-3 py-4 text-center align-middle shadow-xl ring-8 ring-slate-300 ring-opacity-80 ring-offset-8 ring-offset-slate-200 transition-all sm:max-w-sm sm:p-6 sm:px-6"
              >
                <!-- BEGIN case 1 (answer a Q&A question) -->
                <div v-if="AskAnswerQuestionButtonVifTextAndIcon == 1">
                  <DialogTitle
                    class="text-zagblue text-left text-lg font-medium sm:text-lg"
                    ><span class="mr-0.5 text-lg font-semibold underline"
                      >Question</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->
                  <div
                    class="row-span-1 -ml-1 inline-flex rounded-lg p-2.5 sm:-ml-2 md:-ml-8"
                  >
                    <!-- <p class="mr-2 ml-1 -mt-0.5 font-semibold sm:mr-3 text-zagblue">Q:</p> -->
                    <p
                      class="qatext text-zagblue ml-0 text-left !font-semibold !leading-normal md:ml-6"
                    >
                      {{ qText }}
                    </p>
                  </div>

                  <div class="my-3 text-left">
                    <p
                      class="text-zagblue mb-2 rounded-md p-1 text-xs font-medium italic"
                    >
                      <span class="mr-0.5 text-sm underline">Your answer</span>:
                    </p>
                    <form
                      class="bg-slate-50/5"
                      @submit.prevent="handleAnswerSubmit(userInfo)"
                    >
                      <textarea
                        required
                        class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
                        v-model="userAnswer"
                        placeholder="Be helpful & kind."
                        rows="5"
                      ></textarea>

                      <hr class="my-6" />

                      <!-- BEGIN "Any relationship?" as a selection -->
                      <!-- <p class="question mb-4 !font-semibold qatext !text-sm sm:text-base text-zagblue ml-0 text-left sm:ml-6 !leading-normal" -->
                      <p
                        class="qatext text-zagblue !md:ml-6 mb-6 ml-0 text-left !font-semibold !leading-normal"
                      >
                        Do you have any relationship with the seller or real
                        estate agent?
                      </p>
                      <div class="space-y-0">
                        <select
                          required
                          name="answererAnyRelWithSellerOrAgent"
                          id="answererAnyRelWithSellerOrAgent"
                          v-model="answererAnyRelWithSellerOrAgent"
                          class="text-zaggray rounded border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400"
                        >
                          <option value="">Please select</option>
                          <option value="none" class="bg-slate-50 my-2">
                            None
                          </option>
                          <option value="acquainted" class="">
                            Acquainted
                          </option>
                          <option value="solid" class="bg-slate-50 my-2">
                            Solid relationship
                          </option>
                          <option value="isSeller" class="">
                            I am the seller
                          </option>
                          <option value="isSellersAgent" class="bg-slate-50 my-2">
                            I am the seller's agent
                          </option>
                        </select>
                      </div>
                      <!-- END "Any relationship?" as a selection -->

                      <hr class="mt-8 mb-6" />

                      <div class="inline-flex mb-8">
                        <p class="question mt-0.5 !font-semibold">Privacy</p>

                        <div class="inline">
                          <span
                            class="text-zaggray pl-1 pr-0.5 text-sm font-normal"
                            >choice for your answer:</span
                          >
                        </div>
                      </div>

                      <div class="mb-10 -mt-5">
                        <div>
                          <input
                            type="radio"
                            id="postFullPublic"
                            value="postFullPublic"
                            name="reviewPrivacy"
                            required
                            v-model="answerPrivacy"
                          />
                          <label
                            class="ml-2.5 inline-flex align-bottom text-base sm:ml-4 sm:text-base"
                            for="postFullPublic"
                            ><fa
                              icon="user"
                              class="text-zaggray text-lg sm:text-xl"
                            />&nbsp;&nbsp;Public -<span
                              class="ml-1 pt-0.5 align-bottom text-sm italic"
                              >use real name
                            </span></label
                          >
                        </div>

                        <div class="mt-2.5">
                          <input
                            type="radio"
                            id="postAnonPublic"
                            value="postAnonPublic"
                            name="reviewPrivacy"
                            required
                            v-model="answerPrivacy"
                          />
                          <label
                            class="ml-2.5 inline-flex align-bottom text-base sm:ml-4 sm:text-base"
                            for="postAnonPublic"
                            ><fa
                              icon="user-secret"
                              class="text-zaggray text-lg sm:text-xl"
                            />&nbsp;&nbsp;Incognito -<span
                              class="ml-1 pt-0.5 align-bottom text-sm italic"
                              >use screen name
                            </span></label
                          >
                        </div>
                      </div>

                      <button
                        v-if="!isPending"
                        class="text-zaggray mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 1 (answer Q&A question) -->

                <!-- BEGIN v-if case 2 (ask a Q&A question) -->
                <div v-if="AskAnswerQuestionButtonVifTextAndIcon == 2">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-semibold sm:text-xl"
                    >Ask a Question:</DialogTitle
                  > 
                  <!-- was "Ask a Question about<br />{{ address }}:" -->

                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-4 sm:hidden">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->

                  <div class="my-5 text-left">
                    <form
                      @submit.prevent="handleQuestionSubmit(userInfo)"
                      class="bg-transparent"
                    >
                      <textarea
                        type="text"
                        class="focus:border-zagblue focus:ring-zagblue mb-6 w-full rounded-lg border-gray-300 md:w-full"
                        required
                        placeholder="Short and clear"
                        rows="5"
                        v-model="userQuestion"
                      ></textarea>
                      <hr class="my-6" />

                      <!-- BEGIN "Any relationship?" as a selection -->
                      <!-- <p class="question mb-4 !font-semibold qatext !text-sm sm:text-base text-zagblue ml-0 text-left sm:ml-6 !leading-normal" -->
                      <p
                        class="qatext text-zagblue !md:ml-6 mb-6 ml-0 text-left !font-semibold !leading-normal"
                      >
                        Do you have any relationship with the seller or real
                        estate agent?
                      </p>
                      <div class="space-y-0">
                        <select
                          required
                          name="answererAnyRelWithSellerOrAgent"
                          id="answererAnyRelWithSellerOrAgent"
                          v-model="answererAnyRelWithSellerOrAgent"
                          class="text-zaggray rounded border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400"
                        >
                          <option value="">Please select</option>
                          <option value="none" class="bg-slate-50">
                            None
                          </option>
                          <option value="acquainted" class="">
                            Acquainted
                          </option>
                          <option value="solid" class="bg-slate-50">
                            Solid relationship
                          </option>
                          <option value="isSeller" class="">
                            I am the owner/seller
                          </option>
                          <option value="isSellersAgent" class="bg-slate-50">
                            I am the seller's agent
                          </option>
                        </select>
                      </div>
                      <!-- END "Any relationship?" as a selection -->

                      <hr class="mt-8 mb-6" />

                      <div class="inline-flex mb-8">
                        <p class="question mt-0.5 !font-semibold">Privacy</p>

                        <div class="inline">
                          <span
                            class="text-zaggray pl-1 pr-0.5 text-sm font-normal"
                            >choice for your answer:</span
                          >
                        </div>
                      </div>

                      <div class="mb-10 -mt-5">
                        <div>
                          <input
                            type="radio"
                            id="postFullPublic"
                            value="postFullPublic"
                            name="reviewPrivacy"
                            required
                            v-model="answerPrivacy"
                          />
                          <label
                            class="ml-2.5 inline-flex align-bottom text-base sm:ml-4 sm:text-base"
                            for="postFullPublic"
                            ><fa
                              icon="user"
                              class="text-zaggray text-lg sm:text-xl"
                            />&nbsp;&nbsp;Public -<span
                              class="ml-1 pt-0.5 align-bottom text-sm italic"
                              >use real name
                            </span></label
                          >
                        </div>

                        <div class="mt-2.5">
                          <input
                            type="radio"
                            id="postAnonPublic"
                            value="postAnonPublic"
                            name="reviewPrivacy"
                            required
                            v-model="answerPrivacy"
                          />
                          <label
                            class="ml-2.5 inline-flex align-bottom text-base sm:ml-4 sm:text-base"
                            for="postAnonPublic"
                            ><fa
                              icon="user-secret"
                              class="text-zaggray text-lg sm:text-xl"
                            />&nbsp;&nbsp;Incognito -<span
                              class="ml-1 pt-0.5 align-bottom text-sm italic"
                              >use screen name
                            </span></label
                          >
                        </div>
                      </div>

                      <button
                        v-if="!isPending"
                        type="submit"
                        class="text-zaggray mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Submitting...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 2 (ask a Q&A question) -->

                <div class="float-right mt-1">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-sm font-normal text-gray-600 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    cancel
                    <fa
                      :icon="['far', 'times-circle']"
                      class="ml-1.5 text-xl"
                    />
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- END HeadlessUI Dialogue code -->
  </div>
</template>

<script>
import { ref } from "vue";
import useDocument from "@/composables/useDocument"; // for Ask a Question
import getUser from "@/composables/getUser"; // for Ask a Question
import { timestamp } from "@/firebase/config";
import { formatDistanceToNow } from "date-fns";

// import getDocumentNoListener from "@/composables/getDocumentNoListener";
import getDocument from "@/composables/getDocument";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  props: {
    AskAnswerQuestionButtonVifTextAndIcon: [Number, String],
    qIndex: {
      type: Number
    },
    qText: {
      type: String
    },
    qAndAArray: {
      type: Array
    },
    propertyId: {
      type: String
    },
    singleQandA: {
      type: Object
    },
    userInfo: [Object, null],
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },

  setup(props, context) {
    // console.log("propertyId", props.propertyId);
    const {error, updateQuizData, addQuestion, updateQAndAItems, addAnswer } = useDocument('properties', props.propertyId);
    const { user } = getUser();
    const isOpen = ref(false);
    const userInfo = ref(props.userInfo);
    
    
    const userQuestion = ref(""); // for Ask a Question
    const userAnswer = ref(""); // for Answer a Question
    const answererAnyRelWithSellerOrAgent = ref(""); // for Answer a Question
    const answerPrivacy = ref(""); // for Answer a Question
    
    const quizIndex = ref(props.qIndex);
    const oldQandA = ref(props.qAndAArray);
    const singleQandA = ref(props.singleQandA);
    
    const handleAnswerSubmit = async (userInfo1) => {
      
      const updatedData = {};
       const newAnswer = {
        answerText: userAnswer.value,
        answeredBy: user.value.displayName,
        userId: user.value.uid,
        isRealEstateAgent: userInfo1.isRealEstateAgent,  // Need to grab isRealEstateAgent value from database users > {user} and add that here
        isVerified: "", // was isVerified: true,
        createdAt: new Date(),
        upvoteCount: 0,
        flagCount: 0,
        privacy: answerPrivacy.value,
      };
      
      updatedData['answer'] = newAnswer;
      updatedData['quizIndex'] = quizIndex.value;
      
      const updateAnswer = await addAnswer(updatedData);
      
      if(!error.value) {
        // oldQandA.value = updatedQuizAndAns;
        // singleQandA.value = updatedSingleQandA;
        isOpen.value = false;
      }
    };
    // END handleAnswerSubmit

    // BEGIN handleQuestionSubmit
    const handleQuestionSubmit = async (userInfo1) => {
      
      const newQuestion = {
        answers: [],
        questionText: userQuestion.value,
        askedBy: user.value.displayName,
        userId: user.value.uid,
        isRealEstateAgent: userInfo1.isRealEstateAgent,  // Need to grab isRealEstateAgent value from database users > {user} and add that here
        isVerified: "", // was isVerified: true, 
        createdAt: new Date(),
        upvoteCount: 0,
        flagCount: 0,
        privacy: answerPrivacy.value,
      };
      
      const updateQuiz= await addQuestion(newQuestion);
      
      if(!error.value) {
        // oldQandA.value = updatedQandA;
        isOpen.value = false;
      }
    };
    // END handleQuestionSubmit
    return {
      userQuestion, // for Ask a Question
      userAnswer,
      answerPrivacy,
      answererAnyRelWithSellerOrAgent,
      handleAnswerSubmit,
      handleQuestionSubmit,
      formatDistanceToNow,
      
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>
