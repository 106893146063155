// v9 compat packages are API compatible with v8 code
import { getFirestore, updateDoc, serverTimestamp } from "firebase/firestore"; // ### before Firebase 8 to 9 refactoring, was: import 'firebase/compat/firestore';
import { initializeApp } from "firebase/app"; // ### compat mode was: import firebase from 'firebase/compat/app';
import { getAuth } from "firebase/auth"; // ### compat mode was: import 'firebase/compat/auth';
import { getStorage } from "firebase/storage"; // ### before Firebase 8 to 9 refactoring, was: import 'firebase/compat/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// from v8: import firebase from 'firebase/app'
// from v8:import 'firebase/firestore'
// from v8:import 'firebase/auth'
// from v8:import 'firebase/storage'

// BEGIN DEV config

// const firebaseConfig = {
//   apiKey: "AIzaSyCUEOZxs2U1ctWMdq9ZlGW9jEq6uyZbYJs",
//   authDomain: "zagnetic-dev-001.firebaseapp.com",
//   projectId: "zagnetic-dev-001",
//   storageBucket: "zagnetic-dev-001.appspot.com",
//   messagingSenderId: "1025723708519",
//   appId: "1:1025723708519:web:e314cb4ef020c3c21090c2",
// };

// END DEV config

// BEGIN PRODUCTION config

  const firebaseConfig = {
  apiKey: "AIzaSyC3DeXap63vN626x5bCNiziOjc8x0Udp2M",
  authDomain: "real-revs.firebaseapp.com",
  projectId: "real-revs",
  storageBucket: "real-revs.appspot.com",
  messagingSenderId: "321331515543",
  appId: "1:321331515543:web:5426323a0c1ad72872d9d2"
};

// END PRODUCTION config

// initialize firebase (connect to the back-end)
const firebaseApp = initializeApp(firebaseConfig); // ### compat mode was: firebase.initializeApp(firebaseConfig)

// Recaptcha app check added here
// BEGIN COMMENT-OUT ON LOCAL  (KEEP ACTIVE FOR LIVE/PRODUCTION BUILD)
// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider("6LcZudwfAAAAAF44uL0Jtr_4qR5zQHmJx6YHzXSe"), // << site key

//   isTokenAutoRefreshEnabled: true,
// });
// END COMMENT-OUT ON LOCAL (KEEP ACTIVE FOR LIVE/PRODUCTION BUILD)
// Recaptcha app check added here

// init services

// in Udemy NN tutorial on Firebase 9,  "projectFirestore" = "db" ---  "projectAuth" = "auth"
const projectFirestore = getFirestore(firebaseApp); // ### before Firebase 8 to 9 refactoring, was: const projectFirestore = firebase.firestore()
const projectAuth = getAuth(); // ### before Firebase 8 to 9 refactoring, was: const projectAuth = firebase.auth()
const projectStorage = getStorage(); // ### before Firebase 8 to 9 refactoring, was: const projectStorage = firebase.storage()

// timestamp (function which we invoke in order to create a timestamp)
const timestamp = serverTimestamp(); //firebase.firestore.FieldValue.serverTimestamp;
console.log("timestamp", serverTimestamp());

export { projectFirestore, projectAuth, projectStorage, timestamp };

// Google Maps API key AIzaSyDjwwk1WLPrGsZnwh0Vp4zNoSh15jjGljw     &key=YOUR_API_KEY

// const googleMapsConfig = {googleApiKey: "AIzaSyDjwwk1WLPrGsZnwh0Vp4zNoSh15jjGljw",};

// https://maps.googleapis.com/maps/api/place/autocomplete/json&libraries=places?country=us?parameters&key=googleApiKey
