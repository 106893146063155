<template>
  <div>
    <!-- Below, each whoopsButtonVifTextAndIcon number(1, 2, 3, 4 etc.) corresponds to a different pop-up alert button and message (see "BEGIN v-if case 1, 2 or 3" a few dozen lines below) -->
    <div v-if="whoopsButtonVifTextAndIcon == 1">
      <div @click="openModal" class="!text-sm py-1">  <!-- was class="text-sm" -->
        Write a Review<fa
          :icon="['fas', 'pencil-alt']"
          class="ml-2.5 text-sm"
        />
      </div>
    </div>
    <!-- on Property Landing page -->

    

    <!-- <div v-else-if="whoopsButtonVifTextAndIcon == 2">
      <div @click="openModal" class="text-sm">
        <span class="text-sm py-8"
                    >Write a review
                    <fa
                      :icon="['fas', 'pencil-alt']"
                      class="ml-2.5 text-xl opacity-75" /></span
                >
      </div>
    </div> -->

     <!-- BEGIN Zag Home "secret" review -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 2">
      <div @click="openModal" class="text-sm">
        <div class="text-sm py-2 px-1 font-medium"
                    >Write a <span class="text-sm underline underline-offset-[3px]"
                  >SECRET</span
                >
                review<fa :icon="['fas', 'ghost']" class="ml-2 text-sm" />
                    </div
                >
      </div>
    </div>
    <!-- END Zag Home "secret" review -->

    <!-- Top of Zag Home, next to prop search button -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 3">
      <!-- <div @click="openModal">
        Write a review
        <fa :icon="['fas', 'pencil-alt']" class="ml-2.5 text-xl opacity-75" />
      </div> -->
      <div @click="openModal" class="text-sm py-0">
        <span class="text-lg py-8"
                    >Write a review
                    <fa
                      :icon="['fas', 'pencil-alt']"
                      class="ml-2.5 text-xl opacity-75" /></span
                >
      </div>
    </div>
    <!-- Bottom of Zag Home, next to prop search button -->
    <div
      v-else-if="toggle45ten1125282930"
    >
      <div @click="openModal" class="">
        <sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zaggray mb-1 ml-0.5 hover:bg-slate-100 hover:rounded-full"
        /></sup>
      </div>
    </div>
   
    <!-- On ReviewDetails -->
     <div
      v-else-if="
        whoopsButtonVifTextAndIcon == 5.5
      "
    >
      <div @click="openModal" class="">
        <sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zagblue ml-0.5"
        /></sup>
      </div>
    </div>

    <!-- On EditReview -->
     <div
      v-else-if="
        whoopsButtonVifTextAndIcon == 5.8
      "
    >
      <div @click="openModal" class="">
        <sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="ml-1"
        /></sup>
      </div>
    </div>

    <!-- Privacy on CreateReview.vue -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 6">
      <div @click="openModal" class="">
        <sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zaggray ml-0.5 mb-0.5 text-right"
        /></sup>
      </div>
    </div>
    <!-- Privacy on CreateReview.vue -->
    <!-- BEGIN Q&A section's "Ask a question" if user is NOT logged-in -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 7">
      <div
        @click="openModal" class="animate-pulse-slow hover:animate-none underline decoration-slice"
       
      >
        Ask a Question<fa :icon="['fas', 'comments']" class="ml-2.5 text-lg" />
      </div>
    </div>
    <!-- END Q&A section's "Ask a question" if user is NOT logged-in -->

    <!-- BEGIN Q&A section's "Ask a question" if user is NOT logged-in -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 8.5">
      <div @click="openModal" class="">
        <button class="-mr-3 text-xs sm:mr-0 sm:text-base">
          <fa :icon="['far', 'comment']" /><span class="ml-0.5 text-xs sm:ml-1"
            >Answer</span
          >
        </button>
      </div>
    </div>
    <!-- END Q&A section's "Ask a question" if user is NOT logged-in -->

    <!-- BEGIN v-if case 16 ("Report abuse" button on ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 16">
      <div @click="openModal" class="">
        <span
          class="text-zaggray px-6 py-2.5 text-sm font-bold hover:text-white sm:mb-6"
          >Report Abuse</span
        >
      </div>
    </div>
    <!-- END v-if case 16 ("Report abuse" button on ReviewDetails") -->

    <!-- BEGIN v-if case 17 ("Write a review" right-column button of ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 17">
      <div @click="openModal" class="">
        <span class="text-base lg:text-[0.95rem] xl:text-lg"
                  >Write a review&nbsp;&nbsp;<fa icon="pencil-alt" /></span
              >
      </div>
    </div>
    <!-- END v-if case 17 ("Write a review" right-column button of ReviewDetails") -->

     <!-- BEGIN v-if case 18 ("Write a review" bottom ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 18">
      <div @click="openModal" class="">
        <span class="text-sm"
              ><fa icon="pencil-alt" /> &nbsp;Write a review</span
            >
      </div>
    </div>
    <!-- END v-if case 18 ("Write a review" bottom ReviewDetails") -->

    <!-- BEGIN v-if case 19 ("Bookmark this" bottom ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 19">
      <div @click="openModal" class="">
        <span class="text-sm"
              ><fa :icon="['far', 'bookmark']" /> &nbsp;Bookmark this</span
            >
      </div>
    </div>
    <!-- END v-if case 19 ("Bookmark this" bottom ReviewDetails") -->

    <!-- BEGIN v-if case 20 ("Bookmark this" bottom of right-column on ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 20">
      <div @click="openModal" class="">
        <span class="text-lg"
              ><fa :icon="['far', 'bookmark']" /> &nbsp;Bookmark this</span
            >
      </div>
    </div>
    <!-- END v-if case 20 ("Bookmark this" bottom of right-column on ReviewDetails") -->

     <!-- BEGIN v-if case 21 ("Bookmark this" top of right-column on ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 21">
      <div @click="openModal" class="">
        <span class=""
              >Bookmark this review&nbsp;&nbsp;<fa :icon="['far', 'bookmark']" /></span
            >
      </div>
    </div>
    <!-- END v-if case 21 ("Bookmark this" top of right-column on ReviewDetails") -->

    <!-- BEGIN v-if case 22 ("Follow this" top of right-column on ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 22">
      <div @click="openModal" class="">
        <span class=""
              >Follow this property&nbsp;&nbsp;<fa :icon="['far', 'bell']" /></span
            >
      </div>
    </div>
    <!-- END v-if case 22 ("Follow this" top of right-column on ReviewDetails") -->

    <!-- BEGIN v-if case 23 ("Helpful" on bottom of top of ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 23">
      <div @click="openModal" class="">
        <span class="text-sm"
              >Helpful</span
            >
      </div>
    </div>
    <!-- END v-if case 23 ("Helpful" on bottom of top of ReviewDetails") -->

    <!-- BEGIN v-if case 24 ("Reply" on bottom of top of ReviewDetails") -->
     <div v-else-if="whoopsButtonVifTextAndIcon == 24">
      <div @click="openModal" class="">
        <span class="text-sm"
              >Reply</span
            > 
      </div>
    </div>
    <!-- END v-if case 24 ("Reply" on bottom of top of ReviewDetails") -->

    <!-- BEGIN v-if case 25 ("upvote caret") -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 26">
      <div @click="openModal" class="">
        <button
            class="text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
          >
            <i class="fas fa-caret-up"></i>
          </button>
          </div>
    </div>
    <!-- END v-if case 25 ("upvote caret") -->

    <!-- BEGIN v-if case 26 ("downvote caret") -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 27">
      <div @click="openModal" class="">
        <button
            class="text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
          >
            <i class="fas fa-caret-down"></i>
          </button>
          </div>
    </div>
    <!-- END v-if case 26 ("downvote caret") -->



    <div v-else-if="whoopsButtonVifTextAndIcon == 9">
      <div @click="openModal" class="">
        <sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zaggray ml-0.5"
        /></sup>
      </div>
    </div>
     <!-- The seller's real estate agent represents the seller , not you. (question-circle) -->
    <div v-else-if="whoopsButtonVifTextAndIcon == 12">
      <div @click="openModal" class="">
        <sup
          ><fa
            :icon="['fas', 'question-circle']"
            size="lg"
            class="text-sm ml-1.5"
        /></sup>
      </div>
    </div>
     <!-- Bottom of Zag Home, next to prop search button -->
    <div
      v-else-if="
        whoopsButtonVifTextAndIcon == 13">
      <div @click="openModal" class="text-sm sm:text-base">Setting
        <sup><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zaggray ml-0"
        /></sup>
      </div>
    </div>
    <!-- Bottom of ReviewDetails, "setting" star rating -->
    <div
      v-else-if="
        whoopsButtonVifTextAndIcon == 14
      "
    >
      <div @click="openModal" class="text-sm sm:text-base text-right inline-flex"><sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zaggray ml-0.5"
        /></sup>
      </div>
    </div>
    <!-- Bottom of ReviewDetails, "setting" star rating -->
    <div
      v-else-if="
        whoopsButtonVifTextAndIcon == 15 
      "
    >
      <div @click="openModal" class="text-sm sm:text-base align-top pt-1.5 text-right inline-flex"><sup
          ><fa
            :icon="['far', 'question-circle']"
            size="sm"
            class="text-zaggray ml-0.5"
        /></sup>
      </div>
    </div>
    <!-- Create a review's NEVER visited the property -->

    <!-- BEGIN HeadlessUI Dialogue code -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="via-slate-50 my-8 inline-block w-full max-w-[18rem] transform overflow-hidden rounded-2xl bg-gradient-to-br from-white to-indigo-50 px-4 py-4 text-center align-middle shadow-xl ring-8 ring-slate-300 ring-opacity-80 ring-offset-8 ring-offset-slate-200 transition-all sm:max-w-sm sm:p-6 sm:px-6"
              >
                <!-- BEGIN v-if case 1, 2 3, 7 or 8.5 -->
                <div v-if="toggle1_2_3_7_8pt5_16_17_18_19_20_21_22_23_24_26_27">
                  <!-- <div v-if="
                    whoopsButtonVifTextAndIcon == 1 ||
                    whoopsButtonVifTextAndIcon == 2 ||
                    whoopsButtonVifTextAndIcon == 3 ||
                    whoopsButtonVifTextAndIcon == 7 ||
                    whoopsButtonVifTextAndIcon == 8.5
                  "
                > -->
                  <DialogTitle
                    as="h3"
                    class="text-zaggray text-center text-lg font-bold leading-6 sm:text-xl"
                    >Whoops!</DialogTitle
                  >

                  <div class="my-4">
                    <p class="text-zaggray text-sm font-medium sm:text-base">
                      You need to be logged-in to do that.
                    </p>
                  </div>

                  <div class="mt-8">
                    <p
                      class="text-zaggray text-sm font-medium leading-loose sm:text-base sm:leading-none"
                    >
                      Would you like to
                      <span
                        class="mx-0.5 rounded-md bg-slate-100 px-1.5 py-1 text-base ring-2 ring-slate-200 hover:bg-opacity-80 hover:ring-slate-300"
                        ><router-link
                          class="text-base font-semibold tracking-wide"
                          :to="{ name: 'Login', query: {'redirect_url': this.$route.path} }"
                          >Log in</router-link
                        ></span
                      >
                      or
                      <span
                        class="ml-0.5 mr-1 rounded-md bg-slate-100 px-1.5 py-1 text-sm ring-2 ring-slate-200 hover:bg-opacity-80 hover:ring-slate-300 sm:text-base"
                        ><router-link
                          class="text-sm font-semibold tracking-wide sm:text-base"
                          :to="{ name: 'Signup' }"
                          >Sign up</router-link
                        ></span
                      >?
                    </p>
                  </div>
                </div>
                <!-- END v-if case 1, 2, 3, 7 or 8.5 -->

                <!-- BEGIN v-if case 4 (privacy choices alert) -->
                <div v-if="whoopsButtonVifTextAndIcon == 4">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Privacy options for your review</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="fas fa-user mr-1.5 text-lg"></i>
                      <strong>Public</strong> &thinsp;means your full name is
                      displayed
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="fas fa-user-secret mr-1.5 text-lg"></i>
                      <strong>Incognito</strong> &thinsp;means your username is
                      displayed
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="fas fa-ghost mr-1.5 text-lg"></i>
                      <strong>Secret</strong> &thinsp;means your review is
                      only<span class="-mb-1.5 ml-0.5">*</span> visible to you
                      and people you share it with
                    </p>
                    <p class="text-sm italic sm:text-base">
                      * &thinsp;Secret reviews do not help other users, so by
                      default they become Incognito after 30 days.
                    </p>
                    <p class="text-sm italic sm:text-base">
                      We'll send notice before this happens, giving the option
                      to renew as Secret.
                    </p>
                    <p class="text-sm italic sm:text-base">
                      * &thinsp;We disclose if a reviewer is the seller or
                      seller's agent, so even if Secret or Incognito, users may
                      figure out who the reviewer is.
                    </p>
                    <p class="text-sm italic sm:text-base">
                      Please see our privacy policy for details.
                    </p>
                  </div>
                </div>
                <!-- END v-if case 4 (privacy choices alert) -->

                <!-- BEGIN v-if case 5 (relationship w/ realtor or seller) -->
                <div v-if="whoopsButtonVifTextAndIcon == 5">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Any relationship with the seller or real estate
                    agent?</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-meh-blank text-lg"></i>&nbsp;
                      <strong>None</strong> &thinsp;means no relationship with
                      either
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-meh text-lg"></i>&nbsp;
                      <strong>Acquainted</strong> &thinsp;means you and one or
                      both know one another, but aren't close
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-grin text-lg"></i>&nbsp;
                      <strong>Solid</strong> &thinsp;means a stronger or more
                      familiar relationship than above
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="fas fa-house-user text-lg"></i>&nbsp;
                      <strong>I am the owner&thinsp;/&thinsp;seller</strong>
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-clipboard text-lg"></i>&nbsp;
                      <strong>I am the owner&thinsp;/&thinsp;seller's agent</strong> &thinsp;means you
                      are the seller's real estate agent or that agent's
                      business affiliate, such as a manager, partner or employee
                    </p>
                    <p class="mt-4 text-sm italic sm:text-base">
                      These are imperfect categories.<br />Let your conscience
                      be your guide.
                    </p>
                  </div>
                </div>
                <!-- END v-if case 5 (relationship w/ realtor or seller) -->

                <!-- BEGIN v-if case 5.5 (relationship w/ realtor or seller) -->
                <div v-if="whoopsButtonVifTextAndIcon == 5.5">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Relationship with the seller or real estate
                    agent?</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-meh-blank text-lg"></i>&nbsp;
                      <strong>None</strong> &thinsp;means no relationship with
                      either
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-meh text-lg"></i>&nbsp;
                      <strong>Acquainted</strong> &thinsp;means reviewer and one or
                      both know one another, but aren't close
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-grin text-lg"></i>&nbsp;
                      <strong>Solid</strong> &thinsp;means a stronger or more
                      familiar relationship than above
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="fas fa-house-user text-lg"></i>&nbsp;
                      <strong>Reviewer is the seller</strong>
                    </p>
                    <p class="text-sm sm:text-base">
                      &nbsp;<i class="far fa-clipboard text-lg"></i>&nbsp;
                      <strong>Reviewer is the seller's agent</strong> &thinsp;means the reviewer is 
                      the seller's real estate agent or that agent's
                      business affiliate, such as a manager, partner or employee
                    </p>
                    <p class="mt-4 text-sm italic sm:text-base">
                      We know these categories are imperfect (just like the rest of the world).
                    </p>
                  </div>
                </div>
                <!-- END v-if case 5.5 (relationship w/ realtor or seller) -->

                <!-- BEGIN v-if case 5.8 (standard house question) -->
                <div v-if="whoopsButtonVifTextAndIcon == 5.8">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Why Can't The Address Be Edited&thinsp;?</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      We're really sorry about this.
                    </p>
                    <p class="text-sm sm:text-base">
                      The reason is that the way our database is structured, each address is its own separate 'document.'  
                    </p>
                    <p class="text-sm sm:text-base">
                      Each document stores lots of information, including text, images, other users' reviews, Q&A, geo-coordinates for maps, etc. 
                    </p>
                    <p class="text-sm sm:text-base">
                      Lots of that information is based on or 'keyed' to this address.
                    </p>
                     <p class="text-sm sm:text-base">
                      If the address for your review changed, some (but not all) of that other information would become inaccurate.
                    </p>
                    <p class="text-sm sm:text-base">
                      Thanks for your understanding.
                    </p>
                  </div>
                </div>
                <!-- END v-if case 5.8 (standard house question) -->

                <!-- BEGIN v-if case 6 (standard house question) -->
                <div v-if="whoopsButtonVifTextAndIcon == 6">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >'Standard' Questions</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      Each type of property has certain key information that
                      would help a potential buyer make a better decision.
                    </p>
                    <p class="text-sm sm:text-base">
                      <span class="zagnetic text-sm sm:text-base"
                        >zagnetic</span
                      >
                      requests that information through a "standard" set of
                      questions for each type of property.
                    </p>
                    <p class="text-sm sm:text-base">
                      When sellers, real estate agents and others answer these
                      questions upfront, they help buyers (and their agents)
                      avoid confusion & wasted time.
                    </p>
                  </div>
                </div>
                <!-- END v-if case 6 (standard house question) -->

                <!-- END Q&A section's "Ask a question" if user is NOT logged-in -->
                
                <!-- BEGIN v-if case 8 (Q&A section's "Ask a question" if user IS logged-in) >> MOVED this to composables/AskAnswerQuestion.vue -->
                <!-- <div v-if="whoopsButtonVifTextAndIcon == 8">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Ask a Question about<br />{{ address }}:</DialogTitle
                  >

                  <div class="my-5 text-left">
                    <form
                      @submit.prevent="handleQuestionSubmit"
                      class="bg-transparent"
                    >
                      <textarea
                        type="text"
                        class="focus:border-zagblue focus:ring-zagblue mb-8 w-full rounded-lg border-gray-300 md:w-full"
                        required
                        placeholder="Short and clear"
                        rows="3"
                        v-model="userQuestion"
                      ></textarea>

                      <button
                        v-if="!isPending"
                        class="text-zaggray rounded-md border border-slate-900 bg-white py-1 px-1.5 text-base hover:bg-gray-100"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Submitting...</button>
                    </form>
                  </div>
                </div> -->
                <!-- END v-if case 8 (Q&A section's "Ask a question" if user IS logged-in) >> MOVED this to composables/AskAnswerQuestion.vue -->

                <!-- BEGIN v-if case 9 (the "never visited" option when writing a review) -->
                <div v-if="whoopsButtonVifTextAndIcon == 9">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >How can a person review a property they've never been
                    to?</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      There are scenarios where this makes sense and the review
                      can be helpful:
                    </p>
                    <ul class="ml-2.5 list-outside list-disc">
                      <li class="text-sm sm:text-base">
                        Reviewer knows the neighborhood well, but not this
                        specific property
                      </li>
                      <li class="mt-4 text-sm sm:text-base">
                        Reviewer has only seen the exterior of the property
                      </li>
                      <li class="mt-4 text-sm sm:text-base">
                        Reviewer has insight from a zoning or planning board
                        application, etc.
                      </li>
                      <li class="mt-4 text-sm sm:text-base">
                        Review is based solely on photos, videos, maps, etc.
                        <sup>*</sup>
                      </li>
                    </ul>
                    <p class="text-sm italic">
                      <sup>*</sup> We look for patterns, generic wording,
                      extreme ratings, etc. to filter-out untrustworthy reviews
                    </p>
                    <p class="bg-yellow-100 rounded-lg p-1.5 text-sm font-medium sm:text-base">
                      NOTE: &nbsp;We clearly disclose when a review is from a
                      user who has never visited the property.
                    </p>
                  </div>
                </div>
                <!-- END v-if case 9 (the "never visited" option when writing a review) -->

                <!-- BEGIN v-if case 10 (WhichProperty.vue explaining "combined" ) -->
                <div v-if="whoopsButtonVifTextAndIcon == 10">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >What does “combined” mean?</DialogTitle
                  >

                  <div class="my-5 space-y-6 text-left">
                    <p class="text-sm sm:text-base">
                      Imagine a multi-family house, with 3 apartments.
                    </p>
                    <p class="text-sm sm:text-base">
                      If you're looking for a single unit that building (such as
                      “Unit A”), then you would
                      <span class="underline">not</span> select the "combined"
                      option.
                    </p>
                    <p class="text-sm sm:text-base">
                      However, if you're considering the entire 3-family
                      building (Units A, B and C), then that is a "combined"
                      scenario.
                    </p>
                    <p class="text-sm italic">
                      Commercial buildings that also have residential
                      apartments, such as a retail store with an apartment
                      upstairs, are considered "commercial" on
                      <span class="zagnetic">zagnetic</span>.
                    </p>
                    <p class="text-sm italic">
                      Multi-family & commercial buildings have different Q&A
                      questions than individual apartments, condos, etc.
                    </p>
                  </div>
                </div>
                <!-- END v-if case 10 (WhichProperty.vue explaining "combined" ) -->

                <!-- BEGIN v-if case 11 (PublicProfile.vue explaining "verified" ) -->
                <div v-if="whoopsButtonVifTextAndIcon == 11">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >What does “verified” mean?</DialogTitle
                  >

                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      <ul class="list-disc space-y-4">
                      <li>On or about the "date verified" date, an active real
                      estate license was confirmed via an online search of state
                      records</li>
                      <li>The agent's current employer/brokerge and contact
                      information agrees with the licensing board's records at
                      that time</li>
                      <li>A cursory search for the agent's name in the state's real
                      estate licensing disciplinary records for the past 5
                      years.</li>
                      </ul>
                    </p>
                    <p class="text-sm italic bg-white rounded-lg p-2 my-4">
                      For example, in New Jersey:
                       <ul class="mt-2 ml-2.5 list-disc list-inside">
                         <li><a href="https://www-dobi.state.nj.us/DOBI_LicSearch/recSearch.jsp" target="_blank" class="text-xs sm:text-sm underline hover:text-gray-400">license search</a></li>
                         <li><a href="https://www.state.nj.us/dobi/division_rec/recdiscp.htm" target="_blank" class="text-xs sm:text-sm underline hover:text-gray-400">disciplinary records search</a></li>
                       </ul>
                    </p>
                    <p class="font-semibold mb-2">Be aware that:</p>
                    <ul class="list-disc space-y-4">
                      <li class="text-sm">
                        we have not done an exhaustive search for all actual or alleged bad or
                        criminal behavior
                      </li>
                      <li class="text-sm">
                        it's possible there is an identity theft situation
                      </li>
                      <li class="text-sm">
                        the person's license and state disciplinary record were
                        in good standing at the time of verification, but that might
                        no longer be the case
                      </li>
                       <li class="text-sm">
                        we bear no responsibility whatsoever for any actual or alleged errors, omissions, etc.
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- END v-if case 11 (PublicProfile.vue explaining "verified" ) -->
                <!-- BEGIN v-if case 12 (The seller's real estate agent represents the seller , not you.) -->
                <div v-if="whoopsButtonVifTextAndIcon == 12">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Why one agent for both buyer and seller is not a good idea:</DialogTitle
                  >
                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      In two words: <span class="text-sm sm:text-base font-semibold">conflicted loyalty</span>
                    </p>
                    <p class="text-sm sm:text-base my-4">
                      How can one agent get the highest possible price for the seller, while also getting the lowest possible price for the buyer?
                    </p>
                    <p class="my-4 text-sm sm:text-base">
                      There's more to it, but trying to answer that simple question gets right to the heart of the problem.
                    </p>
                   <p class="my-4 text-sm sm:text-base">This is a very important transaction.</p>   
                   <p class="my-4 text-sm sm:text-base">Buyers and sellers <span class="italic text-sm sm:text-base">each</span> deserve someone dedicated solely to their best interests.</p>   
                   <p class="my-4 text-sm sm:text-base">The seller has already hired an agent who will loook out for <span class="italic text-sm sm:text-base">them</span>.</p>   
                   <p class="my-4 text-sm sm:text-base">When you're ready, make sure to do the same…</p>   
                  </div>
                </div>
                <!-- END v-if case 12 (The seller's real estate agent represents the seller , not you.) -->

                 <!-- BEGIN v-if case 13 ("setting" rating) -->
                <div v-if="whoopsButtonVifTextAndIcon == 13">
                  <DialogTitle
                    class="text-zaggray pb-2.5 text-center text-base font-bold sm:text-xl"
                    >What does "setting" mean?</DialogTitle
                  >
                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      It refers to the property's surroundings.
                    </p>
                     <p class="my-4 text-sm sm:text-base">Example: for a condo in a large city building, "setting" might refer to the floor it's on, the elevator & lobby, and the nearby street and properties.
                    </p>
              
                    <p class="text-zaggray pt-4 pb-2.5 text-base text-center font-bold sm:text-xl">Is this different than "location"?</p>
                   <p class="my-4 text-sm sm:text-base">Yes. Location refers to <span class="italic text-sm sm:text-base mr-0.5">where</span> a property is, while setting refers to what the immediate surroundings are like.</p>   
                   <p class="my-4 text-sm sm:text-base">Sometimes the location and setting can overlap, such as being 2 blocks away from a nice park or major highway.</p>  
                   <p class="my-4 text-sm sm:text-base">Just use your best judgement and feel free to add details in the "Anything else?" section. Thanks!</p>  
                    
                  </div>
                </div>
                <!-- END v-if case 13 ("setting" rating) -->

                 <!-- BEGIN v-if case 14 ("setting" rating) -->
                <div v-if="whoopsButtonVifTextAndIcon == 14">
                  <DialogTitle
                    class="text-zaggray pb-2.5 text-center text-base font-bold sm:text-xl"
                    >What does "setting" mean?</DialogTitle
                  >
                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      It refers to the property's surroundings.
                    </p>
                     <p class="my-4 text-sm sm:text-base">Example: for a condo in a large city building, "setting" might refer to the floor it's on, the elevator & lobby, and the nearby street and properties.
                    </p>
              
                    <p class="text-zaggray pt-4 pb-2.5 text-base text-center font-bold sm:text-xl">Is this different than "location"?</p>
                   <p class="my-4 text-sm sm:text-base">Yes. Location refers to <span class="italic text-sm sm:text-base mr-0.5">where</span> a property is, while setting refers to what the immediate surroundings are like.</p>   
                   <p class="my-4 text-sm sm:text-base">Sometimes the location and setting can overlap, such as being 2 blocks away from a nice park or major highway.</p>  
                   <p class="my-4 text-sm sm:text-base">Just use your best judgement and feel free to add details in the "Anything else?" section. Thanks!</p>  
                    
                  </div>
                </div>
                <!-- END v-if case 14 ("setting" rating) -->

                <!-- BEGIN v-if case 15 ($12,000 on PropertyLanding) -->
                <div v-if="whoopsButtonVifTextAndIcon == 15">
                  <DialogTitle
                    class="text-zaggray pb-2.5 text-center text-base font-semibold sm:text-lg"
                    >We asked reviewers:</DialogTitle
                  >
                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-lg italic">
                      Q: If you were going to live here and received a $12,000 gift card, good for any product or service, what would you repair, upgrade or add? Why?
                    </p>
                     <p class="my-4 text-sm sm:text-lg">Answers from the reviews voted "Most Helpful" are posted here.</p>  
                    
                  </div>
                </div>
                <!-- END v-if case 15 ($12,000 on PropertyLanding) -->


                <!-- BEGIN v-if case 25 (GetVerified.vue explaining "too large a market" ) -->
                <div v-if="whoopsButtonVifTextAndIcon == 25">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl"
                    >Don't “specialize” in a giant market</DialogTitle
                  >

                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      <ul class="list-disc space-y-4">
                      <li>"Too large" depends, based on geographic area and population</li>
                      <li>"New York City" is about 300 square miles, with a population above 8 million.</li>
                      <li>It's much too large for a person to specialize in, so pick a neighborhood (or even part of a neighborhood). </li>
                      <li>An exception is if you specialize in a very specific niche, within a huge or populous territory, such as horse ranches over 10,000 acreas in the Rocky Mountains, or brownstones and townhomes in Manhattan.</li>
                      <li>At the other extreme is Winnebago County in Iowa, which is 400 square miles, with a population of 11,000. It's certainly possible that an agent could specialize here. </li>
                      <li>Because there are more total people looking to buy homes in all of Chicago than in just the West Loop neighborhood, it might seem like saying you "specialize" in the entire Chicago market will bring you tons of opportunities.</li>
                      <li>In fact, the opposite is usually true. People will assume you don't have intimate knowledge about the specific neighborhood or street that interests them.</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <!-- END v-if case 25 (GetVerified.vue explaining "too large a market" ) -->

                <!-- BEGIN v-if case 28 (GetVerified.vue explaining "$5- financial institution verification" ) -->
                <div v-if="whoopsButtonVifTextAndIcon == 28">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl underline"
                    >Why $5- for Verification?</DialogTitle
                  >

                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      <ul class="space-y-4">
                      <li>Banks have lots of security measures to prevent identity theft & credit card fraud.</li>
                      <li>This tiny payment lets us piggy-back on all their security, <span class="font-semibold">blocking fraudsters & millions of spam-bots</span>. </li>
                      <li> This is part of why verified<sup
          >&thinsp;<i class="fas fa-check-circle text-zagblue"></i
        ></sup>
        real estate agents are
        <span class="font-semibold">more trustworthy</span> (and why our users
        are more likely to hire
        <span class="mr-0.5 font-semibold underline">YOU</span>).</li>
                      <li>&thinsp;<br /><span class="bg-indigo-100 p-1 rounded-lg italic mt-4">Don't worry: We don't see or store your bank or credit card info.</span></li>
                      </ul>
                    </p>
                  </div>
                </div>
                <!-- END v-if case 28 (GetVerified.vue explaining "$5- financial institution verification" ) -->

                <!-- BEGIN v-if case 29 (Account.vue explaining "User name & incognito" ) -->
                <div v-if="whoopsButtonVifTextAndIcon == 29">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl underline"
                    >What is User Name / Incognito&thinsp;?</DialogTitle
                  >

                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      <ul class="space-y-4">
                      <li>A user name or "display" name is your identity on <span class="zagnetic">zagnetic</span>, when you choose not to share your real identity and full name.</li>
                      <li>User names are unique.</li>
                      <li>A user name may or may not be your first name, or some other combination of characters. It's up to you!</li>
                      <li>Your user name is displayed around your reviews, questions & answers, when you choose "<fa
              icon="user-secret"
              class="text-zaggray text-xl mr-1.5"
            /><span class="font-semibold">Incognito</span>" privacy.</li>
                      <li>We work hard to protect your real identity when you choose "Incognoto" privacy, but our systems are not perfect or immune to malicious hackers.</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <!-- END v-if case 29 (Account.vue explaining "User name & incognito" ) -->

                <!-- BEGIN v-if case 30 (Account.vue explaining "expertise" in a market ) -->
                <div v-if="whoopsButtonVifTextAndIcon == 30">
                  <DialogTitle
                    class="text-zaggray pb-4 text-center text-lg font-bold sm:text-xl underline"
                    >What does this mean&thinsp;?</DialogTitle
                  >

                  <div class="my-5 text-left">
                    <p class="text-sm sm:text-base">
                      <ul class="space-y-4">
                      <li>As part of our verification process, we ask agents to identify one market where they have real esate expertise.</li>
                      <li class="italic text-sm">"Expertise" is a subjective term and we don't guarantee anyone's honesty, experience or qualifications.</li>
                      <li>This person may also have expertise in other markets as well.</li>
                      <li><span class="zagnetic">zagnetic</span> makes a good faith effort to confirm that this person represented a buyer/seller, in a specific transaction, in the above market, within the 30 months prior to verification.</li>
                      <li>IMPORTANT: Beyond this, <span class="zagnetic">zagnetic</span> does not represent, warranty, or have any basis to determine whether this person does or does not have real estate expertise in the above market, or any other market.</li>
                      <li>Please do not rely soley on our verification, which may be inaccurate or dated. Make sure to do your own diligence.</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <!-- END v-if case 30 (Account.vue explaining "expertise" in a market ) -->


                <div class="float-right mt-4">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-sm font-normal text-gray-600 hover:ring-gray-200 hover:bg-slate-900 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    close <fa
                      :icon="['far', 'times-circle']"
                      class="ml-1.5 text-xl"
                    />
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- END HeadlessUI Dialogue code -->
  </div>
</template>

<script>
import { ref, computed } from "vue";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  props: ["whoopsButtonVifText", "whoopsButtonVifTextAndIcon"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },

  setup(props,context) {
    const isOpen = ref(false);
    const whoopsButtonVifTextAndIcon = props.whoopsButtonVifTextAndIcon;

    const toggle1_2_3_7_8pt5_16_17_18_19_20_21_22_23_24_26_27 = computed(() => {
        if (whoopsButtonVifTextAndIcon == 1 ||
            whoopsButtonVifTextAndIcon == 2 ||
            whoopsButtonVifTextAndIcon == 3 ||
            whoopsButtonVifTextAndIcon == 7 ||
            whoopsButtonVifTextAndIcon == 8.5 ||
            whoopsButtonVifTextAndIcon == 16 ||
            whoopsButtonVifTextAndIcon == 17 ||
            whoopsButtonVifTextAndIcon == 18 ||
            whoopsButtonVifTextAndIcon == 19 ||
            whoopsButtonVifTextAndIcon == 20 ||
            whoopsButtonVifTextAndIcon == 21 ||
            whoopsButtonVifTextAndIcon == 22 ||
            whoopsButtonVifTextAndIcon == 23 ||
            whoopsButtonVifTextAndIcon == 24 ||
            whoopsButtonVifTextAndIcon == 26 ||
            whoopsButtonVifTextAndIcon == 27 ){
          return true;  
        } else {
          return false;
        }
      });
    
    const toggle45ten1125282930 = computed(() => {  
      if (whoopsButtonVifTextAndIcon == 4 ||
          whoopsButtonVifTextAndIcon == 5 ||
          whoopsButtonVifTextAndIcon == 10 ||
          whoopsButtonVifTextAndIcon == 11 ||
          whoopsButtonVifTextAndIcon == 25 ||
          whoopsButtonVifTextAndIcon == 28 ||
          whoopsButtonVifTextAndIcon == 29 ||
          whoopsButtonVifTextAndIcon == 30 ){
          return true;
      } else { 
        return false; 
      }
    });
    
    
    
   

    return {

      toggle1_2_3_7_8pt5_16_17_18_19_20_21_22_23_24_26_27, 
      toggle45ten1125282930,
     
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

<!-- Wherever this component is being used:

Wherever being used in the template:   <Whoops />

import getUser from '@/composables/getUser'
import Whoops from '@/components/Whoops.vue'

export default {
  components: {
    Whoops
  },

  setup() {
    const { user } = getUser()

    return {
      user,
    }
  },

}

-->
