import { ref } from "vue";
import { projectAuth } from "../firebase/config";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth"; // ### new for Firebase 8 to 9 refactoring

const error = ref(null);
const isPending = ref(false);
const signupUser = ref({});

const signup = async (email, password, displayName) => {
  error.value = null;
  isPending.value = true;

  try {
    const res = await createUserWithEmailAndPassword(
      projectAuth,
      email,
      password
    ).then( (userCredential) => {
      updateProfile(userCredential.user, {
        displayName: displayName,
      })
      signupUser.value = userCredential.user;
      sendEmailVerification(userCredential.user)
    }); // ### before Firebase 8 to 9 refactoring, was: const res = await projectAuth.createUserWithEmailAndPassword(email, password)
    // if (!res) {
    //   throw new Error("Could not complete signup");
    // }
    // await updateProfile(res.user, {
    //   displayName,
    // }); // ### before Firebase 8 to 9 refactoring, was: await res.user.updateProfile({ displayName })
    error.value = null;
    isPending.value = false;

    // return res;
  } catch (err) {
    console.log(err.message);
    error.value = err.message;
    isPending.value = false;
  }
};

const useSignup = () => {
  return { error, signup, isPending, signupUser };
};

export default useSignup;
