<template>
  <div
    class="mx-auto max-w-screen-sm rounded-lg bg-indigo-50 px-0 pt-4 pb-4 shadow-md ring-8 ring-indigo-300 ring-opacity-80 ring-offset-8 ring-offset-indigo-200 sm:px-0 md:px-4"
  >
    <form class="bg-indigo-50" @submit.prevent="handleSubmit">
      <div class="ml-[14%] sm:ml-[33%]">
        <h3 class="my-8 inline-flex text-center font-medium">
          Log in to
          <span class="zagnetic"
            ><img src="@/assets/zagnetic-logo.svg" class="max-h-14 px-1"
          /></span>
        </h3>
      </div>

      <div class="relative mx-auto my-8 text-center">
        <!-- BEGIN modified version -->
        <input
          class="peer peer w-full appearance-none rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:border-slate-600 focus:placeholder-transparent focus:outline-none focus:ring-0 sm:w-2/3"
          type="email"
          placeholder="Email"
          id="email"
          v-model.trim="email"
        />
        <label
          for="email"
          class="text-zaggray absolute top-2 left-1 z-10 origin-[0] -translate-y-8 scale-75 transform px-2 text-sm transition-all duration-500 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:top-2 peer-focus:-translate-y-7 peer-focus:scale-90 peer-focus:rounded-md peer-focus:bg-white/50 peer-focus:px-2 peer-focus:text-sm peer-focus:text-indigo-900 sm:left-24"
          >Email</label
        >
        <!-- END modified version -->

        <!-- BEGIN Flowbite floating label https://flowbite.com/docs/forms/floating-label/ -->
        <!-- <div class="relative">
          <input
            type="text"
            id="floating_outlined"
            class="border-1 peer block w-full appearance-none rounded-lg border-gray-300 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
            placeholder=" "
          />
          <label
            for="floating_outlined"
            class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 dark:bg-gray-800 dark:text-gray-400 peer-focus:dark:text-blue-500"
            >Floating outlined</label
          >
        </div> -->
        <!-- END Flowbite floating label https://flowbite.com/docs/forms/floating-label/ -->
      </div>
      <div class="relative mx-auto my-8 text-center">
        <input
          class="peer peer w-full appearance-none rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:border-slate-600 focus:placeholder-transparent focus:outline-none focus:ring-0 sm:w-2/3"
          type="password"
          placeholder="Password"
          id="password"
          v-model.trim="password"
        />
        <label
          for="password"
          class="text-zaggray absolute top-2 left-1 z-10 origin-[0] -translate-y-8 scale-75 transform px-2 text-sm transition-all duration-500 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:top-2 peer-focus:-translate-y-7 peer-focus:scale-90 peer-focus:rounded-md peer-focus:bg-white/50 peer-focus:px-2 peer-focus:text-sm peer-focus:text-indigo-900 sm:left-24"
          >Password</label
        >
      </div>
      <div v-if="error" class="error">{{ error }}</div>
      <div v-else class="flex justify-center pt-0 pb-4">
        <button v-if="!isPending" class="borderlessbtn w-50% mx-auto">
          Log in
        </button>
        <button v-if="isPending" class="borderlessbtn w-50% mx-auto" disabled>
          <!-- Loading -->
          <WaitingToLoad />
        </button>
      </div>
    </form>

    <div class="my-8 mx-auto text-center text-sm text-gray-500 sm:text-base">
      <span class="mr-3 text-sm font-medium sm:text-base">No account?</span
      ><span class="text-sm underline sm:text-base"
        ><router-link class="text-sm sm:text-base" :to="{ name: 'Signup' }"
          >Sign up</router-link
        ></span
      >
      in 1 minute
    </div>

    <!-- <img src="@/assets/images/rounded-square-logo.svg" alt="Zagnetic rounded square logo" class="mx-auto w-1/3 md:w-1/4 py-6"> -->
  </div>
</template>

<script>
// using @ means start at the project src root
import useLogin from "@/composables/useLogin";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import getUser from "@/composables/getUser";
import WaitingToLoad from "@/components/WaitingToLoad.vue";

export default {
  components: {
    WaitingToLoad,
     },

  setup(props, context) {
    const { error, login, isPending } = useLogin();
    const router = useRouter();
    const route = useRoute();
    const { user } = getUser();

    const email = ref("");
    const password = ref("");
    
    if(user.value !== null && typeof user.value.uid !== 'undefined') {
      router.push({ name: "Home" });
    }
    
    

    const handleSubmit = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        if(route.query.redirect_url) {
          router.push({ path: route.query.redirect_url });
        } else {
          router.push({ name: "Home" });
        }
      }
    };

    return { email, password, handleSubmit, error, isPending };
  },
  mounted() {
    if(this.$route.query.redirect_url) {
      this.destUrl = this.$route.query.redirect_url;
    }
  }
};
</script>
