<template>
  <div
    class="mx-auto max-w-screen-lg scroll-smooth rounded-lg bg-white sm:bg-gray-50 2xl:bg-opacity-30"
    v-if="property"
  >
    <!-- <div class="error" v-if="error">{{ error }}</div> -->
    <h1
      class="text-zaggray text-center text-3xl font-bold"
      v-if="property.address"
    >
      {{ property.address
      }}<!-- {{ property.street_number }}&nbsp;{{ property.route}} -->
      <span v-if="property.extended_address"
        >&nbsp;&nbsp;&thinsp;{{ property.extended_address }}</span
      >
    </h1>
    <!-- <h1
      class="text-zaggray text-center text-lg font-semibold"
      v-if="property && property.locality"
    >
      {{ property.locality }},&nbsp;{{
        property.administrative_area_level_1
      }}&nbsp;{{ property.postal_code }}
    </h1> -->
    <!-- BEGIN display property type-->
    <div class="mt-2.5 text-center uppercase">
      <div v-if="property.property_type === '1_family'">
        <p class="text-base sm:text-lg">Single family</p>
      </div>
      <div v-else-if="property.property_type === 'condo'">
        <p class="text-base sm:text-lg">Condominium</p>
      </div>
      <div v-else-if="property.property_type === 'townhouse'">
        <p class="text-base sm:text-lg">Townhouse</p>
      </div>
      <div v-else-if="property.property_type === 'coop'">
        <p class="text-base sm:text-lg">Cooperative</p>
      </div>
      <div v-else-if="property.property_type === 'mobilehome'">
        <p class="text-base sm:text-lg">Mobile home</p>
      </div>
      <!-- was "Mobile home, yurt, etc." -->
      <div v-else-if="property.property_type === 'multi_family'">
        <p class="text-base sm:text-lg">Multi-family (whole building)</p>
      </div>
      <div v-else-if="property.property_type === 'commercial'">
        <p class="text-base sm:text-lg">Commercial</p>
      </div>
      <div v-else-if="property.property_type === 'rentalapartment'">
        <p class="text-base sm:text-lg">Apartment for rent</p>
      </div>
      <div v-else><p class="text-base sm:text-lg">Raw land</p></div>
    </div>

    <p
      class="text-zaggray my-2.5 text-center text-sm font-light italic text-opacity-80 hidden sm:block"
    >
      Latitude&thinsp;: {{ property.latNumber }}&thinsp;,
      &nbsp;Longitude&thinsp;:
      {{ property.lngNumber }}
    </p>

    <!--BEGIN v-if there are ZERO reviews -->
    <div v-if="property.numberOfReviews == 0">
      <div class="">
        <p class="mx-auto my-8 text-center text-base font-medium text-red-500">
          Sorry, <span class="text-base underline">no</span> reviews for this
          property yet.
        </p>
        <p class="mx-auto my-8 text-center text-base font-medium">
          View this property's
          <a href="#floodandwildfire"
            ><span class="font-semibold underline hover:text-gray-500"
              >Flood & Wildfire Risk</span
            ></a
          >
        </p>

        <!-- BEGIN 3+ buttons across -->
        <div class="my-6 grid grid-cols-6 gap-4 text-center lg:gap-8">
          <div class="propLandingCard">
            <p class="text-base font-semibold">Get Notified</p>
            <p class="my-1.5 text-sm font-light leading-4">
              about <span class="text-sm underline">new</span> reviews and
              Q&thinsp;&#38;&thinsp;A&thinsp;:
            </p>
            <button class="proplandingbtn">
              Get Notified&nbsp;&nbsp;<fa :icon="['far', 'bell']" />
            </button>
          </div>

          <div class="propLandingCard z-10">
            <p class="text-base font-semibold">
              <span class="text-zagblue"
                >Q<code class="-px-1.5 mx-0.5 text-sm">&</code>A</span
              >
              about this property
            </p>

            <p v-if="property.qAnda" class="text-sm font-light">
              <span class="bg-highlight rounded-lg px-0.5 text-sm font-light">{{
                property.qAnda.length
              }}</span>
              Questions &thinsp;+&thinsp;
              <span class="bg-highlight rounded-lg px-0.5 text-sm font-light">{{
                numberOfAnswers
              }}</span>
              Answer<span v-if="numberOfAnswers != 1" class="text-sm font-light"
                >s</span
              >
            </p>

            <a href="#top-of-qna-section" class="scroll-smooth"
              ><button class="proplandingbtn">
                Go to Q&A&nbsp;&nbsp;<fa
                  :icon="['fas', 'arrow-alt-circle-down']"
                  class=""
                /></button
            ></a>
          </div>

          <!-- <div class="propLandingCard">
            <p class="font-semibold text-base">Is this your home?</p>
            <span class="text-sm font-light leading-4">owner's Q&A answers get priority</span>    
            <button class="proplandingbtn">I'm the owner&nbsp;&nbsp;<fa :icon="['far', 'plus-square']" /></button>
          </div> -->

          <div class="propLandingCard">
            <p class="text-base font-semibold">
              Write your
              <span class="text-zagblue underline">own</span> review
            </p>
            <span class="text-sm font-light leading-4"
              >text, images & star ratings</span
            >
            <button class="proplandingbtn" @click="writeReview">
              <div v-if="user">
                <span class="text-sm"
                  >Review this Property&nbsp;&nbsp;<fa
                    :icon="['fas', 'pencil-alt']"
                /></span>
              </div>
              <div v-else><Whoops whoopsButtonVifTextAndIcon="1" /></div>
            </button>
          </div>
        </div>
        <!-- END 3+ buttons across -->
        <hr class="my-6" />
      </div>
    </div>

    <!--END v-if there are ZERO reviews -->

    <!--BEGIN v-if there are 1 or more reviews -->
    <div v-else>
      <div class="my-6 grid grid-cols-6 gap-4 text-center lg:gap-8">
        <div class="propLandingCard !important lg:col-span-3">
          <p
            v-if="property.numberOfReviews == 1"
            class="text-base font-semibold"
          >
            <span
              class="bg-highlight rounded-lg px-0.5 text-base font-semibold"
              >{{ property.numberOfReviews }}</span
            >
            Review
          </p>
          <p v-else class="text-base font-semibold">
            <span
              class="bg-highlight rounded-lg px-0.5 text-base font-semibold"
              >{{ property.numberOfReviews }}</span
            >
            Reviews
          </p>
          <span class="text-sm font-light italic"
            >star ratings, pros/cons, photos, etc.</span
          >
          <a href="#rrreviews"
            ><button class="proplandingbtn">
              <span class="text-zagblue text-lg font-semibold underline"
                >Reviews</span
              >
              &nbsp;<fa
                :icon="['fas', 'arrow-alt-circle-down']"
                class="text-zagblue"
              /></button
          ></a>
        </div>

        <div class="propLandingCard !important lg:col-span-3">
          <p class="text-base font-semibold" v-if="property.qAnda">
            <span
              class="bg-highlight rounded-lg px-0.5 text-base font-semibold"
              >{{ property.qAnda.length }}</span
            >
            Questions &thinsp;+&thinsp;
            <span
              class="bg-highlight rounded-lg px-0.5 text-base font-semibold"
              >{{ numberOfAnswers }}</span
            >
            Answer<span
              v-if="numberOfAnswers != 1"
              class="text-base font-semibold"
              >s</span
            >
          </p>

          <span class="text-sm font-light italic"
            >about this property (Q&thinsp;&#38;&thinsp;A)</span
          >

          <a href="#top-of-qna-section"
            ><button class="proplandingbtn">
              <span class="text-zagblue text-lg font-semibold underline"
                >Q&thinsp;</span
              ><span class="text-zagblue text-base font-semibold underline"
                >&#38;&thinsp;</span
              ><span class="text-zagblue text-lg font-semibold underline"
                >A</span
              >
              &nbsp;<fa
                :icon="['fas', 'arrow-alt-circle-down']"
                class="text-zagblue"
              /></button
          ></a>
        </div>
      </div>
    </div>
    <!--END v-if there are 1 or more reviews -->

    <div class="text-gray-600">
      <!-- BEGIN of landing page content above Q&A -->

      <div class="mt-8">
        <div class="mx-auto">
          <GoogleMap
            mapStyle="1"
            :latNumber="parseFloat(property.latNumber)"
            :lngNumber="parseFloat(property.lngNumber)"
          />
        </div>

        <!-- <hr class="mb-96 pt-8 opacity-0" /> -->

        <!-- <img src="https://maps.googleapis.com/maps/api/staticmap?center=40.796160,-74.480540&zoom=10&scale=2&size=900x350&maptype=roadmap&markers=size:mid%color=0xFF00D9FF%7Clabel:%7C40.796162%2C-74.480537&key=AIzaSyCUEOZxs2U1ctWMdq9ZlGW9jEq6uyZbYJs" alt="zoomed out roadmap of address" class="rounded-lg border border-gray-300 w-full mx-auto shadow-md"> -->

        <div
          class="my-6 mx-auto grid w-[96%] grid-cols-12 gap-4 rounded-lg border-2 border-indigo-100 border-opacity-50 bg-white px-12 py-2.5 text-center drop-shadow-lg hover:border-none sm:my-4 sm:w-full sm:gap-2.5 sm:border-0 sm:bg-transparent sm:px-0 sm:drop-shadow-none md:col-span-3 md:w-full md:px-0 lg:gap-8 lg:px-0"
        >
          <div class="col-span-12 sm:col-span-4">
            <!-- <p class="text-base font-semibold">Get Alerts</p>
            <span class="text-sm font-light leading-4"
              >about new reviews and Q&thinsp;&#38;&thinsp;A&thinsp;:</span
            > -->
            <a
              href="https://forms.gle/6VTm9ZtirwtkFhpF9"
              target="_blank"
              title="get custom notifications"
            ><button
              class="proplandingbtn !py-2 shadow-indigo-100/30 drop-shadow-md"
            >
              Follow this property&nbsp;&nbsp;<fa :icon="['far', 'bell']" />
            </button></a>
          </div>

          <div class="z-10 col-span-12 sm:col-span-4">
            <!-- <p class="text-base font-semibold">Share this page</p>
            <span class="text-sm font-light leading-4"
              >or embed it on your website</span
            > -->
            <button
              class="proplandingbtn isolate z-30 py-2 shadow-indigo-100/30 drop-shadow-md hover:mb-32 focus:mb-20 active:z-50 active:mb-20"
            >
              <ShareButtonDD
                shareButtonDDStyle="1.1"
                :urlToShare="$route.fullPath"
              />
            </button>
            <!-- <ShareButtonDD
              shareButtonDDStyle="1.1"
              v-if="$route.params.id"
              :urlToShare="$route.params.id"
            /> -->
          </div>

          <!-- <div class="">
            <p class="font-semibold text-base">Is this your home?</p>
            <span class="text-sm font-light leading-4">owner's Q&A answers get priority</span>    
            <button class="proplandingbtn">I'm the owner&nbsp;&nbsp;<fa :icon="['far', 'plus-square']" /></button>
          </div> -->

          <!-- BEGIN "write a review" card / button -->
          <div v-if="user" class="col-span-12 sm:col-span-4">
            <!-- <p class="text-base font-semibold">
              What do
              <span class="text-zagblue underline">you</span> think&thinsp;?
            </p>
            <span class="text-sm font-light leading-4"
              >text, images & star ratings</span
            > -->
            <button
              class="proplandingbtn shadow-indigo-100/30 drop-shadow-md"
              @click="writeReview"
            >
              <div>
                <span class="text-sm"
                  >Write a review&nbsp;&nbsp;<fa :icon="['fas', 'pencil-alt']"
                /></span>
              </div>
            </button>
          </div>

          <div v-else class="col-span-12 sm:col-span-4">
            <!-- <p class="text-base font-semibold">
              What do
              <span class="text-zagblue underline">you</span> think&thinsp;?
            </p>
            <span class="text-sm font-light leading-4"
              >text, images & star ratings</span
            > -->
            <button
              class="proplandingbtn py-2 shadow-indigo-100/30 drop-shadow-md"
            >
              <Whoops whoopsButtonVifTextAndIcon="1" />
            </button>
          </div>
          <!-- END "write a review" card / button -->
        </div>
      </div>

      <!--BEGIN "Reviews" card v-if there are ZERO reviews -->
      <div v-if="property.numberOfReviews == 0">
        <div class="propLandingCard z-0 mb-8 scroll-mt-12" id="rrreviews">
          <div class="headinggg">Reviews for this property</div>
          <p class="mt-2.5 ml-0.5 mb-2 text-sm font-semibold">sort by:</p>
          <div
            class="mt-2.5 rounded-md pt-2.5 pb-6 text-center text-base italic"
          >
            - No review data for this property yet -
          </div>
          <!-- END sample reviews and Q&A -->
        </div>
      </div>
      <!--END "Reviews" card v-if there are ZERO reviews -->

      <!--BEGIN v-if there are 1 or more reviews -->
      <div v-else>
        <div class="propLandingCard z-0 scroll-mt-12" id="rrreviews">
          <div v-if="property.numberOfReviews == 1" class="headinggg">
            {{ property.numberOfReviews }} Review for this property
          </div>
          <div v-else class="headinggg">
            {{ property.numberOfReviews }} Reviews for this property
          </div>

          <!-- BEGIN heading text + row with buttons for sorting reviews (commented-out for now, but will add eventually) -->
          <!-- <p class="mt-2.5 ml-0.5 mb-2 text-sm font-semibold">sort by:</p>
          <div class="row divide-x divide-gray-300 lg:flex">
            <button
              class="flex-initial rounded-t-md border-b px-4 text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-600 hover:underline"
            >
              Most Helpful
            </button>
            <button
              class="flex-initial rounded-t-md border-b px-4 text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-600 hover:underline"
            >
              Newest
            </button>
            <button
              class="flex-initial rounded-t-md border-b px-4 text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-600 hover:underline"
            >
              Oldest
            </button>
            <button
              class="flex-initial rounded-t-md border-b px-4 text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-600 hover:underline"
            >
              With photos
            </button>
            <button
              class="flex-initial rounded-t-md border-b px-4 text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-600 hover:underline"
            >
              By real estate pros
            </button>
          </div> -->
          <!-- END heading text + row with buttons for sorting reviews (commented-out for now, but will add eventually) -->

          <!-- BEGIN if either 1 or 2 reviews, then grid-cols-2 -->
          <div
            v-if="property.numberOfReviews <= 2"
            class="mx-auto mt-2.5 grid grid-cols-12 justify-between gap-6 rounded-md pt-2.5 pb-6"
          >
            <!-- BEGIN v-for, display a  <SmallIndivReviewCard/> with different info, for each review of that property -->
            <div
              v-for="review in property.allReviews"
              :key="review"
              class="col-span-12 sm:col-span-6 lg:col-span-4"
            >
              <router-link
                :to="{
                  name: 'ReviewDetails',
                  params: { id: review.review_id },
                }"
              >
                <SmallIndivReviewCard
                  v-if="$route.params.id && review"
                  :propertyId="$route.params.id"
                  :reviewData="review"
                />
              </router-link>
            </div>
            <!-- END v-for, display a  <SmallIndivReviewCard/> with different info, for each review of that property -->
          </div>
          <!-- END if either 1 or 2 reviews, then grid-cols-2 -->

          <!-- BEGIN if either 3+ reviews, then grid-cols-3 -->
          <div
            v-else
            class="mx-auto mt-2.5 grid grid-cols-12 justify-between gap-6 rounded-md pt-2.5 pb-6"
          >
            <!-- BEGIN v-for, display a  <SmallIndivReviewCard/> with different info, for each review of that property -->
            <div
              v-for="review in property.allReviews"
              :key="review"
              class="col-span-12 sm:col-span-6 lg:col-span-4"
            >
              <router-link
                :to="{
                  name: 'ReviewDetails',
                  params: { id: review.review_id },
                }"
              >
                <SmallIndivReviewCard
                  v-if="$route.params.id && review"
                  :propertyId="$route.params.id"
                  :reviewData="review"
                />
              </router-link>
            </div>
            <!-- END v-for, display a  <SmallIndivReviewCard/> with different info, for each review of that property -->
          </div>
          <!-- END if either 3+ reviews, then grid-cols-3 -->
          <!-- END sample reviews and Q&A -->
        </div>
      </div>
      <!--END v-if there are 1 or more reviews -->

      <!-- BEGIN pro / con grid -->
      <div class="my-8 grid grid-cols-2 gap-8">
        <div class="propLandingCard col-span-2 mx-auto md:col-span-1">
          <div class="headinggg">Pros</div>
          <p class="-mt-1.5 text-sm italic">
            Top pros - from {{ property.numberOfReviews }} review<span
              v-if="property.numberOfReviews !== 1"
              class="text-sm"
              >s</span
            >
          </p>
          <!-- base "top" on most frequently mentioned + those in reviews deemed most helpful and un-flagged for problems -->

          <!--BEGIN v-if there are ZERO reviews -->
          <div
            v-if="property.numberOfReviews == 0"
            class="mt-2.5 rounded-md pt-2.5 pb-6 text-center text-base italic"
          >
            - No review data for this property yet -
          </div>
          <!--END v-if there are ZERO reviews -->
          <!--BEGIN v-else (if there are 1+ reviews) -->
          <div v-else>
            <!-- BEGIN v-for on topPros array at bottom -->
            <!-- <ul class="mt-4">
              <li v-for="topPro in property.topPros" :key="topPro">
                <div class="line-clamp-1 hover:line-clamp-none mb-2">
                  <fa
                    icon="plus-circle"
                    class="text-[#4CBB17]"
                    alt="plus sign icon in a circle"
                  />
                  &nbsp;{{ topPro }}
                </div>
              </li>
            </ul> -->

            <ul class="mt-4">
              <div v-for="review in property.allReviews" :key="review">
                <router-link
                  :to="{
                    name: 'ReviewDetails',
                    params: { id: review.review_id },
                  }"
                >
                  <div v-if="review.propPros">
                    <li
                      v-for="pro in review.propPros.slice(0, 3)"
                      :key="pro"
                      class="my-2 lowercase"
                    >
                      <div v-if="review.propPros">
                        <fa
                          icon="plus-circle"
                          class="text-progreen"
                          alt="plus sign icon in a circle"
                        />
                        &nbsp;{{ pro }}
                      </div>
                    </li>
                  </div>
                </router-link>
              </div>
            </ul>

            <!-- BEGIN Mark try to move "slice(0,3)" above into setup() -->
            <!-- <ul class="mt-4 bg-sky-200">
              <div v-for="review in property.allReviews" :key="review">
                <li v-for="pro in limitedPros" :key="pro" class="my-2">
                  <fa
                    icon="plus-circle"
                    class="text-sky-400"
                    alt="plus sign icon in a circle"
                  />
                  &nbsp;{{ pro }}
                </li>
              </div>
            </ul> -->
            <!-- END Mark try to move "slice(0,3)" above into setup() -->

            <!-- END v-for on topPros array at bottom -->
          </div>
          <!--END v-else (if there are 1+ reviews) -->
          <p class="text-right text-xs italic">
            <span
              class="bg-progreen/10 rounded-lg px-2.5 py-1 text-xs opacity-5 hover:opacity-30"
              >click to see all Pros</span
            >
          </p>
        </div>

        <div class="propLandingCard col-span-2 mx-auto md:col-span-1">
          <div class="headinggg">Cons</div>
          <p class="-mt-1.5 text-sm italic">
            Top cons - from {{ property.numberOfReviews }} review<span
              v-if="property.numberOfReviews !== 1"
              class="text-sm"
              >s</span
            >
          </p>

          <!--BEGIN v-if there are ZERO reviews -->
          <div
            v-if="property.numberOfReviews == 0"
            class="mt-2.5 rounded-md pt-2.5 pb-6 text-center text-base italic"
          >
            - No review data for this property yet -
          </div>
          <!--END v-if there are ZERO reviews -->
          <!--BEGIN v-else (if there are 1+ reviews) -->
          <div v-else>
            <ul class="mt-4">
              <div v-for="review in property.allReviews" :key="review">
                <router-link
                  :to="{
                    name: 'ReviewDetails',
                    params: { id: review.review_id },
                  }"
                >
                  <div v-if="review.propCons">
                    <li
                      v-for="con in review.propCons.slice(0, 3)"
                      :key="con"
                      class="my-2 lowercase"
                    >
                      <div v-if="review.propCons">
                        <fa
                          icon="minus-circle"
                          class="text-conred"
                          alt="minus sign icon in a circle"
                        />
                        &nbsp;{{ con }}
                      </div>
                    </li>
                  </div>
                </router-link>
              </div>
            </ul>
            <!-- BEGIN v-for on topPros array at bottom -->
            <!-- <ul class="mt-4">
              <li v-for="topCon in property.topCons" :key="topCon">
                <div class="line-clamp-1 hover:line-clamp-none mb-2">
                  <fa
                    icon="minus-circle"
                    class="text-[#F2003C]"
                    alt="minus sign icon in a circle"
                  />
                  &nbsp;{{ topCon }}
                </div>
              </li>
            </ul> -->
            <!-- END v-for on topPros array at bottom -->
          </div>
          <p class="text-right text-xs italic">
            <span
              class="bg-conred/10 rounded-lg px-2.5 py-1 text-xs opacity-5 hover:opacity-30"
              >click to see all Cons</span
            >
          </p>
          <!--END v-if there are 1 or more reviews -->
        </div>
      </div>
      <!-- END pro / con grid -->

      <div class="propLandingCard">
        <div class="headinggg">Photo Stream</div>
        <p class="mb-4 -mt-1.5 text-sm italic text-gray-500">
          Selected photos from
          <span class="text-sm underline">all&thinsp;</span> of this property's
          reviews
        </p>
        <p class="mb-6 -mt-1 text-xs italic">
          <span
            class="rounded-lg bg-indigo-900/20 px-2.5 py-1 text-xs opacity-5 hover:opacity-30"
            >click to see all photos from all reviews</span
          >
        </p>

        <!--BEGIN v-if there are ZERO reviews -->
        <div
          v-if="fetchedImages.length == 0"
          class="mt-2.5 rounded-md pt-2.5 pb-2.5 text-center text-base italic"
        >
          - No review photos for this property yet -
        </div>
        <!--END v-if there are ZERO reviews -->
        <!--BEGIN v-else (if there are 1+ reviews) -->
        <div v-else class="">
          <!-- BEGIN cycling through "topPhotos" local array of images on Firebase Storage, using v-for -->
          <div
            class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4"
          >
            <!-- BEGIN new display of limited number (v-if="index <= 4") of photos per review -->
            <div
              v-for="(img, index) in fetchedImages"
              :key="'image' + index"
              class="col-span-2 sm:col-span-2"
            >
              <div>
                <img
                  v-if="index <= 4"
                  :src="img"
                  :alt="dynamicAltText"
                  class="w-full rounded-md ring-1 ring-indigo-900/20"
                />
              </div>
            </div>
            <!-- END new display of limited number (v-if="index <= 4") of photos per review -->
          </div>
          <!-- END cycling through "topPhotos" local array of images on Firebase Storage, using v-for -->
        </div>
        <!--END v-if there are 1 or more reviews -->
      </div>

      <div class="mt-8 grid grid-cols-2 px-1 md:gap-8 lg:gap-12">
        <div class="col-span-2 md:col-span-1">
          <SummaryOfRatings
            :propertyId="$route.params.id"
            v-if="$route.params.id"
            :propertyReviews="property.allReviews"
            whichRatingSummary="kitchen_rating"
            ratingType="Kitchen"
            ><template></template><template></template
          ></SummaryOfRatings>
        </div>
        <div class="col-span-2 md:col-span-1">
          <SummaryOfRatings
            :propertyId="$route.params.id"
            v-if="$route.params.id"
            :propertyReviews="property.allReviews"
            whichRatingSummary="bath_rating"
            ratingType="Bath"
            ><template></template><template></template
          ></SummaryOfRatings>
        </div>
        <div class="col-span-2 md:col-span-1">
          <SummaryOfRatings
            :propertyId="$route.params.id"
            v-if="$route.params.id"
            :propertyReviews="property.allReviews"
            whichRatingSummary="location_rating"
            ratingType="Location"
            ><template></template><template></template
          ></SummaryOfRatings>
        </div>
        <div class="col-span-2 md:col-span-1">
          <SummaryOfRatings
            :propertyId="$route.params.id"
            v-if="$route.params.id"
            :propertyReviews="property.allReviews"
            whichRatingSummary="setting_rating"
            ratingType="Setting"
            ><template></template><template></template
          ></SummaryOfRatings>
        </div>
        <div class="col-span-2 md:col-span-1">
          <SummaryOfRatings
            :propertyId="$route.params.id"
            v-if="$route.params.id"
            :propertyReviews="property.allReviews"
            whichRatingSummary="smell_rating"
            ratingType="Smell"
            ><template></template><template></template
          ></SummaryOfRatings>
        </div>
        <div class="col-span-2 md:col-span-1">
          <div class="propLandingCard col-span-2 md:col-span-1">
            <p class="-mt-1.5 mb-1.5 text-sm font-light italic opacity-70">
              Advertisement
            </p>
            <a
              href="https://www.busterandpunch.com/product-category/electricity/"
              target="_blank"
            >
              <div
                class="rounded-md border border-gray-100 bg-slate-400/10 p-2"
              >
                <p class="text-center text-xl font-bold">
                  Buster + Punch<sup class="text-base font-light">&#8482;</sup>
                </p>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/zagnetic-dev-images%2FScreen-Shot-2022-03-15-at-3.32.07-PM.jpg?alt=media&token=cabb8ad9-77bc-4674-9505-b742c0f36e15"
                  alt="brass Buster + Punch light switch with textured switches"
                  class="mx-auto my-2 h-40 rounded-md"
                />
                <p class="text-center text-base font-light">
                  Explore our custom electricity range
                  <span class="text- font-semibold underline"
                    >busterandpunch.com</span
                  >
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- BEGIN "misleading" summary and "get an agent" cards grid -->
      <div class="my-8 grid grid-cols-2 gap-8">
        <div class="propLandingCard col-span-2 md:col-span-1">
          <div class="headinggg">
            What's misleading or untrue in the listing or photos?
          </div>
          <p class="-mt-1.5 mb-3 text-sm font-light italic opacity-90">
            Top responses from reviewers:
          </p>
          <!--BEGIN v-if there are ZERO reviews -->
          <div
            v-if="property.numberOfReviews == 0"
            class="mt-2.5 rounded-md pt-2.5 pb-6 text-center text-base italic"
          >
            - No review data for this property yet -
          </div>
          <!--END v-if there are ZERO reviews -->
          <!--BEGIN v-else (if there are 1+ reviews) -->
          <div v-else>
            <!-- BEGIN v-for on topWhatInListingIsUntrueSss array at bottom -->
            <ul class="mt-4">
              <div v-for="review in property.allReviews" :key="review">
                <li v-if="review.whatInListingIsUntrue" class="my-4">
                  <div class="line-clamp-3 hover:line-clamp-none mb-2">
                    <fa
                      :icon="['fas', 'exclamation-triangle']"
                      size="md"
                      class="text-red-600"
                      alt=""
                    />
                    &nbsp;{{ review.whatInListingIsUntrue }}
                  </div>
                </li>
                <!-- BEGIN v-else if review has nothing for this item, so it's not just a blank space -->
                <li
                  v-else
                  class="mt-2.5 ml-4 rounded-md pt-2.5 pb-4 text-sm italic"
                >
                  &#8594;&thinsp;
                  <span class="text-sm font-semibold italic">Nothing</span>
                  noted in
                  <router-link
                    :to="{
                      name: 'ReviewDetails',
                      params: { id: review.review_id },
                    }"
                    class="text-center text-sm italic underline"
                    >this review</router-link
                  >
                </li>
                <!-- END v-else if review has nothing for this item, so it's not just a blank space -->
              </div>
            </ul>

            <!-- END v-for on topWhatInListingIsUntrueSss array at bottom -->

            <p class="mt-6 mb-1.5 text-sm font-light italic opacity-90">
              * Above is a partial list, from the most helpful
              <a
                href="#rrreviews"
                class="rounded-sm text-sm font-medium underline hover:bg-indigo-100"
                >reviews</a
              >.
            </p>
          </div>
          <!--END v-if there are 1 or more reviews -->
        </div>

        <div class="propLandingCard col-span-2 md:col-span-1">
          <h5 class="subheadinggg mb-6">
            Read This&ensp;<fa
              :icon="['fas', 'minus']"
              size="2x"
              class="-mb-0.5 -mr-1.5 text-xl"
            /><fa
              :icon="['fas', 'long-arrow-alt-down']"
              size="2x"
              class="-mb-2.5 text-xl"
            />
          </h5>
          <h1 class="headinggg">
            <span class="bg-highlight rounded-sm text-3xl"
              >IMPORTANT:&nbsp;&nbsp;<fa icon="exclamation-triangle"
            /></span>
          </h1>
          <p class="mb-4 text-lg">
            The seller's real estate agent represents the
            <span class="text-lg font-semibold underline">seller</span>&thinsp;,
            <span class="bg-highlight text-lg font-bold">not you</span>.<span
              class="inline-flex"
              ><Whoops whoopsButtonVifTextAndIcon="12" /></span
            >&nbsp;
            <!-- <fa
              :icon="['fas', 'question-circle']"
              size="sm"
              class="mb-1.5"
            /> -->
            <!-- <Whoops whoopsButtonVifTextAndIcon="12" /> -->
          </p>
          <p class="text-lg">
            Look through the Q&A below for real estate agents who demonstrate
            their local knowledge through helpful questions and answers.
          </p>
          <p class="my-3 text-lg">
            Find a
            <span class="text-zagblue"
              >verified<sup>&thinsp;<i class="fas fa-check-circle"></i></sup
            ></span>
            real estate estate agent<span class="inline-flex"
              ><Whoops whoopsButtonVifTextAndIcon="11"
            /></span>
            who will represent
            <span class="text-lg font-semibold underline">you</span>&thinsp;:
          </p>
          <a href="#top-of-qna-section"
            ><button
              class="border-zagblue bg-zagblue hover:border-zagblue hover:bg-zagblue/80 mt-3.5 w-full rounded-md border-2 border-dotted px-6 py-3.5 text-base font-bold uppercase text-white hover:border-2"
            >
              <fa icon="arrow-down" />&nbsp;&nbsp;&nbsp;&nbsp;GO TO
              Q&A&nbsp;&nbsp;&nbsp;&nbsp;<fa icon="arrow-down" /></button
          ></a>

          <p
            class="mt-4 mb-1.5 text-center text-base font-light italic text-gray-500"
          >
            no cost - no obligation
          </p>
        </div>
      </div>
      <!-- END "misleading" summary and "get an agent" cards grid -->

      <!-- BEGIN "repair, upgrade, add $7,000" summary and "sample ad for Marvin Windows" cards grid -->
      <div class="my-8 grid grid-cols-2 gap-8">
        <div class="propLandingCard col-span-2 md:col-span-1">
          <div class="headinggg">What to repair, upgrade or add?</div>
          <p
            class="-mt-1.5 mb-3 inline-flex text-sm font-light italic opacity-90"
          >
            How reviewers would spend
            <span
              class="ml-1 inline-flex text-sm font-semibold italic opacity-90"
              >$12,000</span
            ><Whoops whoopsButtonVifTextAndIcon="15" />on this property&thinsp;:
          </p>
          <!--BEGIN v-if there are ZERO reviews -->
          <div
            v-if="property.numberOfReviews == 0"
            class="mt-2.5 rounded-md pt-2.5 pb-6 text-center text-base italic"
          >
            - No review data for this property yet -
          </div>
          <!--END v-if there are ZERO reviews -->
          <!--BEGIN v-else (if there are 1+ reviews) -->
          <div v-else>
            <!-- BEGIN v-for on topWhatToRepairUpgradeAddSss array at bottom -->
            <ul class="mt-4">
              <div v-for="review in property.allReviews" :key="review">
                <li v-if="review.whatToRepairUpgradeAdd" class="my-4">
                  <div class="line-clamp-3 hover:line-clamp-none mb-2">
                    <fa
                      :icon="['fas', 'hammer']"
                      size="md"
                      class="text-blue-400"
                      alt=""
                    />
                    &nbsp;{{ review.whatToRepairUpgradeAdd }}
                  </div>
                </li>
                <!-- BEGIN v-else if review has nothing for this item, so it's not just a blank space -->
                <li
                  v-else
                  class="mt-2.5 ml-4 rounded-md pt-2.5 pb-4 text-sm italic"
                >
                  &#8594;&thinsp;
                  <span class="text-sm font-semibold italic">Nothing</span>
                  noted in
                  <router-link
                    :to="{
                      name: 'ReviewDetails',
                      params: { id: review.review_id },
                    }"
                    class="text-center text-sm italic underline"
                    >this review</router-link
                  >
                </li>
                <!-- END v-else if review has nothing for this item, so it's not just a blank space -->
              </div>
            </ul>

            <!-- END v-for on topWhatToRepairUpgradeAddSss array at bottom -->
            <p class="mt-6 mb-1.5 text-sm font-light italic opacity-90">
              * Above is a partial list, from the most helpful
              <a
                href="#rrreviews"
                class="rounded-sm text-sm font-medium underline hover:bg-indigo-100"
                >reviews</a
              >.
            </p>
          </div>
          <!--END v-if there are 1 or more reviews -->
        </div>

        <div class="propLandingCard col-span-2 md:col-span-1">
          <p class="-mt-1.5 mb-1.5 text-sm font-light italic opacity-70">
            Advertisement
          </p>
          <a href="https://www.marvin.com/our-story" target="_blank">
            <div class="rounded-sm border border-gray-100 p-2">
              <p class="text-center text-3xl font-bold">
                Marvin<sup class="text-base font-light">&#8482;</sup>
              </p>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/zagnetic-dev-images%2Fmarvin_logo.png?alt=media&token=585c4dad-49e1-4fa3-86fb-3bb27810f9b5"
                alt="marvin windows logo"
                class="mx-auto w-1/2 rounded-sm"
              />
              <p class="text-center text-base font-light uppercase">
                Windows and Doors Built For How You Live
              </p>
            </div>
          </a>
        </div>
      </div>
      <!-- END "repair, upgrade, add $7,000" summary and "sample ad for Marvin Windows" cards grid -->

      <!-- BEGIN ZZZ responsive grid -->
      <div class="my-6 grid grid-cols-12 gap-8">
        <!-- BEGIN Flood & Wildfire section -->
        <div class="col-span-12 md:col-span-8">
          <div
            class="mx-auto w-[96%] rounded-lg bg-white p-4 drop-shadow-lg sm:w-[92%] md:w-full"
            id="floodandwildfire"
          >
            <h3 class="headinggg">Flood & Wildfire Risk</h3>
            <!-- <p class="text-sm italic -mt-1.5 text-gray-500"> Links below open in new windows<fa icon="external-link-alt" class="text-gray-500 ml-1.5 " alt="opens in a new window icon"/></p> -->
            <p class="-mt-1.5 text-base italic text-gray-500">
              search by address or city<fa
                icon="external-link-alt"
                class="ml-3 text-sm text-gray-500"
                alt="opens in a new window icon"
              />
            </p>
            <!-- <p>from ...</p> -->
            <ol class="mt-4 ml-1.5 list-inside space-y-4 sm:list-disc">
              <li
                class="text-lg hover:text-gray-400 hover:underline sm:text-xl"
              >
                <a href="https://floodfactor.com/" target="_blank"
                  ><span class="font-semibold underline"
                    >What's This Property's Flood Risk?</span
                  >
                  <fa
                    icon="tint"
                    class="text-zagblue text-sm"
                    alt="water droplet icon"
                /></a>
                <span class="ml-2 mt-1 mb-4 text-base italic"
                  >- &nbsp;FloodFactor</span
                >
              </li>
              <li
                class="text-lg hover:text-gray-400 hover:underline sm:text-xl"
              >
                <a href="https://wildfirerisk.org/explore/" target="_blank"
                  ><span class="-mr-0.5 font-semibold underline"
                    >What's This Property's Wildfire Risk?</span
                  ><span class="fa-stack fa-xs -mr-1 -mb-1"
                    ><i
                      class="fas fa-fire fa-stack-2x fa-xs pt-.05 -rotate-3 text-amber-500"
                    ></i
                    ><i
                      class="fas fa-fire fa-stack-1x fa-sm rotate-2 text-red-700 opacity-80"
                      alt="fire icon"
                    ></i></span
                ></a>
                <span class="ml-2 mt-1 mb-4 text-base italic"
                  >- &nbsp;U.S. Forest Service</span
                >
              </li>

              <!-- <span class="fa-stack fa-xs"><i class="fas fa-fire fa-stack-2x fa-xs text-amber-500 -rotate-3"></i><i class="fas fa-fire fa-stack-1x fa-sm text-red-700 rotate-2 opacity-80"></i></span> -->
            </ol>
          </div>
        </div>
        <!-- END Flood & Wildfire section -->

        <div class="col-span-12 mb-2 md:col-span-4">
          <div
            class="mx-auto w-[96%] rounded-lg border-2 border-gray-200 border-opacity-50 bg-white py-4 px-3 drop-shadow-lg sm:w-[92%] md:w-full"
          >
            <div class="headinggg">Real Estate Listings+</div>
            <p class="-mt-2 mb-2.5 text-base font-medium">
              for this property<fa
                :icon="['fas', 'external-link-alt']"
                class="ml-3 mb-0.5 text-sm text-gray-500"
                alt="opens in a new window icon"
              />
            </p>
            <!-- <ol class="list-inside mt-4 ml-20 space-y-4">
                <li class="hover:text-gray-400 hover:underline"><a href="#" target="_blank" class="underline hover:bg-red-700 hover:text-white rounded-sm font-medium px-1.5">Redfin</a></li>
                <li class="hover:text-gray-400 hover:underline"><a href="#" target="_blank" class="underline hover:bg-blue-700 hover:text-white rounded-sm font-medium px-1.5">Zillow</a></li>
            </ol>     -->
            <div class="grid grid-rows-2">
              <div v-if="property.redfinUrl == ''" class="mx-auto mb-2.5">
                <a
                  href="https://redfin.com"
                  target="_blank"
                  class="rounded-sm font-medium underline hover:bg-red-700 hover:px-2 hover:py-0.5 hover:text-white"
                  >Redfin</a
                >
              </div>
               <div v-else class="mx-auto mb-2.5">
                <a
                  :href="property.redfinUrl"
                  target="_blank"
                  class="rounded-sm font-medium underline hover:bg-red-700 hover:px-2 hover:py-0.5 hover:text-white"
                  >Redfin</a
                >
              </div>

              <div v-if="property.zillowUrl == ''" class="mx-auto">
                <a
                  href="https://zillow.com"
                  target="_blank"
                  class="rounded-sm font-medium underline hover:bg-blue-700 hover:px-2 hover:py-0.5 hover:text-white"
                  >Zillow</a
                >
              </div>
              <div v-else class="mx-auto">
                <a
                  :href= "property.zillowUrl"
                  target="_blank"
                  class="rounded-sm font-medium underline hover:bg-blue-700 hover:px-2 hover:py-0.5 hover:text-white"
                  >Zillow</a
                >
              </div>
              <!-- <div class="mx-auto">
                <a
                  href="#"
                  target="_blank"
                  class="rounded-sm font-medium underline hover:bg-blue-700 hover:px-2 hover:py-0.5 hover:text-white"
                  >Link to Nextdoor community</a
                >
              </div>
              <div class="mx-auto">
                <a
                  href="https://www.dailyfreeman.com/2022/04/08/photos-flooding-in-ulster-county-on-april-8-2022/"
                  target="_blank"
                  class="rounded-sm font-medium underline hover:bg-blue-700 hover:px-2 hover:py-0.5 hover:text-white"
                  >Local newpapers:</a
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- END ZZZ responsive grid -->

      <!-- BEGIN Review Text section -->
      <div class="propLandingCard">
        <h3 class="headinggg">In Reviewers' Own Words</h3>
        <p class="font-regular mt-1.5 mb-4 text-base">
          Excerpts from helpful reviews&thinsp;:
        </p>
        <!--BEGIN v-if there are ZERO reviews -->
        <div
          v-if="property.numberOfReviews == 0"
          class="mt-2.5 rounded-md pt-2.5 pb-6 text-center text-base italic"
        >
          - No review data for this property yet -
        </div>
        <!--END v-if there are ZERO reviews -->
        <!--BEGIN v-else (if there are 1+ reviews) -->
        <div v-else>
          <!-- BEGIN v-for on topreviewerTextReviewSss array at bottom -->
          <ul class="mt-4">
            <div v-for="review in property.allReviews" :key="review">
              <li v-if="review.reviewerTextReview" class="my-4">
                <div class="line-clamp-2 hover:line-clamp-none mb-2">
                  <fa
                    :icon="['fas', 'microphone']"
                    size="md"
                    class="text-blue-400"
                    alt=""
                  />
                  &nbsp;{{ review.reviewerTextReview }}
                </div>
              </li>
              <!-- BEGIN v-else if review has nothing for this item, so it's not just a blank space -->
              <li
                v-else
                class="mt-2.5 ml-4 rounded-md pt-2.5 pb-4 text-sm italic"
              >
                &#8594;&thinsp;
                <span class="text-sm font-semibold italic">Nothing</span>
                noted in
                <router-link
                  :to="{
                    name: 'ReviewDetails',
                    params: { id: review.review_id },
                  }"
                  class="text-center text-sm italic underline"
                  >this review</router-link
                >
              </li>
              <!-- END v-else if review has nothing for this item, so it's not just a blank space -->
            </div>
          </ul>

          <ul class="mt-4 list-inside">
            <li
              v-for="topreviewerTextReview in property.topreviewerTextReviews"
              :key="topreviewerTextReview"
            >
              <div
                class="line-clamp-2 hover:line-clamp-none mb-8 italic hover:bg-indigo-50"
              >
                <fa
                  :icon="['fas', 'microphone']"
                  size="md"
                  class="text-blue-400"
                  alt=""
                />
                &nbsp;"&thinsp;{{ topreviewerTextReview }}
              </div>
            </li>
          </ul>

          <!-- END v-for on topreviewerTextReviewSss array at bottom -->
          <p class="mt-6 mb-1.5 text-sm font-light opacity-90">
            For more content like this, go to the top of most
            <a
              href="#rrreviews"
              class="rounded-sm text-sm font-medium underline hover:bg-indigo-100"
              >reviews</a
            >.
          </p>
        </div>
        <!--END v-if there are 1 or more reviews -->
      </div>
      <!-- END Review Text section -->
    </div>
    <!-- END of landing page content above Q&A -->

    <!-- BEGIN pasted Q&A -->
    <div class="mx-auto mt-12 max-w-screen-lg space-y-10 rounded-lg">
      <!-- <p class="text-sm text-gray-500 pb-6">Home &nbsp;&nbsp;>&nbsp;&nbsp; 20 Perconti Lane Utica NY 12601 &nbsp;&nbsp;>&nbsp;&nbsp; Reviews &nbsp;&nbsp;>&nbsp;&nbsp; Q & A</p> -->

      <div class="mt-10 rounded-lg bg-white py-5 px-2 drop-shadow-xl sm:px-5">
        <!-- <h1 class="headinggg">Questions & Answers  (Q & A)</h1> -->

        <div class="relative flex flex-row">
          <h1
            class="text-zagblue inline scroll-mt-16 align-middle text-2xl font-bold sm:text-3xl md:text-4xl"
            id="top-of-qna-section"
          >
            Questions<span
              class="px-2 text-xl sm:text-2xl md:text-3xl lg:px-2.5"
              >&</span
            >Answers  (Q<span class="px-1 text-xl sm:text-2xl md:text-3xl"
              >&</span
            >A)
          </h1>
        </div>
        <!-- BEGIN number of Q's and A's -->
        <p class="mt-4 ml-6 text-sm font-medium" v-if="property.qAnda">
          <span class="rounded-lg bg-none px-0.5 text-sm font-medium">{{
            property.qAnda.length
          }}</span>
          Questions &thinsp;+&thinsp;
          <span class="rounded-lg bg-none px-0.5 text-sm font-medium">{{
            numberOfAnswers
          }}</span>
          Answer<span v-if="numberOfAnswers != 1" class="text-sm font-medium"
            >s</span
          >
        </p>
        <!-- END number of Q's and A's -->

        <div class="grid grid-cols-4 pb-2 pt-2">
          <!-- was py-5 -->
          <div
            class="col-span-4 py-5 px-2.5 font-medium text-gray-500 sm:col-span-3 md:px-5"
          >
            <h4 class="text-xl font-semibold">
              <i class="fa-map-marker-alt fas mr-2"></i
              >{{ property.street_number }}&nbsp;{{ property.route
              }}<span v-if="property.extended_address" class="ml-4 underline">{{
                property.extended_address
              }}</span>
            </h4>
            <p class="ml-6 text-base font-semibold">
              {{ property.locality }},&nbsp;{{
                property.administrative_area_level_1
              }}&nbsp;{{ property.postal_code }}
            </p>

            <!-- BEGIN display property type-->
            <div class="ml-6 mt-2.5 text-base font-light uppercase">
              <div v-if="property.property_type === '1_family'">
                <p class="text-base">Single family</p>
              </div>
              <div v-else-if="property.property_type === 'condo'">
                <p class="text-base">Condominium</p>
              </div>
              <div v-else-if="property.property_type === 'townhouse'">
                <p class="text-base">Townhouse</p>
              </div>
              <div v-else-if="property.property_type === 'coop'">
                <p class="text-base">Cooperative</p>
              </div>
              <div v-else-if="property.property_type === 'mobilehome'">
                <p class="text-base">Mobile home</p>
              </div>
              <!-- was "Mobile home, yurt, etc." -->
              <div v-else-if="property.property_type === 'multi_family'">
                <p class="text-base">Multi-family (whole building)</p>
              </div>
              <div v-else-if="property.property_type === 'commercial'">
                <p class="text-base">Commercial</p>
              </div>
              <div v-else-if="property.property_type === 'rentalapartment'">
                <p class="text-base">Apartment for rent</p>
              </div>
              <div v-else><p class="text-base">Raw land</p></div>
            </div>
            <!-- END display property type -->
          </div>

          <div class="col-span-4 sm:col-span-1">
            <div class="mx-auto justify-end pt-6 text-left md:-mr-11 lg:-mr-16">
              <a href="#rrreviews" class=""
                ><button
                  class="my-2 w-full rounded-md bg-gray-50 px-2 py-1.5 text-sm font-medium text-gray-500 ring-1 ring-indigo-200 hover:bg-indigo-50 hover:text-gray-600 hover:ring-indigo-500 focus:ring-indigo-500 active:ring-red-500 md:w-[75%]"
                >
                  <span class="text-zagblue text-base font-semibold underline"
                    >Reviews</span
                  >
                  &nbsp;<fa
                    :icon="['fas', 'arrow-alt-circle-up']"
                    class="text-zagblue"
                  /></button
              ></a>
            </div>
          </div>
        </div>

        <!-- BEGIN search input + ask question button -->
        <div class="mt-4 mb-8 grid grid-cols-3 gap-6">
          <div class="col-span-3 md:col-span-2">
            <form action="#" id="search-q-n-a" class="scroll-mt-12">
              <div class="relative">
                <span class=""
                  ><input
                    class="form-control focus:ring-zagblue inline-block w-full rounded-md bg-white py-2 ring-2 ring-gray-300 hover:ring-gray-400"
                    placeholder="&nbsp;&nbsp;Search this Q&#38;A"
                    aria-label="Search this Q&#38;A for a keyword" /><fa
                    :icon="['fas', 'search']"
                    class="-ml-7 opacity-75"
                /></span>
              </div>
            </form>
            <p
              class="mx-auto mt-8 ml-6 -mb-2 hidden text-center text-xs italic sm:flex"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Not this one - at the end of each question below"
            >
              <span class="rounded-lg bg-lime-100/50 px-2.5 py-1 text-xs"
                >click<i
                  class="far fa-arrow-alt-circle-down text-zagblue ml-1.5 mr-1 -mb-0.5 text-lg"
                ></i
                >to see answers</span
              >
            </p>
          </div>

          <div class="col-span-3 ml-auto mb-4 md:col-span-1 md:mb-0">
            <!-- BEGIN "Ask a Question" button beneath a question, with v-if logged in or "Whoops" -->
            <button
              class="border-zagblue/70 text-zagblue hover:text-zagblue/80 mb-2 w-full rounded-md border bg-indigo-50/50 px-5 py-1.5 text-base font-semibold hover:border-indigo-500 hover:bg-indigo-50"
            >
              <div v-if="user">
                <!-- <Whoops whoopsButtonVifTextAndIcon="8" /> -->
                <AskAnswerQuestion
                  AskAnswerQuestionButtonVifTextAndIcon="2"
                  :propertyId="$route.params.id"
                  :qAndAArray="property.qAnda || []"
                  :userInfo="loggedInUser"
                />
              </div>
              <div v-else><Whoops whoopsButtonVifTextAndIcon="7" /></div>
            </button>
            <!-- END "Ask a Question" button beneath a question, with v-if logged in or "Whoops" -->
          </div>
        </div>
        <!-- END search input + ask question button -->
        <p
          class="mx-auto -mt-4 mb-7 text-center text-xs italic sm:hidden"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Not this one - at the end of each question below"
        >
          <span class="rounded-lg bg-lime-100/50 px-2.5 py-1 text-xs"
            >click<i
              class="far fa-arrow-alt-circle-down text-zagblue ml-1.5 mr-1 -mb-0.5 text-lg"
            ></i
            >to see answers</span
          >
        </p>

        <!-- BEGIN list of questions in this Q&A with links to each -->
        <div class="py-2" v-if="property && property.qAnda">
          <div
            class="text-zagblue ml-1 space-y-6 text-sm sm:ml-4 lg:ml-6 lg:w-3/4"
          >
            <div
              class="inline-flex"
              v-for="(quiz, index) in property.qAnda"
              :key="`quiz${index}`"
              :class="[
                index % 2 == 0 ? 'rounded-md bg-indigo-50 py-0.5 pr-1' : '',
              ]"
            >
              <p class="mr-3 font-semibold">Q:</p>
              <a :href="`#quiz-${index}`"
                ><p
                  class="qatext hover:underline"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Jump to this topic"
                >
                  {{ quiz.questionText
                  }}<i class="far fa-arrow-alt-circle-down ml-1 text-lg"></i></p
              ></a>
            </div>
          </div>
        </div>
        <!-- END list of questions in this Q&A with links to each -->
      </div>

      <!-- BEGIN Q&A 1 -->
      <!-- {{ property.qAnda }} -->
      <!-- <QandAnswers
        v-if="$route.params.id && property && property.qAnda"
        :propertyId="$route.params.id"
        :propertyQandA="property.qAnda"
        :key="property.qAnda.length"
      /> -->
      <div
        v-if="$route.params.id && property && property.qAnda"
        :key="property.qAnda.length"
      >
        <SingleQandA
          v-for="(qAndaItem, qAndaItemIndex) in property.qAnda"
          :propertyId="$route.params.id"
          :propertyQandA="property.qAnda"
          :singleQandA="qAndaItem"
          :quizIndex="qAndaItemIndex"
          :userInfo="loggedInUser"
          :key="qAndaItem.answers.length"
        />
      </div>
      <a href="#top-of-qna-section"
        ><button class="borderlessbtn">
          Back to top of Q&A<i class="fa fa-arrow-up ml-2"></i></button
      ></a>
    </div>
    <!-- END pasted Q&A -->
  </div>
</template>

<script>
import useStorage from "@/composables/useStorage";
import useDocument from "@/composables/useDocument";
import getSetUserInfo from "@/composables/getSetUserInfo";
import getDocument from "@/composables/getDocument";
import getUser from "@/composables/getUser";
import { computed, ref, onRenderTriggered, onMounted } from "vue";
import { useRouter } from "vue-router";

import { formatDistanceToNow } from "date-fns";
// import { timestamp } from '@/firebase/config'

import ShowRatingStars from "@/components/ShowRatingStars.vue";

import ShareButtonDD from "@/components/ShareButtonDD.vue";

import SummaryOfRatings from "@/components/SummaryOfRatings.vue";

import Whoops from "@/components/Whoops.vue";

import AskAnswerQuestion from "@/components/AskAnswerQuestion.vue";

import GoogleMap from "@/components/GoogleMap.vue";

import SmallIndivReviewCard from "@/components/SmallIndivReviewCard.vue";

import QandAnswers from "@/components/QandAnswers.vue";
import SingleQandA from "@/components/SingleQandA.vue";

export default {
  props: ["id"],
  components: {
    SummaryOfRatings,
    Whoops,
    AskAnswerQuestion,
    SmallIndivReviewCard,
    ShareButtonDD,
    GoogleMap,
    QandAnswers,
    SingleQandA,
  },

  setup(props, context) {
    const { user } = getUser();
    const { userErr, loggedInUser, getLoggedInUserInfo } = getSetUserInfo();
    const error = null;
    const router = useRouter();
    const propertyId = ref(props.id);
    const isRenderedTriggered = ref(false);
    const { getImagesByFolderRef, fetchedImages } = useStorage();

    const { document: property } = getDocument(
      // error when this line is: const { error, document: property } = getDocument(
      "properties",
      propertyId.value
    );

    // const limitedPros = computed(() => {
    //   return review.propPros.slice(0, 3);
    // });
    if (user.value) {
      getLoggedInUserInfo(user.value.uid);
    }

    const theZillowUrlFromDatabase = property.zillowUrl;

    const numberOfAnswers = computed(() => {
      let sumOfAllDatabaseAnswers = 0;
      const qAndAValues = property.value.qAnda;
      if (qAndAValues.length > 0) {
        qAndAValues.forEach((item, index) => {
          sumOfAllDatabaseAnswers =
            sumOfAllDatabaseAnswers + +item.answers.length;
        });
      }

      return sumOfAllDatabaseAnswers;
    });

    const dynamicAltText = computed(() => {
      let altText = property.value.address;
      if (
        typeof property.value.address != "undefined" &&
        property.value.address !== null
      ) {
        altText = altText + " " + property.value.address;
      }
      return altText;
    });

    const writeReview = () => {
      console.log(property.value);
      localStorage.setItem("exist_prop_id", propertyId.value);
      localStorage.setItem("exist_prop_addr", property.value.address);
      localStorage.setItem(
        "exist_prop_extended_addr",
        property.value.extended_address
      );
      localStorage.setItem("exist_prop_type", property.value.property_type); // added by Mark (in case it causes an error later, we know who to blame!)
      router.push({ name: "CreateReview" });
    };

    onMounted(() => {
      getImagesByFolderRef("review_images/" + propertyId.value);
    });

    return {
      user,
      error,
      property,
      fetchedImages,
      numberOfAnswers,
      dynamicAltText,
      writeReview,
      loggedInUser,
      // limitedPros,
      theZillowUrlFromDatabase,
    };
  },

  data() {
    return {};
  },
};
</script>

<style></style>
