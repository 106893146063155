<template>
  <div class="mx-auto my-0 max-w-screen-lg">
    <h1 class="my-20 text-center text-4xl font-bold underline">Sitemap</h1>
    <div class="mx-4 grid gap-8 sm:grid-cols-2 xl:mx-0">
      <div class="col-span-1 mx-auto">
        <h2 class="pb-3.5 font-bold uppercase underline">Pages</h2>
        <ul class="list-inside list-none space-y-3 text-slate-500">
          <li>
            <router-link :to="{ name: 'Home' }">Home page</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CreateReview' }"
              >Write a review (create a review)</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'FAQ' }"
              ><span class="hover:text-slate-400">FAQ</span></router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'ForRealEstatePros' }"
              >Real Estate Pros</router-link
            >
          </li>
          <li>
            <a
              class="text-muted text-hover-primary"
              href="#"
              target="_blank"
              title=""
              >Site map</a
            >
          </li>
          <li>
            <a
              class="text-muted text-hover-primary"
              href="#"
              target="_blank"
              title="contact"
              >Contact</a
            >
          </li>
        </ul>
        <p class="text-slate-500"></p>
      </div>

      <div class="col-span-1 mx-auto">
        <h2 class="pb-3.5 font-bold uppercase underline">More pages</h2>
        <ul class="list-inside list-none space-y-3 text-slate-500">
          <li>
            <router-link :to="{ name: 'About' }"
              ><span class="hover:text-slate-400">About</span></router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Terms' }"
              ><span class="hover:text-slate-400">Terms</span></router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Privacy' }"
              ><span class="hover:text-slate-400">Privacy</span></router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Accessibility' }"
              ><span class="hover:text-slate-400"
                >Accessibility Help</span
              ></router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Dmca' }"
              ><span class="hover:text-slate-400">DMCA</span></router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="mx-auto mt-20">
    <ThePropertyList />
    </div>

  </div>
</template>

<script>
import { useRouter } from "vue-router";
import ThePropertyList from "@/components/ThePropertyList";

export default {
  components: {
    ThePropertyList,
  },

  setup() {
    const router = useRouter();
  },

  
};
</script>

<style></style>
