// useCollection.js is to add a new document to a collection

import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { collection, query, orderBy, startAt, endAt, getDocs, limit } from "firebase/firestore";
import { geohashQueryBounds, distanceBetween } from "geofire-common";

const getNearbyLocations = (collectionName) => {
  const error = ref(null);
  const matchingDocs = ref([]);
  // add a new document
  const collectionRef = collection(projectFirestore, collectionName);
  const getLocations = async (lat, lng, distance, addressToIgnore) => {
    error.value = null;
    const center = [lat, lng];
    const radiusInM = distance * 1000;
    
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
        const q = query(collectionRef, orderBy("geohash"), startAt(b[0]), endAt(b[1]), limit(12));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            // return doc.id && results.push({ ...doc.data(), id: doc.id });
            if(doc.data().address === addressToIgnore) { 
                return true; 
            }

            const lat = parseFloat(doc.data().latNumber);
            const lng = parseFloat(doc.data().lngNumber);
            const distanceInKm = distanceBetween([lat, lng], center);
                        const distanceInM = distanceInKm * 1000;
                        if (distanceInM <= radiusInM) {
                            let answersLength = 0;
                            const qAndA = doc.data().qAnda;
                            qAndA.forEach((item, key) => {
                                answersLength += item.answers.length;
                            });
                            const docToPush = {doc_id: doc.id, answers_length: answersLength, ...doc.data()}
                            matchingDocs.value.push(docToPush);
                        }
        });
      
        
      }
      
  };

  return { error, getLocations, matchingDocs };
};

export default getNearbyLocations;
