<template>
  <div
    class="bg-rounded mx-auto max-w-screen-md bg-white px-0 pt-4 pb-4 sm:bg-gray-50 sm:px-0 md:px-4"
  >
    <div>
      <h1
        class="text-zaggray mt-8 mb-2.5 text-center text-3xl font-bold underline"
      >
        Create a New Review
      </h1>
      <p class="text-zaggray mb-14 text-center text-sm italic">
        Be honest & kind
      </p>
    </div>

    <form
      class="bg-white sm:bg-gray-50 sm:px-4"
      @submit.prevent="handleSubmit"
      @input="storeDataLocally"
    >
      <h3 class="question">
        What property?<span class="required">(required)</span>
      </h3>
      <div>
        <button
          v-if="showAddressErr"
          class="animate-fade text-conred my-4 rounded-lg px-1 text-sm italic sm:text-base"
        >
          <fa
            icon="exclamation-triangle"
            class="text-conred mr-1.5 text-lg"
          />Please confirm the address in the dropdown
        </button>
        <input
          type="text"
          id="autocomplete"
          ref="autocomplete"
          class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
          required
          placeholder="Enter an address"
          v-model="address"
          @keyup="googleInputSelected = false"
          @change="isPropertyExist(), checkIfGoogleInputSelected()"
        />
      </div>

      <div v-if="toggleExtendedAddrProp">
        <h3 class="question mt-8 ml-12 !font-semibold">
          Apartment, unit, suite, or floor #
        </h3>
        <p class="-mt-2 mb-2 ml-12 text-sm font-normal italic text-slate-600">
          <span class="animate-fade rounded-lg px-1.5 py-1 text-sm font-normal"
            >Leave blank if none</span
          >
        </p>
        <!-- <p class="-mt-2 mb-2 ml-12 text-sm font-light italic text-gray-500">
          Leave blank if none
        </p> -->
        <div>
          <input
            v-if="toggleExtendedAddrProp"
            type="text"
            id="extended_address"
            @change="isPropertyExist()"
            class="focus:border-zagblue focus:ring-zagblue ml-12 w-1/3 rounded-lg border-gray-300 md:w-1/6"
            placeholder=""
            v-model="extended_address"
          />
        </div>
      </div>

      <hr class="my-10" />

      <h3 class="question mb-4">
        What kind of property is it?<span class="required">(required)</span>
      </h3>

      <!-- BEGIN "What kind of property" as a selection -->
      <div class="space-y-0">
        <select
          required
          name="property_type"
          id="property_type"
          v-model="property_type"
          @change="toggleExtendedAddr(), isPropertyExist()"
          class="text-zaggray rounded border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400"
        >
          <option disabled value="">Please select</option>
          <option value="1_family">Single family house</option>
          <option value="condo">Condominium</option>
          <option value="coop">Cooperative</option>
          <option value="townhouse">Townhouse</option>
          <option value="rentalapartment">Apartment for rent</option>
          <!--<option value="mobilehome">Mobile home</option>-->
          <!-- was "Mobile home, yurt, etc." >> Removed this for now because it's difficult to separate the mobile home from the (often rented) lot where it's located -->
          <option value="multi_family">Multi-family (whole building)</option>
          <option value="commercial">Commercial</option>
          <option value="raw_land">Raw land</option>
        </select>
      </div>
      <!-- END "What kind of property" as a selection -->

      <hr class="my-10" />

      <div class="relative">
        <h3 class="question">Pros<span class="optional">(optional)</span></h3>
        <!-- <div for="propPros" class="text-base -mb-3.5 pb-0 mt-3.5">In just a few words, what are 3 <span class="italic text-base mr-0.5">positive</span> things about the house, property or neighborhood?</div> -->
        <div for="propPros" class="mb-1 mt-3.5 pb-0 text-base">
          <!-- was class="-mb-3.5 mt-3.5 pb-0 text-base"> -->
          What are 3+
          <span class="mr-0.5 text-base underline">positive</span> things about
          the house, property or neighborhood?
          <!-- was What are 3<span class="mr-0.5 text-base underline">positive</span> things about the house, property or neighborhood? -->
        </div>
        <label
          for="propPros"
          class="text-zagblue contrast-more:text-black ml-0.5 mb-1 text-sm italic"
          >separate , with , commas</label
        >
        <TagInput
          class="peer focus:border-zagblue focus:ring-zagblue absolute w-full rounded-lg border-gray-300 placeholder-transparent hover:placeholder-transparent focus:placeholder-transparent md:w-full"
          placeholder="separate , with , commas"
          alt="add short tags describing positives, separating each with a comma"
          id="propPros"
          v-model="propPros"
        />
      </div>

      <!-- <hr class="mt-8 mb-12 invisible"> -->
      <hr class="my-10" />

      <div class="relative">
        <h3 class="question">Cons<span class="optional">(optional)</span></h3>
        <div for="propCons" class="mb-1 mt-3.5 pb-0 text-base">
          List 3 or more
          <span class="mr-0.5 text-base underline">negatives,</span> or things
          you don’t like:
          <!-- was List up to 3 <span class="mr-0.5 text-base underline">negatives,</span> or things you don’t like: -->
        </div>
        <label for="propCons" class="text-zagblue ml-0.5 mb-1 text-sm italic"
          >separate , with , commas</label
        >
        <TagInput
          class="peer focus:border-zagblue focus:ring-zagblue absolute w-full rounded-lg border-gray-300 placeholder-transparent hover:placeholder-transparent focus:placeholder-transparent md:w-full"
          placeholder="separate , with , commas"
          alt="add short tags describing negatives, separating each with a comma"
          id="propCons"
          v-model="propCons"
        />
      </div>

      <hr class="my-10" />

      <!-- <h3 class="question">
        Anything misleading or untrue in the listing or photos?<span
          class="optional"
          >(optional)</span
        >
      </h3>

      <textarea
        class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
        v-model="whatInListingIsUntrue"
        placeholder="If no, leave blank"
        rows="3"
      ></textarea>

     <hr class="my-10" /> -->

      <h3 class="question">
        Anything misleading or untrue in the listing or photos?<span
          class="optional"
          >(optional)</span
        >
      </h3>

      <input
        type="text"
        class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
        v-model.trim="whatInListingIsUntrue"
        placeholder="If no, leave blank"
        rows="3"
      />

      <hr class="my-10" />
      <!--<hr class="my-10" /> -->

      <h3 class="question">$12,000<span class="optional">(optional)</span></h3>
      <label for="whatToRepairUpgradeAdd" class="mt-3.5 pb-3.5 text-base"
        >If you were going to
        <span
          v-if="
            property_type == 'multi_family' || property_type == 'commercial'
          "
          >own this building</span
        ><span v-else-if="property_type == 'raw_land'">own this land</span
        ><span v-else>live here</span> and received a $12,000 gift card, good
        for any product or service,
        <span class="text-base font-semibold"
          >what would you repair, upgrade or add?</span
        >
        Why?</label
      >

      <textarea
        class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
        v-model.trim="whatToRepairUpgradeAdd"
        placeholder=""
        rows="3"
      ></textarea>

      <hr class="my-10" />

      <!-- BEGIN Star Ratings v2 -->

      <h3 class="question">
        On a 1 - 5 scale, with 5 being the best, how would you rate these:<span
          class="optional"
          >(optional)</span
        >
      </h3>

      <div class="row-span-1 pt-2.5">
        <div class="inline-flex items-end">
          <p
            class="ml-12 mr-2 pb-1 pl-5 text-sm sm:ml-24 sm:pl-1.5 sm:text-base md:ml-6 md:pl-20"
          >
            Kitchen:
          </p>
          <!-- kitchen_rating -->
          <div>
            <fieldset
              class="rating float-left justify-end text-base sm:text-lg"
              alt="five empty rating stars in a horizontal line. Select the star that matches your rating. Example, select 3rd star if rating is 3 out of 5"
            >
              <input
                type="radio"
                id="kitchen_rating5"
                v-model.number="kitchen_rating"
                value="5"
              />
              <label for="kitchen_rating5">5 stars</label>
              <input
                type="radio"
                id="kitchen_rating4"
                v-model.number="kitchen_rating"
                value="4"
              />
              <label for="kitchen_rating4">4 stars</label>
              <input
                type="radio"
                id="kitchen_rating3"
                v-model.number="kitchen_rating"
                value="3"
              />
              <label for="kitchen_rating3">3 stars</label>
              <input
                type="radio"
                id="kitchen_rating2"
                v-model.number="kitchen_rating"
                value="2"
              />
              <label for="kitchen_rating2">2 stars</label>
              <input
                type="radio"
                id="kitchen_rating1"
                v-model.number="kitchen_rating"
                value="1"
              />
              <label for="kitchen_rating1">1 star</label>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end">
          <p
            class="ml-2.5 mr-2 pb-1 pl-5 text-sm sm:ml-3 sm:pl-12 sm:text-base md:ml-5 md:pl-10"
          >
            Bathroom(s):
          </p>
          <!-- bath_rating -->
          <div>
            <fieldset
              class="rating float-left justify-end text-base sm:text-lg"
              alt="five empty rating stars in a horizontal line. Select the star that matches your rating. Example, select 3rd star if rating is 3 out of 5"
            >
              <input
                type="radio"
                id="bath_rating5"
                v-model.number="bath_rating"
                value="5"
              />
              <label for="bath_rating5">5 stars</label>
              <input
                type="radio"
                id="bath_rating4"
                v-model.number="bath_rating"
                value="4"
              />
              <label for="bath_rating4">4 stars</label>
              <input
                type="radio"
                id="bath_rating3"
                v-model.number="bath_rating"
                value="3"
              />
              <label for="bath_rating3">3 stars</label>
              <input
                type="radio"
                id="bath_rating2"
                v-model.number="bath_rating"
                value="2"
              />
              <label for="bath_rating2">2 stars</label>
              <input
                type="radio"
                id="bath_rating1"
                v-model.number="bath_rating"
                value="1"
              />
              <label for="bath_rating1">1 star</label>
            </fieldset>
          </div>
        </div>
      </div>

      <!-- BEGIN location -->
      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end">
          <!-- <p
            class="ml-6 mr-2 pb-1 pl-8 text-sm sm:ml-2.5 sm:pl-20 sm:text-base md:pl-20"
          >

          </p> -->
          <p
            class="ml-7 mr-2 pb-1 pl-8 text-sm sm:ml-3.5 sm:pl-20 sm:text-base md:pl-20"
          >
            Location:
          </p>
          <!-- location_rating -->
          <div>
            <fieldset
              class="rating float-left justify-end text-base sm:text-lg"
              alt="five empty rating stars in a horizontal line. Select the star that matches your rating. Example, select 3rd star if rating is 3 out of 5"
            >
              <input
                type="radio"
                id="location_rating_star5"
                v-model.number="location_rating"
                value="5"
              />
              <label for="location_rating_star5">5 stars</label>
              <input
                type="radio"
                id="location_rating_star4"
                v-model.number="location_rating"
                value="4"
              />
              <label for="location_rating_star4">4 stars</label>
              <input
                type="radio"
                id="location_rating_star3"
                v-model.number="location_rating"
                value="3"
              />
              <label for="location_rating_star3">3 stars</label>
              <input
                type="radio"
                id="location_rating_star2"
                v-model.number="location_rating"
                value="2"
              />
              <label for="location_rating_star2">2 stars</label>
              <input
                type="radio"
                id="location_rating_star1"
                v-model.number="location_rating"
                value="1"
              />
              <label for="location_rating_star1">1 star</label>
            </fieldset>
          </div>
        </div>
      </div>
      <!-- END location -->

      <!-- BEGIN setting -->
      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end">
          <p
            class="ml-[1.6rem] mr-2 inline-flex pb-1 pl-8 text-sm sm:ml-3 sm:pl-20 sm:text-base md:pl-20"
          >
            <Whoops whoopsButtonVifTextAndIcon="13" />&thinsp;:
          </p>
          <!-- location_rating -->
          <div>
            <fieldset
              class="rating float-left justify-end text-base sm:text-lg"
              alt="five empty rating stars in a horizontal line. Select the star that matches your rating. Example, select 3rd star if rating is 3 out of 5"
            >
              <input
                type="radio"
                id="setting_rating_star5"
                v-model.number="setting_rating"
                value="5"
              />
              <label for="setting_rating_star5">5 stars</label>
              <input
                type="radio"
                id="setting_rating_star4"
                v-model.number="setting_rating"
                value="4"
              />
              <label for="setting_rating_star4">4 stars</label>
              <input
                type="radio"
                id="setting_rating_star3"
                v-model.number="setting_rating"
                value="3"
              />
              <label for="setting_rating_star3">3 stars</label>
              <input
                type="radio"
                id="setting_rating_star2"
                v-model.number="setting_rating"
                value="2"
              />
              <label for="setting_rating_star2">2 stars</label>
              <input
                type="radio"
                id="setting_rating_star1"
                v-model.number="setting_rating"
                value="1"
              />
              <label for="setting_rating_star1">1 star</label>
            </fieldset>
          </div>
        </div>
      </div>
      <!-- END setting -->

      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end">
          <p class="ml-0 mr-2 pb-1 text-sm sm:ml-7 sm:text-base">
            Smell & odor free:
          </p>
          <!-- smell_rating -->
          <div>
            <fieldset
              class="rating float-left justify-end text-base sm:text-lg"
              alt="five empty rating stars in a horizontal line. Select the star that matches your rating. Example, select 3rd star if rating is 3 out of 5"
            >
              <input
                type="radio"
                id="smell_rating_star5"
                v-model.number="smell_rating"
                value="5"
              />
              <label for="smell_rating_star5">5 stars</label>
              <input
                type="radio"
                id="smell_rating_star4"
                v-model.number="smell_rating"
                value="4"
              />
              <label for="smell_rating_star4">4 stars</label>
              <input
                type="radio"
                id="smell_rating_star3"
                v-model.number="smell_rating"
                value="3"
              />
              <label for="smell_rating_star3">3 stars</label>
              <input
                type="radio"
                id="smell_rating_star2"
                v-model.number="smell_rating"
                value="2"
              />
              <label for="smell_rating_star2">2 stars</label>
              <input
                type="radio"
                id="smell_rating_star1"
                v-model.number="smell_rating"
                value="1"
              />
              <label for="smell_rating_star1">1 star</label>
            </fieldset>
          </div>
        </div>
      </div>

      <!-- END Star Ratings v2 -->

      <hr class="my-10" />

      <h3 class="question">Photos <span class="optional">(optional)</span></h3>
      <div class="ml-0.5 mb-1 text-sm text-gray-500">
        Any photos of the house or neighborhood&thinsp;?
      </div>
      <div
        class="space-y-4 rounded-md border-2 border-dashed border-gray-300 bg-white pt-4 pb-6"
      >
        <div class="mb-10 text-center text-base font-normal text-gray-500">
          <!-- mb-10 on left is is new for vue3-file-selector test --><fa
            icon="cloud-upload-alt"
            size="4x"
            alt="cloud icon"
            class="-mb-2 text-gray-500 text-opacity-70"
          />
        </div>

        <!-- BEGIN new file input button -->

        <div class="text-center text-sm font-normal text-gray-500">
          <label for="image_uploads" class="mx-auto"
            ><div
              class="hover:bg-100 mx-auto w-36 rounded-md border border-gray-500 bg-gray-50 px-2.5 py-0.5 text-sm hover:border-indigo-900 hover:bg-indigo-50 hover:ring-2 hover:ring-indigo-200"
            >
              Upload
              <fa
                icon="camera-retro"
                size="lg"
                alt="camera icon"
                class="mx-1"
              />
              files
            </div>
            <span class="-my-2 ml-1 hidden text-xs text-gray-600">
              or drag &#8210;n&#8210; drop</span
            ></label
          >
          <input
            type="file"
            id="image_uploads"
            name="image_uploads"
            accept="image/*"
            @change="handleChange"
            multiple
            class="invisible text-pink-400"
          />
          <!-- class="invisible" hides default "browse..." + file name -->
          <div class="error">{{ fileError }}</div>

          <ul class="mb-4 list-disc">
            <div v-for="name in imageNames" v-bind:key="name" class="">
              <li class="my-1.5 text-xs italic text-indigo-900">{{ name }}</li>
            </div>
          </ul>

          <div class="text-center text-sm font-light text-gray-500 opacity-90">
            PNG, JPG, JPEG &thinsp;up to 10&thinsp;MB
          </div>

          <!-- <div v-if="selectedImages"> -->
          <!-- <img class="preview" :src="picture"> -->
          <!-- <p class="text-zagblue text-sm">Image(s) selected:</p>
          </div> -->
        </div>

        <!-- END new file input button -->
        <!-- was accept=".jpg, .jpeg, .png" -->

        <!-- BEGIN vue3-file-selector -->

        <!-- <div class="text-gray-500 text-center font-normal text-sm">
          <file-selector v-model="files" v-slot="{ openDialog }">
            <span @click="openDialog" class="border border-gray-500 hover:border-indigo-900 hover:ring-indigo-200 hover:ring-2 hover:bg-indigo-50 px-2.5 mr-1.5 py-0.5 rounded-md bg-gray-50 hover:bg-100 text-sm">Upload <fa icon="camera-retro" size="lg" alt="camera icon" class="mx-1"/> files</span>
          </file-selector>
          <p class="text-gray-500 opacity-90 text-center text-sm font-light mt-6">PNG, JPG, JPEG &thinsp;up to 10&thinsp;MB</p>
        </div> -->

        <!-- END vue3-file-selector -->
      </div>

      <hr class="my-10" />
      <!-- END test of new upload design-->

      <h3 class="question">
        Anything else?<span class="optional">(optional)</span>
      </h3>
      <textarea
        class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
        v-model.trim="reviewerTextReview"
        placeholder="Short snippet? &nbsp;Detailed review? &nbsp;Star rating reasons?"
        rows="8"
      ></textarea>
      <!-- considered this: placeholder="Short snippet? &nbsp;Detailed review? &nbsp;Anything misleading in the listing or photos?" -->

      <hr class="my-10" />

      <!-- BEGIN When were you physically at the property? -->
      <h3 class="question">
        When were you physically at the property?<span class="required"
          >(required)</span
        >
      </h3>
      <div class="mt-6 space-y-4">
        <div>
          <input
            type="radio"
            id="30"
            value="30"
            name="whenReviewerVisitedProp"
            required
            v-model="whenReviewerVisitedProp"
          />
          <label class="ml-4 inline-flex text-base" for="30"
            >Within the last month</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="180"
            value="180"
            name="whenReviewerVisitedProp"
            required
            v-model="whenReviewerVisitedProp"
          />
          <label class="ml-4 inline-flex text-base" for="180"
            >Within the last 6 months</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="181"
            value="181"
            name="whenReviewerVisitedProp"
            required
            v-model="whenReviewerVisitedProp"
          />
          <label class="ml-4 inline-flex text-base" for="181"
            >More than 6 months ago</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="0"
            value="0"
            name="whenReviewerVisitedProp"
            required
            v-model="whenReviewerVisitedProp"
          />
          <label class="ml-4 inline-flex text-base" for="0"
            >Never<button class="ml-0">
              <Whoops whoopsButtonVifTextAndIcon="9" /></button
          ></label>
        </div>
      </div>
      <!-- END When were you physically at the property? -->

      <hr class="my-10" />

      <div class="inline-flex">
        <p class="question mt-0.5">
          Do you have any relationship with the seller or real estate agent?
        </p>
        <button class="mb-2 ml-0">
          <Whoops whoopsButtonVifTextAndIcon="5" />
        </button>
        <div class="inline">
          <span class="required">(required)</span>
        </div>
      </div>

      <div class="mt-4 space-y-4">
        <div>
          <input
            type="radio"
            id="none"
            value="none"
            name="reviewerAnyRelWithSellerOrAgent"
            required
            v-model="reviewerAnyRelWithSellerOrAgent"
          />
          <label class="ml-4 inline-flex align-bottom text-base" for="none"
            ><fa
              :icon="['far', 'meh-blank']"
              class="text-zaggray text-xl"
            />&nbsp;&nbsp;None</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="acquainted"
            value="acquainted"
            name="reviewerAnyRelWithSellerOrAgent"
            required
            v-model="reviewerAnyRelWithSellerOrAgent"
          />
          <label
            class="ml-4 inline-flex align-bottom text-base"
            for="acquainted"
            ><fa
              :icon="['far', 'meh']"
              class="text-zaggray text-xl"
            />&nbsp;&nbsp;Acquainted</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="solid"
            value="solid"
            name="reviewerAnyRelWithSellerOrAgent"
            required
            v-model="reviewerAnyRelWithSellerOrAgent"
          />
          <label class="ml-4 inline-flex align-bottom text-base" for="solid"
            ><fa
              :icon="['far', 'grin']"
              class="text-zaggray text-xl"
            />&nbsp;&nbsp;Solid</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="solid"
            value="isSeller"
            name="reviewerAnyRelWithSellerOrAgent"
            required
            v-model="reviewerAnyRelWithSellerOrAgent"
          />
          <label class="ml-4 inline-flex align-bottom text-base" for="isSeller"
            ><fa
              :icon="['fas', 'house-user']"
              class="text-zaggray text-opacity-85 text-xl"
            />&nbsp;&nbsp;I am the owner&thinsp;/&thinsp;seller</label
          >
          <!-- also consider 'far', 'user-circle' -->
        </div>

        <div>
          <input
            type="radio"
            id="solid"
            value="isSellersAgent"
            name="reviewerAnyRelWithSellerOrAgent"
            required
            v-model="reviewerAnyRelWithSellerOrAgent"
          />
          <label
            class="ml-5 inline-flex align-bottom text-base"
            for="isSellersAgent"
            ><fa
              :icon="['far', 'clipboard']"
              class="text-zaggray text-xl"
            />&nbsp;&nbsp;I am the owner&thinsp;/&thinsp;seller's agent</label
          >
        </div>
      </div>

      <hr class="my-10" />

      <div class="inline-flex">
        <p class="question mt-0.5">Privacy</p>
        <button class="mb-2 ml-0">
          <Whoops whoopsButtonVifTextAndIcon="4" />
        </button>
        <div class="inline">
          <span class="text-zaggray mb-0.5 pl-1 pr-0.5 text-sm font-normal"
            >choice for this review</span
          >
          <span class="required">(required)</span>
        </div>
      </div>

      <div class="mb-16 mt-4 space-y-4">
        <div>
          <input
            type="radio"
            id="postFullPublic"
            value="postFullPublic"
            name="reviewPrivacy"
            required
            v-model="reviewPrivacy"
          />
          <label
            class="ml-4 inline-flex align-bottom text-base"
            for="postFullPublic"
            ><fa
              icon="user"
              class="text-zaggray text-xl"
            />&nbsp;&nbsp;Public</label
          >
        </div>

        <div>
          <input
            type="radio"
            id="postAnonPublic"
            value="postAnonPublic"
            name="reviewPrivacy"
            required
            v-model="reviewPrivacy"
          />
          <label
            class="ml-4 inline-flex align-bottom text-base"
            for="postAnonPublic"
            ><fa
              icon="user-secret"
              class="text-zaggray text-xl"
            />&nbsp;&nbsp;Incognito<span
              class="ml-2 mt-1 inline-flex align-text-bottom text-xs"
              >(user name)</span
            ></label
          >
        </div>

        <div>
          <input
            type="radio"
            id="keepFullySecret"
            value="keepFullySecret"
            name="reviewPrivacy"
            required
            v-model="reviewPrivacy"
          />
          <label
            class="ml-4 inline-flex align-bottom text-base"
            for="keepFullySecret"
            ><fa icon="ghost" class="text-zaggray text-xl" />&nbsp;&nbsp;Secret
            <span class="ml-2 mt-1 inline-flex align-text-bottom text-xs"
              >(your eyes only)</span
            ></label
          >
        </div>
        <div
          v-if="reviewPrivacy === 'keepFullySecret'"
          class="ml-8 inline-flex pt-6 align-text-bottom text-sm italic"
        >
          Secret reviews become Incognito after 30+ days
          <span class="ml-1 mb-2 pb-2 text-xs italic underline"
            ><Whoops whoopsButtonVifTextAndIcon="4"
          /></span>
        </div>
      </div>

      <button
        v-if="!isPending"
        class="text-zaggray rounded-md border border-indigo-900 py-1.5 px-5 text-lg hover:bg-indigo-900 hover:text-white hover:ring-pink-500"
      >
        Submit review&nbsp;&nbsp;<fa icon="pencil-alt" />
      </button>
      <!-- <button v-else disabled>Saving...</button> -->
      <button v-else disabled><WaitingToLoad /></button>
    </form>
  </div>
</template>

<script>
import { ref, computed, useAsyncState, onMounted, watch } from "vue";
import { geohashForLocation } from "geofire-common";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import useSubCollection from "@/composables/useSubCollection";
import getSetUserInfo from "@/composables/getSetUserInfo";
import getDocumentId from "@/composables/getDocumentId";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
// import { FileSelector, Dropzone, DialogButton } from 'vue3-file-selector'
import TagInput from "@/components/TagInput.vue";
import Whoops from "@/components/Whoops";
import { deleteDoc } from "@/composables/useDocument";
import WaitingToLoad from "@/components/WaitingToLoad.vue";

export default {
  props: ["str"],
  components: {
    TagInput: TagInput,
    Whoops,
    WaitingToLoad,
  },
  data() {
    return {
      tags: ["Hello", "World"],
      tags2: ["Hello"],
      location: "",
    };
  },

  mounted() {
    // State of NJ = LatLngBounds(39.833851, -74.871826)     &location=39.833851%-74.871826
    const autocomplete = new google.maps.places.Autocomplete(
      //document.getElementById("autocomplete"),
      this.$refs["autocomplete"],
      {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(39.833851, -74.871826)
        ),
        fields: ["address_components", "geometry", "formatted_address"],
        componentRestrictions: { country: ["us", "ca"] },
        types: ["address"],
      }
    );

    autocomplete.addListener("place_changed", (blur) => {
      const place = autocomplete.getPlace();
      // new code
      // console.log("place API", place);
      // console.log('place API', place.geometry.location.lat(), place.geometry.location.lng());
      this.googleInputSelected = true;
      this.showAddressErr = false;
      if (place.formatted_address) {
        this.setPropertyVars(
          place.address_components,
          place.formatted_address,
          place.geometry
        );
        this.address = place.formatted_address;
        this.isPropertyExist();
        this.storeDataLocally();
      }
      // new code

      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
    });
    this.isPropertyExist();
    this.toggleExtendedAddr();
  },

  methods: {
    checkIfChanged() {
      console.log("changed detected");
    },
  },

  setup(props, context) {
    /* BEGIN from vue3-file-selector */
    // const files = ref([])
    /* END from vue3-file-selector */
    if (getLocalStorage("isFromHome") !== null) {
      clearLocalStorage("review_form");
      clearLocalStorage("isFromHome");
    }
    const { filePath, url, uploadImage } = useStorage();
    const { error, addDoc } = useCollection("properties");
    const { subCollectionError, addSubDoc } = useSubCollection(
      "reviews",
      "properties"
    );
    const { getDocIdErr, getDocId } = getDocumentId("properties");
    const { userErr, loggedInUser, getLoggedInUserInfo, updateUserReviewHistory } = getSetUserInfo();
    const { user } = getUser();
    const router = useRouter();

    const address = props.str ? ref(props.str) : ref("");
    const googleInputSelected = ref(false);
    const showAddressErr = ref(false);
    const extended_address = ref("");
    const property_type = ref("");
    // const propPros = ref('')
    // const propCons = ref('')
    const propPros = ref([]);
    const propCons = ref([]);
    const whenReviewerVisitedProp = ref("");
    const whatInListingIsUntrue = ref("");
    const whatToRepairUpgradeAdd = ref("");
    const reviewerTextReview = ref("");
    const reviewerAnyRelWithSellerOrAgent = ref("");
    const reviewPrivacy = ref("");

    // BELOW = part of shift from displayName to nameToShow, per Trello card https://trello.com/c/wi0X1pgX
    const nameToShow = ref("");

    const smell_rating = ref(0);
    const kitchen_rating = ref(0);
    const bath_rating = ref(0);
    // const windows_rating = ref("");
    const location_rating = ref(0);
    const setting_rating = ref(0);

    const file = ref([]); // was const file = ref(null)
    const fileError = ref(null);
    const imageNames = ref(null);
    const imageFiles = ref(null);
    const isPending = ref(false);
    const selectedImages = ref([]);
    const existPropertyId = ref(null);
    const propertyJustCreated = ref(false);
    const toggleExtendedAddrProp = ref(false);
    // Property parent level vars
    const latNumber = ref(null);
    const lngNumber = ref(null);
    const locality = ref(null);
    const postal_code = ref(null);
    const street_address = ref(null);
    const street_number = ref(null);
    const route = ref(null);

    if (user.value) {
      getLoggedInUserInfo(user.value.uid);
    }

    const locStorageItems = [
      "exist_prop_addr",
      "exist_prop_type",
      "exist_prop_extended_addr",
      "exist_prop_id",
      "latNumber",
      "lngNumber",
      "locality",
      "postal_code",
      "street_address",
      "street_number",
      "route",
      "addrToMatch",
    ];

    // const docRef = doc(projectFirestore, properties, 'm2szqALfIP1Ucbh3eWhy'); // part of Mark's test to update numberOfReviews upon review submit. Temporarily hard-coded propertyId

    if (getLocalStorage("exist_prop_addr") !== null) {
      address.value = getLocalStorage("exist_prop_addr");
    }

    if (getLocalStorage("exist_prop_extended_addr") !== null) {
      extended_address.value = getLocalStorage("exist_prop_extended_addr");
    }

    if (getLocalStorage("exist_prop_id") !== null) {
      existPropertyId.value = getLocalStorage("exist_prop_id");
    }

    if (getLocalStorage("exist_prop_type") !== null) {
      // mark changed from exist_prop_id
      property_type.value = getLocalStorage("exist_prop_type");
    }

    // Retriving the form data from localstorage
    if (getLocalStorage("review_form") !== null) {
      const review_form_data = JSON.parse(getLocalStorage("review_form"));
      address.value = review_form_data.address;
      extended_address.value = review_form_data.extended_address;
      property_type.value = review_form_data.property_type;
      propPros.value = review_form_data.propPros;
      propCons.value = review_form_data.propCons;
      whenReviewerVisitedProp.value = review_form_data.whenReviewerVisitedProp;
      whatInListingIsUntrue.value = review_form_data.whatInListingIsUntrue;
      whatToRepairUpgradeAdd.value = review_form_data.whatToRepairUpgradeAdd;
      reviewerTextReview.value = review_form_data.reviewerTextReview;
      reviewerAnyRelWithSellerOrAgent.value =
        review_form_data.reviewerAnyRelWithSellerOrAgent;
      reviewPrivacy.value = review_form_data.reviewPrivacy;
      // Nothing here for 'nameToShow' because it isn't actuall data in the form
      smell_rating.value = review_form_data.smell_rating;
      kitchen_rating.value = review_form_data.kitchen_rating;
      bath_rating.value = review_form_data.bath_rating;
      location_rating.value = review_form_data.location_rating;
      setting_rating.value = review_form_data.setting_rating;
      setting_rating.value = review_form_data.setting_rating;

      // place values
      latNumber.value = review_form_data.latNumber;
      lngNumber.value = review_form_data.lngNumber;
      locality.value = review_form_data.locality;
      postal_code.value = review_form_data.postal_code;
      street_address.value = review_form_data.street_address;
      street_number.value = review_form_data.street_number;
      route.value = review_form_data.route;

      googleInputSelected.value = true;
    }

    if (
      getLocalStorage("latNumber") !== null &&
      getLocalStorage("lngNumber") !== null &&
      getLocalStorage("locality") !== null &&
      getLocalStorage("postal_code") !== null &&
      getLocalStorage("street_address") !== null &&
      getLocalStorage("street_number") !== null &&
      getLocalStorage("route") !== null &&
      getLocalStorage("exist_prop_addr") !== null &&
      getLocalStorage("exist_prop_addr") === address.value
    ) {
      latNumber.value = getLocalStorage("latNumber");
      lngNumber.value = getLocalStorage("lngNumber");
      locality.value = getLocalStorage("locality");
      postal_code.value = getLocalStorage("postal_code");
      street_address.value = getLocalStorage("street_address");
      street_number.value = getLocalStorage("street_number");
      route.value = getLocalStorage("route");
      googleInputSelected.value = true;
    }

    // BELOW = part of shift from displayName to nameToShow, per Trello card https://trello.com/c/wi0X1pgX
    // if (reviewPrivacy.value == 'postFullPublic' && loggedInUser.realLastName !== null) {nameToShow = loggedInUser.realFirstName + " " + loggedInUser.realMiddleNameOrInit + " " + loggedInUser.realLastName}
    // if (reviewPrivacy.value == 'postAnonPublic' || reviewPrivacy.value == 'keepFullySecret' || loggedInUser.realLastName == null) {nameToShow = loggedInUser.displayName}
    // this line isn't needed (added as third 'or' condition in line above): if (reviewPrivacy.value == 'postFullPublic' && user.realLastName == null) {nameToShow = user.displayName}

    const createParentPropertyDoc = async () => {
      if (address.value == "") {
        return false;
      }

      const createPropCollection = await addDoc({
        address: address.value,
        extended_address:
          extended_address.value !== "" ? extended_address.value : null,
        property_type: property_type.value,
        locality: locality.value,
        postal_code: postal_code.value,
        street_address: street_address.value,
        street_number: street_number.value,
        route: route.value,
        latNumber: latNumber.value,
        lngNumber: lngNumber.value,
        allPros: [],
        allCons: [],
        allWhatInListingIsUntrue: [],
        allWhatToRepairUpgradeAdd: [],
        allReviewerTextReviews: [],
        allReviews: [],
        topPros: [],
        topCons: [],
        topWhatInListingIsUntrue: [],
        topWhatToRepairUpgradeAdd: [],
        topreviewerTextReviews: [],
        zillowUrl: "",
        redfinUrl: "",
        createdAt: timestamp,
        geohash: geohashForLocation([
          parseFloat(latNumber.value),
          parseFloat(lngNumber.value),
        ]),
      });

      if (!error.value) {
        existPropertyId.value = createPropCollection.id;
        // console.log(existPropertyId.value);
      }
    };

    const handleSubmit = async () => {
      // console.log("current User info", loggedInUser.value);

      isPending.value = true;
      if (!googleInputSelected.value) {
        showAddressErr.value = true;
        isPending.value = false;
        const addressInput = document.getElementById("autocomplete");
        addressInput.focus();
        return false;
      }

      if (existPropertyId.value == null) {
        const createPropCollection = await addDoc({
          address: address.value,
          extended_address:
            extended_address.value !== "" ? extended_address.value : null,
          property_type: property_type.value,
          locality: locality.value,
          postal_code: postal_code.value,
          street_address: street_address.value,
          street_number: street_number.value,
          route: route.value,
          latNumber: latNumber.value,
          lngNumber: lngNumber.value,
          allPros: [],
          allCons: [],
          allWhatInListingIsUntrue: [],
          allWhatToRepairUpgradeAdd: [],
          allReviewerTextReviews: [],
          allReviews: [],
          topPros: [],
          topCons: [],
          topWhatInListingIsUntrue: [],
          topWhatToRepairUpgradeAdd: [],
          topreviewerTextReviews: [],
          zillowUrl: "",
          redfinUrl: "",
          createdAt: timestamp,
          geohash: geohashForLocation([
            parseFloat(latNumber.value),
            parseFloat(lngNumber.value),
          ]),
        });

        if (!error.value) {
          existPropertyId.value = createPropCollection.id;
          console.log(existPropertyId.value);
        }
      }

      await imageFiles.value.forEach((file) => {
        uploadImage(file, "review_images", existPropertyId.value);
        selectedImages.value.push(filePath.value);
      });

      const res = await addSubDoc({
        address: address.value,
        extended_address: extended_address.value,
        property_type: property_type.value,
        propPros: propPros.value,
        propCons: propCons.value,
        whenReviewerVisitedProp: whenReviewerVisitedProp.value,
        whatInListingIsUntrue: whatInListingIsUntrue.value,
        whatToRepairUpgradeAdd: whatToRepairUpgradeAdd.value,
        reviewerTextReview: reviewerTextReview.value,
        reviewerAnyRelWithSellerOrAgent: reviewerAnyRelWithSellerOrAgent.value,
        reviewPrivacy: reviewPrivacy.value,

        // BELOW = part of shift from displayName to nameToShow, per Trello card https://trello.com/c/wi0X1pgX
        nameToShow: nameToShow.value,

        smell_rating: smell_rating.value,
        kitchen_rating: kitchen_rating.value,
        bath_rating: bath_rating.value,
        // windows_rating: windows_rating.value,
        location_rating: location_rating.value,
        setting_rating: setting_rating.value,
        location_rating: location_rating.value,

        userId: user.value.uid,
        userName: loggedInUser.value.displayName,
        isRealEstateAgent: loggedInUser.value.isRealEstateAgent,
        isVerified: loggedInUser.value.isVerified,
        // coverUrl: url.value,
        filePath: selectedImages.value, // so we can delete it later
        propertyId: existPropertyId.value,
        createdAt: timestamp,
      });
      isPending.value = false;
      if (!subCollectionError.value) {
        locStorageItems.forEach((item, index) => {
          clearLocalStorage(item);
        });
        clearLocalStorage("review_form");
        // clearLocalStorage("exist_prop_addr");
        // clearLocalStorage("exist_prop_extended_addr");
        // clearLocalStorage("exist_prop_id");
        const userReviewHistory = {
          propertyId: existPropertyId.value,
          reviewId: res.id,
          reviewPrivacy: reviewPrivacy.value,
        }
        updateUserReviewHistory(user.value.uid, reviewPrivacy.value, userReviewHistory);
        router.push({ name: "AfterReviewSubmit", params: { id: res.id } });
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg", "image/webp", "image/heic"];

    //Begin Oscar Edits
    imageFiles.value = [];
    imageNames.value = [];

    const handleChange = (e) => {
      let allImages = e.target.files;
      for (let i = 0; i < allImages.length; i++) {
        // if (!imageNames.value.includes(allImages[i].name)) {
        //   imageNames.value.push(allImages[i].name);
        //   imageFiles.value.push(allImages[i]);
        // }
        if (!types.includes(allImages[i].type)) {
          file.value = null;
          fileError.value =
            "Please select an image file (png, heic, webp, jpg or jpeg)";
          return false;
        } else {
          imageNames.value.push(allImages[i].name);
          imageFiles.value.push(allImages[i]);
        }
      }
    };

    const isPropertyExist = async () => {
      // console.log("called on mounted");
      if (address.value == "" || property_type.value == "") {
        return false;
      }

      if (extended_address.value == "") {
        extended_address.value = null;
      }
      const fieldsKeyValue = [
        ["address", "==", address.value],
        ["extended_address", "==", extended_address.value],
        ["property_type", "==", property_type.value],
      ];

      const property = await getDocId(fieldsKeyValue);
      // console.log(property);
      if (property) {
        existPropertyId.value = property;
      } else {
        createParentPropertyDoc();
      }
    };

    const checkIfGoogleInputSelected = () => {
      if (!googleInputSelected.value) {
        showAddressErr.value = true;
        isPending.value = false;
        const addressInput = document.getElementById("autocomplete");
        // addressInput.focus();
        return false;
      }
    };

    function getLocalStorage(key) {
      return localStorage.getItem(key);
    }

    function clearLocalStorage(key) {
      localStorage.removeItem(key);
    }

    const setPropertyVars = (
      address_components,
      formatted_address,
      geometry
    ) => {
      locStorageItems.forEach((item, index) => {
        clearLocalStorage(item);
      });
      extended_address.value = "";
      existPropertyId.value = null;
      property_type.value = "";

      if (
        getLocalStorage("addrToMatch") !== null &&
        getLocalStorage("addrToMatch") !== formatted_address
      ) {
        clearLocalStorage("review_form");
        clearFormData();
      }
      // console.log(address_components);
      localStorage.setItem("addrToMatch", formatted_address);

      latNumber.value = geometry.location.lat();
      lngNumber.value = geometry.location.lng();
      localStorage.setItem("latNumber", geometry.location.lat());
      localStorage.setItem("lngNumber", geometry.location.lng());
      address_components.forEach((item, key) => {
        if (item.types.includes("locality")) {
          localStorage.setItem("locality", item.long_name);
          locality.value = item.long_name;
        }
        if (item.types.includes("postal_code")) {
          localStorage.setItem("postal_code", item.long_name);
          postal_code.value = item.long_name;
        }
        if (item.types.includes("route")) {
          localStorage.setItem("street_address", item.long_name);
          street_address.value = item.long_name;
          localStorage.setItem("route", item.long_name);
          route.value = item.long_name;
        }
        if (item.types.includes("street_number")) {
          localStorage.setItem("street_number", item.long_name);
          street_number.value = item.long_name;
        }
      });
    };

    const toggleExtendedAddr = () => {
      const allowedType = [
        "condo",
        "coop",
        "townhouse",
        "rentalapartment",
        "commercial",
        "raw_land",
      ];
      if (allowedType.includes(property_type.value)) {
        toggleExtendedAddrProp.value = true;
      } else {
        toggleExtendedAddrProp.value = false;
        extended_address.value = "";
      }
    };

    const storeDataLocally = () => {
      const reviewForm = {
        address: address.value,
        extended_address: extended_address.value,
        property_type: property_type.value,
        propPros: propPros.value,
        propCons: propCons.value,
        whenReviewerVisitedProp: whenReviewerVisitedProp.value,
        whatInListingIsUntrue: whatInListingIsUntrue.value,
        whatToRepairUpgradeAdd: whatToRepairUpgradeAdd.value,
        reviewerTextReview: reviewerTextReview.value,
        reviewerAnyRelWithSellerOrAgent: reviewerAnyRelWithSellerOrAgent.value,
        reviewPrivacy: reviewPrivacy.value,

        // BELOW = part of shift from displayName to nameToShow, per Trello card https://trello.com/c/wi0X1pgX
        nameToShow: nameToShow.value,

        smell_rating: smell_rating.value,
        kitchen_rating: kitchen_rating.value,
        bath_rating: bath_rating.value,
        location_rating: location_rating.value,
        setting_rating: setting_rating.value,
        latNumber: latNumber.value,
        lngNumber: lngNumber.value,
        locality: locality.value,
        postal_code: postal_code.value,
        street_address: street_address.value,
        street_number: street_number.value,
        route: route.value,
      };

      localStorage.setItem("review_form", JSON.stringify(reviewForm));
    };

    const clearFormData = () => {
      extended_address.value = "";
      property_type.value = "";
      propPros.value = [];
      propCons.value = [];
      whenReviewerVisitedProp.value = "";
      whatInListingIsUntrue.value = "";
      whatToRepairUpgradeAdd.value = "";
      reviewerTextReview.value = "";
      reviewerAnyRelWithSellerOrAgent.value = "";
      reviewPrivacy.value = "";
      smell_rating.value = 0;
      kitchen_rating.value = 0;
      bath_rating.value = 0;
      location_rating.value = 0;
      setting_rating.value = 0;
      setting_rating.value = 0;
    };

    return {
      imageNames,
      address,
      property_type,
      propPros,
      propCons,
      whenReviewerVisitedProp,
      whatInListingIsUntrue,
      whatToRepairUpgradeAdd,
      reviewerTextReview,
      reviewerAnyRelWithSellerOrAgent,
      reviewPrivacy,

      // BELOW = part of shift from displayName to nameToShow, per Trello card https://trello.com/c/wi0X1pgX
      nameToShow,

      smell_rating,
      kitchen_rating,
      bath_rating,
      // windows_rating,
      location_rating,
      setting_rating,
      extended_address,
      handleSubmit,
      fileError,
      handleChange,
      isPending,
      selectedImages,
      isPropertyExist,
      setPropertyVars,
      toggleExtendedAddr,
      toggleExtendedAddrProp,
      checkIfGoogleInputSelected,
      googleInputSelected,
      showAddressErr,
      storeDataLocally,
    }; /* from vue3-file-selector:  , files  */
  },
};
</script>

<style scoped>
/* BEGIN star rating from https://jsfiddle.net/swfour/fnU5V/4/ */
.rating {
  /* float:left; */
  border: none;
}
.rating:not(:checked) > input {
  position: absolute;
  top: auto; /* was -9999px; */
  clip: rect(0, 0, 0, 0);
}
.rating:not(:checked) > label {
  float: right;
  width: 1.05em; /* was width:.90em; */
  padding: 0 0.1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 200%;
  line-height: 1.2;
  color: rgba(28, 117, 188, 0.43); /* was #868e96; */
}
.rating:not(:checked) > label:before {
  content: "★ "; /* was content:'★ '; */
}
.rating > input:checked ~ label {
  color: #ffc107;
}
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: rgba(28, 117, 188, 0.8);
}
.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffc107;
}
.rating > label:active {
  position: relative;
}
/* END star rating from https://jsfiddle.net/swfour/fnU5V/4/ */

.pac-item {
  cursor: pointer;
  /* color: zagblue; */
}

.pac-item:hover {
  background-color: #dedaee;
}

.pac-item-query {
  font-size: 16px;
}
</style>
