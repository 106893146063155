<template>
  <div class="mx-auto my-0 bg-slate-200">
    <div class="print:hidden">
      <div class="mx-auto my-0 mt-16 max-w-screen-xl pt-24">
        <div
          class="col-span-1 mx-4 grid gap-8 sm:grid-cols-2 md:grid-cols-12 xl:mx-0">
          <div class="col-span-1 sm:col-span-2 md:col-span-3">
            <h2 class="pb-3.5 font-bold uppercase">Zagnetic is social</h2>
            <p class="text-slate-500">Are you?</p>
            <ul
              class="inline-flex list-inside list-none space-x-2 pt-3.5 text-slate-500">
              <li class="flex-1">
                <a
                  class="text-muted text-hover-primary"
                  href="https://www.facebook.com/Zagnetic"
                  target="_blank"
                  title="facebook"
                  ><fa :icon="['fab', 'facebook']" fixed-width
                /></a>
              </li>
              <li class="flex-1">
                <a
                  class="text-muted text-hover-primary"
                  href="https://www.instagram.com/zagnetic/"
                  target="_blank"
                  title="instagram"
                  ><fa :icon="['fab', 'instagram']" fixed-width
                /></a>
              </li>

              <li class="flex-1">
                <a
                  class="text-muted text-hover-primary"
                  href="https://www.pinterest.com/zagnetic/"
                  target="_blank"
                  title="pinterest"
                  ><fa :icon="['fab', 'pinterest']" fixed-width
                /></a>
              </li>
              <li class="flex-1">
                <a
                  class="text-muted text-hover-primary opacity-20"
                  href="#"
                  target="_blank"
                  title="twitter"
                  ><fa :icon="['fab', 'twitter']" fixed-width
                /></a>
              </li>
            </ul>
          </div>

          <div class="col-span-1 sm:col-span-1 md:col-span-3">
            <h2 class="pb-3.5 font-bold uppercase">Pages</h2>
            <ul class="list-inside list-none space-y-3 text-slate-500">
              <li>
                <router-link :to="{ name: 'FAQ' }"
                  ><span class="hover:text-slate-400">FAQ</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'ForRealEstatePros' }"
                  >Real Estate Pros</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Sitemap' }"
                  ><span class="hover:text-slate-400"
                    >Sitemap</span
                  ></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Home' }"
                  ><span class="hover:text-slate-400"
                    >Contact</span
                  ></router-link
                >
              </li>
              <li>
                <a
                  class="text-muted text-hover-primary"
                  href="https://forms.gle/TptSUCWDZvJkU55y6"
                  target="_blank"
                  title="feedback"
                  >Feedback
                  <fa
                    :icon="['far', 'lightbulb']"
                    fixed-width
                    class="rounded-xl bg-yellow-300/60 py-[0.1rem]"
                /></a>
              </li>
            </ul>
            <p class="text-slate-500"></p>
          </div>

          <div class="col-span-1 sm:col-span-1 md:col-span-2 xl:col-span-3">
            <h2 class="pb-3.5 font-bold uppercase">More pages</h2>
            <ul class="list-inside list-none space-y-3 text-slate-500">
              <li>
                <router-link :to="{ name: 'About' }"
                  ><span class="hover:text-slate-400">About</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Terms' }"
                  ><span class="hover:text-slate-400">Terms</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Privacy' }"
                  ><span class="hover:text-slate-400"
                    >Privacy</span
                  ></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Accessibility' }"
                  ><span class="hover:text-slate-400"
                    >Accessibility Help</span
                  ></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Dmca' }"
                  ><span class="hover:text-slate-400">DMCA</span></router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-span-1 sm:col-span-2 md:col-span-4 xl:col-span-3">
            <h2 class="pb-3.5 font-bold uppercase">Occasional newsletter</h2>
            <p class="pb-1 text-slate-500">Interesting stuff</p>
            <p class="text-sm italic text-slate-500">
              (in case you're interested)
            </p>
            <span
              ><input
                class="ppplaceholder-zagblue mt-3.5 w-full rounded bg-slate-200 p-1 text-sm placeholder-slate-400 ring-1 ring-slate-800 focus:bg-white focus:placeholder-slate-400 focus:placeholder-opacity-50 active:bg-white sm:w-1/2 md:w-full lg:w-4/5"
                placeholder=" you@example.com" /><fa
                :icon="['fas', 'envelope']"
                class="z-0 -ml-7 text-slate-400"
            /></span>
          </div>
        </div>

        <div class="">
          <img :src="zagneticSvgLogo" class="mx-auto h-80 px-6 pt-8" />
          <!-- was <img src="@/assets/zagnetic-logo.svg" class="mx-auto h-80 px-6 pt-8" /> -->
        </div>
      </div>
    </div>

    <div class="bg-slate-800 print:bg-transparent">
      <div class="mx-auto mt-12 max-w-screen-xl py-4">
        <span
          class="ml-4 text-sm font-light text-slate-300 print:text-slate-900 md:ml-0"
          >&nbsp;&copy; 2024, Zagnetic LLC. All rights reserved.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const zagneticSvgLogo =
      "https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/zagnetic-dev-images%2Fzagnetic-logo.svg?alt=media&token=13e62d0b-fc68-45eb-940a-85d198da15bb";

    const router = useRouter();

    return {
      zagneticSvgLogo,
    };
  },
};
</script>

<style scoped>
/* .footer {
    padding: 16px 10px;
    margin-bottom: 60px;
    background: white;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  span {
    font-size: 14px;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #eee;
  } */
</style>
