// useCollection.js is to add a new document to a collection

import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { collection, doc, setDoc, updateDoc, deleteField } from "firebase/firestore"; // ### new for Firebase 8 to 9 refactoring

const useSubCollection = (subCollectionName, collectionName) => {
  const subCollectionError = ref(null);
  const isPending = ref(false);

  // add a new document
  const addSubDoc = async (docObj) => {
    subCollectionError.value = null;
    isPending.value = true;
    console.log(docObj);
    try {
      const newSubCollectionRef = doc(
        collection(
          projectFirestore,
          `${collectionName}/${docObj.propertyId}/${subCollectionName}`
        )
      );

      // Adding review id explicitly
      docObj["review_id"] = newSubCollectionRef.id;

      const res = await setDoc(newSubCollectionRef, docObj);

      return newSubCollectionRef;
    } catch (err) {
      console.log(err);
      subCollectionError.value = "Sorry, could not add to the database";
      isPending.value = false;
    }
  };

  // BEGIN updateSubDoc  >>> This is mostly just a copy of addSubDoc() above - NOT FINISHED <<<

  // ** Firestore Documentation to update fields: https://firebase.google.com/docs/firestore/manage-data/add-data#update-data
  // ** Firestore Documentation to update fields in nested objects: https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
  // ** Firestore Documentation to delete fields: https://firebase.google.com/docs/firestore/manage-data/delete-data#fields
  // ** Firestore Documentation to delete an entire document: https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
  const updateSubDoc = async (parentDocId, docId, updateObj) => {
    subCollectionError.value = null;
    isPending.value = true;
    let docRef = doc(projectFirestore, `${collectionName}/${parentDocId}/${subCollectionName}`, docId);
    try {
      const res = await updateDoc(docRef, updateObj); // ### before Firebase 8 to 9 refactoring, was: const res = await docRef.update(updates)
      isPending.value = false;
      return res;
    } catch (err) {
      console.log(err);
      subCollectionError.value = "Sorry, could not update the database";
      isPending.value = false;
    }
  };
  // END updateSubDoc

  const addSubDocWithName = async (docObj) => {
    subCollectionError.value = null;
    isPending.value = true;
    console.log(docObj);
    try {
      const prepareDoc = doc(
        projectFirestore,
        `${collectionName}/${docObj.parentDocId}/${subCollectionName}`,
        docObj.parentDocId
      );

      const res = await setDoc(prepareDoc, docObj);
      return prepareDoc;
    } catch (err) {
      console.log(err);
      subCollectionError.value = "Sorry, could not add to the database";
      isPending.value = false;
    }
  };

  return {
    subCollectionError,
    updateSubDoc,
    addSubDoc,
    isPending,
    addSubDocWithName,
  };
};

export default useSubCollection;
