<template>
  <div class="max-w-screen-xl pb-1" v-if="loggedInUser">
    <h1
      class="mx-auto mt-16 w-full break-words rounded-full bg-sky-300 py-8 px-2 text-center text-4xl font-bold sm:w-5/6 md:w-3/4 lg:text-7xl"
    >
      Welcome &thinsp;{{ loggedInUser.displayName }}!
    </h1>
    <h3
      class="bg-highlight mx-auto my-12 break-words rounded-full py-6 px-2 text-center text-2xl font-semibold leading-9 sm:w-4/6 sm:text-2xl md:my-24"
    >
      Thank you for joining us.
    </h3>

    <h3
      class="bg-conred mx-auto mb-10 w-5/6 break-words rounded-full py-6 px-2 text-center text-base font-medium leading-snug text-white sm:w-3/6 sm:text-lg md:w-1/3"
    >
      Please check your email<fa
        :icon="['fas', 'envelope']"
        class="fa-fw ml-1.5 mr-2 text-base text-white md:text-base"
      />for a confirmation link
    </h3>

    <p class="text-conred mt-6 mb-12 text-center font-medium italic">
      (&thinsp;helps prevent spam&thinsp;)
    </p>

    <p class="my-24 mx-auto text-center text-base text-indigo-900/90">
      <fa
        :icon="['fas', 'envelope']"
        class="fa-fw ml-1.5 mr-2 hidden text-base text-indigo-900/90 sm:inline-flex md:text-base"
      />Subject line is: &thinsp;
      <span
        class="mx-auto rounded-full bg-indigo-50/70 px-4 py-2.5 text-center text-sm font-medium text-indigo-900/90 underline ring-0 sm:text-base"
        >"Please verify your email for Zagnetic"</span
      >
    </p>

    <p class="my-6 mx-auto text-center text-base text-indigo-900/90">
      <fa
        :icon="['fas', 'at']"
        class="fa-fw ml-1.5 mr-2 hidden text-base text-indigo-900/90 sm:inline-flex md:text-base"
      />The verification email is sent from: &thinsp;
      <span
        class="mx-auto rounded-full bg-indigo-50/70 px-4 py-2.5 text-center text-sm font-medium text-indigo-900/90 underline ring-0 sm:text-base"
        >"verify@zagnetic&thinsp;.&thinsp;com"</span
      >
    </p>

    <p
      class="mx-auto mb-24 mt-16 text-center text-base leading-10 text-indigo-900/90"
    >
      <fa
        :icon="['fas', 'link']"
        class="fa-fw ml-1.5 mr-2 hidden text-base text-indigo-900/90 sm:inline-flex md:text-base"
      />The verification link in the email looks like this: &thinsp;<br />
      <span
        class="mx-auto rounded-full bg-indigo-50/70 px-4 py-2.5 text-center text-sm font-medium leading-10 text-indigo-900/90 underline ring-0 sm:text-base"
        >"https://real-revs.firebaseapp.com/__/auth/action?mode=verifyEmail&oobCode="</span
      >
    </p>

    <!-- <div class="my-12 grid grid-cols-3 md:my-36">
      <div class="col-span-3 mx-auto mb-12 md:col-span-1 md:mb-0">
        <router-link :to="{ name: 'Account' }">
          <button class="borderbtn font-bold uppercase tracking-widest">
            Go to your Profile<fa
              :icon="['fas', 'user-circle']"
              class="fa-fw ml-1.5 text-base text-indigo-900 md:text-base"
            />
          </button>
        </router-link>
      </div>
      <div
        class="col-span-3 mx-auto mb-12 hover:text-white md:col-span-1 md:mb-0"
      >
        <button
          class="borderbtn font-bold uppercase tracking-widest"
          @click="goHome"
        >
          return to home page<fa
            :icon="['fas', 'search']"
            class="fa-fw ml-1.5 text-base text-indigo-900 hover:text-white md:text-base"
          />
        </button>
      </div>
      <div class="col-span-3 mx-auto md:col-span-1">
        <router-link :to="{ name: 'CreateReview' }"
          ><button
            class="borderbtn font-bold uppercase tracking-widest"
            @click="goHome"
          >
            Write a review<fa
              :icon="['fas', 'pencil-alt']"
              class="fa-fw ml-1.5 text-base text-indigo-900 md:text-base"
            />
          </button>
        </router-link>
      </div>
    </div> -->

    <!-- <p class="mb-12 text-lg italic text-center">Please check your email for a confirmation link (this helps prevent spam)</p>

    <p class="mb-12 text-center">What now?</p>
    <div class="row mx-auto space-x-6">
      <button
        class="bg-conred rounded-full py-3 px-6 text-white hover:opacity-70"
      >
        Go to your profile
      </button>
      <button
        class="rounded-full bg-indigo-900 py-3 px-6 text-white hover:opacity-70"
      >
        Go to your profile
      </button>
      <button
        class="bg-progreen rounded-full py-3 px-6 text-white hover:opacity-70"
      >
        Go to your profile
      </button>
    </div> -->
  </div>
</template>

<script>
import { ref, computed, useAsyncState } from "vue";
import { useRouter } from "vue-router";
import Whoops from "@/components/Whoops";
import getUser from "@/composables/getUser";
import getSetUserInfo from "@/composables/getSetUserInfo";

export default {
  components: {
    Whoops,
  },

  setup() {
    const { user } = getUser();
    const { userErr, loggedInUser, getLoggedInUserInfo } = getSetUserInfo();
    const router = useRouter();

    const goHome = async () => {
      router.push({ name: "Home" });
    };

    if (user.value) {
      getLoggedInUserInfo(user.value.uid);
    } else {
      if (localStorage.getItem("display_name")) {
        const local_display_name = localStorage.getItem("display_name");
        loggedInUser.value = { displayName: local_display_name };
      } else {
        loggedInUser.value = { displayName: "" };
      }
    }

    return {
      user,
      goHome,
      loggedInUser,
    };
  },
};
</script>

<style></style>
