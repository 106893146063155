// useDocument.js is to update / delete a document

import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { doc, deleteDoc, updateDoc, arrayUnion, arrayRemove, getDoc } from "firebase/firestore"; // ### new for Firebase 8 to 9 refactoring

const useDocument = (collectionName, id) => {
  
  let error = ref(null);
  let isPending = ref(false);

  // ??? not sure whether docRef below should be "const" or "let"
  // ??? not sure "id" at the end of the line below is correct
  let docRef = doc(projectFirestore, collectionName, id); // ### before Firebase 8 to 9 refactoring, was: let docRef = projectFirestore.collection(collection).doc(id)

  // BEGIN delete
  const deleteDoc = async () => {
    isPending.value = true;
    error.value = null;

    try {
      const res = await deleteDoc(docRef); // ### before Firebase 8 to 9 refactoring, was: const res = await docRef.delete()
      isPending.value = false;
      return res;
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "could not delete the document";
    }
  };
  // END delete

  // BEGIN update  >> Below un-commented-out by Mark 4/12/2022, for use in UpdateUserInfo component
  const updateMyDoc = async (updates) => {
    isPending.value = true;
    error.value = null;

    try {
      // ??? not sure line below is correct
      const res = await updateDoc(docRef, updates); // ### before Firebase 8 to 9 refactoring, was: const res = await docRef.update(updates)
      isPending.value = false;
      return res;
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "could not update the document";
    }
  };
  // END update >> above un-commented-out by Mark 4/12/2022, for use in UpdateUserInfo component

  // Hander review question and ansewers section
  const updateQuizData = async (updatedData) => {
    const quizIndex = updatedData.quizIndex;
    const qAndAArray = [...updatedData.oldQandA];
    const userAnswer = updatedData.userAnswer;
    const answerBy = updatedData.userName;
    const userId = updatedData.userId;
    const createdAt = new Date();

    const newAnswer = {
      answerText: userAnswer,
      answeredBy: answerBy,
      userId: userId,
      isRealEstateAgent: false,
      isVerified: true,
      createdAt: createdAt,
      upvoteCount: 0,
      flagCount: 0,
    };
    qAndAArray[quizIndex].answers.push(newAnswer);
    // qAndAArray[quizIndex].answers = [newAnswer, ...qAndAArray[quizIndex].answers];

    await updateDoc(docRef, {
      qAnda: qAndAArray,
    })
      .then(() => {
        console.log("final array", qAndAArray);
        return qAndAArray;
      })
      .catch((err) => {
        error.value =
          "Error in submitting the Answer, please try after some time";
        console.log(err);
      });
  };

  const addAnswer = async(updatedData) => {
    
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const foundDoc = docSnap.data();
      const existingQAndA = foundDoc.qAnda;
      const existingSingleQandA = existingQAndA[updatedData.quizIndex];
      existingSingleQandA['answers'] = [updatedData.answer, ...existingSingleQandA['answers']];
      console.log(existingQAndA);
      await updateDoc(docRef, {
        qAnda: existingQAndA
        })
          .then(() => {
            return existingQAndA;
          })
          .catch((err) => {
            console.log(err);
            error.value = "Error in submitting the Answer, please try after some time";
          });

    } else {
      // doc.data() will be undefined in this case
      error.value = "Error in submitting the Answer, please try after some time";
      console.log("No such document!");
    }
    
  }

  const addQuestion = async (questionData) => {
    
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      const foundDoc = docSnap.data();
      const existingQAndA = foundDoc.qAnda;
      const newQandA = [questionData, ...existingQAndA];
      await updateDoc(docRef, {
        qAnda: newQandA
        })
          .then(() => {
            return newQandA;
          })
          .catch((err) => {
            console.log(err);
            error.value = "Error in submitting the question, please try after some time";
          });

    } else {
      // doc.data() will be undefined in this case
      error.value = "Error in submitting the question, please try after some time";
      console.log("No such document!");
    }
    
  };

  const updateQAndAItems = async (updatedQandA) => {
    await updateDoc(docRef, {
      qAnda: updatedQandA
    })
      .then(() => {
        return updatedQandA;
      })
      .catch((err) => {
        console.log(err);
        error.value = "Error in submitting the Answer, please try after some time";
      });
  };
  // Hander review question and ansewers section

  return { error, isPending, deleteDoc, updateMyDoc, updateQuizData, addQuestion, updateQAndAItems, addAnswer };
};

export default useDocument;
