import { ref } from "vue";
import { projectAuth } from "../firebase/config";
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth"; // ### new for Firebase 8 to 9 refactoring

const error = ref(null);
const isPending = ref(false);

const login = async (email, password) => {
  error.value = null;
  isPending.value = true;

  try {
    await signInWithEmailAndPassword(projectAuth, email, password)
    .then((authUser) => {
      console.log('Use login- ', authUser);
      if(!authUser.user.emailVerified) {
        sendEmailVerification(authUser.user);
        error.value =
          "We just sent you an email with 'Please verify your email for Zagnetic' in the subject line. Click the link inside to verify your account (helps prevent spam)."; // was "Email Verification is pending, we have sent you an email please verify your account.";
      }
    }); // ### before Firebase 8 to 9 refactoring, was: const res = await projectAuth.signInWithEmailAndPassword(email, password)
    // error.value = null;
    // isPending.value = false;
  
  } catch (err) {
    console.log(err.message);
    error.value = "Incorrect login credentials";
    isPending.value = false;
  }
};

const useLogin = () => {
  return { error, login, isPending };
};

export default useLogin;
