// getDocument.js is a real-time listener to just one single document

import { watchEffect, ref } from "vue";
import { projectFirestore } from "../firebase/config";

//Not sure import below is rght - it's partly taken from getCollection.js
import { doc, updateDoc, onSnapshot, getDoc } from "firebase/firestore"; // ### new for Firebase 8 to 9 refactoring
import { getAuth, updateProfile, updatePassword } from "firebase/auth";

const getSetUserInfo =  () => {
  const userErr = ref(null);
  const updateErr = ref(null);
  const loggedInUser = ref(null);
  const targetedUserPublicInfo = ref(null);

  const getLoggedInUserInfo = async (userId) => {
    const docRef = await doc(projectFirestore, "users", userId);
    onSnapshot(
      docRef,
      (doc) => {
        console.log("Current data: ", doc.data());
        if (doc.data()) {
          loggedInUser.value = { ...doc.data(), id: doc.id };
          userErr.value = null;
          // console.log("Current data: ", doc.data());
        } else {
          userErr.value = "that document does not exist";
        }
      },
      (err) => {
        console.log(err.message);
        userErr.value = "problem fetching the document";
      }
    );
  };

  // BEGIN copy of "getLoggedInUserInfo" that will be changed to only get user's public data from that sub-collection, per this card: https://trello.com/c/8AmIHn8r
  const getTargetedUsersPublicProfileInfo = async (userId) => {
    const docRef = await doc(projectFirestore, "users", userId);
    onSnapshot(
      docRef,
      (doc) => {
        console.log("Current data: ", doc.data());
        if (doc.data()) {
          targetedUserPublicInfo.value = { ...doc.data(), id: doc.id };
          userErr.value = null;
          // console.log("Current data: ", doc.data());
        } else {
          userErr.value = "that document does not exist";
        }
      },
      (err) => {
        console.log(err.message);
        userErr.value = "problem fetching the document";
      }
    );
  };
  // END copy of "getLoggedInUserInfo" that will be changed to only get user's public data from that sub-collection, per this card: https://trello.com/c/8AmIHn8r

  const updateProfileField = async (userId, updateObject) => {
    console.log(userId, updateObject);
    const userRef = await doc(projectFirestore, "users", userId);
    await updateDoc(userRef, updateObject)
      .then((res) => {
        updateErr.value = null;
      })
      .catch((err) => {
        console.log(err);
        updateErr.value =
          "Sorry, we're unable to update the profile item. Please try again in a while.";
      });
  };

  const updateAuthField = async (updateObject) => {
    const auth = getAuth();
    updateProfile(auth.currentUser, updateObject)
      .then(() => {
        console.log(auth.currentUser);
        updateErr.value = null;
      })
      .catch((error) => {
        console.log(error.message);
        updateErr.value =
          "Sorry, we're unable to update the profile item. Please try again in a while.";
      });
  };

  const updateUserPassword = (newPassword) => {
    const auth = getAuth();
    updatePassword(auth.currentUser, newPassword)
      .then(() => {
        updateErr.value = null;
      })
      .catch((error) => {
        console.log(error.message);
        updateErr.value = error.message;
      });
  };

  const updateUserReviewHistory = async (userId, fieldName, updateObj) => {
    console.log(userId, updateObj);
    let field = null;
    switch(fieldName) {
      case 'postFullPublic':
        field = 'reviewsWrittenFullPublic';
        break;
      case 'postAnonPublic':
        field = 'reviewsWrittenAnonPublic';
        break;
      case 'keepFullySecret':
        field = 'reviewsWrittenKeepPrivate';
        break;
      default: 
        field = null;
        break;
    }
    if(field === null) {
      return;
    }
    const userRef = await doc(projectFirestore, "users", userId);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      
      const foundDoc = docSnap.data();
      const existingData = foundDoc[`${field}`];
      const newData = [updateObj, ...existingData];
      await updateDoc(userRef, {
        [`${field}`]: newData
        })
          .then(() => {
            updateErr.value = null;
            // return newData;
          })
          .catch((err) => {
            console.log(err);
            error.value = "Error in updating the review data in users collection";
          });

    } else {
      // doc.data() will be undefined in this case
      error.value = "Error in updating the review data in users collection";
      console.log("No such document!");
    }
  };

  return {
    userErr,
    loggedInUser,
    getLoggedInUserInfo,
    getTargetedUsersPublicProfileInfo,
    targetedUserPublicInfo,
    updateErr,
    updateProfileField,
    updateAuthField,
    updateUserPassword,
    updateUserReviewHistory,
  };
}

export default getSetUserInfo;
