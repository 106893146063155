<template>
  <div class="max-w-screen-xl pb-1">
    <div class="pb-96">
      <img
        class="3xl:h-[155%] absolute inset-0 z-0 h-[75%] w-full object-cover py-20 sm:h-[89%] md:h-[70%] lg:h-[68%] xl:h-[630px] 2xl:h-[820px]"
        src="@/assets/images/sf-houses-city-dark3.jpg"
        alt="san francisco painted houses"
      />
      <div class="absolute inset-0 z-10 mt-20 h-[24.5%] w-full lg:h-[36.5%]">
        <div class="relative z-20 sm:my-2 lg:my-10 2xl:m-12">
          <img
            src="@/assets/images/logo-for-dark-bg.svg"
            alt="Zagnetic logo"
            class="mx-auto mt-4 mb-2 h-14 opacity-80 mix-blend-overlay shadow-sm md:mb-2 lg:mt-10 lg:mb-6 lg:h-16"
          />
          <h1
            class="pt-8 pb-2 text-center text-3xl font-bold text-indigo-50 sm:text-4xl md:pb-6 md:text-5xl lg:text-6xl"
          >
            Real Estate
            <span
              class="bg-gradient-to-b from-emerald-100 via-indigo-100 to-indigo-300 bg-clip-text px-2 text-3xl font-bold text-transparent sm:text-4xl md:text-5xl lg:text-6xl"
              >Reviews</span
            >
          </h1>
          <h1
            class="pb-2 text-center text-3xl font-bold text-indigo-50 md:text-4xl lg:text-6xl"
          >
            and
            <span
              class="bg-zagblue bg-opacity-60 bg-gradient-to-b from-emerald-100 via-indigo-100 to-indigo-300 bg-clip-text px-2 text-3xl font-bold text-transparent sm:text-4xl md:text-5xl lg:text-6xl"
              >Q&thinsp;&#38;&thinsp;A</span
            >
          </h1>

          <!-- BEGIN only for smaller-than-sm screen size -->
          <div
            class="visible z-10 mx-auto -ml-20 rotate-6 pt-6 text-center text-sm font-medium leading-relaxed text-indigo-900 sm:invisible sm:h-0 sm:w-0"
          >
            <span
              class="ring-offset-highlight border-highlight visible z-10 mb-2 rounded-md border bg-indigo-50 bg-opacity-90 py-0.5 px-4 text-sm leading-loose ring-4 ring-indigo-500 ring-opacity-90 ring-offset-4 sm:invisible"
              >Save time</span
            >
          </div>

          <div
            class="visible mx-auto mt-2.5 -rotate-6 pt-6 text-center text-sm font-medium leading-relaxed text-indigo-900 sm:hidden sm:h-0 sm:w-0"
          >
            <span
              class="ring-offset-highlight border-highlight visible mb-2 rounded-md border bg-indigo-50 bg-opacity-90 py-0.5 px-4 text-sm leading-loose ring-4 ring-indigo-500 ring-opacity-90 ring-offset-4 sm:invisible"
              >+ Make better real estate decisions</span
            >
          </div>
          <!-- END only for smaller-than-sm screen size -->

          <!-- BEGIN only for sm-and-larger screen size -->
          <div class="z-10 flex items-center justify-center">
            <!-- <h2
              class="z-10 ml-12 md:mx-2 lg:ml-[12%] xl:ml-[20%] 2xl:ml-[33%] mt-4 hidden -rotate-3 pt-6 pl-2 text-center text-base font-medium leading-relaxed text-indigo-900 sm:visible sm:inline-flex sm:pl-16 md:mt-0 md:pt-10 md:pl-32 md:text-xl lg:-mt-1 lg:text-2xl"
            > -->
            <h2
              class="z-10 mr-[5%] mt-4 hidden -rotate-3 pt-6 pl-2 text-center text-base font-medium leading-relaxed text-indigo-900 sm:visible sm:inline-flex sm:pl-16 md:mt-0 md:pt-8 md:pl-32 md:text-xl lg:-mt-1 lg:text-2xl xl:mr-[11%] 2xl:mr-[8%] 2xl:-mt-4"
            >
              <button
                class="sm:ring-offset-highlight border-highlight z-10 mx-auto mb-2 rounded-md border bg-indigo-50 bg-opacity-90 py-0.5 px-4 shadow-sm sm:visible sm:ring-4 sm:ring-indigo-500 sm:ring-opacity-90 sm:ring-offset-4 md:text-xl lg:text-xl 2xl:rounded-lg 2xl:py-1 2xl:px-5 2xl:text-xl"
              >
                Save
                <span
                  class="z-10 text-base font-medium leading-relaxed underline decoration-indigo-900/20 decoration-dotted underline-offset-[4px] md:text-xl lg:text-xl 2xl:text-xl"
                  >time</span
                >
                + Make better real estate
                <span
                  class="z-10 text-base font-medium leading-relaxed underline decoration-indigo-900/20 decoration-dotted underline-offset-[4px] md:text-xl lg:text-xl 2xl:text-xl"
                  >decisions</span
                >
              </button>
            </h2>
          </div>
          <!-- END only for sm-and-larger screen size -->
        </div>
      </div>
    </div>

    <!-- BEGIN v2 search box inside a card -->
    <div
      id="searchforprop"
      class="ring-blur-2xl border-1 mx-1 mt-0 rotate-2 transform rounded-lg border-indigo-900/80 bg-indigo-50 bg-opacity-80 px-2 pt-2 shadow-lg shadow-indigo-900/10 ring-1 ring-indigo-600/30 transition duration-700 ease-in-out hover:rotate-0 focus:rotate-0 active:rotate-0 sm:mx-0 sm:mt-4 sm:border-0 sm:pt-0 sm:ring-opacity-50 md:mt-8 md:px-6 lg:mt-24 2xl:mt-28"
    >
      <!-- BEGIN test with floating label, per https://www.youtube.com/watch?v=nJzKi6oIvBA -->
      <div class="z-40 grid grid-cols-12 pt-1 pb-4 sm:pt-2 sm:pb-6 md:pt-3">
        <div class="col-span-12 lg:col-span-9">
          <form
            action="#"
            id="search-index"
            class="z-40 bg-indigo-50 bg-opacity-0"
            @submit.prevent="handleSearchSubmit('WhichProperty')"
          >
            <div class="z-40 content-center">
              <span
                class="pointer-events-auto relative cursor-pointer align-middle"
              >
                <button
                  v-if="showAddressErr"
                  class="animate-fade text-conred z-10 my-4 rounded-lg bg-white px-1 text-sm italic sm:text-base"
                >
                  <fa
                    icon="exclamation-triangle"
                    class="text-conred mr-1.5 text-lg"
                  />Please confirm the address in the dropdown
                </button>

                <input
                  type="text"
                  id="autocomplete"
                  ref="autocomplete"
                  name="searchString"
                  v-model="selectedLoc"
                  class="peer pointer-events-auto z-40 inline-block w-full cursor-pointer rounded-lg placeholder-transparent hover:placeholder-transparent focus:border focus:border-indigo-900 focus:placeholder-transparent active:border active:border-indigo-900 sm:w-[74%] md:w-[79%] lg:w-3/4"
                  placeholder="Enter an address, neighborhood or MLS#"
                  aria-label="Search this Q&#38;A for a keyword"
                  @keyup="googleInputSelected = false"
                  @change="checkIfGoogleInputSelected()"
                />
                <label
                  for="autocomplete"
                  class="absolute left-0 -top-12 z-40 text-sm text-indigo-900 text-opacity-60 transition-all duration-500 ease-in-out peer-placeholder-shown:left-1 peer-placeholder-shown:-top-1.5 peer-placeholder-shown:text-slate-500 peer-focus:text-opacity-60 sm:-top-8 sm:text-base sm:peer-placeholder-shown:left-5 sm:peer-placeholder-shown:-top-0"
                  >Enter<span class="z-40 hidden text-base sm:inline"> an</span>
                  address, neighborhood or MLS#</label
                >
              </span>
              <!-- <button v-if="showAddressErr" class="z-10 text-sm animate-fade text-conred rounded-lg bg-white px-1 sm:text-base italic my-4"><fa icon="exclamation-triangle" class="text-conred text-lg mr-1.5" />Please confirm the address in the dropdown</button> -->

              <button
                class="z-40 mt-3.5 rounded-md border-2 bg-indigo-900 px-4 py-2 text-sm font-semibold uppercase tracking-widest text-white ring-4 ring-indigo-300 ring-opacity-50 hover:border-2 hover:bg-indigo-900 hover:ring-2 hover:ring-indigo-900 sm:ml-1.5"
              >
                <!-- <span class="hidden text-sm sm:inline">search&nbsp;&nbsp;</span
                ><fa icon="search" /> -->
                <span class="z-40 inline text-sm">search&nbsp;&nbsp;</span
                ><fa icon="search" />
              </button>
            </div>
            <!-- <span v-if="showAddressErr">Please confirm the address in the dropdown (to be sure we use the right address for your review)</span> -->
            <!-- <button v-if="showAddressErr" class="z-10 text-sm animate-fade text-conred rounded-lg bg-white px-1 sm:text-base italic my-4"><fa icon="exclamation-triangle" class="text-conred text-lg mr-1.5" />Please confirm the address in the dropdown</button> -->
          </form>
        </div>

        <div class="col-span-12 mt-2.5 sm:mx-auto lg:col-span-3 lg:mt-0">
          <button
            class="borderlessbtn !px-4 !py-1 shadow-md ring-2 ring-opacity-10"
            @click="handleSearchSubmit('CreateReview')"
          >
            <div v-if="user">
              <span class="text-sm"
                >Write a review<fa
                  :icon="['fas', 'pencil-alt']"
                  class="ml-2.5 text-sm"
              /></span>
            </div>
            <div v-else><Whoops whoopsButtonVifTextAndIcon="3" /></div>
          </button>
        </div>
      </div>
      <!-- END test with floating label, per https://www.youtube.com/watch?v=nJzKi6oIvBA   
              ring-blur-2xl mx-1 mt-0 rotate-2 transform rounded-lg border-1 border-indigo-900/80 bg-indigo-50 bg-opacity-80 px-2 pt-2 ring-1 ring-indigo-600/30 shadow-lg shadow-indigo-900/10 transition duration-700 ease-in-out hover:rotate-0 focus:rotate-0 active:rotate-0 sm:mx-0 sm:mt-4 sm:border-0 sm:pt-0 sm:ring-opacity-50 md:mt-8 md:px-6 lg:mt-24 2xl:mt-28  -->
    </div>
    <!-- END v2 search box inside a card -->
    <div class="sm:[8%] lg:[12%] xl:ml-[15%]">
      <p
        class="mx-auto mt-8 w-[95%] rounded-xl bg-pink-50 p-2.5 text-center text-sm text-indigo-900 shadow-md shadow-pink-400/20 ring-1 ring-pink-400/20 sm:mx-2 sm:mt-12 md:mt-20 lg:w-[95%] xl:w-[73%]"
      >
        <fa
          :icon="['fas', 'ghost']"
          class="ml-2.5 rounded-lg text-lg text-indigo-900 ring-2 ring-pink-400/20"
        />
        &nbsp;Want to
        <span class="text-sm italic text-indigo-900">secretly</span
        ><sup class="text-indigo-900">&thinsp;*</sup> keep track of properties
        you're interested in?
        <button
          class="m-2 mt-3 rounded-lg bg-white py-0.5 px-2 text-xs tracking-wide shadow-sm shadow-indigo-100 ring-1 ring-indigo-400 ring-opacity-30 hover:bg-indigo-50 hover:ring-2 hover:ring-opacity-70 sm:mt-6 lg:mt-2"
        >
          <div v-if="user">
            <router-link :to="{ name: 'CreateReview' }"
              ><p class="py-1 text-sm font-semibold uppercase">
                Write a
                <span class="text-sm underline underline-offset-[3px]"
                  >SECRET</span
                >
                review<fa :icon="['fas', 'ghost']" class="ml-2.5 text-sm" /></p
            ></router-link>
          </div>
          <div v-else><Whoops whoopsButtonVifTextAndIcon="2" /></div>
        </button>
      </p>

      <p class="my-2.5 ml-8 text-xs italic text-indigo-900 sm:text-sm">
        <sup>*</sup> Secret reviews & photos<fa
          :icon="['fa', 'camera']"
          class="ml-1 mr-0.5 text-sm opacity-80 sm:text-base"
        />
        are NOT visible to others, unless you share<button class="mb-2.5 ml-0">
          <Whoops whoopsButtonVifTextAndIcon="4" />
        </button>
      </p>
    </div>

    <!-- <p class="text-center mt-10"><button class="m-2 mt-3 rounded-lg bg-white py-1.5 px-4 font-medium tracking-wide shadow-sm shadow-indigo-100 ring-1 ring-indigo-400 ring-opacity-30 hover:bg-indigo-50 hover:ring-2 hover:ring-opacity-70 sm:mt-6 lg:mt-2">Get <span class="font-semibold text-xl text-transparent bg-clip-text bg-gradient-to-r from-pink-600 to-indigo-700/80">Custom</span> Notifications<fa :icon="['fas', 'exclamation-circle']" class="ml-1.5 text-lg" />
            </button></p>
   <p class="text-center text-sm sm:text-base italic mt-2">for <span class="underline text-sm sm:text-base italic">new</span> reviews in towns you choose&thinsp;!</p> -->

    <div class="mb-12">
      <p class="mt-16 pb-4 text-xl font-bold uppercase text-pink-500">
        What is this?
      </p>
      <h1 class="pb-4 text-3xl font-bold">
        Reviews, Questions & Answers about Real Estate
      </h1>

      <!-- BEGIN fours icons and info -->
      <div
        class="my-12 grid grid-cols-4 grid-rows-1 justify-between gap-12 pt-4 pb-6"
      >
        <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
          <div
            class="relative row-span-1 mb-16 ml-[40%] sm:ml-[38%] md:ml-[30%] lg:ml-[35%] xl:ml-[38%]"
          >
            <div
              class="absolute top-0 left-4 h-12 w-12 rounded-full bg-violet-200 bg-opacity-70 mix-blend-multiply blur-md filter"
            >
              <div
                class="absolute top-0 right-4 h-12 w-12 rounded-full bg-amber-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 right-4 h-12 w-12 rounded-full bg-emerald-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 left-0 h-12 w-12 rounded-full bg-blue-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
            </div>

            <fa
              :icon="['fas', 'map-marker-alt']"
              class="absolute top-0 left-2 z-20 ml-2.5 text-4xl text-gray-700"
            />
            <fa
              :icon="['fas', 'map-marker-alt']"
              class="absolute top-0 left-1.5 z-10 ml-2.5 text-4xl text-indigo-100"
            />
          </div>

          <div class="row-span-1">
            <p class="pb-2.5 text-center font-semibold uppercase">
              Huh<span class="ml-0.5">?</span>
            </p>
            <p class="px-4 text-center text-base text-gray-500 sm:px-0">
              It's the same way experts & regular people review restaurants,
              books, etc.
            </p>
          </div>
        </div>

        <div class="col-span-4 sm:col-span-2 md:col-span-1">
          <div
            class="relative row-span-1 mb-16 ml-[40%] sm:ml-[38%] md:ml-[30%] lg:ml-[35%] xl:ml-[38%]"
          >
            <div
              class="absolute top-0 left-4 h-12 w-12 rounded-full bg-violet-200 bg-opacity-70 mix-blend-multiply blur-md filter"
            >
              <div
                class="absolute top-0 right-4 h-12 w-12 rounded-full bg-amber-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 right-4 h-12 w-12 rounded-full bg-emerald-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 left-0 h-12 w-12 rounded-full bg-blue-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
            </div>

            <fa
              :icon="['fas', 'home']"
              class="absolute top-0 left-2 z-20 ml-2.5 text-4xl text-gray-700"
            />
            <fa
              :icon="['fas', 'home']"
              class="absolute top-0 left-1.5 z-10 ml-2.5 text-4xl text-gray-100"
            />
          </div>

          <div class="row-span-1">
            <p class="pb-2.5 text-center font-semibold uppercase leading-6">
              What kind of real estate<span class="ml-0.5">?</span>
            </p>
            <p class="px-4 text-center text-base text-gray-500 sm:px-0">
              Houses, apartments, condos, land & investment property.
            </p>
          </div>
        </div>

        <div class="col-span-4 sm:col-span-2 md:col-span-1">
          <div
            class="relative row-span-1 mb-16 ml-[40%] sm:ml-[38%] md:ml-[30%] lg:ml-[35%] xl:ml-[38%]"
          >
            <div
              class="absolute top-0 left-4 h-12 w-12 rounded-full bg-violet-200 bg-opacity-70 mix-blend-multiply blur-md filter"
            >
              <div
                class="absolute top-0 right-4 h-12 w-12 rounded-full bg-amber-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 right-4 h-12 w-12 rounded-full bg-emerald-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 left-0 h-12 w-12 rounded-full bg-blue-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
            </div>

            <fa
              :icon="['fas', 'comments']"
              class="absolute top-0 left-2 z-20 ml-2.5 text-4xl text-gray-700"
            />
            <fa
              :icon="['fas', 'comments']"
              class="absolute top-0 left-1.5 z-10 ml-2.5 text-4xl text-gray-100"
            />
          </div>

          <div class="row-span-1">
            <p class="pb-2.5 text-center font-semibold uppercase">
              What's <span class="tracking-wider">Q&A</span
              ><span class="ml-0.5">?</span>
            </p>
            <p class="text-center font-semibold text-gray-500">
              Questions & Answers.
            </p>
            <p class="px-4 text-center text-base text-gray-500 sm:px-0">
              Lots of important & helpful info isn't shared publicly (until
              now).
              <a class="hover:text-gray-600 hover:underline" href="#bottombox"
                >Learn more</a
              >
              ↓
            </p>
          </div>
        </div>

        <div class="col-span-4 sm:col-span-2 md:col-span-1">
          <div
            class="relative row-span-1 mb-16 ml-[40%] sm:ml-[38%] md:ml-[30%] lg:ml-[35%] xl:ml-[38%]"
          >
            <div
              class="absolute top-0 left-4 h-12 w-12 rounded-full bg-violet-200 bg-opacity-70 mix-blend-multiply blur-md filter"
            >
              <div
                class="absolute top-0 right-4 h-12 w-12 rounded-full bg-amber-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 right-4 h-12 w-12 rounded-full bg-emerald-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
              <div
                class="absolute bottom-4 left-0 h-12 w-12 rounded-full bg-blue-200 bg-opacity-70 mix-blend-multiply blur-md filter"
              ></div>
            </div>

            <fa
              :icon="['fas', 'map']"
              class="absolute top-0 left-2 z-20 ml-2.5 text-4xl text-gray-700"
            />
            <fa
              :icon="['fas', 'map']"
              class="absolute top-0 left-1.5 z-10 ml-2.5 text-4xl text-gray-100"
            />
          </div>

          <div class="row-span-1">
            <p class="pb-2.5 text-center font-semibold uppercase">
              Why<span class="ml-0.5">?</span>
            </p>
            <p class="px-4 text-center text-base text-gray-500 sm:px-0">
              People have tons of insight (and photos
              <fa
                icon="camera-retro"
                size=""
                alt="camera icon"
                class="mx-0 text-indigo-900"
              />) to share, so you can make better, faster decisions.
            </p>
            <!-- clipped "Great for remote tours & buying" -->
          </div>
        </div>
      </div>
      <!-- END fours icons and info -->
    </div>

    <div class="pb-12 -pt-4">
      <iframe src="https://map.proxi.co/r/Xf4Ty5Gp2A_a-dIgSXb-" allow="geolocation; clipboard-write" width="100%" height="625px" style="border-width: 0px;" allowfullscreen></iframe> <div style="font-family: Sans-Serif; font-size:12px;color:#000000;opacity:0.5; padding-top: 5px;"> powered by <a href="https://www.proxi.co/" style="color:#000000" target="_blank">Proxi</a> </div> 
    </div>

    <!-- BEGIN table of all properties -->
   <ThePropertyList />
    <!-- END table of all properties -->

    <!-- BEGIN sample reviews and Q&A -->
    <span class="text-zaggray ml-1 mb-6 p-6 text-xs font-semibold uppercase"
      >recent activity:</span
    >
    <div
      class="mb-20 -mt-6 grid grid-cols-4 grid-rows-1 justify-between gap-6 rounded-md bg-indigo-100 px-6 pt-8 pb-6"
    >
      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'pBny0u4x3fkrzi1Woa2E' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                73 Brittin Street<br />
                Madison, NJ 07940
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div>
                <img
                  src="@/assets/images/73-brittin-madison-nj.jpeg"
                  alt="73 Brittin St Madison NJ real estate review kitchen with chairs at counter and big windows"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <span class="ml-1 text-sm font-semibold">(5.0)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'hnbFOMaNN993Yfo2ik0f' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                185 Ridgedale Avenue<br />
                Madison, NJ 07940
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div>
                <img
                  src="@/assets/images/185-Ridgedale-madison.jpeg"
                  alt="185 Ridgedale Ave Madison NJ real estate review beautiful kitchen with counter lights and dining table in background"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa
                  icon="star-half-alt"
                  id="rating_star"
                  size="lg"
                  class="mr-1.5"
                />
                <span class="ml-1 text-sm font-semibold">(4.6)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'PropertyLanding',
            params: { id: 'eHCn4K88DYOKsvThLpHv' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                q&thinsp;&#38;&thinsp;a:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                25 Holden Lane<br />Madison, NJ 07940
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div class="inline-flex">
                <p class="text-zagblue mr-2 text-sm font-semibold">Q:</p>
                <p class="text-zagblue text-sm">
                  Is the water source public, well or something else? How does
                  it taste? Any problems, filters or treatments?
                </p>
              </div>
              <div class="inline-flex">
                <p class="text-zaggray mr-2 text-sm font-semibold">A:</p>
                <p class="text-zaggray text-sm">
                  Public water. Here's a link to the recent water quality
                  report:
                  <a
                    href="https://rosenet.org/1075/Water-Quality-Report"
                    target="_blank"
                    class="text-zaggray text-sm underline"
                    >Rosenet.org/1075/Water-Quality-Report</a
                  >
                  Also, here's the...
                  <span class="text-xs italic">(more)</span>
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'Lx5EdPeUyPPI41okx50H' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                8 Beverly Road<br />
                Madison, NJ 07940
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div>
                <img
                  src="@/assets/images/8-beverly-dining-room.jpeg"
                  alt="8 Beverly Road Madison NJ real estate review dining room table with chandelier and covered chairs"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <span class="ml-1 text-sm font-semibold">(5.0)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'PropertyLanding',
            params: { id: 'iVX6JiIvKwCjCYMGifEX' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                q&thinsp;&#38;&thinsp;a:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                3 Wilson Lane<br />Madison, NJ 07940
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div class="inline-flex">
                <p class="text-zagblue mr-2 text-sm font-semibold">Q:</p>
                <p class="text-zagblue text-sm">
                  How is the mobile phone service at this location? Any
                  providers that are especially good or bad?
                </p>
              </div>
              <div class="inline-flex">
                <p class="text-zaggray mr-2 text-sm font-semibold">A:</p>
                <p class="text-zaggray text-sm">
                  Verizon and Mint both worked well here, though I'm not sure
                  about 5G
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'p1KV20Om9O5iXgIcquPU' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                369 Shunpike Road<br />Chatham, NJ 07928
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div>
                <img
                  src="@/assets/images/369-shunpike.jpeg"
                  alt="369 Shunpike Road>Chatham, NJ 07928 review kitchen stove stone backsplash"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa
                  icon="star-half-alt"
                  id="rating_star"
                  size="lg"
                  class="mr-1.5"
                />
                <span class="ml-1 text-sm font-semibold">(4.6)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'Hd6Ue9egCmb4ftpihuP8' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                83 Madison Ave<br />Madison, NJ 07940
              </div>
              <div class="text-zaggray py-1.5 text-xs">
                multi-family property
              </div>
              <div>
                <img
                  src="@/assets/images/83-Madison-Ave.jpeg"
                  alt="83 Madison Ave real estate review high ceilings wood floors and some of natural wood cabinets in kitchen"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa
                  icon="star-half-alt"
                  id="rating_star"
                  size="lg"
                  class="mr-1.5"
                />
                <fa icon="star" id="non_rating_star" size="lg" class="mr-1.5" />
                <span class="ml-1 text-sm font-semibold">(3.8)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'mSX0z0OrtJCOUOxCqd8d' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                33 Green Village Rd, #4404<br />Madison, NJ 07945
              </div>
              <div class="text-zaggray py-1.5 text-xs">condominium</div>
              <div>
                <img
                  src="@/assets/images/33-green-vill-rd.jpeg"
                  alt="33 Green Village Rd, unit 4404 Madison, NJ 07940 real estate review  stainless range and hood dark gray cabinets"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa
                  icon="star-half-alt"
                  id="rating_star"
                  size="lg"
                  class="mr-1.5 bg-slate-50"
                />
                <span class="ml-1 text-sm font-semibold">(4.8)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <!-- <div class="relative col-span-4 sm:col-span-2 md:col-span-1">
        <router-link
          :to="{
            name: 'ReviewDetails',
            params: { id: 'YP301ugXw08scpQAizyl' },
          }"
        >
          <div class="relative row-span-1 mx-auto mb-2">
            <div
              class="space-y-1.5 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg"
            >
              <div class="text-xs font-medium text-pink-500 underline">
                review:
              </div>
              <div class="text-zaggray text-sm font-semibold leading-3">
                407 Verbank Road<br />Millbrook, NY 12545
              </div>
              <div class="text-zaggray py-1.5 text-xs">1-family house</div>
              <div>
                <img
                  src="@/assets/images/407_VerbankRd_Millbrook_NY_6.jpg"
                  alt="407 Verbank Road Millbrook, NY 12545 review high-end kitchen dark blue-green cabinets and stainless backsplash tile"
                  class="w-full rounded-sm"
                />
              </div>
              <div>
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                <fa icon="star-half-alt" id="rating_star" size="lg" class="mr-1.5" />
                <span class="text-sm font-semibold ml-1">(4.8)</span>
              </div>
              <div class="text-zaggray text-xs">
                avg. for kitchen, baths, etc.
              </div>
            </div>
          </div>
        </router-link>
      </div> -->
    </div>
    <!-- END sample reviews and Q&A -->

    <div
      id="bottombox"
      class="mb-16 grid grid-cols-2 gap-16 rounded-md bg-slate-100 py-12 px-12 drop-shadow-lg"
    >
      <div class="col-span-2 space-y-4 sm:col-span-1">
        <p class="text-2xl font-bold uppercase tracking-wide text-pink-500">
          Start using Zagnetic today !
        </p>
        <h1>Learn the good & bad things that <i>aren't</i> advertised.</h1>
        <h2>
          For example, a beautiful house or apartment in a great location,
          <strong>but</strong>...
        </h2>
        <h2>
          <ul class="list-inside list-disc pl-6">
            <li>it smells like mold or cat pee</li>
            <li>a neighbor's yard is filled with junk</li>
            <li>the homeowner's association doesn't allow pets</li>
            <li>the area has terrible cell & internet service</li>
          </ul>
        </h2>
        <h1>
          This important information does <strong>not</strong> show-up in real
          estate listings. So what can you do?? &nbsp;&#8594;&nbsp;
          <span class="zagnetic">zagnetic</span>.
        </h1>
      </div>

      <div
        class="col-span-2 flex-col-reverse items-center justify-center space-y-12 pt-6 sm:col-span-1"
      >
        <div class="">
          <div class="flex items-center justify-center">
            <a href="#searchforprop"
              ><button
                class="zzzigbutton duration-400 text-zaggray hover:ring-zaggray transform rounded-md border border-indigo-900 bg-indigo-100 py-1.5 px-5 text-lg ring-2 ring-indigo-900 ring-opacity-90 ring-offset-2 ring-offset-indigo-50 transition ease-in-out hover:scale-110 hover:bg-indigo-900 hover:text-white"
              >
                Browse reviews / Q&thinsp;&#38;&thinsp;A
                <fa
                  :icon="['fas', 'search']"
                  class="z-10 ml-2.5 text-xl text-indigo-900 opacity-75 hover:text-indigo-200"
                /></button
            ></a>
          </div>
          <p class="pt-2.5 text-center text-sm text-gray-500">
            100% free - no account or login
          </p>
        </div>

        <!-- <Whoops /> -->

        <div>
          <div class="flex items-center justify-center">
            <button
              class="zzzigbutton duration-400 text-zaggray hover:ring-zaggray transform rounded-md border border-indigo-900 bg-indigo-100 py-1.5 px-5 text-lg ring-2 ring-indigo-900 ring-opacity-90 ring-offset-2 ring-offset-indigo-50 transition ease-in-out hover:scale-110 hover:bg-indigo-900 hover:text-white"
            >
              <div v-if="user">
                <router-link :to="{ name: 'CreateReview' }"
                  ><span class=""
                    >Write a review
                    <fa
                      :icon="['fas', 'pencil-alt']"
                      class="ml-2.5 text-xl opacity-75" /></span
                ></router-link>
              </div>
              <div v-else><Whoops whoopsButtonVifTextAndIcon="3" /></div>
            </button>
          </div>
          <p class="pt-2.5 text-center text-sm text-gray-500">
            Can be secret. Free account needed.
          </p>
        </div>

        <div>
          <div class="flex items-center justify-center">
            <button
              class="zzzigbutton duration-400 text-zaggray hover:ring-zaggray transform rounded-md border border-indigo-900 bg-indigo-100 py-1.5 px-5 text-lg ring-2 ring-indigo-900 ring-opacity-90 ring-offset-2 ring-offset-indigo-50 transition ease-in-out hover:scale-110 hover:bg-indigo-900 hover:text-white"
            >
              Ask / answer a question
              <fa
                :icon="['fas', 'comments']"
                class="ml-2.5 text-xl opacity-75"
              />
            </button>
          </div>
          <p class="pt-2.5 text-center text-sm text-gray-500">
            Account only needed to write.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, useAsyncState } from "vue";
import { useRouter } from "vue-router";
import Whoops from "@/components/Whoops";
import getUser from "@/composables/getUser";
import ThePropertyList from "@/components/ThePropertyList";

export default {
  components: {
    Whoops,
    ThePropertyList,
  },

  setup() {
    const { user } = getUser();
    const router = useRouter();

    const googleInputSelected = ref(false);
    const showAddressErr = ref(false);
    // const isOpen = ref(false) // relates to HeadlessUI dialogue
    const selectedLoc = ref(null);
    const handleSearchSubmit = (componentName) => {
      if (selectedLoc.value == null) {
        return false;
      }

      if (!googleInputSelected.value) {
        showAddressErr.value = true;
        const addressInput = document.getElementById("autocomplete");
        addressInput.focus();
        return false;
      }
      setTimeout(() => {
        if (selectedLoc.value !== null) {
          router.push({
            name: componentName,
            params: { str: selectedLoc.value },
          });
        }
      }, 500);
    };

    const checkIfGoogleInputSelected = () => {
      if (!googleInputSelected.value) {
        showAddressErr.value = true;
        const addressInput = document.getElementById("autocomplete");
        // addressInput.focus();
        return false;
      }
    };

    const setPropertyVars = (address_components, geometry) => {
      localStorage.setItem("latNumber", geometry.location.lat());
      localStorage.setItem("lngNumber", geometry.location.lng());
      address_components.forEach((item, key) => {
        if (item.types.includes("locality")) {
          localStorage.setItem("locality", item.long_name);
        }
        if (item.types.includes("postal_code")) {
          localStorage.setItem("postal_code", item.long_name);
        }
        if (item.types.includes("route")) {
          localStorage.setItem("street_address", item.long_name);
          localStorage.setItem("route", item.long_name);
        }
        if (item.types.includes("street_number")) {
          localStorage.setItem("street_number", item.long_name);
        }
        localStorage.setItem("isFromHome", true);
      });
    };

    return {
      user,
      selectedLoc,
      handleSearchSubmit,
      checkIfGoogleInputSelected,
      showAddressErr,
      googleInputSelected,
      setPropertyVars,
    };
  },

  mounted() {
    /* BEGIN code for address autocomplete via Google Maps places */
    // State of NJ = LatLngBounds(39.833851, -74.871826)     &location=39.833851%-74.871826
    const autocomplete = new google.maps.places.Autocomplete(
      //document.getElementById("autocomplete"),
      this.$refs["autocomplete"],
      {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(39.833851, -74.871826)
        ),
        fields: ["address_components", "geometry", "formatted_address"],
        componentRestrictions: { country: ["us"] },
        types: ["address"],
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      // new code
      if (place.formatted_address) {
        this.selectedLoc = place.formatted_address;
        this.googleInputSelected = true;
        this.showAddressErr = false;
        this.setPropertyVars(place.address_components, place.geometry);
      }
      // new code
      for (const component of place.address_components) {
      }

      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
    });
    /* END code for address autocomplete via Google Maps places */
  },
};
</script>

<style>
/* BEGIN related to Google Maps autocomplete */
.pac-item {
  cursor: pointer;
  /* color: zagblue; */
}

.pac-item:hover {
  background-color: #dedaee;
}

.pac-item-query {
  font-size: 16px;
}
/* END related to Google Maps autocomplete */
</style>
