import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/auth/Login.vue";
import Signup from "../views/auth/Signup.vue";

// BEGIN new mgav route imports
import CreateReview from "../views/reviews/CreateReview.vue";
import EditReview from "../views/reviews/EditReview.vue";
import ReviewDetails from "../views/reviews/ReviewDetails.vue";
import PropertyLanding from "../views/PropertyLanding.vue";
// import QandA from "../views/reviews/QandA.vue";
import About from "../views/content/About.vue";
import WhichProperty from "../views/reviews/WhichProperty.vue";
import FAQ from "../views/content/FAQ.vue";
import Sitemap from "../views/content/Sitemap.vue";
import Privacy from "../views/legal/Privacy.vue";
import Terms from "../views/legal/Terms.vue";
import Accessibility from "../views/legal/Accessibility.vue";
import Dmca from "../views/legal/Dmca.vue";
import Account from "../views/auth/Account.vue";
import Welcome from "../views/auth/Welcome.vue";
import PublicProfile from "../views/auth/PublicProfile.vue";
import PageNotFound from "../views/content/PageNotFound.vue";
import ForRealEstatePros from "../views/content/ForRealEstatePros.vue";
import AfterReviewSubmit from "../views/reviews/AfterReviewSubmit.vue";
import GetVerified from "../views/auth/GetVerified.vue";
import ThanksAfterGetVerified from "../views/auth/ThanksAfterGetVerified.vue";
// END new mgav route imports

// route guard
import { projectAuth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login", query: {'redirect_url':from.path} });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // beforeEnter: requireAuth,
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: Home,
  //   props: true,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  // BEGIN new mgav routes
  {
    path: "/reviews/create/:str?",
    name: "CreateReview",
    component: CreateReview,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/reviews/edit/:review_id/:propertyId",
    name: "EditReview",
    component: EditReview,
    props: true,
    beforeEnter: requireAuth,
  },
  // BEGIN 404 catchall route
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
  // END 404 catchall route
  {
    path: "/reviews/after-review-submit/:id",
    name: "AfterReviewSubmit",
    component: AfterReviewSubmit,
    props: true,
  },
  {
    path: "/property/:id", // was path: "/propertylanding/:id",
    name: "PropertyLanding",
    component: PropertyLanding,
    props: true,
  },

  {
    path: "/review/:id", // was  path: "/reviews/:id",
    name: "ReviewDetails",
    component: ReviewDetails,
    props: true,
    // Removed "beforeEnter: requireAuth," because it's fine for anyone to see these
  },
  // {
  //   path: "/reviews/qanda",
  //   name: "QandA",
  //   component: QandA,
  //   props: true,
  // },

  {
    path: "/content/about",
    name: "About",
    component: About,
  },
  {
    path: "/which-property/:str",
    name: "WhichProperty",
    component: WhichProperty,
    props: true,
  },
  {
    path: "/content/for-real-estate-pros",
    name: "ForRealEstatePros",
    component: ForRealEstatePros,
  },
  {
    path: "/content/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/content/site-map",
    name: "Sitemap",
    component: Sitemap,
  },
  {
    path: "/legal/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/legal/acccessibility",
    name: "Accessibility",
    component: Accessibility,
  },
  {
    path: "/legal/dmca",
    name: "Dmca",
    component: Dmca,
  },
  {
    path: "/auth/getverified",
    name: "GetVerified",
    component: GetVerified,
    beforeEnter: requireAuth,
    // props: true,
  },
  {
    path: "/auth/thanksaftergetverified",
    name: "ThanksAfterGetVerified",
    component: ThanksAfterGetVerified,
    beforeEnter: requireAuth,
    // props: true,
  },
  {
    path: "/auth/account",
    name: "Account",
    component: Account,
    beforeEnter: requireAuth,
  },
  {
    path: "/auth/welcome",
    name: "Welcome",
    component: Welcome,
    beforeEnter: requireAuth,
  },
  {
    path: "/auth/public-profile/:id", // was path: "/auth/publicprofile",
    name: "PublicProfile",
    component: PublicProfile,
    props: true,
  },
  {
    path: "/auth/privacy",
    name: "Privacy",
    component: Privacy,
  },

  // END new mgav routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
