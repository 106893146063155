<template>
  <!-- <div class="bg-red-200 sm:bg-amber-200 md:bg-blue-200 lg:bg-emerald-200 xl:bg-violet-200 2xl:bg-pink-200 pt-4 lg:mx-24 lg:px-8"> -->
  <div id="top" class="scroll-mt-32">
    <img
      class="absolute inset-0 z-0 h-[400px] w-full object-cover pt-24 lg:h-[600px]"
      src="@/assets/images/mtown-kitchen-opt-trimmed-dark.jpg"
      alt="white kitchen island"
    />
    <div class="absolute inset-0 z-10 mt-24 h-[24.5%] w-full lg:h-[34.5%]">
      <div class="sm:my-2 lg:my-16 2xl:m-12">
        <p class="py-6 text-center text-sm text-indigo-50">about</p>
        <img
          src="@/assets/images/logo-for-dark-bg.svg"
          alt="Zagnetic logo"
          class="mx-auto w-1/2 shadow-sm sm:w-2/5 md:w-1/3 xl:w-1/4"
        />
        <h2
          class="py-8 text-center text-xl font-medium leading-relaxed text-indigo-50 shadow-sm lg:text-2xl"
        >
          We help you save time and make<br />better real estate decisions
        </h2>
      </div>
    </div>
  </div>

  <div class="bg-gray-50 lg:mx-24 lg:px-8">
    <div>
      <!-- <div>
            <img class="object-cover absolute inset-0 w-full h-[500px] lg:h-[600px] pt-24 z-0" src="@/assets/images/mtown-kitchen-opt-trimmed-dark.jpg" alt="white kitchen island">
              <div class="z-10 absolute inset-0 w-full h-[24.5%] lg:h-[34.5%] mt-24  shadow-lg">
                <div class="sm:my-2 lg:my-16 2xl:m-12">
                  <p class="py-6 text-center text-indigo-50 text-sm">about</p>
                  <img src="@/assets/images/logo-for-dark-bg.svg" alt="Zagnetic logo" class="w-1/2 sm:w-2/5 md:w-1/3 xl:w-1/4 mx-auto shadow-sm">
                  <h2 class="text-indigo-50 py-8 text-center font-medium text-xl lg:text-2xl leading-relaxed shadow-sm">We help you save time and make<br />better real estate decisions</h2>
                </div>
              </div>
        </div> -->
    </div>

    <div
      class="prose clear-both mx-auto mt-56 py-6 text-xl md:mt-64 lg:mt-96 lg:text-2xl"
    >
      <h4 class="pt-32 font-bold text-gray-700 md:pt-24">
        <br />Buying or renting a home is a big deal&thinsp;!
      </h4>
      <p class="pt-8">
        <strong>Imagine</strong> if you could benefit from the information and
        insights of people who have already been to a house or apartment you’re
        considering.
      </p>
      <p>
        Maybe even see some of their photos &#38; videos, showing good and bad
        things that aren’t disclosed in the listing’s crisp text or glossy
        photos.
      </p>
      <p>
        How about being able to read and submit questions &#38; answers
        (Q&#38;A) about the property?
      </p>

      <img
        src="@/assets/images/rounded-square-logo.svg"
        alt="Zagnetic rounded square logo"
        class="mx-auto w-1/2 py-6 md:w-1/3"
      />

      <blockquote class="bg-gray-100">
        <p>
          <strong>A good decision</strong> will impact your life in so many
          long-lasting ways.
        </p>
        <p>And so will a bad one...</p>
      </blockquote>

      <hr />

      <img
        class="float-right w-full pl-4 sm:w-2/5"
        src="@/assets/images/family-photo.jpg"
        alt="Gavagan family"
      />
      <p class="first-letter:text-2xl first-letter:font-semibold">
        My name is Mark Gavagan.
      </p>

      <p>
        I’ve been interested in real estate for as long as I can remember. Not
        as a real estate agent, or builder, or other person in the business.
      </p>
      <p>
        I’m just a fan of real estate. Maybe “real estate geek” is a good way to
        put it.
      </p>

      <p>
        I love to walk through unfamiliar neighborhoods and even go through open
        houses, just to see what they’re like.
      </p>
      <p>I really appreciate amazing design and craftsmanship.</p>
      <p>
        Some of the appeal is also aspirational. I’d love to have a modest,
        efficient, beautifully-crafted home one day.
      </p>
      <figure class="float-left mr-6 w-full md:w-1/2">
        <img
          src="@/assets/images/mark-glacial-runoff.jpg"
          alt="Mark Gavagan foot in freezing glacial runoff Glacier National Park, Montana"
        />
        <figcaption class="text-sm italic">
          That water is FREEZING! (Glacier National Park, MT)
        </figcaption>
      </figure>
      <p class="md:pt-5">
        I think part of this is that something like this would help offset some
        of my personal insecurities.
      </p>
      <p>
        It doesn’t really make sense, but it’s kind of like “Hey, I have all
        these shortcomings and some things I don't like about myself, but I’m
        connected with this beautifully-designed place.”
      </p>
      <p>
        I guess it would make me feel like a little bit of that shines on me.
      </p>

      <p class="first-letter:text-2xl first-letter:font-semibold">
        Sometimes I take photos at open houses, but I <i>always</i> walk out
        with a mental <strong>list of things I like and don’t like</strong>, as
        well as whether or not it’s a good value.
      </p>
      <figure class="float-right ml-6 w-full md:w-3/5">
        <img
          src="@/assets/images/mtown-kitchen-opt.jpg"
          alt="Kitchen counter, cabinets and window"
        />
        <figcaption class="text-sm italic">
          Kitchen I photographed at an open house
        </figcaption>
      </figure>

      <p class="md:pt-5">And <strong>it's not just me</strong>.</p>
      <p>
        Even when I drag my children to an open house, they notice things I
        didn't and have good insights (and sometimes even enjoy it).
      </p>
      <p>
        We don’t have any special qualifications, but it seems likely that
        <strong
          >these could help someone else who’s considering that property</strong
        >.
      </p>

      <blockquote class="clear-both">
        <strong>It doesn't matter if that person agrees or not</strong>. What
        matters is that they are aware of the information &#38; opinions
        <i>before</i> making a very serious and expensive commitment.
      </blockquote>

      <p class="clear-both">
        I learned this personally, when my friend Dave looked carefully at a
        house my wife Kimberly and I had fallen for.
      </p>
      <figure class="w-full">
        <img
          src="@/assets/images/colorful-brick-shed3.jpg"
          alt="Colorful brick shed in front of a lake"
        />
        <figcaption class="text-sm italic">
          Photo by
          <a
            href="https://unsplash.com/@silverringvee?utm_source=unsplash"
            class="underline decoration-sky-500"
            >Silver Ringvee</a
          >
          on
          <a
            href="https://unsplash.com/?utm_source=unsplash"
            class="underline decoration-amber-500"
            >Unsplash</a
          >
        </figcaption>
      </figure>

      <p>
        It had a lot of good qualities, but Dave pointed out a few key reasons
        it didn't really fit our situation.
      </p>

      <p>
        <i>Every</i> time I go past that house, I think how nice it looks and
        how grateful I am that we didn't move there.
      </p>

      <h4>A Quick Story:</h4>
      <div>
        <p>
          I interviewed <i>tons</i> of people for
          <span class="zagnetic">zagnetic</span> who had all purchased a house,
          condo or apartment in the last three years.
        </p>

        <p>I wanted to learn about all their good and bad experiences.</p>

        <p>On my 27th interview, I spoke with a lovely woman named Theresa.</p>
        <p class="mb-6 font-semibold">
          I almost could not believe the story she told me:
        </p>
      </div>

      <blockquote class="bg-gray-100">
        <p><strong>Theresa</strong> had been house hunting for a while.</p>

        <p>
          She was frustrated because the market was hot and she was wasting a
          lot of time seeing things in-person that were not what was shown in
          photos or described in the listing.
        </p>

        <p>
          The worst had obvious dealbreakers that were simply not disclosed.
        </p>

        <p>
          In one case, she drove over an hour to see a house that seemed
          promising.
        </p>

        <p>She couldn’t help getting her hopes up about this one.</p>

        <p>
          It had everything she wanted, and
          <strong>one more thing she hadn’t even dreamed of</strong>: a
          beautiful lake right behind the house.
        </p>

        <p>It was in full view from a small sunroom.</p>

        <p>
          Even just a few days after being listed, Theresa was amazed the
          property was still available.
        </p>

        <p>She dropped everything and made the drive.</p>

        <p>
          Unfortunately, she was very frustrated when she arrived, because it
          was a <strong>complete waste of time</strong>.
        </p>

        <p>
          In addition to plenty of other untold shortcomings, the biggest
          disappointment was the lake, or <strong>lack</strong> of a lake.
        </p>

        <p>
          Incredibly,
          <strong
            >the listing agent had Photoshopped a pretend lake into the
            background</strong
          >
          of the sunroom.
        </p>

        <figure class="clear-both mx-auto w-5/6 sm:w-4/5 md:w-3/5">
          <img
            src="@/assets/images/lake-photoshopped-2.jpg"
            alt="Mark Gavagan foot in freezing glacial runoff Glacier National Park, Montana"
          />
          <figcaption class="text-sm italic">
            Photo from my interview notes (I know &#8594; terrible handwriting!)
          </figcaption>
        </figure>

        <p>
          Who knows what that agent was thinking, but it illustrates the point
          that <strong>not all listings are accurate and honest</strong>.
        </p>

        <p>
          Theresa did not buy that house, but did find one a few months later.
          It doesn’t have a lake, but I suppose there’s always Photoshop.
        </p>
      </blockquote>

      <!-- <figure class="float-right w-full md:w-3/5 ml-6">
        <img src="@/assets/images/lake-photoshopped-2.jpg" alt="Mark Gavagan foot in freezing glacial runoff Glacier National Park, Montana">
        <figcaption class="bg-amber-100 italic text-sm">Photo from Mark's interview notes (I know, handwriting)
        </figcaption>
      </figure> -->

      <h4 class="clear-both pt-4 font-bold">Last Words</h4>

      <div class="">
        <p>
          If you know someone looking for a home, please tell them about 
          <span class="zagnetic">zagnetic</span>.
        </p>
        <p><span class="underline">You'll be helping them</span>:</p>
        <ol class="space-y-4 mb-8 font-medium">
          <li>Keep track<sup>*</sup> of all the properties they visit. &thinsp;(&thinsp;can be 100% secret<button class="mb-2.5 ml-0">
        <Whoops whoopsButtonVifTextAndIcon="4" class="-mb-4"/>
      </button>&thinsp;)</li>
          <p class="italic font-light text-base pl-2.5 -pt-2"><sup>*</sup>&thinsp;photos, pros, cons, what to spend money on, etc.</p>
          <li>Learn from other reviews about each property & neighborhood</li>
          <li>Ultimately, they'll make a better decision, so they're <span class="font-bold text-xl text-transparent bg-clip-text bg-gradient-to-r from-pink-600 to-indigo-700/80">Happy!</span></li>
        </ol>

        <!-- <p class="italic font-light text-sm"><sup>*</sup> these can be 100% secret, in case they don't want to tip others off on places they might try to buy</p> -->
        
        <!-- <p> <span class="font-semibold">How?</span> New information about about homes <u>and</u> neighborhoods they're considering, from reviews, questions &#38; answers.</p> -->
      </div>
      <p>Thank you!</p>

      <div class="space-between flex py-4">
        <ShareButtonDD shareButtonDDStyle="4" :urlToShare="$route.fullPath"/>
        <button
          class="ml-12 text-base font-medium text-indigo-900 underline hover:text-gray-400"
          @click="goHome"
        >
          Home
        </button>
      </div>

      
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import ShareButtonDD from "@/components/ShareButtonDD.vue";
import Whoops from "@/components/Whoops";

export default {
  components: {
    ShareButtonDD,
    Whoops,
  },

  setup() {
    const router = useRouter();

    const goHome = async () => {
      router.push({ name: "Home" });
    };

    return { goHome };
  },
};
</script>

<style></style>
