<template>
  <div class="">
    <div class="propLandingCard mb-4 md:mb-0">
      <p class="-mt-1.5 pb-2 text-base italic">Summary of this property's</p>

      <!-- <slot name="one"><p>blank slot</p></slot>
           <div class="mt-4>">
            <slot name="two"><p>REALLY blank slot</p></slot>
           </div> -->

      <div class="headinggg">{{ ratingType }} Ratings</div>

      <!-- BEGIN hollow rating star test -->
      <!-- you are here -->
      <div class="mt-3.5 mb-6 inline-block sm:flex">
        <div v-if="whichRatingSummary" class="flex">
          <div v-if="weightedRating > 0.25 && weightedRating < 0.75">
            <fa
              icon="star-half-alt"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>
          <div v-else-if="weightedRating >= 0.75">
            <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
          </div>
          <div v-else>
            <fa
              :icon="['far', 'star']"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>

          <div v-if="weightedRating > 1.25 && weightedRating < 1.75">
            <fa
              icon="star-half-alt"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>
          <div v-else-if="weightedRating >= 1.75">
            <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
          </div>
          <div v-else>
            <fa
              :icon="['far', 'star']"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>

          <div v-if="weightedRating > 2.25 && weightedRating < 2.75">
            <fa
              icon="star-half-alt"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>
          <div v-else-if="weightedRating >= 2.75">
            <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
          </div>
          <div v-else>
            <fa
              :icon="['far', 'star']"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>

          <div v-if="weightedRating > 3.25 && weightedRating < 3.75">
            <fa
              icon="star-half-alt"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>
          <div v-else-if="weightedRating >= 3.75">
            <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
          </div>
          <div v-else>
            <fa
              :icon="['far', 'star']"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>

          <div v-if="weightedRating > 4.25 && weightedRating < 4.75">
            <fa
              icon="star-half-alt"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>
          <div v-else-if="weightedRating >= 4.75">
            <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
          </div>
          <div v-else>
            <fa
              :icon="['far', 'star']"
              id="rating_star"
              size="md"
              class="mr-0.5"
            />
          </div>
        </div>

        <div class="">
          <span class="ml-1 mr-0 text-sm font-semibold"
            >({{ weightedRating.toFixed(1) }}) </span
          ><span class="ml-0.5 pt-1 text-sm"
            >Based on 
            <a class="text-sm underline font-semibold hover:text-gray-400" href="#rrreviews">
              {{ numberOfRatings }} rating<span v-if="numberOfRatings != 1" class="text-sm underline font-semibold hover:text-gray-400">s</span>
            </a>
            </span
          >
        </div>
      </div>
      <!-- BEGIN hollow rating star test -->

      <div class="space-y-1.5">
        <div class="grid grid-cols-12">
          <div class="col-span-2 grid grid-cols-3">
            <span
              class="col-span-3 pr-2.5 text-right text-base sm:col-span-2 sm:pr-0"
              >5&thinsp;<fa
                icon="star"
                id="rating_star"
                class="ml-0.5 pb-0.5 text-base md:ml-0 lg:ml-0.5"
            /></span>
          </div>
          <div class="relative col-span-8">
            <div
              class="absolute -inset-x-1 inset-y-2 z-10 mx-1.5 h-2.5 w-full rounded-md bg-gray-100 ring-1 ring-gray-200"
            ></div>
            <div v-if="pctFive <= 0.95">
              <div
                class="bg-gradient-to-r from-gray-300 to-transparent  ring-gray-300 absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[1.5%] rounded-lg ring-0"
              ></div>
            </div>
            <div v-else-if="pctFive > 0.95 && pctFive  <= 3">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[2%] rounded-md"
              ></div>
            </div>
            <div v-else-if="pctFive > 3 && pctFive  <= 6">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[5%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 6 && pctFive <= 12">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[9%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 12 && pctFive <= 18">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[15%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 18 && pctFive <= 23">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[20%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 23 && pctFive <= 29">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[26%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 29 && pctFive <= 40">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[35%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 40 && pctFive <= 49">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[45%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 49 && pctFive <= 58">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[55%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 58 && pctFive <= 69">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[65%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 69 && pctFive <= 79">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[75%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 79 && pctFive <= 89">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[85%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFive > 89 && pctFive <= 95">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[92%] rounded-md ring-1"
              ></div>
            </div>
             <div v-else-if="pctFive > 95 && pctFive <= 99.5">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[98%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else>
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[100%] rounded-md ring-1"
              ></div>
            </div>
          </div>
          <span class="col-span-2 text-right text-base">{{ pctFive.toFixed(0) }}%</span>
        </div>

        <div class="grid grid-cols-12">
          <div class="col-span-2 grid grid-cols-3">
            <span
              class="col-span-3 pr-2.5 text-right text-base sm:col-span-2 sm:pr-0"
              >4&thinsp;<fa
                icon="star"
                id="rating_star"
                class="pb-0.5 text-base"
            /></span>
          </div>
          <div class="relative col-span-8">
            <div
              class="absolute -inset-x-1 inset-y-2 z-10 mx-1.5 h-2.5 w-full rounded-md bg-gray-100 ring-1 ring-gray-200"
            ></div>
            <div v-if="pctFour <= 0.95">
              <div
                class="bg-gradient-to-r from-gray-300 to-transparent  ring-gray-300 absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[1.5%] rounded-lg ring-0"
              ></div>
            </div>
             <div v-else-if="pctFour > 0.95 && pctFour  <= 3">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[2%] rounded-md"
              ></div>
            </div>
            <div v-else-if="pctFour > 3 && pctFour  <= 6">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[5%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 6 && pctFour <= 12">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[9%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 12 && pctFour <= 18">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[15%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 18 && pctFour <= 23">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[20%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 23 && pctFour <= 29">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[26%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 29 && pctFour <= 40">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[35%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 40 && pctFour <= 49">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[45%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 49 && pctFour <= 58">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[55%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 58 && pctFour <= 69">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[65%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 69 && pctFour <= 79">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[75%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 79 && pctFour <= 89">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[85%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 89 && pctFour <= 95">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[92%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctFour > 95 && pctFour <= 99.5">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[98%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else>
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[100%] rounded-md ring-1"
              ></div>
            </div>
          </div>
          <span class="col-span-2 text-right text-base">{{ pctFour.toFixed(0) }}%</span>
        </div>

        <div class="grid grid-cols-12">
          <div class="col-span-2 grid grid-cols-3">
            <span
              class="col-span-3 pr-2.5 text-right text-base sm:col-span-2 sm:pr-0"
              >3&thinsp;<fa
                icon="star"
                id="rating_star"
                class="pb-0.5 text-base"
            /></span>
          </div>
          <div class="relative col-span-8">
            <div
              class="absolute -inset-x-1 inset-y-2 z-10 mx-1.5 h-2.5 w-full rounded-md bg-gray-100 ring-1 ring-gray-200"
            ></div>
            <div v-if="pctThree <= 0.95">
              <div
                class="bg-gradient-to-r from-gray-300 to-transparent  ring-gray-300 absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[1.5%] rounded-lg ring-0"
              ></div>
            </div>
             <div v-else-if="pctThree > 0.95 && pctThree  <= 3">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[2%] rounded-md"
              ></div>
            </div>
            <div v-else-if="pctThree > 3 && pctThree  <= 6">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[5%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 6 && pctThree <= 12">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[9%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 12 && pctThree <= 18">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[15%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 18 && pctThree <= 23">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[20%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 23 && pctThree <= 29">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[26%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 29 && pctThree <= 40">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[35%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 40 && pctThree <= 49">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[45%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 49 && pctThree <= 58">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[55%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 58 && pctThree <= 69">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[65%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 69 && pctThree <= 79">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[75%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 79 && pctThree <= 89">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[85%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 89 && pctThree <= 95">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[92%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctThree > 95 && pctThree <= 99.5">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[98%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else>
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[100%] rounded-md ring-1"
              ></div>
            </div>
          </div>
          <span class="col-span-2 text-right text-base">{{ pctThree.toFixed(0) }}%</span>
        </div>

        <div class="grid grid-cols-12">
          <div class="col-span-2 grid grid-cols-3">
            <span
              class="col-span-3 pr-2.5 text-right text-base sm:col-span-2 sm:pr-0"
              >2&thinsp;<fa
                icon="star"
                id="rating_star"
                class="pb-0.5 text-base"
            /></span>
          </div>
          <div class="relative col-span-8">
            <div
              class="absolute -inset-x-1 inset-y-2 z-10 mx-1.5 h-2.5 w-full rounded-md bg-gray-100 ring-1 ring-gray-200"
            ></div>
            <div v-if="pctTwo <= 0.95">
              <div
                class="bg-gradient-to-r from-gray-300 to-transparent  ring-gray-300 absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[1.5%] rounded-lg ring-0"
              ></div>
            </div>
             <div v-else-if="pctTwo > 0.95 && pctTwo  <= 3">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[2%] rounded-md"
              ></div>
            </div>
            <div v-else-if="pctTwo > 3 && pctTwo  <= 6">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[5%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 6 && pctTwo <= 12">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[9%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 12 && pctTwo <= 18">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[15%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 18 && pctTwo <= 23">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[20%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 23 && pctTwo <= 29">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[26%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 29 && pctTwo <= 40">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[35%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 40 && pctTwo <= 49">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[45%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 49 && pctTwo <= 58">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[55%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 58 && pctTwo <= 69">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[65%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 69 && pctTwo <= 79">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[75%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 79 && pctTwo <= 89">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[85%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 89 && pctTwo <= 95">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[92%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctTwo > 95 && pctTwo <= 99.5">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[98%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else>
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[100%] rounded-md ring-1"
              ></div>
            </div>
          </div>
          <span class="col-span-2 text-right text-base">{{ pctTwo.toFixed(0) }}%</span>
        </div>

        <div class="grid grid-cols-12">
          <div class="col-span-2 grid grid-cols-3">
            <span
              class="col-span-3 pr-2.5 text-right text-base sm:col-span-2 sm:pr-0"
              >1&thinsp;<fa
                icon="star"
                id="rating_star"
                class="ml-0.5 pb-0.5 text-base"
            /></span>
          </div>
          <div class="relative col-span-8">
            <div
              class="absolute -inset-x-1 inset-y-2 z-10 mx-1.5 h-2.5 w-full rounded-md bg-gray-100 ring-1 ring-gray-200"
            ></div>
            <div v-if="pctOne <= 0.95">
              <div
                class="bg-gradient-to-r from-gray-300 to-transparent  ring-gray-300 absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[1.5%] rounded-lg ring-0"
              ></div>
            </div>
             <div v-else-if="pctOne > 0.95 && pctOne  <= 3">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[2%] rounded-md"
              ></div>
            </div>
            <div v-else-if="pctOne > 3 && pctOne  <= 6">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[5%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 6 && pctOne <= 12">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[9%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 12 && pctOne <= 18">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[15%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 18 && pctOne <= 23">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[20%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 23 && pctOne <= 29">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[26%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 29 && pctOne <= 40">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[35%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 40 && pctOne <= 49">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[45%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 49 && pctOne <= 58">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[55%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 58 && pctOne <= 69">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[65%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 69 && pctOne <= 79">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[75%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 79 && pctOne <= 89">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[85%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 89 && pctOne <= 95">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[92%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else-if="pctOne > 95 && pctOne <= 99.5">
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[98%] rounded-md ring-1"
              ></div>
            </div>
            <div v-else>
              <div
                class="bg-ratingstar ring-ratingstar absolute -inset-x-1 inset-y-2 z-20 mx-1.5 h-2.5 w-[100%] rounded-md ring-1"
              ></div>
            </div>
          </div>
          <span class="col-span-2 text-right text-base">{{ pctOne.toFixed(0) }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  props: {
    propertyId: String,
    propertyReviews: Array,
    whichRatingSummary: String,
    ratingType: String,
  },

  setup(props, context) {
    const reviews = props.propertyReviews;
    const ratingName = props.whichRatingSummary;
    
    const totalReviews = ref(reviews.length);

    const fiveStarCount = ref(0);
    const fourStarCount = ref(0);
    const threeStarCount = ref(0);
    const twoStarCount = ref(0);
    const oneStarCount = ref(0);
    const blankRatingCount = ref(0);

    reviews.forEach((value, index) => {
            
            if(value[ratingName] && value[ratingName] == 5) {
                fiveStarCount.value = fiveStarCount.value + 1;
            }
            if(value[ratingName] && value[ratingName] == 4) {
                fourStarCount.value = fourStarCount.value + 1;
            }
            if(value[ratingName] && value[ratingName] == 3) {
                threeStarCount.value = threeStarCount.value + 1;
            }
            if(value[ratingName] && value[ratingName] == 2) {
                twoStarCount.value = twoStarCount.value + 1;
            }
            if(value[ratingName] && value[ratingName] == 1) {
                oneStarCount.value = oneStarCount.value + 1;
            }
            if(typeof value[ratingName] === 'undefined' || value[ratingName] == 0 || value[ratingName] == null) {
                blankRatingCount.value = blankRatingCount.value + 1;
            }
        });


    console.log("blankRatingCount = ", blankRatingCount);

    const numberOfRatings = computed(() => {
        const calculatedVal = (+totalReviews.value - blankRatingCount.value);
        return calculatedVal;
    });


    console.log("total length", totalReviews);

    // const pctFive = computed(() => {
    //     const calculatedVal = (+fiveStarCount.value / + totalReviews.value) * 100;
    //     return calculatedVal;
    // });

    // const pctFour = computed(() => {
        
    //     const calculatedVal = (+fourStarCount.value / + totalReviews.value) * 100;
    //     return calculatedVal;
    // });
    
    // const pctThree = computed(() => {
    //   const calculatedVal = (+threeStarCount.value / + totalReviews.value) * 100;
    //     return calculatedVal;
    // });

    // const pctTwo = computed(() => {
    //    const calculatedVal = (+twoStarCount.value / + totalReviews.value) * 100;
    //     return calculatedVal;
    // });

    // const pctOne = computed(() => {
    //   const calculatedVal = (+oneStarCount.value / + totalReviews.value) * 100;
    // return calculatedVal;
    // });

    // BEGIN Mark's attempt to calculate based on numberOfRatings instead of totalReviews
    const pctFive = computed(() => {
        if(+fiveStarCount.value === 0) { return 0; } 
        const calculatedVal = (+fiveStarCount.value / + numberOfRatings.value) * 100;
        return calculatedVal;
    });

    const pctFour = computed(() => {
        if(+fourStarCount.value === 0) { return 0; }
        const calculatedVal = (+fourStarCount.value / + numberOfRatings.value) * 100;
        return calculatedVal;
    });
    
    const pctThree = computed(() => {
      if(+threeStarCount.value === 0) { return 0; }
      const calculatedVal = (+threeStarCount.value / + numberOfRatings.value) * 100;
        return calculatedVal;
    });

    const pctTwo = computed(() => {
      if(+twoStarCount.value === 0) { return 0; }
       const calculatedVal = (+twoStarCount.value / + numberOfRatings.value) * 100;
        return calculatedVal;
    });

    const pctOne = computed(() => {
      if(+oneStarCount.value === 0) { return 0; }
      const calculatedVal = (+oneStarCount.value / + numberOfRatings.value) * 100;
    return calculatedVal;
    });
    // END Mark's attempt to calculate based on numberOfRatings instead of totalReviews

    const weightedRating = computed(() => {
      
      return (
        (+pctFive.value * 5 +
          +(pctFour.value * 4) +
          +pctThree.value * 3 +
          +pctTwo.value * 2 +
          +pctOne.value * 1) /
        100
      );
    });

    

    return {
      
      pctFive,
      pctFour,
      pctThree,
      pctTwo,
      pctOne,

      weightedRating,
      totalReviews,
      numberOfRatings,
    };
  },
};
</script>

<style></style>
