// useStorage.js is for uploading and deleting images+ in Firebase Storage

import { projectStorage } from "../firebase/config";
import { ref } from "vue";
import getUser from "@/composables/getUser"; // ### effectively the same, but changed by mgav 01282022, was: import getUser from "./getUser"
// ??? not sure import below is correct - included a lot of storage functions then will delete unused when working properly
import {
  deleteObject,
  getDownloadURL,
  uploadBytes,
  ref as firebaseRef,
  listAll,
} from "firebase/storage"; // ### new for Firebase 8 to 9 refactoring

const { user } = getUser();

const useStorage = () => {
  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);
  const fetchedImages = ref([]);
  const isUploadStarted = ref(false);
  const isUploadDone = ref(false);
  const totalUpload = ref(null);
  const uploadedCount = ref(0);
  const uploadedFilesArray = ref([]);
  const uploadImage = async (file, baseDir, storageDir = null) => {
    // ??? need to make this code below more reusable, so it can also be used to upload / delete a user's profile photo, for example
    const storeTo = storageDir !== null ? storageDir : user.value.uid;
    filePath.value = `${baseDir}/${storeTo}/${file.name}`; /* was filePath.value = `covers/${user.value.uid}/${file.name}` */
    const storageRef = firebaseRef(projectStorage, filePath.value);
    const metadata = {
      contentType: "image/jpeg",
    };
    isUploadStarted.value = false;
    isUploadDone.value = false;

    await uploadBytes(storageRef, file, metadata)
      .then((snapshot) => {
        isUploadDone.value = true;
        console.log('upload in then-', snapshot);
        
      }).catch(err=>{
        console.log('error in then-', err.message);
        error.value = err.message;
      });
  };

  const bulkUpload = async (fileArray, baseDir, storageDir = null) => {
    const collectFilePaths = [];
    totalUpload.value = fileArray.length;
    uploadedCount.value = 0;
    const storeTo = storageDir !== null ? storageDir : user.value.uid;
    const metadata = {
      contentType: "image/jpeg",
    };
    
    isUploadStarted.value = true;
    fileArray.forEach((file) => {
      filePath.value = `${baseDir}/${storeTo}/${file.name}`; /* was filePath.value = `covers/${user.value.uid}/${file.name}` */
      const storageRef = firebaseRef(projectStorage, filePath.value);
      uploadBytes(storageRef, file, metadata)
      .then((snapshot) => {
        console.log('upload in then-', snapshot);
        uploadedCount.value++;
      }).catch(err=>{
        console.log('error in then-', err.message);
        error.value = err.message;
      });
      collectFilePaths.push(filePath.value);
    });
    uploadedFilesArray.value = collectFilePaths;
  }

  const deleteImage = async (path) => {
    const storageRef = firebaseRef(projectStorage, path);

    // Delete the file
    deleteObject(storageRef).then(() => {
      // File deleted successfully
      console.log('Image deleted successfully');
    }).catch((error) => {
      // Uh-oh, an error occurred!
      error.value = error.message;
    });
  };

  const getImages = async (imgPathArray) => {
    imgPathArray.forEach(element => {
      const imgRef = firebaseRef(projectStorage, element);
      getDownloadURL(imgRef)
      .then((url) => {
        fetchedImages.value.push(url);
      })
      .catch((error) => {
        // console.log('img error', error);
      });    
    });
  }

  const getImagesWithPath = async (imgPathArray) => {
    imgPathArray.forEach(element => {
      const imgRef = firebaseRef(projectStorage, element);
      getDownloadURL(imgRef)
      .then((url) => {
        const arrayItem = {};
        arrayItem['dbPath'] = element;
        arrayItem['imgPath'] = url;
        fetchedImages.value.push(arrayItem);
      })
      .catch((error) => {
        // console.log('img error', error);
      });    
    });
  }

  const getImagesByFolderRef = async (folderRef) => {
    const imgArray = [];
    const listRef = firebaseRef(projectStorage, folderRef);
    listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        // console.log('getImagesByFolderRef folderRef', folderRef);
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach((itemRef) => {
        // All the items under listRef.
        imgArray.push(itemRef._location.path_);
        // console.log('getImagesByFolderRef itemRef', itemRef);
      });
      
      imgArray.forEach(element => {
        
        const imgRef = firebaseRef(projectStorage, element);
        getDownloadURL(imgRef)
        .then((url) => {
          fetchedImages.value.push(url);
        })
        .catch((error) => {
          console.log('img error', error);
        });
      });

    }).catch((error) => {
      // console.log('getImagesByFolderRef error', error);
      // Uh-oh, an error occurred!
    });

    
  }

  return { uploadImage, deleteImage, getImages, getImagesByFolderRef, url, filePath, fetchedImages, error, bulkUpload, isUploadStarted, totalUpload, uploadedCount, getImagesWithPath, uploadedFilesArray, isUploadDone };
};

export default useStorage;
