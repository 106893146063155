<template>
  <div
    class="mx-auto max-w-screen-md rounded-lg bg-slate-50 p-2.5"
    v-if="targetedUser"
  >
    <!-- <p class="mb-8 text-orange-600">
      UserId from $route.params.id =
      <span class="underline">{{ $route.params.id }}</span>
    </p>
    <p class="mb-8 text-lime-600">
      profiledUser = <span class="underline">{{ profiledUser }}</span>
    </p>
    <p class="mb-8 text-sky-600">
      value of targetedUser.realFirstName is
      <span class="underline">{{ targetedUser.realFirstName }}</span>
    </p> -->

    <p class="py-1.5 text-center text-base">Public profile for</p>
    <h1 v-if="isIncognitoProfile == true" class="mt-2 mb-6 text-3xl font-semibold">
      {{ targetedUser.displayName
      }}
      <span class="tempdevnote">(profiledUser's displayName)</span>
    </h1>
    <h1
      v-else
      class="mt-2 mb-6 text-center text-3xl font-bold sm:text-5xl"
    >
      {{ targetedUser.realFirstName }}&nbsp;{{
        targetedUser.realMiddleNameOrInit
      }}&nbsp;{{ targetedUser.realLastName }}
    </h1>
    
    <!-- Display either real name or display name here, depending on privacy of item user clicked-on to reach this public profile page of another user (we do NOT want an Incognito review leading to that reviewer's real name) -->
    <!-- BEGIN v-if isVerified = true -->
    <div
      v-if="targetedUser.isRealEstateAgent == 'verified'"
      class="mt-0 mb-12 text-lg font-semibold leading-5 md:text-lg md:leading-normal bg-highlight/70 rounded-2xl mx-4 sm:mx-28 md:mx-44 pt-4 pb-0.5"
    >
      <p class="text-center">
        <span
          class="text-zagblue ml-0 text-lg leading-5 sm:text-xl md:leading-normal"
          >verified<sup>&thinsp;<i class="fas fa-check-circle"></i></sup
        ></span>
        real estate agent<button class="mb-1 ml-0.5">
          <Whoops whoopsButtonVifTextAndIcon="11" />
        </button>
      </p>

      <p class="mb-4 text-center text-sm font-light italic text-slate-500">
        verified
        {{
          targetedUser.createdAt
            .toDate()
            .toLocaleString("en-US", { month: "long" })
        }}
        {{ targetedUser.createdAt.toDate().getUTCFullYear() }}
        <!-- will be targetedUser.dateVerified -->
      </p>
    </div>
    <!-- END v-if isVerified = true -->

    <!-- BEGIN test for profile photo from publicProfilePhoto -->
    <!-- <p class="tempdevnote my-6">targetedUser.profilePhotoUrl = {{targetedUser.profilePhotoUrl}}</p> -->
    
    <img v-if="isIncognitoProfile == false && fetchedImages.length > 0"
      :src=fetchedImages[0]
      alt="profile photo from targetedUser.profilePhotoUrl"
      class="mx-auto w-4/5 rounded-xl my-6 sm:w-2/5"
    />
    <img v-else
      :src="incognitoProfileImage"
      alt="profile photo from publicProfilePhoto"
      class="mx-auto w-4/5 rounded-xl pt-6  sm:w-2/5 lg:w-1/4"
    />


    <!-- END test for profile photo from publicProfilePhoto -->

    <!-- BEGIN profile photo, for all users, BUT if an Incognito profile, use a generic incognito image -->
    <!-- <div
    v-if="isIncognitoProfile == false"
    class="my-4 sm:my-6 rounded-lg px-2 py-6 text-sm font-light italic"
    ><span class="tempdevnote">(profiledUser's profilePhotoUrl)</span>
    <img
                src="https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/profile_pics%2Fjack-july2020.jpg?alt=media&token=2213f083-4ec2-449c-b907-6d71f1b5fb03"
                alt="profile photo"
                class="mx-auto w-4/5 sm:w-2/5 lg:w-1/4 rounded-xl"
              />
              </div
  >
  <span v-else
    ><img
      :src="incognitoProfileImage"
      class="my-4 mx-auto sm:my-6 w-1/2 lg:w-1/4 rounded-lg"
      alt="generic spy icon with a blank background"
  /></span> -->

    <!-- END profile photo, for all users, BUT if an Incognito profile, use a generic incognito image -->
    <!-- BEGIN v-if isVerified = true -->
    <div
      v-if="targetedUser.isRealEstateAgent == 'verified'"
      class="mt-12 mb-8 text-base leading-5 md:text-base md:leading-normal"
    >

    <!-- BEGIN contact info -->
    <div class="mb-12">
      

      <!-- <p v-if="targetedUser.displayPhoneOnPublicProfile == 'true'" class="mt-6 text-base font-medium">
        <span class="text-sm font-light underline">Phone</span>&thinsp;:&nbsp; {{ targetedUser.profPhone }}
      </p> -->

      <p v-if="targetedUser.displayPhoneOnPublicProfile == 'true'" class="mt-6 text-lg font-normal text-center">
        <fa
          :icon="['fas', 'mobile-alt']"
          class="mr-1.5 -mb-0 text-xl"
          alt="mobile phone icon"
        /> {{ targetedUser.profPhone }}
      </p>
     <p v-if="targetedUser.displayEmailOnPublicProfile == 'true'" class="mt-6 text-lg font-normal text-center">
        <fa
          :icon="['far', 'envelope']"
          class="mr-1.5 -mb-0.5 text-xl"
          alt="email icon"
        /> {{ targetedUser.profEmail }}
      </p>
      </div>
      <!-- END contact info -->

      <div class="bg-indigo-50 rounded-xl px-2.5 sm:mx-16 py-6">
        <p class="text-center text-base font-semibold">{{ targetedUser.realFirstName }}&nbsp;cites <span class="text-base text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-800 to-indigo-600 font-semibold">expertise</span><button
          class="-ml-0.5 align-sub"
        >
          <Whoops whoopsButtonVifTextAndIcon="30" />
        </button> in
      </p>
       <p class="text-center text-base font-semibold">
        this real estate market:
      </p>

      <p class="my-8 text-center text-xl font-semibold">
        <span class="rounded-xl bg-white ring-1 ring-slate-300 py-1.5 px-4 text-xl font-semibold"
          >{{ targetedUser.primarySpecialtyLocation }}</span
        >
        <!-- will be targetedUser.primarySpecialtyLocation -->
      </p>

      <p v-if="targetedUser.primarySpecialtyLocationContext" class="text-left font-normal text-sm italic px-6 sm:px-20"><span class="font-normal text-sm italic underline">Details</span>&thinsp;:&nbsp; {{ targetedUser.primarySpecialtyLocationContext }}</p>
      </div>

      <!-- BEGIN additional info v-if isRealEstateAgent == true (regardless of isVerified status) -->
    <div v-if="targetedUser.isRealEstateAgent == 'verified'">
      <details
        class=" bg-rounded-lg my-8 rounded-lg p-2 open:border open:border-slate-400 open:bg-white open:py-2.5 sm:open:mx-16"
      >
        <summary class="mb-4 mx-auto text-center">
          <span class="rounded-lg px-0 text-sm font-normal hover:text-slate-400">
            Real estate license & business info</span
          >
          <span class="text-indigo-900 ml-0.5 text-xs hover:text-slate-400 font-light italic sm:text-xs">(click)</span
          >
        </summary>
        <p class="text-center"><span class="text-xs italic rounded-xl text-slate-700 bg-pink-50 px-3 py-1">Info is deemed reliable, but is not guaranteed&thinsp;:</span></p>
        <ul class="my-6 space-y-6 text-base">
          <li class="text-base font-medium">
            <span class="text-sm font-light underline"
              >full name on license</span
            >&thinsp;:&nbsp; {{ targetedUser.realFirstName }}&nbsp;{{
        targetedUser.realMiddleNameOrInit
      }}&nbsp;{{ targetedUser.realLastName }}
          </li>
          <li class="text-base font-medium">
            <span class="text-sm font-light underline"
              >licensed in</span
            >&thinsp;:&nbsp; {{ targetedUser.primaryLicenseGovernment }}
          </li>
          <li class="text-base font-medium">
            <span class="text-sm font-light underline">license type</span
            >&thinsp;:&nbsp; {{ targetedUser.primaryLicenseType }}
          </li>
          <li class="text-base font-medium">
            <span class="text-sm font-light underline">license #</span>&thinsp;:&nbsp;
            {{ targetedUser.primaryLicenseNumber }}
          </li>
          <li class="text-base font-medium">
            <span class="text-sm font-light underline"
              >brokerage / employer</span
            >&thinsp;:&nbsp;
            <p v-if="targetedUser.businessFranchise" class="ml-20 text-base font-medium">{{ targetedUser.businessFranchise }}</p>
            <p class="ml-20 text-base font-medium">{{ targetedUser.businessName }}</p>
            <p class="ml-20 text-base font-medium">{{ targetedUser.businessAddress }}</p>
            <p class="ml-20 text-base font-medium">{{ targetedUser.businessPhone }}</p>
          </li>
          <li v-if="isVerified == true" class="text-base font-medium">
            <span class="text-sm font-light underline text-orange-400">date first licensed</span
            >&thinsp;:&nbsp; Jan 7, 2012
          </li>
          <!-- <li class="text-base font-medium">
            <span class="text-sm font-light underline text-orange-400">license expiration</span
            >&thinsp;:&nbsp; Sept 27, 2028
          </li> -->
          <!-- <li class="text-base font-medium">
            <span class="text-sm font-light underline text-orange-400">license status</span
            >&thinsp;:&nbsp; active
          </li> -->
          <li v-if="targetedUser.additionalInfoAndLicenses" class="text-base font-medium">
            <span class="text-sm font-light underline">additional licenses & other info</span
            >&thinsp;:&nbsp; {{ targetedUser.additionalInfoAndLicenses }}
          </li>
        </ul>
      </details>
    </div>
    <!-- BEGIN additional info v-if isRealEstateAgent == true (regardless of isVerified status) -->

    </div>
    <!-- END v-if isVerified = true -->
    <!-- BEGIN v-else (isVerified = false) -->
    <div
      v-if="targetedUser.isRealEstateAgent == 'unverified'"
      class="mt-12 mb-12 px-16 text-center text-xs font-light italic leading-5 md:text-base md:leading-normal"
    >
      User identifies as a real estate agent, but
      <span class="zagnetic">zagnetic</span> has not verified<button
        class="mb-2.5 ml-0.5"
      >
        <Whoops whoopsButtonVifTextAndIcon="11" />
      </button>
      any of the user's info.
    </div>
    <!-- END v-else (isVerified = false) -->

    <!-- BEGIN upvotes section - display for ALL profiles, regardless of isVerified and isRealEstateAgent -->
    <p class="mt-10 mb-8 text-lg font-semibold">Stats, Leaderboards, Awards & Badges&thinsp;:</p>
    <ul class="my-16 space-y-8 text-base font-medium">
      <li>
        <span class="rounded-lg bg-indigo-50 p-2"
          >Upvotes for user's questions: &thinsp;20</span
        >
      </li>

      <li>
        <span class="rounded-lg bg-amber-100 p-2"
          >Upvotes for user's answers: &thinsp;7</span
        >
      </li>
      <li>
        <span class="rounded-lg bg-lime-100 p-2"
          >Upvotes for user's reviews: &thinsp;18</span
        >
      </li>
      <li>
        <span class="rounded-lg bg-slate-100 p-2 font-semibold"
          >Total upvotes received by user: &thinsp;45</span
        >
        <!-- <span class="ml-4 text-sm font-light italic text-orange-600"
       >*&thinsp;top 5% for all real estate agents</span
      > -->
      </li>
    </ul>
    <!-- END upvotes section - display for ALL profiles, regardless of isVerified and isRealEstateAgent -->

    <ul class="my-12 space-y-6 text-base">
      <li v-if="isIncognitoProfile == false" class="text-base font-medium">
        <span class="text-sm font-light underline"> zagnetic member since</span
        >: &thinsp;{{
          targetedUser.createdAt
            .toDate()
            .toLocaleString("en-US", { month: "long" })
        }}
        {{ targetedUser.createdAt.toDate().getUTCFullYear() }}
      </li>
      <!-- <li class="text-base font-medium">
      <span class="text-sm font-light underline">last signed in</span>&thinsp;:&nbsp;
      Feb 2, 2022
    </li> -->
    </ul>

    <!-- <h2 class="pb-3.5 font-bold uppercase">
      {{ targetedUser.displayName }} on social media
    </h2>
    <p class="text-sm italic text-slate-500">all open in new windows</p>
    <ul
      class="inline-flex list-inside list-none space-x-2 pt-3.5 text-slate-500"
    >
      <li class="flex-1">
        <a
          class="text-muted text-hover-primary"
          href="#"
          target="_blank"
          title="twitter"
          ><fa :icon="['fab', 'twitter']" fixed-width
        /></a>
      </li>
      <li class="flex-1">
        <a
          class="text-muted text-hover-primary"
          href="https://www.instagram.com/zagnetic/"
          target="_blank"
          title="instagram"
          ><fa :icon="['fab', 'instagram']" fixed-width
        /></a>
      </li>
      <li class="flex-1">
        <a
          class="text-muted text-hover-primary"
          href="https://www.facebook.com/Zagnetic"
          target="_blank"
          title="facebook"
          ><fa :icon="['fab', 'facebook']" fixed-width
        /></a>
      </li>
      <li class="flex-1">
        <a
          class="text-muted text-hover-primary"
          href="https://www.pinterest.com/zagnetic/"
          target="_blank"
          title="pinterest"
          ><fa :icon="['fab', 'pinterest']" fixed-width
        /></a>
      </li>
    </ul> -->

    <br />
    <p class="mx-auto text-center">
    <button
      class="my-12 borderbtn !rounded-lg"
      @click="goHome"
    >
      Home
    </button>
    </p>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import { useRouter, useRoute } from "vue-router";
import Whoops from "@/components/Whoops";
// import getSetUserInfo from "@/composables/getSetUserInfo";
import useStorage from "@/composables/useStorage";
import getDocument from "../../composables/getDocument";

export default {
  components: {
    Whoops,
  },

  setup(props, context) {
    const router = useRouter();

    const route = useRoute();
    const profiledUser = ref(route.params.id);
    const { document:targetedUser, error } = getDocument('users', profiledUser.value);
    const { getImages, getImagesByFolderRef, fetchedImages } = useStorage();

    const isIncognitoProfile = false; // temporary, to allow dev of what content is shown v-if="isIncognitoProfile = true/false"

    const incognitoProfileImage =
      "https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/profile_pics%2Fincognito-profile-image-200x200.png?alt=media&token=16bdbce6-b235-4584-a400-1281d4928bd7";
    // const incognitoProfileImage = "https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/profile_pics%2Fincognito-profile-image-402x402-text.png?alt=media&token=632ffbef-33a9-46f2-b416-4187152f6a17";

    console.log('targetedUser ', targetedUser);
    const goHome = async () => {
      router.push({ name: "Home" });
    };

    watch(targetedUser, async(newVal, oldVal) => {
      console.log('watching you -', newVal.profilePhotoUrl);
      if(newVal.profilePhotoUrl) {
        getImages([newVal.profilePhotoUrl]);
      }
    })

    return {
      targetedUser,
      isIncognitoProfile,
      goHome,
      incognitoProfileImage,
      profiledUser,
      fetchedImages,
    };
  },
};
</script>

<style></style>
