<template>
  <div>
    <!-- Below, each FlagReportAbuseButtonVifTextAndIcon number(1, 2, 3, 4 etc.) corresponds to a different pop-up alert button and message (see "BEGIN v-if case 1, 2 or 3" a few dozen lines below) -->

    <!-- BEGIN case 1 (answer a Q&A question) -->
    <div v-if="FlagReportAbuseButtonVifTextAndIcon == 1">
      <div @click="openModal" class="">
        <span
          class="text-zaggray px-6 py-2.5 text-sm font-bold hover:text-white sm:mb-6"
          >Report Abuse</span
        >
      </div>
    </div>
    <!-- END case 1 (answer a Q&A question) -->

    <!-- BEGIN case 2 (ask a Q&A question) -->
    <div v-else-if="FlagReportAbuseButtonVifTextAndIcon == 2">
      <div @click="openModal">
        <span class="underline decoration-dotted">Ask a Question</span
        ><fa
          :icon="['fas', 'comments']"
          class="text-zagblue/70 animate-pulse-slow ml-2.5 text-lg hover:animate-none"
        />
      </div>
    </div>
    <!-- END case 2 (ask a Q&A question) -->

    <!-- BEGIN HeadlessUI Dialogue code -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-1 text-center sm:px-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="via ingigo-50 my-8 inline-block w-full max-w-[18rem] transform overflow-hidden rounded-2xl bg-gradient-to-br from-white to-slate-100 px-3 py-4 text-center align-middle shadow-xl ring-8 ring-slate-300 ring-opacity-80 ring-offset-8 ring-offset-slate-200 transition-all sm:max-w-sm sm:p-6 sm:px-6"
              >
                <!-- BEGIN case 1 (answer a Q&A question) -->
                <div v-if="FlagReportAbuseButtonVifTextAndIcon == 1">
                  <DialogTitle
                    class="text-zagblue text-left text-lg font-medium sm:text-lg"
                    ><span class="mr-0.5 text-xl font-bold underline"
                      >Flag / Report Abuse</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-4">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->

                  <!-- BEGIN data to include in form, displayed here temporarily -->
                  <p class="my-4 text-sm font-semibold text-orange-500">
                    Want to attach these dynamic fields to form submission:
                  </p>
                  <p class="my-4 text-sm text-orange-500">
                    $route.fullPath - {{ $route.fullPath }}
                    <span class="bg-lime-300 text-indigo-700"
                      ><br />flaggersUid = {{ flaggersUid }} <br />flaggersEmail
                      = {{ flaggersEmail }} <br />flagPath =
                      {{ flagPath }}</span
                    >
                  </p>
                  <p class="my-4 text-sm text-orange-500">
                    user.uid - {{ user.uid }}
                  </p>
                  <p class="my-4 text-sm text-orange-500">
                    user.email - {{ user.email }}
                  </p>
                  <!-- END data to include in form, displayed here temporarily -->

                  <div class="my-4 text-left">
                    <form
                      class="bg-indigo-50/5"
                      @submit.prevent="handleFlagSubmit"
                    >
                      <p
                        class="text-zagblue mb-2 px-1 pt-6 text-base font-semibold"
                      >
                        Please describe your concern:
                      </p>
                      <textarea
                        required
                        class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
                        id="userFlagText"
                        name="userFlagText"
                        v-model="userFlagText"
                        placeholder="Be clear and succint"
                        rows="5"
                      ></textarea>

                      <hr class="my-8" />

                      <p
                        class="text-zagblue mb-0.5 px-1 text-base font-semibold"
                      >
                        Would you like us to respond to you?
                      </p>
                      <!-- <p class="text-zagblue mb-2 p-1 text-sm italic">
                        {{ user.email }}
                      </p> -->
                      <div class="space-y-0">
                        <div>
                          <input
                            type="radio"
                            id="0"
                            value="{{ user.email }}"
                            name="howToRespond"
                            required
                            v-model="respondViaEmail"
                          />
                          <label
                            class="ml-4 -mr-5 inline-flex text-base"
                            for="{{ user.email }}"
                            >Yes, email me</label
                          >
                          <span
                            class="ml-6 -mt-1 rounded-lg bg-indigo-100/30 px-2 py-1 text-sm italic"
                            >{{ user.email }}</span
                          >
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="doNotRespond"
                            value="No"
                            name="doNotRespond"
                            required
                            v-model="doNotRespond"
                          />
                          <label class="ml-4 inline-flex text-base" for="No"
                            >No thanks</label
                          >
                        </div>
                      </div>

                      <hr class="my-8" />

                      <!-- BEGIN "Any relationship?" as a selection -->

                      <p
                        class="qatext text-zagblue !md:ml-6 mb-4 ml-0 text-left !font-semibold !leading-normal"
                      >
                        Do you have any relationship with the owner or their
                        real estate agent?
                      </p>
                      <div class="space-y-0">
                        <select
                          required
                          name="flaggerAnyRelWithSellerOrAgent"
                          id="flaggerAnyRelWithSellerOrAgent"
                          v-model="flaggerAnyRelWithSellerOrAgent"
                          class="text-zaggray rounded border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400"
                        >
                          <option value="">Please select</option>
                          <option value="none" class="bg-slate-50">None</option>
                          <option value="acquainted" class="">
                            Acquainted
                          </option>
                          <option value="solid" class="bg-slate-50">
                            Solid relationship
                          </option>
                          <option value="isSeller" class="">
                            I am the owner/seller
                          </option>
                          <option value="isSellersAgent" class="bg-slate-50">
                            I am the owner/seller's agent
                          </option>
                        </select>
                      </div>
                      <!-- END "Any relationship?" as a selection -->

                      <button
                        v-if="!isPending"
                        class="text-zaggray mb-4 mt-12 ml-[30%] rounded-md border border-indigo-900 bg-white py-1.5 px-6 text-lg hover:bg-indigo-900 hover:text-white hover:ring-pink-500"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 1 (answer Q&A question) -->

                <div class="float-right mt-1">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-sm font-normal text-gray-600 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    cancel
                    <fa
                      :icon="['far', 'times-circle']"
                      class="ml-1.5 text-xl"
                    />
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- END HeadlessUI Dialogue code -->
  </div>
</template>

<script>
import { ref } from "vue";
import useCollection from "@/composables/useCollection"; // for Ask a Question
import getUser from "@/composables/getUser"; // for Ask a Question
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  props: [
    "FlagReportAbuseButtonVifText",
    "FlagReportAbuseButtonVifTextAndIcon",
  ],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },

  setup() {
    const isOpen = ref(false);
    const userQuestion = ref(""); // for Ask a Question
    const router = useRouter();
    const { user } = getUser();
    const { error, addDoc } = useCollection("flags");

    const userFlagText = ref(""); // for Answer a Question
    const flaggerAnyRelWithSellerOrAgent = ref(""); // for Answer a Question
    const howToRespond = ref(""); // for Answer a Question

    const flaggersUid = user.uid;
    const flaggersEmail = user.email;
    
    const flagPath = ref(router.currentRoute.value.fullPath);
    

    // BEGIN handleFlagSubmit
    const handleFlagSubmit = async () => {
      if (file.value) {
        isPending.value = true;

        const res = await addDoc({
          userFlagText: userFlagText.value,
          flaggerAnyRelWithSellerOrAgent: flaggerAnyRelWithSellerOrAgent.value,
          howToRespond: howToRespond,
          flaggersUid: flaggersUid.value,
          flaggersEmail: flaggersEmail.value,
          flagPath: flagPath.value,
        });
        isPending.value = false;
        if (!error.value) {
          closeModal();
          // isOpen.value = false
        }
      } else {
        console.log("file.value does not have a value");
      }
    };
    // END handleFlagSubmit

    return {
      userFlagText,
      flaggerAnyRelWithSellerOrAgent,
      howToRespond,

      flaggersUid,
      flaggersEmail,
      flagPath,
      // user,

      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

<!-- Wherever this component is being used:

Wherever being used in the template:   <FlagReportAbuse />

import getUser from '@/composables/getUser'
import FlagReportAbuse from '@/components/FlagReportAbuse.vue'

export default {
  components: {
    FlagReportAbuse
  },

  setup() {
    const { user } = getUser()

    return {
      user,
    }
  },

}

-->
