<template>
  <div><!-- <div class="relative col-span-6 sm:col-span-3 lg:col-span-2"> -->
    <div class="relative row-span-1 mx-auto mb-2 hover:cursor-pointer">
      <!-- BEGIN "7 found this helpful" at top of card -->

      <div
        class="relative rounded-lg border-2 border-gray-200 border-opacity-50 bg-white px-4 pb-4 drop-shadow-lg"
      >
        <div
          v-if="reviewData.upvoteCount"
          class="text-zaggray absolute inset-x-0 top-0 mb-6 w-full rounded-t-lg bg-indigo-50 py-1.5 text-center text-sm ring-1 ring-indigo-100"
        >
          <span class="text-sm font-semibold">{{
            reviewData.upvoteCount
          }}</span>
          found this helpful
        </div>
        <div
          v-else
          class="text-zaggray absolute inset-x-0 top-0 mb-6 w-full rounded-t-lg bg-indigo-50 py-1.5 text-center text-sm ring-1 ring-indigo-100"
        >
          <span class="py-1.5 text-center text-sm italic">click to see full review</span>
          <!-- <span class="py-1.5 text-center text-sm">No votes yet on whether this is 'helpful'</span> -->
          <span class="py-1.5 text-center text-sm">&nbsp;</span>
        </div>
        <div class="mt-10 mb-2 text-xs font-medium text-indigo-900">
          Posted
          <span class="text-xs font-medium">{{
            formatDistanceToNow(new Date(reviewData.createdAt.seconds * 1000))
          }}</span>
          ago
        </div>
        <div
          class="text-zaggray mt-1 mb-2 text-xs"
          v-if="reviewData.displayName"
        >
          by
          <span
            class="front-medium text-xs underline hover:text-sm hover:font-semibold"
            >reviewData.displayName</span
          ><fa
            :icon="['fas', 'user-secret']"
            size="sm"
            class="ml-1 mb-0.5 hidden opacity-60"
          />
        </div>

        <!-- <div class="text-sm text-zaggray rounded-sm bg-gray-50 ring-1 w-full ring-indigo-100 text-center py-0.5 my-3"><span class="font-semibold text-sm">7</span> found this helpful</div> -->

        <!-- END "7 found this helpful" at top of card -->

        <!-- BEGIN regular "7 found this helpful" at top of card -->

        <!-- <div class="py-4 px-4 bg-white drop-shadow-lg rounded-lg border-gray-200 border-2 border-opacity-50 relative">
                            <div class="text-xs font-medium text-indigo-900">Posted <span class="font-medium text-xs">1 month ago</span></div>
                            <div class="text-zaggray text-xs mt-1">by <span class="underline front-medium hover:font-semibold text-xs hover:text-sm ">SomeOne23</span><fa :icon="['fas', 'zzzuser-secret']" size="sm" class="ml-1 mb-0.5 opacity-60"/></div>
                            <div class="text-sm text-zaggray rounded-sm bg-gray-50 ring-1 w-full ring-indigo-100 text-center py-0.5 my-3"><span class="font-semibold text-sm">7</span> found this helpful</div> -->

        <!-- END regular "7 found this helpful" at top of card -->

        <!-- <div class="text-zaggray font-semibold text-sm my-2">7 found this helpful</div> -->

        <!-- <div class="text-xs font-medium text-pink-500">Posted <span class="font-medium text-xs">1 month ago</span></div> -->
        <!-- <div class="text-sm text-zaggray italic">Posted 1 month ago</div> -->

        <!-- <div class="text-sm text-zaggray rounded-lg bg-indigo-100 ring-1 w-full ring-indigo-200 text-center mt-6"><span class="font-bold text-sm">7</span> found this helpful</div> -->

        <!-- <div class="text-sm underline -pb-1">1st Pro:</div> -->
        <div v-if="firstPropPro" class="line-clamp-1 hover:line-clamp-none text-sm hover:bg-progreen/5 hover:rounded-xl hover:leading-relaxed hover:p-0.5 lowercase">
          <fa
            icon="plus-circle"
            class="text-progreen opacity-80"
            size="sm"
            alt="plus sign icon in a circle"
          />&nbsp;{{ firstPropPro.join("&thinsp;, &nbsp;&thinsp;") }}
          <span class="text-xs font-medium italic text-pink-500"></span>
        </div>

        <!-- <div class="text-sm underline -pb-1">1st Con:</div> -->
        <div v-if="firstPropCon" class="line-clamp-1 hover:line-clamp-none my-2 text-sm hover:bg-conred/5 hover:rounded-xl hover:leading-relaxed hover:p-0.5 lowercase">
          <fa
            icon="plus-circle"
            class="text-conred opacity-80"
            size="sm"
            alt="plus sign icon in a circle"
          />&nbsp;{{ firstPropCon.join("&thinsp;, &nbsp;&thinsp;") }}
          <span class="text-xs font-medium italic text-pink-500"></span>
        </div> 
        

        <!-- <div class="text-sm text-zaggray italic">Views: <span class="font-bold text-sm">28</span></div> -->
        <!-- <div class="text-zaggray font-light text-xs leading-3">Review title (user or AI supplied?)? pros/cons</div> -->
        <!-- <div class="text-sm text-zaggray italic">Reviewer is a <span class="font-semibold text-sm">Realtor</span>&#8482;</div> -->

        <div v-if="fetchedImages.length > 0" class="inline-flex gap-2 mt-1">
          <img
            v-for="(singleImg, key) in fetchedImages"
            :src="singleImg"
            :key="`reviewImg-${key}`"
            alt=""
            class="w-1/2 rounded-sm"
          />
        </div>
        <div v-if="fetchedImages.length == 0" class="inline-flex mt-1">
          <img
                  src="@/assets/images/review-has-no-photos-w-camera.png"
                  alt="rewview has no photos"
                  class="w-3/5 mx-auto"
                />
          
        </div>
        <!-- <p class="text-xs font-light italic -mt-0.5">and <span class="text-xs font-light italic  hover:underline">4 more photos</span>...</p> -->
        <!-- <p class="text-xs font-light italic mt-0.5">Full review has <span class="text-xs font-light italic  hover:underline">7 more pros/cons</span>, <span class="text-xs font-light italic  hover:underline">4 more photos</span> +</p> -->
        <!-- <div class="text-sm text-zaggray rounded-lg bg-indigo-100 ring-1 w-full ring-indigo-200 text-center"><span class="font-bold text-sm">7</span> found this helpful</div> -->

         <div v-if="reviewData.whatToRepairUpgradeAdd" class="line-clamp-3 hover:line-clamp-none my-2 text-sm italic hover:bg-slate-50 hover:rounded-xl hover:leading-relaxed hover:p-0.5">
         <span class="text-sm font-medium underline text-slate-700">How they'd spend $12,000</span>: &thinsp;
         "{{ reviewData.whatToRepairUpgradeAdd }}"
        </div> 
        <div v-else-if="reviewData.reviewerTextReview" class="line-clamp-3 hover:line-clamp-none my-2 text-sm italic hover:bg-slate-50 hover:rounded-xl hover:leading-relaxed hover:p-0.5">
         <span class="text-sm font-medium underline text-slate-700">Review text</span>: &thinsp;
         "{{ reviewData.reviewerTextReview }}"
        </div> 
        <!-- BEGIN hollow rating star test -->
        <div class="my-1 inline-block sm:flex">
          <div class="flex">
            <div
              v-if="superWeightedRating > 0.25 && superWeightedRating < 0.75"
            >
              <fa
                icon="star-half-alt"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>
            <div v-else-if="superWeightedRating >= 0.75">
              <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
            </div>
            <div v-else>
              <fa
                :icon="['far', 'star']"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>

            <div
              v-if="superWeightedRating > 1.25 && superWeightedRating < 1.75"
            >
              <fa
                icon="star-half-alt"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>
            <div v-else-if="superWeightedRating >= 1.75">
              <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
            </div>
            <div v-else>
              <fa
                :icon="['far', 'star']"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>

            <div
              v-if="superWeightedRating > 2.25 && superWeightedRating < 2.75"
            >
              <fa
                icon="star-half-alt"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>
            <div v-else-if="superWeightedRating >= 2.75">
              <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
            </div>
            <div v-else>
              <fa
                :icon="['far', 'star']"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>

            <div
              v-if="superWeightedRating > 3.25 && superWeightedRating < 3.75"
            >
              <fa
                icon="star-half-alt"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>
            <div v-else-if="superWeightedRating >= 3.75">
              <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
            </div>
            <div v-else>
              <fa
                :icon="['far', 'star']"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>

            <div
              v-if="superWeightedRating > 4.25 && superWeightedRating < 4.75"
            >
              <fa
                icon="star-half-alt"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>
            <div v-else-if="superWeightedRating >= 4.75">
              <fa icon="star" id="rating_star" size="md" class="mr-0.5" />
            </div>
            <div v-else>
              <fa
                :icon="['far', 'star']"
                id="rating_star"
                size="md"
                class="mr-0.5"
              />
            </div>
          </div>
          
          <div class="">
            <span v-if="superWeightedRating != NaN" class="text-sm font-semibold ml-1">({{ superWeightedRating.toFixed(1)}}) </span>
            <span v-else class="text-sm font-medium ml-1">(no star ratings) </span>
          </div>
        </div>
        <p class="ml-1.5 pt-0 text-xs">
          Avg. of <span class="text-xs underline">all</span
          >&thinsp; of this reviewer's
          ratings for this property
        </p>
        
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import useStorage from "@/composables/useStorage";
import { formatDistanceToNow, format } from "date-fns";

export default {
  props: {
    propertyId: String,
    reviewData: Object,
  },

  setup(props, context) {
    const { getImages, fetchedImages } = useStorage();
    
    const reviewData = ref(props.reviewData);
    
    const kitchen_rating = reviewData.value.kitchen_rating || 0;
    const smell_rating = reviewData.value.smell_rating || 0;
    const bath_rating = reviewData.value.bath_rating || 0;
    const location_rating = reviewData.value.location_rating || 0;
    const setting_rating = reviewData.value.setting_rating || 0;

    const firstPropPro = reviewData.value.propPros;
    const firstPropCon = reviewData.value.propCons;
    
    const superWeightedRating = computed(() => {
      
      let denominatorCounter = 0;
      if (kitchen_rating > 0) {
        denominatorCounter++;
      }
      if (bath_rating > 0) {
        denominatorCounter++;
      }
      if (smell_rating > 0) {
        denominatorCounter++;
      }
      if (location_rating > 0) {
        denominatorCounter++;
      }
      if (setting_rating > 0) {
        denominatorCounter++;
      }
      if(denominatorCounter === 0) {
        return 0;
      }
      return (
        (+kitchen_rating +
          +bath_rating +
          +smell_rating +
          +location_rating +
          +setting_rating) / denominatorCounter 
      );
    });

    onMounted(() => {
      const filePaths = [reviewData.value.filePath[0]];
      const totalImageCount = reviewData.value.filePath.length;
      const randomNumber = Math.floor(Math.random() * (totalImageCount - 1)) + 1;
      filePaths.push(reviewData.value.filePath[randomNumber]);
      // const filePaths = [reviewData.value.filePath[randomNumber]];
      getImages(filePaths);
    });

    return {
      reviewData,
      firstPropPro,
      firstPropCon,
      superWeightedRating,
      
      formatDistanceToNow,
      fetchedImages,
      // denominatorCounter,
    };
  },

  data() {
    return {};
  },
};
</script>

<style></style>
