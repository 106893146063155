<template>
  <div>
    <!-- BEGIN headless ui dropdown menu -->

    <div class="relative inline-block text-left">
      <Menu>
        <span class="z-30 isolate rounded-md shadow-sm">
          <!-- BEGIN v-if shareButtonDDStyle === zzz to allow various custom Tailwind button styles -->
          <div v-if="shareButtonDDStyle == 1">
            <MenuButton class="proplandingbtn z-30 isolate"
              ><fa :icon="['fas', 'paper-plane']" /> &nbsp;Share</MenuButton
            >
          </div>
          <div v-if="shareButtonDDStyle == 1.1">
            <MenuButton class="z-30"
              ><span class="px-2 sm:px-0.5 md:px-2 text-sm py-4"><fa
                :icon="['fas', 'paper-plane']"
              />
              &nbsp;Share</span></MenuButton
            >
            <!-- above was class="proplandingbtn z-30 !md:px-10 lg:px-12 !py-1" but changed when wrapped on page, like this: <button class="proplandingbtn py-2"> <ShareButtonDD shareButtonDDStyle="1.1" :urlToShare="$routefullPath"/></button> -->
            
             <!-- <MenuButton class="proplandingbtn z-30 isolate"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<fa
                :icon="['fas', 'paper-plane']"
              />
              &nbsp;Share&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuButton
            > -->
          </div>

          <!-- BEGIN "Share Print Embed" -->
          <!-- <div v-if="shareButtonDDStyle == 1.2">
            <MenuButton class="z-30"
              ><span class="px-2 sm:px-0.5 md:px-2 text-sm"><fa
                :icon="['fas', 'paper-plane']"
              />
              &nbsp;Share&thinsp;&middot;<span class="py-4">&thinsp;</span>Print&thinsp;&middot;&thinsp;Embed</span></MenuButton
            >
          </div> -->
          <!-- END "Share Print Embed" -->

          <!-- below map on PropertyLanding -->
          <div v-else-if="shareButtonDDStyle == 2">
            <MenuButton class="z-30 isolate text-xs text-slate-500 sm:text-base"
              ><fa :icon="['fas', 'paper-plane']" class="text-sm" /><span
                class="text-xs"
              >
                &nbsp;Share</span
              ></MenuButton
            >
          </div>
          <div v-else-if="shareButtonDDStyle == 2.2">
            <MenuButton class="z-30 isolate text-xs text-slate-500 sm:text-base"
              ><fa
                :icon="['fas', 'paper-plane']"
                class="mb-1.5 text-sm sm:mb-0 sm:text-base"
              /><span class="hidden text-xs md:inline">
                &nbsp;Share</span
              ></MenuButton
            >
          </div>
          <!-- 2.2 is the share button below a Q&A question -->
          <div v-else-if="shareButtonDDStyle == 3">
            <MenuButton class="z-30 isolate text-sm text-slate-500 md:text-base"
              ><fa :icon="['fas', 'paper-plane']" class="text-md"
            /></MenuButton>
          </div>
          <!-- ? -->
          <div v-else-if="shareButtonDDStyle == 4">
            <MenuButton
              class="align-center m-2.5 flex items-center rounded-lg border-2 border-dotted border-indigo-900 bg-white px-2.5 py-2.5 text-base leading-none text-indigo-900 hover:border-indigo-600 hover:bg-indigo-50"
              ><fa icon="paper-plane" /> &nbsp;share
              <span class="zagnetic px-1 pb-0.5">zagnetic</span></MenuButton
            >
          </div>
          <!-- Right column on ReviewDetails.vue -->
          <div v-else-if="shareButtonDDStyle == 5">
            <MenuButton
              class="z-30 isolate rounded-lg text-indigo-900 hover:bg-indigo-900 hover:px-1 hover:text-white"
            >
              <fa icon="paper-plane" />&nbsp;&nbsp;Share this review</MenuButton
            >
          </div>

          <!-- bottom of About.vue -->
          <div v-else>
            <MenuButton class="borderlessbtn z-30 isolate w-full"
              ><fa :icon="['far', 'paper-plane']" /> &nbsp;Share</MenuButton
            >
          </div>
          <!-- END v-if shareButtonDDStyle === zzz to allow various custom Tailwind button styles -->
        </span>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-out"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="border-zagblue absolute left-0 z-30 isolate mt-2 w-44 origin-top-right divide-y divide-pink-300 rounded-md border bg-slate-50 shadow-lg outline-none focus:z-auto"
          >
            <div class="relative z-30 isolate px-4 pt-4 pb-2">
              <!-- <MenuItem>
                <a
                  href="#"
                  :class="
                    active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                  "
                  class="z-30 isolate mr-2 flex w-full px-4 py-3.5 text-left text-base leading-5 hover:bg-indigo-50 hover:rounded-xl !text-slate-500/50"
                >
                  <fa :icon="['fab', 'facebook-square']" fixed-width /><span
                    class="z-30 isolate ml-2 mt-0 text-sm hover:text-slate-500 md:-mt-1.5 md:text-base line-through !text-slate-400/50"
                    >Facebook</span
                  ></a
                >
              </MenuItem> -->

              <!-- <MenuItem>
                <a
                  href="#"
                  :class="
                    active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                  "
                  class="z-30 isolate flex w-full px-4 py-3.5 text-left text-base leading-5 hover:bg-indigo-50 hover:rounded-xl !text-slate-500/50"
                >
                  <fa :icon="['fab', 'twitter']" fixed-width /><span
                    class="z-30 isolate ml-2 mt-0 text-sm hover:text-slate-500 md:-mt-1.5 md:text-base line-through !text-slate-400/50"
                    >Twitter</span
                  ></a
                >
              </MenuItem> -->

              <!-- <MenuItem>
                <a
                  href="#"
                  :class="
                    active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                  "
                  class="justify-left z-30 isolate flex w-full px-4 py-3.5 text-left text-base leading-5 hover:bg-indigo-50 hover:rounded-xl !text-slate-500/50"
                >
                  <fa :icon="['fab', 'instagram']" fixed-width /><span
                    class="z-30 isolate ml-2 mt-0 text-sm hover:text-slate-500 md:-mt-1.5 md:text-base line-through !text-slate-500/50"
                    >Instagram</span
                  ></a
                >
              </MenuItem> -->

              <!-- <MenuItem>
                <a
                  href="#"
                  :class="
                    active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                  "
                  class="z-30 isolate flex w-full px-4 py-3.5 text-left text-base leading-5 hover:bg-indigo-50 hover:rounded-xl !text-slate-500/50"
                >
                  <fa
                    :icon="['fas', 'code']"
                    id="my_cloud_icon"
                    fixed-width
                  /><span
                    class="z-30 isolate ml-2 mt-0 text-sm hover:text-slate-500 md:-mt-1.5 md:text-base line-through !text-slate-400/50"
                    >embed</span
                  ></a
                >
              </MenuItem> -->

              <MenuItem
                @click="toClipboard('https://zagnetic.com' + urlToShare)"
              >
                <div>
                  <div
                    :class="
                      active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                    "
                    class="z-30 isolate flex w-full px-4 py-3.5 text-left text-base leading-5 hover:rounded-xl hover:bg-indigo-50"
                  >
                    <span></span>
                    <fa :icon="['fas', 'link']" fixed-width /><span
                      class="z-30 isolate ml-2 mt-0 text-sm hover:text-slate-500 md:-mt-1.5 md:text-base"
                      >copy link</span
                    ><br />

                    <!-- <span class="text-xs text-indigo-600">{{ urlToShare }}</span> -->
                  </div>
                  <p
                    class="z-30 isolate mt-0 text-center text-xs italic text-slate-500 md:-mt-1"
                  >
                    (&thinsp;click&thinsp;)&nbsp;&nbsp;&nbsp;
                  </p>
                </div>
              </MenuItem>

               <!-- <MenuItem
                @click="printPage()"
              >
                <div>
                  <div
                    :class="
                      active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                    "
                    class="z-30 isolate flex w-full px-4 py-3.5 text-left text-base leading-5 hover:rounded-xl hover:bg-indigo-50"
                  >
                    <span></span>
                    <fa :icon="['fas', 'print']" fixed-width /><span
                      class="z-30 isolate ml-2 mt-0 text-sm hover:text-slate-500 md:-mt-1.5 md:text-base"
                      >print</span
                    ><br />
                  </div>
                </div>
              </MenuItem> -->

            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <!-- END headless ui dropdown menu -->
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";

export default {
  props: {
    shareButtonDDStyle: String,
    urlToShare: String,
  },

  setup(props, context) {
    const urlToShare = props.urlToShare;

    const printPage = () => {
      window.print();
    }

    return { urlToShare, toClipboard, printPage };
  },

  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
};
</script>

<style></style>

<!-- 

To use this component:

1. import ShareButtonDD from '@/components/ShareButtonDD.vue'

2. export default {
  components: {
    ShareButtonDD,
  },

  }

3. When inserting the share button, add the shareButtonDDStyle number (1, 2, 3, etc.) - each one corresponds with a different style via the v-if around line 10 above. 
Example: <ShareButtonDD shareButtonDDStyle='3' />

-->
