<template>
  <div
    class="mx-auto max-w-screen-sm rounded-lg bg-indigo-50 px-0 pt-4 pb-4 shadow-md ring-8 ring-indigo-300 ring-opacity-80 ring-offset-8 ring-offset-indigo-200 sm:px-0 md:px-4"
  >
    <form class="bg-indigo-50" @submit.prevent="handleSubmit">
      <div class="ml-[14%] sm:ml-[33%]">
        <h3 class="my-5 inline-flex text-center font-medium sm:my-8">
          Join
          <span class="zagnetic"
            ><img src="@/assets/zagnetic-logo.svg" class="max-h-14 px-1"
          /></span>
        </h3>
      </div>
      <p class="text-xs font-light after:content-['*'] after:ml-0.5 after:text-red-500 italic text-center">all fields required</p>
      <!-- <div class="relative mx-auto my-6 text-center sm:my-8">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-2/3"
          type="text"
          placeholder="Display name"
          id="displayName"
          v-model.trim="displayName"
        />
        <label
          for="displayName"
          class="peer-placeholder-shown:left-26 absolute -top-12 pl-3 text-sm text-zaggray transition-all duration-500 ease-in-out peer-placeholder-shown:-top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:-top-12 peer-focus:text-sm peer-focus:text-indigo-900 sm:left-28 sm:pl-0"
          >Display name</label
        >
      </div> -->
      <div class="relative mx-auto my-6 text-center sm:my-8">
        <input
          class="peer peer w-full appearance-none rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:border-slate-600 focus:placeholder-transparent focus:outline-none focus:ring-0 sm:w-2/3"
          type="text"
          placeholder="Your real first name"
          id="displayName"
          v-model.trim="displayName"
        />
        <label
          for="displayName"
          class="text-zaggray absolute top-2 left-1 z-10 origin-[0] -translate-y-8 scale-75 transform px-2 text-sm transition-all duration-500 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:top-2 peer-focus:-translate-y-7 peer-focus:scale-90 peer-focus:rounded-md peer-focus:bg-white/50 peer-focus:px-2 peer-focus:text-sm peer-focus:text-indigo-900 sm:left-24"
          >Your real first name</label
        >
      </div>
      <div class="relative mx-auto my-6 text-center sm:my-8">
        <input
          class="peer peer w-full appearance-none rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:border-slate-600 focus:placeholder-transparent focus:outline-none focus:ring-0 sm:w-2/3"
          type="email"
          placeholder="Email"
          id="email"
          v-model.trim="email"
        />
        <label
          for="email"
          class="text-zaggray absolute top-2 left-1 z-10 origin-[0] -translate-y-8 scale-75 transform px-2 text-sm transition-all duration-500 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:top-2 peer-focus:-translate-y-7 peer-focus:scale-90 peer-focus:rounded-md peer-focus:bg-white/50 peer-focus:px-2 peer-focus:text-sm peer-focus:text-indigo-900 sm:left-24"
          >Email</label
        >
      </div>
      <div class="relative mx-auto my-6 text-center sm:my-8">
        <input
          class="peer peer w-full appearance-none rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:border-slate-600 focus:placeholder-transparent focus:outline-none focus:ring-0 sm:w-2/3"
          type="password"
          placeholder="Password"
          id="password"
          v-model.trim="password"
        />
        <label
          for="password"
          class="text-zaggray absolute top-2 left-1 z-10 origin-[0] -translate-y-8 scale-75 transform px-2 text-sm transition-all duration-500 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:top-2 peer-focus:-translate-y-7 peer-focus:scale-90 peer-focus:rounded-md peer-focus:bg-white/50 peer-focus:px-2 peer-focus:text-sm peer-focus:text-indigo-900 sm:left-24"
          >Password <span class="text-sm">(8+ characters)</span></label
        >
      </div>

      <!-- BEGIN isRealEstateAgent -->
      <!-- <p class="text-center font-semibold">Are you a real estate agent?</p> -->
      <div class="mt-2 sm:-mt-2 flex justify-center pb-4">
        <label class="mx-auto pb-6 inline-flex items-center text-sm sm:text-base"
          ><span class="mt-7 text-sm sm:text-base"
            >Are you a real estate agent?</span
          >
          <!--BEGIN when isRealEstateAgent becomes a string -->

          <div class="ml-5">
            <input
              type="radio"
              id="unverified"
              value="unverified"
              name="isRealEstateAgent"
              required
              v-model="isRealEstateAgent"
            />
            <label class="ml-2.5 pt-4 mt-3 inline-flex text-sm sm:text-base" for="unverified"
              >Yes</label
            >
          </div>

          <div class="ml-5">
            <input
              type="radio"
              id="no"
              value="no"
              name="isRealEstateAgent"
              required
              v-model="isRealEstateAgent"
            />
            <label class="ml-2.5 mt-7 inline-flex text-sm sm:text-base" for="no"
              >No</label
            >
          </div>
          
          <!-- END when isRealEstate becomes a string -->
          <!-- <div class="ml-5">
            <input
              type="radio"
              id="true"
              value="true"
              name="isRealEstateAgent"
              required
              v-model="isRealEstateAgent"
            />
            <label class="ml-2.5 pt-4 mt-3 inline-flex text-sm sm:text-base" for="true"
              >Yes</label
            >
          </div>

          <div class="ml-5">
            <input
              type="radio"
              id="false"
              value="false"
              name="isRealEstateAgent"
              required
              v-model="isRealEstateAgent"
            />
            <label class="ml-2.5 mt-7 inline-flex text-sm sm:text-base" for="false"
              >No</label
            >
          </div> -->
        </label>
      </div>
      <!-- END isRealEstateAgent -->

      <div class="flex justify-center mt-6 pt-0 pb-4">
        <label class="mx-auto inline-flex items-center">
          <input type="checkbox" class="rounded-sm" required />
          <div class="ml-2 text-sm sm:text-base">
            I agree to the
            <span class="text-sm underline sm:text-base"
              ><router-link class="text-sm sm:text-base" :to="{ name: 'Terms' }"
                >terms</router-link
              ></span
            >
            and
            <span class="text-sm underline sm:text-base"
              ><router-link
                class="text-sm sm:text-base"
                :to="{ name: 'Privacy' }"
                >privacy policy</router-link
              ></span
            >
          </div>
        </label>
      </div>

      <div v-if="error" class="error">{{ error }}</div>

      <div class="flex justify-center pt-2 pb-4">
        <button v-if="!isPending" class="borderlessbtn w-50% mx-auto">
          Sign up
        </button>
        <button v-if="isPending" disabled class="borderlessbtn w-50% mx-auto">
          <!-- Loading -->
          <WaitingToLoad />
        </button>
      </div>
    </form>

    <div
      class="my-4 mx-auto pb-8 text-center text-sm text-gray-500 sm:my-8 sm:text-base"
    >
      <span class="mr-3 text-sm font-medium sm:text-base"
        >Already have an account?</span
      ><span class="text-sm underline sm:text-base"
        ><router-link class="text-sm sm:text-base" :to="{ name: 'Login' }"
          >Log in</router-link
        ></span
      >
    </div>

    <!-- <img src="@/assets/images/rounded-square-logo.svg" alt="Zagnetic rounded square logo" class="mx-auto w-1/3 md:w-1/4 py-6"> -->
  </div>
</template>

<script>
// using @ means start at the project src root
import useSignup from "@/composables/useSignup";
import { ref, onMounted } from "vue";
import { RecaptchaVerifier } from "firebase/auth";
import { useRouter } from "vue-router";
import useCollection from "@/composables/useCollection"; // added 2/11/2022 by Mark to create user document in "users" top-level collection
import useSubCollection from "../../composables/useSubCollection";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser";
import WaitingToLoad from "@/components/WaitingToLoad.vue";
import { load } from 'recaptcha-v3';

export default {
  components: {
    WaitingToLoad,
     },

  setup() {
    const { error, signup, isPending, signupUser } = useSignup();
    const {subCollectionError, addSubDocWithName } = useSubCollection('public_data', 'users');
    const router = useRouter();
    const { user } = getUser();

    if(user.value !== null && typeof user.value.uid !== 'undefined') {
      router.push({ name: "Home" });
    }

    const email = ref("");
    const password = ref("");
    const displayName = ref("");

    // BEGIN fields related to Mark's attempt to to create user document in "users" top-level collection via useCollection.js
    const userId = ref("");
    const phone = ref("");
    const twofactorAuth = ref("");
    const realFirstName = ref("");
    const realMiddleNameOrInit = ref("");
    const realLastName = ref("");
    const userStatus = ref("signedUp");
    const profilePhotoUrl = ref("");
    const isRealEstateAgent = ref("");
    const isVerified = ref("");

    const reviewsWrittenFullPublic = ref([]);
    const questionsWrittenFullPublic = ref([]);
    const answersWrittenFullPublic = ref([]);
    const reviewsWrittenAnonPublic = ref([]);
    const questionsWrittenAnonPublic = ref([]);
    const answersWrittenAnonPublic = ref([]);
    const reviewsWrittenKeepPrivate = ref([]);
    const questionsWrittenKeepPrivate = ref([]);
    const answersWrittenKeepPrivate = ref([]);
    const reviewVotes = ref([]);
    const questionsVotes = ref([]);
    const answerVotes = ref([]);
    const createdAt = ref(null);

    const { err, addDoc, addDocWithCustomId } = useCollection("users"); // was const { error, addDoc } = useCollection('users')

    // END fields related to Mark's attempt to to create user document in "users" top-level collection via useCollection.js

    const handleSubmit = async () => {
      await signup(
        email.value,
        password.value,
        displayName.value
      ); // ### before Firebase 8 to 9 refactoring, was:const res = await signup(email.value, password.value, displayName.value)
      if (!error.value) {
        console.log('signup template-', signupUser.value);
        
       console.log("Signup was successful");
        localStorage.setItem('display_name', displayName.value);
        const createdUser = signupUser.value;
        email.value = createdUser.email;
        // displayName.value = createdUser.displayName;
        userId.value = createdUser.uid;

         addSubDocWithName({
            profEmail: email.value,
            realFirstName: displayName.value,
            parentDocId: createdUser.uid,
          });
        // createdAt.value = createdUser.metadata.createdAt;
        // console.log(createdAt.value);
        // BEGIN Mark's attempt to to create user document in "users" top-level collection via useCollection.js
        const res = await addDocWithCustomId({
          phone: phone.value,
          twofactorAuth: twofactorAuth.value,
          realFirstName: displayName.value, // was realFirstName: realFirstName.value,
          realMiddleNameOrInit: realMiddleNameOrInit.value,
          realLastName: realLastName.value,
          userStatus: userStatus.value,
          profilePhotoUrl: profilePhotoUrl.value,
          isRealEstateAgent: isRealEstateAgent.value,
          isVerified: isVerified.value,

          reviewsWrittenFullPublic: reviewsWrittenFullPublic.value,
          questionsWrittenFullPublic: questionsWrittenFullPublic.value,
          answersWrittenFullPublic: answersWrittenFullPublic.value,
          reviewsWrittenAnonPublic: reviewsWrittenAnonPublic.value,
          questionsWrittenAnonPublic: questionsWrittenAnonPublic.value,
          answersWrittenAnonPublic: answersWrittenAnonPublic.value,
          reviewsWrittenKeepPrivate: reviewsWrittenKeepPrivate.value,
          questionsWrittenKeepPrivate: questionsWrittenKeepPrivate.value,
          answersWrittenKeepPrivate: answersWrittenKeepPrivate.value,
          reviewVotes: reviewVotes.value,
          questionsVotes: questionsVotes.value,
          answerVotes: answerVotes.value,

          userId: userId.value,
          displayName: displayName.value,
          createdAt: timestamp,
        });
        
        // END Mark's attempt to to create user document in "users" top-level collection via useCollection.js

        router.push({ name: "Welcome" });
      }
    };

    // onMounted(() => {
      
    //   const reCaptchaVerifier = new RecaptchaVerifier('', {
    //     size: 'invisible',
    //     callback: function (response) {
    //       console.log('It works!');
    //     },
    //   });
    //   reCaptchaVerifier.render();
    
    // });

    return {
      email,
      password,
      displayName,
      handleSubmit,
      error,
      isPending,
      err,
      phone,
      twofactorAuth,
      realFirstName,
      realMiddleNameOrInit,
      realLastName,
      userStatus,
      profilePhotoUrl,
      isRealEstateAgent,
      isVerified,
      reviewsWrittenFullPublic,
      questionsWrittenFullPublic,
      answersWrittenFullPublic,
      reviewsWrittenAnonPublic,
      questionsWrittenAnonPublic,
      answersWrittenAnonPublic,
      reviewsWrittenKeepPrivate,
      questionsWrittenKeepPrivate,
      answersWrittenKeepPrivate,
      reviewVotes,
      questionsVotes,
      answerVotes,

      //userId,
      displayName,
      createdAt,
    };
  },
};
</script>
