<template>
  <div
    class="mx-auto max-w-screen-md rounded-lg border-2 border-indigo-200 bg-indigo-50 py-8"
  >
    <h1 class="my-8 text-center text-2xl font-extrabold">
      That page does not exist!
    </h1>
    <div class="text-center">Sorry about that (it's probably our fault).</div>
    <div
      class="hover:ring-bg-indigo-50 mx-auto my-8 mb-8 w-1/2 justify-center rounded-md bg-white px-4 py-2 text-center ring-2 ring-indigo-200 hover:bg-indigo-900 hover:text-white hover:ring-8 hover:ring-indigo-300"
    >
      <router-link :to="{ name: 'Home' }"
        ><span class="">Go to the Home Page</span></router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
