import { ref, watchEffect } from "vue";
import { projectFirestore } from "../firebase/config";
import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  getDocs,
} from "firebase/firestore";

const getDocumentId = (collectionName) => {
  const error = ref(null);

  const getDocId = async (fieldKeyValue) => {
    let condition1 = fieldKeyValue[0];
    let condition2 = fieldKeyValue[1];
    let condition3 = fieldKeyValue[2];
    console.log(condition1);
    error.value = null;
    let collectionRef = collection(projectFirestore, collectionName);
    let results = [];
    const q = await query(
      collectionRef,
      where(condition1[0], condition1[1], condition1[2]),
      where(condition2[0], condition2[1], condition2[2]),
      where(condition3[0], condition3[1], condition3[2]),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      return doc.id && results.push(doc.id);
    });

    if (results.length > 0) {
      error.value = null;
      return results[0];
    } else {
      return false;
    }

    // const unsub = onSnapshot(q, (snapshot) => {
    //   let results = []
    //   snapshot.docs.forEach(doc => {
    //     console.log(doc.id, " => ", doc.data());
    //     return doc.id && results.push({id: doc.id})
    //   });

    //   // update values
    //   document.value = results
    //   error.value = null
    // }, err => {
    //   console.log(err.message)
    //   document.value = null
    //   error.value = 'could not fetch the data'
    // })
  };

  // //below unsubscribes upon unmounting from DOM
  // watchEffect((onInvalidate) => {
  //   onInvalidate(() => unsub());
  // });

  return { error, getDocId };
};

export default getDocumentId;
