<template>
  <div>
    <nav
      class="mx-auto flex max-w-full flex-wrap items-center justify-between p-4 md:p-4"
    >
      <!--<img src="@/assets/zagnetic-logo.svg"/>-->
      <span class="z-30"
        ><router-link :to="{ name: 'Home' }"
          ><img
            :src="zagneticSvgLogo"
            class="mr-6 flex max-h-14 shrink-0 items-center text-white" /></router-link
      ></span>

      <!-- BEGIN "hamburger" menu for screens smaller than "lg" -->
      <div class="z-40 block print:hidden lg:hidden">
        <!-- BEGIN headless ui dropdown menu -->

        <div class="relative inline-block text-left">
          <Menu>
            <span class="rounded-md shadow-sm">
              <MenuButton
                class="to-zagblue active:to-zagblue flex items-center rounded border border-slate-700 from-indigo-900 px-3 py-2 text-slate-700 hover:border-white hover:bg-gradient-to-tr hover:text-white focus:ring-1 focus:ring-pink-400 active:bg-gradient-to-tr active:from-indigo-600 active:text-white"
              >
                <svg
                  class="h-3 w-3 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </MenuButton>
            </span>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-out"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="border-zagslate absolute right-0 mt-2 w-60 origin-top-right divide-y divide-pink-300 rounded-md border bg-slate-50 shadow-lg outline-none"
              >
                <div class="px-4 pt-4 pb-2">
                  <!-- moved "About" standalone from here into each of v-if and v-else situations below -->

                  <!-- BEGIN if user -->
                  <div v-if="user" class="space-y-4">
                    <!-- <MenuItem
                      ><p>
                        <router-link
                          :to="{ name: 'Account' }"
                          class="text-zagblue rounded-lg hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
                          ><span class="italic"
                            ><fa
                              :icon="['fas', 'user-circle']"
                              class="fa-fw mr-1 text-sm md:text-lg"
                            />{{ user.displayName }}</span
                          ></router-link
                        >
                      </p></MenuItem
                    >
                    <hr class="text-slate-200" /> -->
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Home' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'home']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Home page
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Home' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'search']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Search reviews&thinsp;+
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem>
                      <p>
                        <router-link :to="{ name: 'CreateReview' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'pencil-alt']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Write a review
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <a
                          href="https://forms.gle/TptSUCWDZvJkU55y6"
                          target="_blank"
                          title="feedback"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'lightbulb']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Feedback
                          </button></a
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'About' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'sun']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />About
                          </button></router-link
                        >
                      </p></MenuItem
                    >

                    <!-- BEGIN test of "Get custom notifications" -->
                    <MenuItem
                      ><p>
                        <a
                          href="https://forms.gle/6VTm9ZtirwtkFhpF9"
                          target="_blank"
                          title="get custom notifications"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'bell']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            /><span
                              class="bg-gradient-to-r from-pink-600 to-indigo-700/80 bg-clip-text text-base font-semibold text-transparent"
                              >Custom</span
                            >
                            Notifications
                          </button></a
                        >
                      </p></MenuItem
                    >
                    <!-- END test of "Get custom notifications" -->

                    <hr />
                    <!-- BEGIN hamberger menu to Account items (only if logged-IN) -->

                    <!-- <MenuItem
                      ><p>
                        <router-link
                          :to="{ name: 'Account' }"
                          class="text-zagblue rounded-lg hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
                          ><span class="italic"
                            ><fa
                              :icon="['fas', 'user-circle']"
                              class="fa-fw mr-1 text-base md:text-lg"
                            />{{ user.displayName }}</span
                          ></router-link
                        >
                      </p></MenuItem
                    >
                    <hr class="text-slate-200" /> -->

                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn" id="profilebtn">
                            <fa
                              :icon="['fas', 'user-circle']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Profile
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'cog']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Settings & privacy
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem>
                      <p>
                        <router-link :to="{ name: 'Account' }"
                          ><button
                            @click="handleClickPassword"
                            class="dropdownmenubtn"
                          >
                            <fa
                              :icon="['fas', 'chart-bar']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Activity & stats
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'bell']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Notifications
                          </button>
                        </router-link>
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'life-ring']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Help & Support
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'balance-scale']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Legal
                          </button>
                        </router-link>
                      </p></MenuItem
                    >

                    <hr />
                    <MenuItem
                      ><p>
                        <button @click="handleClick" class="dropdownmenubtn">
                          <fa
                            :icon="['fas', 'sign-out-alt']"
                            class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                          />Sign out
                        </button>
                      </p></MenuItem
                    >
                    <!-- END hamberger menu to Account items (only if logged-IN) -->
                  </div>
                  <!-- END if user -->

                  <!-- BEGIN if !user -->
                  <div v-else class="space-y-4">
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Home' }"
                          ><button class="dropdownmenubtn" id="profilebtn">
                            <fa
                              :icon="['fas', 'home']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Home page
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Home' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'search']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Search reviews&thinsp;+
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'About' }"
                          ><button class="dropdownmenubtn" id="profilebtn">
                            <fa
                              :icon="['fas', 'sun']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />About
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <!-- BEGIN test of "Get custom notifications" -->
                    <MenuItem
                      ><p>
                        <a
                          href="https://forms.gle/6VTm9ZtirwtkFhpF9"
                          target="_blank"
                          title="get custom notifications"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'bell']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            /><span
                              class="bg-gradient-to-r from-pink-600 to-indigo-700/80 bg-clip-text text-base font-semibold text-transparent"
                              >Custom</span
                            >
                            Notifications
                          </button></a
                        >
                      </p></MenuItem
                    >
                    <!-- END test of "Get custom notifications" -->
                    
                    <MenuItem
                      ><p>
                        <button
                          @click="openModal"
                          class="dropdownmenubtn"
                          id="profilebtn"
                        >
                          <fa
                            :icon="['fas', 'pencil-alt']"
                            class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                          />Write a review
                        </button>
                      </p></MenuItem
                    >

                    <hr />
                    <MenuItem
                      ><p>
                        <router-link
                          class="btn rounded-lg text-base hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
                          :to="{ name: 'Signup' }"
                          ><button class="dropdownmenubtn" id="profilebtn">
                            <fa
                              :icon="['fas', 'user-plus']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Sign up
                          </button></router-link
                        >
                      </p></MenuItem
                    >

                    <MenuItem
                      ><p>
                        <router-link
                          class="btn rounded-lg text-base hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
                          :to="{ name: 'Login' }"
                          ><button class="dropdownmenubtn" id="profilebtn">
                            <fa
                              :icon="['fas', 'sign-in-alt']"
                              class="fa-fw mr-2 text-base text-slate-600 md:text-lg"
                            />Log in
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                  </div>
                  <!-- END if !user -->
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <!-- END headless ui dropdown menu -->
      </div>
      <!-- ?? END "hamburger" menu for sceens smaller than "lg" -->

      <div class="hidden print:hidden lg:z-30 lg:flex">
        <!-- was <div class="links"> -->

        <div
          class="block w-full grow font-light lg:flex lg:w-auto lg:items-center"
        >
          <div class="">
            <div
              class="mt-4 mr-4 flex space-x-4 font-medium text-slate-600 lg:mt-0 xl:space-x-8"
            >
              <div
                class="cursor-pointer rounded-lg pt-0.5 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
              >
                <router-link :to="{ name: 'About' }"
                  ><span
                    class="rounded-lg text-sm hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                    >About</span
                  ></router-link
                >
              </div>

              <!-- <div class="pt-0.5">
                <router-link
                  class="btn rounded-lg text-sm hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                  :to="{ name: 'Home' }"
                >
                  Search <fa :icon="['fas', 'search']" fixed-width />
                </router-link>
              </div> -->

              <div
                class="cursor-pointer rounded-lg pt-0.5 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
              >
                <div v-if="user">
                  <router-link :to="{ name: 'CreateReview' }"
                    ><span class="text-sm xl:text-base"
                      >Write a review<fa
                        :icon="['fas', 'pencil-alt']"
                        class="ml-1.5 text-base opacity-75" /></span
                  ></router-link>
                </div>
                <div v-else>
                  <div @click="openModal">
                    <span class="text-sm xl:text-base"
                      >Write a review<fa
                        :icon="['fas', 'pencil-alt']"
                        class="ml-1.5 text-base opacity-75"
                    /></span>
                  </div>
                </div>
              </div>

              <div
                v-if="!user"
                class="cursor-pointer rounded-lg pt-0.5 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
              >
                <router-link
                  class="btn rounded-lg text-sm hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                  :to="{ name: 'Home' }"
                >
                  Search <fa :icon="['fas', 'search']" fixed-width />
                </router-link>
              </div>

               <!-- BEGIN test of "Get Custom Notifications" -->
              <div
                class="cursor-pointer rounded-lg pt-0.5 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
              >
                <div class="flex-1">
                  <a
                    class="text-muted text-hover-primary text-sm xl:text-base"
                    href="https://forms.gle/6VTm9ZtirwtkFhpF9"
                    target="_blank"
                    title="Get notified about new reviews, questions & answers for properties / towns you choose"
                  >
                    Get <span
                      class="bg-gradient-to-r from-pink-600 to-indigo-700/80 bg-clip-text text-base font-semibold text-transparent"
                      >Custom</span
                    >
                    Notifications <fa :icon="['fas', 'bell']" fixed-width
                  /></a>
                </div>
              </div>
              <!-- END test of "Get Custom Notifications" -->
              
              <div
                class="cursor-pointer rounded-lg pt-0.5 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
              >
                <div class="flex-1">
                  <a
                    class="text-muted text-hover-primary text-sm xl:text-base"
                    href="https://forms.gle/TptSUCWDZvJkU55y6"
                    target="_blank"
                    title="feedback"
                    >Feedback <fa :icon="['far', 'lightbulb']" fixed-width
                  /></a>
                </div>
              </div>

              <div v-if="user" class="space-x-4 xl:space-x-8">
                <!-- <router-link :to="{ name: 'Home' }"><span class="text-sm xl:text-base hover:text-slate-400">Zag-Home</span></router-link> -->
                <router-link
                  class="btn rounded-lg text-sm hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                  :to="{ name: 'Home' }"
                >
                  Search <fa :icon="['fas', 'search']" fixed-width />
                </router-link>

                <!-- <router-link
                  :to="{ name: 'Account' }"
                  class="rounded-lg text-indigo-900 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
                  ><span class="text-sm italic xl:text-base"
                    ><fa
                      :icon="['fas', 'user-circle']"
                      class="fa-fw mr-1 text-sm md:text-lg"
                    />{{ user.displayName }}</span
                  ></router-link
                > -->
                <!-- <button @click="handleClick" class="border py-1 px-2 text-sm xl:text-base hover:text-slate-700 hover:bg-indigo-50 hover:px-0.5 rounded-lg">Log out</button> -->
              </div>

              <div v-else class="space-x-4 xl:space-x-8">
                <!-- <router-link :to="{ name: 'Home' }"><span class="text-sm xl:text-base hover:text-slate-700 hover:bg-indigo-50 hover:px-0.5 rounded-lg">Zag-Home</span></router-link> -->
                <router-link
                  class="btn rounded-lg text-sm hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                  :to="{ name: 'Signup' }"
                  >Sign Up</router-link
                >
                <router-link
                  class="btn rounded-lg text-sm hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                  :to="{ name: 'Login' }"
                  >Log in</router-link
                >
              </div>
            </div>
          </div>

          <!-- BEGIN Only if user logged-in: dropdown menu with "Account" nav for screens "lg" and up -->
          <Menu v-if="user">
            <span class="rounded-md shadow-sm">
              <!-- <MenuButton
                class="to-zagblue active:to-zagblue flex items-center rounded border border-slate-700 from-indigo-900 px-3 py-2 text-slate-700 hover:border-white hover:bg-gradient-to-tr hover:text-white focus:ring-1 focus:ring-pink-400 active:bg-gradient-to-tr active:from-indigo-600 active:text-white"
              >
                <svg
                  class="h-3 w-3 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </MenuButton> -->
              <MenuButton v-if="user">
                <span
                  class="mx-4 rounded-lg text-sm italic text-indigo-900 hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700 xl:text-base"
                  ><fa
                    :icon="['fas', 'user-circle']"
                    class="fa-fw mr-1 text-sm text-indigo-900 md:text-lg"
                  />{{ user.displayName }}</span
                ></MenuButton
              >
            </span>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-out"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute top-16 right-0 mt-2 w-60 origin-top-right divide-y divide-pink-300 rounded-md border border-indigo-900 bg-slate-50 shadow-lg outline-none"
              >
                <div class="px-4 pt-2 pb-2">
                  <div class="mt-4 space-y-2.5">
                    <!-- <MenuItem
                      ><p>
                        <router-link
                          :to="{ name: 'Account' }"
                          class="text-zagblue rounded-lg hover:bg-indigo-50 hover:px-0.5 hover:text-slate-700"
                          ><span class="italic"
                            ><fa
                              :icon="['fas', 'user-circle']"
                              class="fa-fw mr-1 text-sm md:text-lg"
                            />{{ user.displayName }}</span
                          ></router-link
                        >
                      </p></MenuItem
                    >
                    <hr class="text-slate-200" /> -->
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn" id="profilebtn">
                            <fa
                              :icon="['fas', 'user-circle']"
                              class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                            />Profile
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'cog']"
                              class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                            />Settings & privacy
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem>
                      <p>
                        <router-link :to="{ name: 'Account' }"
                          ><button
                            @click="handleClickPassword"
                            class="dropdownmenubtn"
                          >
                            <fa
                              :icon="['fas', 'chart-bar']"
                              class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                            />Activity & stats
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'bell']"
                              class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                            />Notifications
                          </button>
                        </router-link>
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'life-ring']"
                              class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                            />Help & Support
                          </button></router-link
                        >
                      </p></MenuItem
                    >
                    <MenuItem
                      ><p>
                        <router-link :to="{ name: 'Account' }"
                          ><button class="dropdownmenubtn">
                            <fa
                              :icon="['fas', 'balance-scale']"
                              class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                            />Legal
                          </button>
                        </router-link>
                      </p></MenuItem
                    >

                    <hr />
                    <MenuItem
                      ><p>
                        <button @click="handleClick" class="dropdownmenubtn">
                          <fa
                            :icon="['fas', 'sign-out-alt']"
                            class="fa-fw mr-2 text-sm text-slate-600 md:text-lg"
                          />Sign out
                        </button>
                      </p></MenuItem
                    >
                  </div>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <!-- END Only if user logged-in: dropdown menu with "Account" nav for screens "lg" and up -->
        </div>
      </div>
    </nav>

    <!-- BEGIN HeadlessUI Dialogue code -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="via ingigo-50 my-6 inline-block w-full max-w-sm transform overflow-hidden rounded-2xl bg-gradient-to-br from-white to-indigo-100 px-10 py-4 text-center align-middle shadow-xl ring-8 ring-indigo-300 ring-opacity-80 ring-offset-8 ring-offset-indigo-200 transition-all sm:p-6"
              >
                <DialogTitle
                  as="h3"
                  class="text-zagslate text-center text-xl font-bold leading-6"
                  >Whoops!</DialogTitle
                >

                <div class="my-4">
                  <p class="text-zagslate text-base font-medium">
                    You need to be logged-in to do that.
                  </p>
                </div>

                <div class="mt-8">
                  <p
                    class="text-zagslate text-base font-medium leading-loose sm:leading-none"
                  >
                    Would you like to
                    <span
                      class="mx-0.5 rounded-md bg-indigo-100 px-1.5 py-1 text-base ring-2 ring-indigo-200 hover:bg-opacity-80 hover:ring-indigo-300"
                      ><router-link
                        class="text-base font-semibold tracking-wide"
                        :to="{ name: 'Login' }"
                        @click="closeModal"
                        >Log in</router-link
                      ></span
                    >
                    or
                    <span
                      class="ml-0.5 mr-1 rounded-md bg-indigo-100 px-1.5 py-1 text-base ring-2 ring-indigo-200 hover:bg-opacity-80 hover:ring-indigo-300"
                      ><router-link
                        class="text-base font-semibold tracking-wide"
                        :to="{ name: 'Signup' }"
                        @click="closeModal"
                        >Sign up</router-link
                      ></span
                    >?
                  </p>
                </div>

                <div class="float-right mt-8">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-sm font-normal text-slate-600 hover:ring-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-600 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    close<fa
                      :icon="['far', 'times-circle']"
                      class="ml-1.5 text-xl"
                    />
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- END HeadlessUI Dialogue code -->
  </div>
</template>

<script>
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import getSetUserInfo from "@/composables/getSetUserInfo";

import useStorage from "@/composables/useStorage";

import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from "@headlessui/vue"; /* relates to menu dropdown */

import { computed, onMounted, ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const router = useRouter();
    const zagneticSvgLogo =
      "https://firebasestorage.googleapis.com/v0/b/zagnetic-dev-001.appspot.com/o/zagnetic-dev-images%2Fzagnetic-logo.svg?alt=media&token=13e62d0b-fc68-45eb-940a-85d198da15bb";

    const handleClick = async () => {
      await logout();
      console.log("logged out");
      router.push({ name: "Login", query: { redirect_url: "/" } });
      location.reload();
    };

    const isOpen = ref(false); // relates to HeadlessUI dialogue

    return {
      handleClick,
      user,
      zagneticSvgLogo,
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
  components: {
    /* relates to menu dropdown */ Menu,
    MenuButton,
    MenuItems,
    MenuItem,

    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
};
</script>

<style scoped></style>
