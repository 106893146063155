<template>
  <div v-if="ownership && propertyBeingEdited"
    class="bg-rounded mx-auto max-w-screen-md bg-white px-0 pt-4 pb-4 sm:bg-gray-50 sm:px-0 md:px-4 cursor-auto"
  >
    <div>
      <h1
        class="text-zaggray mt-8 mb-2.5 text-center text-3xl font-bold underline"
      >
        Edit Your Review
      </h1>

      <p class="tempdevnote text-center text-sm italic !p-4 m-4 hidden">
        Review and Property IDs from route params:
        <br /> <br />
        reviewBeingEdited_Id = {{ reviewBeingEdited_Id }}
        <br /> <br />
        review_id of review being edited, gathered from $route.params.review_id = {{ $route.params.review_id }}
        <br /> <br />
        propertyBeingEdited_Id = {{ propertyBeingEdited_Id }}
         <br /> <br />
        propertyBeingEdited.allReviews.length = {{ propertyBeingEdited.allReviews.length }}
        <br /> <br />
        propertyId of review being edited, gathered from $route.params.propertyId = {{ $route.params.propertyId }}
         <br /> <br />
        value of "reviewBeingEdited" = {{ reviewBeingEdited }}
      </p>

      <p class="text-zaggray mb-14 text-center text-sm italic">
        Be honest & kind
      </p>
    </div>

    <p class="text-center mt-2 mb-16">
        <span v-if="!isPending">
        <router-link
                :to="{
                  name: 'ReviewDetails',
                  params: { id: reviewBeingEdited_Id },
                }"
              >
              <button
        class="text-zaggray rounded-md border border-indigo-900 py-1.5 px-4 text-sm hover:bg-indigo-900 hover:text-white hover:ring-pink-500"
      >
        Done editing&nbsp;&nbsp;<fa class="text-zagblue" icon="check" />
      </button>
        </router-link>
        </span>
        <span v-else>
       <button disabled><WaitingToLoad /></button>
       </span>
       </p>


      <h3 class="question">
        What property?<span class="required">(required)</span>
      </h3>
      <div class="inline-flex ml-4 sm:ml-6"><span class="">{{ reviewBeingEdited.address }}</span><span v-if="reviewBeingEdited.extended_address" class="">&nbsp;&nbsp;-&nbsp;&nbsp;{{reviewBeingEdited.extended_address}}</span></div>
       <div v-if="propertyBeingEdited.allReviews.length < 2
       " class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.address"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="address"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="string"
          />
        </button></div>
      <div v-else class="ml-4 sm:ml-6 mt-4  hover:text-orange-600 italic text-sm text-right mr-2">Sorry, the address cannot be edited<button><Whoops whoopsButtonVifTextAndIcon="5.8"
          /></button></div>

      <hr class="my-10" />

      <div >
        <h3 class="question mb-4">
          Apartment, unit, suite, or floor #
        </h3>
        <p v-if="reviewBeingEdited.extended_address" class="mt-0 mb-2 ml-0 text-sm font-normal italic text-slate-600">
          {{reviewBeingEdited.extended_address}}
          
        </p>
        <p v-else class="ml-4 italic text-base">-&thinsp;none&thinsp;-
        </p>

       <div v-if="propertyBeingEdited.allReviews.length < 2
       " class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.extended_address"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="extended_address"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="string"
          />
        </button></div>
      <div v-else class="ml-4 sm:ml-6 mt-4  hover:text-orange-600 italic text-sm text-right mr-2">Sorry, this cannot be edited<button><Whoops whoopsButtonVifTextAndIcon="5.8"
          /></button></div>
      </div>

      <hr class="my-10" />

      <h3 class="question mb-4">
        What kind of property is it?<span class="required">(required)</span>
      </h3>
       <div class="inline-flex ml-4 sm:ml-6">
          <!-- BEGIN display property type-->
          <span v-if="reviewBeingEdited.property_type === '1_family'" class="">Single family</span>
          <span v-else-if="reviewBeingEdited.property_type === 'condo'" class="">Condominium</span>
          <span v-else-if="reviewBeingEdited.property_type === 'townhouse'" class="">Townhouse</span>
          <span v-else-if="reviewBeingEdited.property_type === 'coop'" class="">Cooperative</span>
          <span v-else-if="reviewBeingEdited.property_type === 'mobilehome'" class="">Mobile Home</span>
          <span v-else-if="reviewBeingEdited.property_type === 'multi_family'" class="">Multi-family (whole building)</span>
          <span v-else-if="reviewBeingEdited.property_type === 'commercial'" class="">Commercial</span>
          <span v-else-if="reviewBeingEdited.property_type === 'rentalapartment'" class="">Apartment for rent</span>
           <span v-else-if="reviewBeingEdited.property_type === 'raw_land'" class="">Raw land</span>
          <!-- END display property type -->
        </div>
        <div v-if="propertyBeingEdited.allReviews.length < 2" class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.property_type"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="property_type"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="select"
          />
        </button></div>
        <div v-else class="ml-4 sm:ml-6 mt-4  hover:text-orange-600 italic text-sm text-right mr-2">Sorry, the property type cannot be edited<button><Whoops whoopsButtonVifTextAndIcon="5.8"
          /></button></div>

      <hr class="my-10" />

      <div class="relative">
        <h3 class="question">Pros<span class="optional">(optional)</span></h3>
        <div class="mb-1 mt-3.5 text-base font-medium pb-8 italic text-zagblue">
          What are 3+
          <span class="mr-0.5 text-base font-medium italic underline text-zagblue">positive</span> things about
          the house, property or neighborhood?
        </div>
        <div v-if="reviewBeingEdited.propPros.length > 0">
              <div v-for="propPro in reviewBeingEdited.propPros" :key="propPro.id">
                <div class="mb-2 ml-4 sm:ml-6  lowercase text-base">
                  <fa
                    icon="plus-circle"
                    class="text-sm text-progreen/80"
                    alt="plus sign icon in a circle"
                  />
                  &nbsp;{{ propPro }}
                </div>
              </div>
            </div>
            <div v-else><p class="px-1.5 italic text-base"
            >-&thinsp;none&thinsp;-</p></div>
         <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.propPros"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="propPros"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="tagarray"
          />
        </button></div>
      </div>

      <!-- <hr class="mt-8 mb-12 invisible"> -->
      <hr class="my-10" />

      <div class="relative">
        <h3 class="question">Cons<span class="optional">(optional)</span></h3>
        <div class="mb-1 mt-3.5 pb-8 text-base italic text-zagblue">
          List 3 or more
          <span class="mr-0.5 text-base underline italic text-zagblue">negatives,</span> or things
          you don’t like:
        </div>
        <div v-if="reviewBeingEdited.propCons.length > 0">
              <div v-for="propCon in reviewBeingEdited.propCons" :key="propCon.id">
                <div class="mb-2 ml-4 sm:ml-6 lowercase text-base">
                  <fa
                    icon="minus-circle"
                    class="text-sm text-conred/80"
                    alt="plus sign icon in a circle"
                  />
                  &nbsp;{{ propCon }}
                </div>
              </div>
            </div>
            <div v-else><p class="px-1.5 italic text-base"
            >-&thinsp;none&thinsp;-</p></div>
         <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.propCons"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="propCons"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="tagarray"
          />
        </button></div>
      </div>

      <hr class="my-10" />

      <h3 class="question">
        Anything misleading or untrue in the listing or photos?<span
          class="optional"
          >(optional)</span
        >
      </h3>

      <p v-if="reviewBeingEdited.whatInListingIsUntrue" class="ml-4 sm:ml-6">{{ reviewBeingEdited.whatInListingIsUntrue }}</p>
      <p v-else class="px-1.5 italic text-base ml-4 sm:ml-6">-&thinsp;none&thinsp;-</p>
      <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.whatInListingIsUntrue"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="whatInListingIsUntrue"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="string"
          />
        </button></div>


      <hr class="my-10" />

      <!-- <p class="text-center my-6">
        <span v-if="!isPending">
        <router-link
                :to="{
                  name: 'ReviewDetails',
                  params: { id: reviewBeingEdited_Id },
                }"
              >
              <button
        class="text-zaggray rounded-md border border-indigo-900 py-1.5 px-4 text-sm hover:bg-indigo-900 hover:text-white hover:ring-pink-500"
      >
        Done editing&nbsp;&nbsp;<fa class="text-zagblue" icon="check" />
      </button>
        </router-link>
        </span>
        <span v-else>
       <button disabled><WaitingToLoad /></button>
       </span>
       </p>
       
      <hr class="my-10" /> -->

      <h3 class="question">$12,000<span class="optional">(optional)</span></h3>
      <p class="mt-3.5 pb-8 text-base italic text-zagblue"
        >If you were going to live here and received a $12,000 gift card, good
        for any product or service,
        <span class="text-base font-semibold italic text-zagblue"
          >what would you repair, upgrade or add?</span
        >
        Why?</p
      >

      <p v-if="reviewBeingEdited.whatToRepairUpgradeAdd" class="text-base ml-4 sm:ml-6">{{ reviewBeingEdited.whatToRepairUpgradeAdd }}</p>
      <p v-else class="px-1.5 italic text-base ml-4 sm:ml-6">-&thinsp;none&thinsp;-</p>
      <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.whatToRepairUpgradeAdd"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="whatToRepairUpgradeAdd"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="string"
          />
        </button></div>

      <hr class="my-10" />

      <!-- BEGIN Star Ratings v2 -->

      <h3 class="question">
        On a 1 - 5 scale, with 5 being the best, how would you rate these:<span
          class="optional"
          >(optional)</span
        >
      </h3>

      <div class="row-span-1 pt-4">

        
        <div class="inline-flex items-end mb-4">
          <p
            class="ml-12 mr-2 pb-1 pl-5 text-sm sm:ml-24 sm:pl-1.5 sm:text-base md:ml-6 md:pl-20"
          >
            Kitchen:
          </p>
          <p class=""></p>
          <!-- kitchen_rating -->
          <div>
           <!-- BEGIN 2nd column, with stars, on right -->
                <div class="mb-1 inline-flex">
                  <div
                    v-if="reviewBeingEdited.kitchen_rating === '5'"
                    alt="reviewer rated kitchen 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.kitchen_rating === '4'"
                    alt="reviewer rated kitchen 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    /> 
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.kitchen_rating === '3'"
                    alt="reviewer rated kitchen 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.kitchen_rating === '2'"
                    alt="reviewer rated kitchen 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.kitchen_rating === '1'"
                    alt="reviewer rated kitchen 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                  <p>
                    <button>
                      <UpdateCollAndSubcoll
                        :fieldValue="reviewBeingEdited.kitchen_rating"
                        updateCollAndSubCollVersion="1"
                        whichItemToUpdate="kitchen_rating"
                        :review_id="reviewBeingEdited_Id"
                        :propertyId="propertyBeingEdited_Id"
                        itemType="starrating"
                      />
                    </button>
                  </p>
                </div>
          </div>
        </div>
      </div>

      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end mb-4">
          <p
            class="ml-2.5 mr-2 pb-1 pl-5 text-sm sm:ml-3 sm:pl-12 sm:text-base md:ml-5 md:pl-10"
          >
            Bathroom(s):
          </p>
          <!-- bath_rating -->
          <div>
             <!-- BEGIN 2nd column, with stars, on right -->
                <div class="mb-1 inline-flex">
                  <div
                    v-if="reviewBeingEdited.bath_rating === '5'"
                    alt="reviewer rated kitchen 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.bath_rating === '4'"
                    alt="reviewer rated kitchen 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    /> 
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.bath_rating === '3'"
                    alt="reviewer rated kitchen 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.bath_rating === '2'"
                    alt="reviewer rated kitchen 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.bath_rating === '1'"
                    alt="reviewer rated kitchen 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                  <p>
                    <button>
                      <UpdateCollAndSubcoll
                        :fieldValue="reviewBeingEdited.bath_rating"
                        updateCollAndSubCollVersion="1"
                        whichItemToUpdate="bath_rating"
                        :review_id="reviewBeingEdited_Id"
                        :propertyId="propertyBeingEdited_Id"
                        itemType="starrating"
                      />
                    </button>
                  </p>
                </div>
          </div>
        </div>
      </div>

      <!-- BEGIN location -->
      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end mb-4">
          <!-- <p
            class="ml-6 mr-2 pb-1 pl-8 text-sm sm:ml-2.5 sm:pl-20 sm:text-base md:pl-20"
          >

          </p> -->
          <p
            class="ml-7 mr-2 pb-1 pl-8 text-sm sm:ml-3.5 sm:pl-20 sm:text-base md:pl-20"
          >
            Location:
          </p>
          <!-- location_rating -->
          <div>
             <!-- BEGIN 2nd column, with stars, on right -->
                <div class="mb-1 inline-flex">
                  <div
                    v-if="reviewBeingEdited.location_rating === '5'"
                    alt="reviewer rated kitchen 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.location_rating === '4'"
                    alt="reviewer rated kitchen 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    /> 
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.location_rating === '3'"
                    alt="reviewer rated kitchen 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.location_rating === '2'"
                    alt="reviewer rated kitchen 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.location_rating === '1'"
                    alt="reviewer rated kitchen 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                  <p>
                    <button>
                      <UpdateCollAndSubcoll
                        :fieldValue="reviewBeingEdited.location_rating"
                        updateCollAndSubCollVersion="1"
                        whichItemToUpdate="location_rating"
                        :review_id="reviewBeingEdited_Id"
                        :propertyId="propertyBeingEdited_Id"
                        itemType="starrating"
                      />
                    </button>
                  </p>
                </div>
          </div>
        </div>
      </div>
      <!-- END location -->

      <!-- BEGIN setting -->
      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end mb-4">
          <p
            class="ml-[1.6rem] mr-2 inline-flex pb-1 pl-8 text-sm sm:ml-3 sm:pl-20 sm:text-base md:pl-20"
          >
            <Whoops whoopsButtonVifTextAndIcon="13" />&thinsp;:
          </p>
          <!-- location_rating -->
          <div>
             <!-- BEGIN 2nd column, with stars, on right -->
                <div class="mb-1 inline-flex">
                  <div
                    v-if="reviewBeingEdited.setting_rating === '5'"
                    alt="reviewer rated kitchen 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.setting_rating === '4'"
                    alt="reviewer rated kitchen 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    /> 
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.setting_rating === '3'"
                    alt="reviewer rated kitchen 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.setting_rating === '2'"
                    alt="reviewer rated kitchen 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.setting_rating === '1'"
                    alt="reviewer rated kitchen 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                  <p>
                    <button>
                      <UpdateCollAndSubcoll
                        :fieldValue="reviewBeingEdited.setting_rating"
                        updateCollAndSubCollVersion="1"
                        whichItemToUpdate="setting_rating"
                        :review_id="reviewBeingEdited_Id"
                        :propertyId="propertyBeingEdited_Id"
                        itemType="starrating"
                      />
                    </button>
                  </p>
                </div>
          </div>
        </div>
      </div>
      <!-- END setting -->

      <div class="row-span-1 mt-4">
        <div class="inline-flex items-end">
          <p class="ml-0 mr-2 pb-1 text-sm sm:ml-7 sm:text-base">
            Smell & odor free:
          </p>
          <!-- smell_rating -->
          <div>
             <!-- BEGIN 2nd column, with stars, on right -->
                <div class="mb-1 inline-flex">
                  <div
                    v-if="reviewBeingEdited.smell_rating === '5'"
                    alt="reviewer rated kitchen 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.smell_rating === '4'"
                    alt="reviewer rated kitchen 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    /> 
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.smell_rating === '3'"
                    alt="reviewer rated kitchen 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.smell_rating === '2'"
                    alt="reviewer rated kitchen 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="reviewBeingEdited.smell_rating === '1'"
                    alt="reviewer rated kitchen 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                  <p>
                    <button>
                      <UpdateCollAndSubcoll
                        :fieldValue="reviewBeingEdited.smell_rating"
                        updateCollAndSubCollVersion="1"
                        whichItemToUpdate="smell_rating"
                        :review_id="reviewBeingEdited_Id"
                        :propertyId="propertyBeingEdited_Id"
                        itemType="starrating"
                      />
                    </button>
                  </p>
                </div>
          </div>
        </div>
      </div>

      <!-- END Star Ratings v2 -->

      <hr class="my-10" />

      <h3 class="question">Photos <span class="optional">(optional)</span></h3>
      <div class="ml-0.5 mb-4 text-sm text-gray-500">
        Photos of the house or neighborhood&thinsp;:
      </div>

      <div class="mb-4">
          <div v-if="reviewBeingEdited.filePath != null" class="print:hidden">
              <ReviewMedia 
                :filesPath="reviewBeingEdited.filePath" 
                :displayCode="2.22"
                :review_id="reviewBeingEdited_Id"
                :propertyId="propertyBeingEdited_Id"
              />
          </div>
          <div v-else class="italic text-sm">review has no images</div>
      </div>

     

      <hr class="my-10" />
      <!-- END test of new upload design-->

      <h3 class="question">
        Anything else?<span class="optional">(optional)</span>
      </h3>

      <p v-if="reviewBeingEdited.reviewerTextReview" class="ml-4 sm:ml-6">{{ reviewBeingEdited.reviewerTextReview }}</p>
      <p v-else class="px-1.5 italic text-base ml-4 sm:ml-6">-&thinsp;none&thinsp;-</p>
      <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.reviewerTextReview"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="reviewerTextReview"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="string"
          />
        </button></div>

      <hr class="my-10" />

      <!-- BEGIN When were you physically at the property? -->
      <h3 class="question">
        When were you physically at the property?<span class="required"
          >(required)</span
        >
      </h3>
      <div class="inline-flex pt-4 ml-4 sm:ml-6">
        <span v-if="reviewBeingEdited.whenReviewerVisitedProp === '30'" class="text-base">Within the last month</span>
        <span v-else-if="reviewBeingEdited.whenReviewerVisitedProp === '180'" class="text-base">Within the last 6 months</span>
        <span v-else-if="reviewBeingEdited.whenReviewerVisitedProp === '181'" class="text-base">More than 6 months ago</span>
        <span v-else-if="reviewBeingEdited.whenReviewerVisitedProp === '0'" class="text-base">Never</span>
      </div>
      <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.whenReviewerVisitedProp"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="whenReviewerVisitedProp"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="radio"
          />
        </button></div>
      <!-- END When were you physically at the property? -->

      <hr class="my-10" />

      <div class="inline-flex pb-4">
        <p class="question mt-0.5">
          Do you have any relationship with the seller or real estate agent?
        </p>
        <button class="mb-2 ml-0">
          <Whoops whoopsButtonVifTextAndIcon="5" />
        </button>
          <button class="inline"><span class="required">(required)</span></button>
      </div>

       <div class="ml-4 sm:ml-6">
        <span v-if="reviewBeingEdited.reviewerAnyRelWithSellerOrAgent === 'none'"  class="ml-0 text-base inline-flex -mb-1"><fa
              :icon="['far', 'meh-blank']"
              class="text-zaggray/80 text-xl mr-2 -mb-1"
            />None</span>
        <span v-else-if="reviewBeingEdited.reviewerAnyRelWithSellerOrAgent === 'acquainted'"  class="ml-0 text-base inline-flex align-bottom -mb-1.5"><fa
              :icon="['far', 'meh']"
              class="text-zaggray/80 text-xl mr-2"
            />Acquainted</span>
        <span v-else-if="reviewBeingEdited.reviewerAnyRelWithSellerOrAgent === 'solid'"  class="ml-0 text-base inline-flex align-bottom -mb-1.5"><fa
              :icon="['far', 'grin']"
              class="text-zaggray/80 text-xl mr-2"
            />Solid</span>
        <span v-else-if="reviewBeingEdited.reviewerAnyRelWithSellerOrAgent === 'isSeller'"  class="ml-0 text-base inline-flex align-bottom -mb-1.5"><fa
              :icon="['fas', 'house-user']"
              class="text-zaggray/80 text-xl mr-2"
            />I am the owner&thinsp;/&thinsp;seller</span>
        <span v-else-if="reviewBeingEdited.reviewerAnyRelWithSellerOrAgent === 'isSellersAgent'"  class="ml-0 text-base inline-flex align-bottom -mb-1.5"><fa
              :icon="['far', 'clipboard']"
              class="text-zaggray/80 text-xl mr-2"
            />I am the owner&thinsp;/&thinsp;seller's agent</span>
      </div>
      <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.reviewerAnyRelWithSellerOrAgent"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="reviewerAnyRelWithSellerOrAgent"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="radio"
          />
        </button></div>


      <hr class="my-10" />

      <div class="inline-flex">
        <p class="question mt-0.5">Privacy</p>
        <button class="mb-2 ml-0">
          <Whoops whoopsButtonVifTextAndIcon="4" />
        </button>
        <div class="inline pb-4">
          <span class="text-zagblue mb-0.5 pl-0.5 pr-0.5 text-sm font-normal"
            >choice for this review</span
          >
          <span class="required">(required)</span>
        </div>
      </div>

      <div class="ml-4 sm:ml-6">
        <span v-if="reviewBeingEdited.reviewPrivacy === 'postFullPublic'" class="ml-0 inline-flex align-bottom -mb-1.5"><fa
              icon="user"
              class="text-zaggray text-xl mr-2"
            />Public</span>
        <span v-else-if="reviewBeingEdited.reviewPrivacy === 'postAnonPublic'" class="ml-0 inline-flex align-bottom -mb-1.5"><fa
              icon="user-secret"
              class="text-zaggray text-xl mr-2"
            />Incognito</span>
       <span v-else-if="reviewBeingEdited.reviewPrivacy === 'keepFullySecret'" class="ml-0 inline-flex align-bottom -mb-1.5"><fa icon="ghost" class="text-zaggray text-xl mr-2" />Secret
            &nbsp;&nbsp;(your eyes only)</span
            >

        <div class="ml-6 italic text-right mr-2"><button>
          <UpdateCollAndSubcoll
            :fieldValue="reviewBeingEdited.reviewPrivacy"
            updateCollAndSubCollVersion="1"
            whichItemToUpdate="reviewPrivacy"
            :review_id="reviewBeingEdited_Id"
            :propertyId="propertyBeingEdited_Id"
            itemType="radio"
          />
        </button></div>
      </div>


      <p class="text-center mt-16 mb-6">
        <span v-if="!isPending">
        <router-link
                :to="{
                  name: 'ReviewDetails',
                  params: { id: reviewBeingEdited_Id },
                }"
              >
              <button
        class="text-zaggray rounded-md border border-indigo-900 py-1.5 px-4 text-sm hover:bg-indigo-900 hover:text-white hover:ring-pink-500"
      >
        Done editing&nbsp;&nbsp;<fa class="text-zagblue" icon="check" />
      </button>
        </router-link>
        </span>
        <span v-else>
       <button disabled><WaitingToLoad /></button>
       </span>
       </p>
      <!-- <button v-else disabled>Saving...</button> -->
     

  </div>
  <div v-else>
    <h3>You are not allowed to  view this page.</h3>
  </div>
</template>

<script>
import getSubCollectionDoc from "@/composables/getSubCollectionDoc";
import useSubCollection from "@/composables/useSubCollection";
import getSetUserInfo from "@/composables/getSetUserInfo";
import getDocumentId from "@/composables/getDocumentId";
import getDocument from "@/composables/getDocument";
import { timestamp } from "@/firebase/config";
import TagInput from "@/components/TagInput.vue";
import Whoops from "@/components/Whoops";
import { deleteDoc } from "@/composables/useDocument";
import WaitingToLoad from "@/components/WaitingToLoad.vue";

import useStorage from "@/composables/useStorage";

import getUser from "@/composables/getUser";
import { computed, ref} from "vue";
import { useRouter } from "vue-router";

import { formatDistanceToNow, format } from "date-fns";

import ShowRatingStars from "@/components/ShowRatingStars.vue";
import ReviewMedia from "@/components/ReviewMedia";
import UpdateCollAndSubcoll from "@/components/UpdateCollAndSubcoll";

export default {
  props: ["review_id" , "propertyId"],

  components: {
    TagInput: TagInput,
    Whoops,
    WaitingToLoad,
    ShowRatingStars,
    ReviewMedia,
    UpdateCollAndSubcoll,
  },


  setup(props, context) {

    const reviewBeingEdited_Id = ref(props.review_id);
    const propertyBeingEdited_Id = ref(props.propertyId);

    const { filePath, url, uploadImage } = useStorage();
    
    const { error, document: reviewBeingEdited } = getSubCollectionDoc(
      "reviews",
      "review_id",
      reviewBeingEdited_Id.value,
      "=="
    );

    const { document: propertyBeingEdited } = getDocument(
      "properties",
      propertyBeingEdited_Id.value,
    );

    console.log("HHHH propertyBeingEdited", propertyBeingEdited);

    const { userErr, loggedInUser, getLoggedInUserInfo } = getSetUserInfo();
    const { user } = getUser();
    const router = useRouter();

     if (user.value) {
      getLoggedInUserInfo(user.value.uid);
    }

    let reviewCreatedTime = "";
    
    const ownership = computed(() => {
      return (
        reviewBeingEdited.value && user.value && user.value.uid == reviewBeingEdited.value.userId
      );
    });

   const toggleExtendedAddr = () => {
      const allowedType = [
        "condo",
        "coop",
        "townhouse",
        "rentalapartment",
        "commercial",
        "raw_land",
      ];
      if (allowedType.includes(property_type.value)) {
        toggleExtendedAddrProp.value = true;
      } else {
        toggleExtendedAddrProp.value = false;
        extended_address.value = "";
      }
    };



    return {

      toggleExtendedAddr,
      reviewBeingEdited,
      reviewBeingEdited_Id,
      propertyBeingEdited_Id,
      propertyBeingEdited,
      ownership,
      
    }; 
  },
};
</script>

<style scoped>

</style>
