<template>
  <div
    class="bg-rounded mx-auto max-w-screen-md bg-white px-0 pt-4 pb-4 sm:bg-gray-50 sm:px-0 md:px-4"
  >
    <div class="prose">
      <h1 class="text-center text-2xl font-bold">Accessibility</h1>
      <p class="text-center text-xl">
        <span class="zagnetic">Zagnetc</span> understands and strongly believes
        in the priciples behind accessibility - that everyone should be able to
        acess, consume and interact with web and mobile content & services.
      </p>
      <p>
        We will certainly make mistakes and ask that you please help us
        understand where we fall short by contacting us immediately and letting
        us know how we can remedy the situation.
      </p>
      <p>
        We offer a free service where a member of our team will call or text to
        help you in real-time, by conveying what's on any screen(s) to you, and
        even conveying content you wish to share, such as creating a review, and
        entering it into the website or mobile application on your behalf.
      </p>
      <p>
        Just email
        <span class="underline">help [at] zagentic [dotcom]</span> and include
        the problem, the best times and methods to respond to you (such as a
        telephone number, if you'd like a phone call response) and we will get
        back to you ASAP!
      </p>
      <p>
        Of course we want everything to be fully accessible, so these measures
        are not necessary, but this is the best way we can think of to offer
        fast help, until we can fix the underlying problems.
      </p>

      <p>Thank you.</p>

      <button
        class="my-12 text-base font-medium text-indigo-900 underline hover:text-gray-400"
        @click="goHome"
      >
        return to home page
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    const goHome = async () => {
      router.push({ name: "Home" });
    };

    return {
      goHome,
    };
  },
};
</script>

<style></style>
