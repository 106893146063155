<template v-if="propId">
  <div :id="`quiz-${quizIndex}`" :key="singleQandA.answers.length">
    <!--BEGIN question 1 -->

    <div class="md:gap-.5 mt-6 grid grid-cols-12 gap-3 px-0.5 pt-11 pb-5">
      <div
        class="col-span-1 -mt-1.5 ml-0.5 mr-2 -space-y-1.5 sm:ml-0 md:mr-16 lg:mr-12"
      >
        <!-- BEGIN upvote / downvote this item -->

        <div class="flex items-center justify-center">
          <div v-if="user">
            <button
              class="place-self-center text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
              @click="
                upvoteQuestion(
                  `quiz_${singleQandA.userId}_${quizIndex}`,
                  quizIndex,
                  singleQandA.upvoteCount,
                  qAndA,
                  true,
                  $event
                )
              "
            >
              <i class="fas fa-caret-up"></i>
            </button>
          </div>
          <div v-else><Whoops whoopsButtonVifTextAndIcon="26" /></div>
        </div>
        <div class="flex items-center justify-center">
          <p class="text-center text-sm text-slate-500 sm:text-base">
            {{
              typeof singleQandA.upvoteCount !== "undefined" &&
              !isNaN(singleQandA.upvoteCount)
                ? singleQandA.upvoteCount
                : 0
            }}
          </p>
        </div>
        <div class="flex items-center justify-center">
          <div v-if="user">
            <button
              class="text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
              @click="
                upvoteQuestion(
                  `quiz_${singleQandA.userId}_${quizIndex}`,
                  quizIndex,
                  singleQandA.upvoteCount,
                  qAndA,
                  false,
                  $event
                )
              "
            >
              <i class="fas fa-caret-down"></i>
            </button>
          </div>
          <div v-else><Whoops whoopsButtonVifTextAndIcon="27" /></div>
        </div>

        <!-- END upvote / downvote this item -->
      </div>

      <div class="col-span-10 grid md:col-span-10">
        <div
          class="bg-zagblue row-span-1 -ml-1 inline-flex rounded-lg p-2.5 text-white sm:-ml-2 md:-ml-8 lg:rounded-xl"
        >
          <p class="mr-2.5 -mt-0.5 font-semibold sm:mr-3">Q:</p>
          <p id="first" class="qatext scroll-mt-12 text-left text-white">
            {{ singleQandA.questionText }}
          </p>
        </div>

        <div
          class="space-between-96 row-span-1 -ml-0.5 mt-1.5 inline-flex items-start px-0.5 sm:ml-1.5 sm:px-1.5"
        >
          <div
            class="inline-flex w-2/5 space-x-5 text-slate-500 sm:w-1/2 sm:space-x-5 md:-ml-10 md:w-3/5 lg:space-x-8"
          >
            <!-- BEGIN "Answer a Question" button beneath a question, with v-if logged in or "Whoops" 8.5 -->

            <div v-if="user">
              <!-- <Whoops whoopsButtonVifTextAndIcon="8" /> -->
              <AskAnswerQuestion
                v-if="propId"
                AskAnswerQuestionButtonVifTextAndIcon="1"
                :qIndex="quizIndex"
                :qText="singleQandA.questionText"
                :qAndAArray="qAndA"
                :propertyId="propId"
                :singleQandA="singleQandA"
                :userInfo="userInfo"
              />
            </div>
            <div v-else><Whoops whoopsButtonVifTextAndIcon="8.5" /></div>

            <!-- END "Answer a Question" button beneath a question, with v-if logged in or "Whoops" 8.5 -->

            <ShareButtonDD shareButtonDDStyle="2.2" />
            <button class="text-xs sm:text-base">
              <fa :icon="['far', 'flag']" />&nbsp;<span
                class="hidden text-xs md:inline"
                >Flag</span
              >
            </button>
          </div>

          <div
            class="inline-block w-3/5 text-slate-500 sm:w-1/2 md:ml-10 md:w-2/5"
          >
            <p
              class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              v-if="singleQandA.createdAt"
            >
              {{
                formatDistanceToNow(
                  new Date(singleQandA.createdAt.seconds * 1000)
                )
              }}
              ago
            </p>

            <div v-if="singleQandA.askedBy === 'zagnetic'">
              <p
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                by <span class="zagnetic !text-sm">zagnetic</span>
              </p>
              <div class="float-right inline-flex">
                <p
                  class="mr-0.5 text-right text-xs italic leading-5 md:text-sm md:leading-normal"
                >
                  standard question
                </p>
                <button class="mb-0.5 ml-0">
                  <Whoops whoopsButtonVifTextAndIcon="6" />
                </button>
              </div>
            </div>
            <div v-else>
              <!-- BEGIN link to question creator's public profile v-if privacy == postFullPublic, otherwise no link -->
              <div v-if="singleQandA.privacy == 'postFullPublic'">
                <router-link
                  :to="{
                    name: 'PublicProfile',
                    params: { id: singleQandA.userId },
                  }"
                >
                <p
                  class="text-right text-xs leading-5 md:text-sm md:leading-normal"
                >
                  by
                  <span class="text-sm italic underline">{{ singleQandA.askedBy }}</span>
                </p>
                </router-link>
              </div>
              <div v-else>
                <p
                  class="text-right text-xs leading-5 md:text-sm md:leading-normal"
                >
                  by
                  <span class="text-sm italic">{{ singleQandA.askedBy }}</span>
                </p>
              </div>
              <!-- END link to question creator's public profile v-if privacy == postFullPublic, otherwise no link -->
              
              <!-- BEGIN conditional text depending on isRealEstateAgent status -->
              <div
                v-if="singleQandA.isRealEstateAgent != 'no'"
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                <p class="text-sm">
                  <span
                    v-if="singleQandA.isRealEstateAgent == 'verified'"
                    class="text-zagblue ml-0 text-xs leading-5 sm:text-sm md:leading-normal"
                    >verified<sup
                      >&thinsp;<i class="fas fa-check-circle"></i></sup
                  ></span>
                  <span
                    v-if="singleQandA.isRealEstateAgent == 'unverified'"
                    class="text-sm"
                    >unverified</span
                  >&nbsp;real estate agent {{ singleQandA.isRealEstateAgent }}
                </p>
              </div>
              <!-- END conditional text depending on isRealEstateAgent status -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-1 md:col-span-1"></div>
    </div>
    <!--END question 1 -->

    <!-- BEGIN answer 1 -->
    <div
      v-if="singleQandA.answers.length > 0"
      :key="singleQandA.answers.length"
    >
      <div
        class="mt-1 grid grid-cols-12 gap-3 py-1 px-0.5 sm:py-2 sm:px-0.5 md:gap-0.5 md:px-0"
        v-for="(answerItem, answerIndex) in singleQandA.answers"
        :key="`answer-${answerIndex}`"
      >
        <div class="col-span-1 md:col-span-1"></div>

        <!-- <div class="col-span-1 -space-y-1.5 -mt-1.5 mr-2 md:-mr-6"> -->
        <div class="col-span-1 -mt-1.5 ml-0.5 -space-y-1.5 sm:mr-1 lg:-mr-2">
          <!-- BEGIN upvote / downvote this item -->

          <div class="flex items-center justify-center">
            <div v-if="user">
              <button
                class="place-self-center text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
                @click="
                  upvoteAnswer(
                    `${answerItem.userId}_${quizIndex}_${answerIndex}`,
                    quizIndex,
                    answerIndex,
                    answerItem.upvoteCount,
                    qAndA,
                    true,
                    $event
                  )
                "
              >
                <i class="fas fa-caret-up"></i>
              </button>
            </div>
            <div v-else><Whoops whoopsButtonVifTextAndIcon="26" /></div>
          </div>
          <div class="flex items-center justify-center">
            <p
              class="text-center text-sm text-slate-500 sm:text-base"
              :id="`${answerItem.userId}_${quizIndex}_${answerIndex}`"
            >
              {{ answerItem.upvoteCount }}
            </p>
          </div>
          <div class="flex items-center justify-center">
            <div v-if="user">
              <button
                class="text-3xl text-slate-500 hover:text-slate-400 focus:text-amber-500 active:text-amber-500"
                @click="
                  upvoteAnswer(
                    `${answerItem.userId}_${quizIndex}_${answerIndex}`,
                    quizIndex,
                    answerIndex,
                    answerItem.upvoteCount,
                    qAndA,
                    false,
                    $event
                  )
                "
              >
                <i class="fas fa-caret-down"></i>
              </button>
            </div>
            <div v-else><Whoops whoopsButtonVifTextAndIcon="27" /></div>
          </div>

          <!-- END upvote / downvote this item -->
        </div>

        <div class="col-span-10 grid md:col-span-10 md:mr-2.5">
          <div
            class="row-span-1 rounded-lg bg-slate-200 p-2 sm:p-4 lg:rounded-xl"
          >
            <p class="qatext text-zagslate text-left">
              {{ answerItem.answerText }}
            </p>
          </div>

          <div
            class="space-between-96 row-span-1 ml-2.5 mt-1.5 inline-flex items-start px-0.5 sm:px-1.5"
          >
            <div
              class="inline-flex w-2/5 space-x-5 text-slate-500 sm:w-1/2 md:w-3/5 lg:space-x-8"
            >
              <ShareButtonDD shareButtonDDStyle="3" />
              <button class="text-sm md:text-base">
                <fa :icon="['far', 'flag']" />
              </button>
            </div>

            <div class="inline-block w-3/5 text-slate-500 sm:w-1/2 md:w-2/5">
              <p
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                {{
                  formatDistanceToNow(
                    new Date(answerItem.createdAt.seconds * 1000)
                  )
                }}
                ago
              </p>
              <!-- BEGIN link to answer creator's public profile v-if privacy == postFullPublic, otherwise no link -->
              <div v-if="answerItem.privacy == 'postFullPublic'">
                <router-link
                  :to="{
                    name: 'PublicProfile',
                    params: { id: answerItem.userId },
                  }"
                >
                <p
                  class="text-right text-xs leading-5 md:text-sm md:leading-normal"
                >
                  by
                  <span class="text-xs font-medium italic md:text-sm underline"
                    >{{ answerItem.answeredBy }}
                  </span>
                </p>
                </router-link>
              </div>
              <div v-else>
                <p
                  class="text-right text-xs leading-5 md:text-sm md:leading-normal"
                >
                  by
                  <span class="text-xs font-medium italic md:text-sm"
                    >{{ answerItem.answeredBy }}
                  </span>
                </p>
              </div>
              <!-- END link to answer creator's public profile v-if privacy == postFullPublic, otherwise no link -->

              <!-- BEGIN conditional text depending on isRealEstateAgent status -->
              <div
                v-if="answerItem.isRealEstateAgent != 'no'"
                class="text-right text-xs leading-5 md:text-sm md:leading-normal"
              >
                <p class="text-sm">
                  <span
                    v-if="answerItem.isRealEstateAgent == 'verified'"
                    class="text-zagblue ml-0 text-xs leading-5 sm:text-sm md:leading-normal"
                    >verified<sup
                      >&thinsp;<i class="fas fa-check-circle"></i></sup
                  >&nbsp;real estate agent</span>
                  <span
                    v-if="answerItem.isRealEstateAgent == 'unverified'"
                    class="text-sm"
                    >unverified&nbsp;real estate agent</span
                  >
                </p>
              </div>
              <!-- END conditional text depending on isRealEstateAgent status -->
            </div>
          </div>
        </div>
      </div><hr class="my-5 text-center w-4/5 mx-auto"/>
    </div>
    <div v-else>
      <div class="text-zagblue pb-4 text-center text-sm italic">
        -&nbsp;no answers yet&nbsp;-
      </div> <hr class="my-5 text-center w-4/5 mx-auto"/>
    </div>

    <!-- END answer 1 -->
  </div>
</template>

<script>
import { ref } from "vue";
import ShareButtonDD from "@/components/ShareButtonDD.vue";
import Whoops from "@/components/Whoops.vue";
import AskAnswerQuestion from "@/components/AskAnswerQuestion.vue";
import getUser from "../composables/getUser";
import { formatDistanceToNow, format } from "date-fns";

import useDocument from "@/composables/useDocument";
import useCollection from "../composables/useCollection";

export default {
  props: {
    // propertyId data is from PropertyLanding.vue (hard-coded real propertyId), being passed-into IndivQandAnswer.vue component
    propertyId: {
      type: String,
      required: true,
    },
    propertyQandA: {
      type: Array,
      required: true,
    },
    singleQandA: {
      type: Object,
      required: true,
    },
    quizIndex: {
      type: Number,
      required: true,
    },
    userInfo: [Object, null],
  },

  components: {
    Whoops,
  },

  setup(props, context) {
    // accepting props as an argument, so props (propertyId) can be used below in setup()
    const { updateQAndAItems, error } = useDocument(
      "properties",
      props.propertyId
    );
    const { updateDocById, error:userDocErr, getDocById, document:userDoc } = useCollection('users');
    const { user } = getUser();
    const propId = ref(props.propertyId);
    const qAndA = ref(props.propertyQandA);
    const singleQandA = ref(props.singleQandA);
    // const userInfo = ref(props.userInfo);
    // console.log("singleQandA", props);
    const upvoteAnswer = async (
      id,
      quizIndex,
      answerIndex,
      previousCount,
      oldQandA,
      isIncrement,
      event
    ) => {
      // new code
      await getDocById(user.value.uid);
      if(userDocErr.value == null) {
        let indexOfAlreadyVoted = null;
        let oldAnswerVotes = [...userDoc.value.answerVotes];
        let quizText = oldQandA[quizIndex].questionText;
        let answerText = oldQandA[quizIndex].answers[answerIndex].answerText;
        
        indexOfAlreadyVoted = oldAnswerVotes.findIndex(element => element.propertyId === propId.value && element.quizText === quizText && element.answerText === answerText);
        
        if(indexOfAlreadyVoted !== null && indexOfAlreadyVoted !== -1) {
          const getPrevVote = oldAnswerVotes[indexOfAlreadyVoted].vote;
          const newVote = (isIncrement) ? 1 : -1;
          if(getPrevVote === newVote) {
            event.target.style.color = 'red';
            return false;
          } else {
            oldAnswerVotes[indexOfAlreadyVoted].vote = newVote;
            let updatedVotesObj = {};
            updatedVotesObj['answerVotes'] = [...oldAnswerVotes];
            await updateDocById(user.value.uid, updatedVotesObj);
          }

        } else {
          let newVoteObj = {};
          newVoteObj['propertyId'] = propId.value;
          newVoteObj['quizIndex'] = quizIndex;
          newVoteObj['vote'] = (isIncrement) ? 1 : -1;
          newVoteObj['quizText'] = quizText;
          newVoteObj['answerText'] = answerText;
          let updatedVotesObj = {};
          updatedVotesObj['answerVotes'] = [...oldAnswerVotes, newVoteObj];
          await updateDocById(user.value.uid, updatedVotesObj);
        }
      }
    // new code
      const updatedQandA = [...oldQandA];
      // console.log(updatedQandA);
      let newCount = +previousCount;
      if (isIncrement) {
        newCount = newCount + 1;
      } else {
        newCount = newCount - 1;
      }
      updatedQandA[quizIndex].answers[answerIndex].upvoteCount = newCount;
      console.log(updatedQandA[quizIndex].answers[answerIndex].upvoteCount);
      const updateUpvoteCount = await updateQAndAItems(updatedQandA);
      if (error.value === null) {
        qAndA.value = updatedQandA;
      }
    };

    const upvoteQuestion = async (
      id,
      quizIndex,
      previousCount,
      oldQandA,
      isIncrement,
      event
    ) => {
      
      await getDocById(user.value.uid);
      if(userDocErr.value == null) {
        let indexOfAlreadyVoted = null;
        let oldQuizVotes = [...userDoc.value.questionsVotes];
        let quizText = oldQandA[quizIndex].questionText;
        console.log(quizText);
        indexOfAlreadyVoted = oldQuizVotes.findIndex(element => element.propertyId === propId.value && element.quizText === quizText);
        
        if(indexOfAlreadyVoted !== null && indexOfAlreadyVoted !== -1) {
          const getPrevVote = oldQuizVotes[indexOfAlreadyVoted].vote;
          const newVote = (isIncrement) ? 1 : -1;
          if(getPrevVote === newVote) {
            event.target.style.color = 'red';
            return false;
          } else {
            oldQuizVotes[indexOfAlreadyVoted].vote = newVote;
            let updatedVotesObj = {};
            updatedVotesObj['questionsVotes'] = [...oldQuizVotes];
            await updateDocById(user.value.uid, updatedVotesObj);
          }

        } else {
          let newVoteObj = {};
          newVoteObj['propertyId'] = propId.value;
          newVoteObj['quizIndex'] = quizIndex;
          newVoteObj['vote'] = (isIncrement) ? 1 : -1;
          newVoteObj['quizText'] = quizText;
          let updatedVotesObj = {};
          updatedVotesObj['questionsVotes'] = [...oldQuizVotes, newVoteObj];
          await updateDocById(user.value.uid, updatedVotesObj);
        }
      }
      
      const updatedQandA = [...oldQandA];
      let newCount =
        typeof previousCount !== "undefined" && !isNaN(previousCount)
          ? +previousCount
          : 0;
      if (isIncrement) {
        newCount = newCount + 1;
      } else {
        newCount = newCount - 1;
      }
      updatedQandA[quizIndex].upvoteCount = newCount;
      
      await updateQAndAItems(updatedQandA);
      if (error.value === null) {
        // document.getElementById(id).innerHTML = newCount;
        qAndA.value = updatedQandA;
        await getDocById(user.value.uid);
        
        // if(userDocErr.value == null) {
        //   let oldQuizVotes = [...userDoc.value.questionsVotes];
        //   let newVoteObj = {};
        //   let quizText = oldQandA[quizIndex].questionText;
        //   newVoteObj['propertyId'] = propId.value;
        //   newVoteObj['quizIndex'] = quizIndex;
        //   newVoteObj['vote'] = (isIncrement) ? 1 : -1;
        //   newVoteObj['quizText'] = quizText;
        //   let updateVoteObj = {};
        //   updateVoteObj['questionsVotes'] = [...oldQuizVotes, newVoteObj];
        //   await updateDocById(user.value.uid, updateVoteObj);
        // }
      }
    };

    return {
      user,
      qAndA,
      singleQandA,
      propId,
      // userInfo,
      formatDistanceToNow,
      format,
      upvoteAnswer,
      upvoteQuestion,
    };
  },

  components: {
    ShareButtonDD,
    Whoops,
    AskAnswerQuestion,
  },

  data() {
    return {};
  },
};
</script>

<style></style>
