<!-- based on "Building a Tag Input Component..." via https://vueschool.io/articles/vuejs-tutorials/building-a-tag-input-component-with-the-vue-3-composition-api/ -->
<template>
  <div class="tag-input peer" :class="{ 'with-count': showCount }">
    <input
      v-model="newTag"
      type="text"
      :list="id"
      autocomplete="off"
      @keydown.prevent.enter="addTag(newTag)"
      @keydown.prevent.,="addTag(newTag)"
      @keydown.delete="newTag.length || removeTag(tags.length - 1)"
      :style="{ 'padding-left': `${paddingLeft}px` }"
    />
    <!-- removed @keydown.prevent.tab="addTag(newTag)" from immediately below @keydown.prevent.,="addTag(newTag)" -->
    <!-- removed class="peer placeholder-transparent focus:placeholder-transparent hover:placeholder-transparent" from immediately beneath placeholder="separate , with , commas" -->
    <!-- removed placeholder="separate , with , commas" AND class="placeholder-transparent focus:placeholder-transparent hover:placeholder-transparent active:placeholder-transparent" from immediately below  :style="{ 'padding-left': `${paddingLeft}px` }" -->

    <!-- <label for="propPros" class="pt-6 sm:pt-0 absolute transition-all duration-500 ease-in-out pl-1 sm:pl-0
                  
        peer-placeholder-shown:bg-red-200 peer-placeholder-shown:text-gray-600 peer-placeholder-shown:left-4 peer-placeholder-shown:top-[-1.1rem] peer-placeholder-shown:opacity-80 peer-placeholder-shown:text-base 
        peer-focus:bg-amber-200 peer-focus:text-zagblue peer-focus:top-[-3.3rem] peer-focus:left-1 peer-focus:text-sm
        bg-emerald-200 text-zagblue sm:left-0 top-[-3.3rem] left-1 text-sm       
        visited:bg-gray-800 

      ">ZZZseparate , with , commas</label> -->

    <datalist v-if="options" :id="id">
      <option v-for="option in availableOptions" :key="option" :value="option">
        {{ option }}
      </option>
    </datalist>

    <ul class="tags" ref="tagsUl">
      <li
        v-for="(tag, index) in tags"
        :key="tag"
        class="tag"
        :class="{ duplicate: tag === duplicate }"
      >
        {{ tag }}
        <button class="delete" @click="removeTag(index)">
          <fa icon="times-circle" class="mx-0.5 text-lg text-gray-700" />
        </button>
      </li>
    </ul>
    <div v-if="showCount" class="count">
      <span>{{ tags.length }}</span> tags
    </div>
  </div>
</template>
<script>
import { ref, watch, nextTick, onMounted, computed } from "vue";
export default {
  props: {
    name: { type: String, default: "" },
    modelValue: { type: Array, default: () => [] },
    options: { type: [Array, Boolean], default: false },
    allowCustom: { type: Boolean, default: true },
    showCount: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    // Tags
    const tags = ref(props.modelValue);
    const newTag = ref("");
    const id = Math.random().toString(36).substring(7);

    const addTag = (tag) => {
      if (!tag) return; // prevent empty tag
      if (tag === " ") return;
      // only allow predefined tags when allowCustom is false
      if (!props.allowCustom && !props.options.includes(tag)) return;
      // return early if duplicate
      if (tags.value.includes(tag)) {
        handleDuplicate(tag);
        return;
      }
      tags.value.push(tag);
      newTag.value = ""; // reset newTag
    };
    const removeTag = (index) => {
      tags.value.splice(index, 1);
    };

    // handling duplicates
    const duplicate = ref(null);
    const handleDuplicate = (tag) => {
      duplicate.value = tag;
      setTimeout(() => (duplicate.value = null), 1000);
      newTag.value = "";
    };

    // positioning and handling tag change
    const paddingLeft = ref(12);
    const tagsUl = ref(null);
    const onTagsChange = () => {
      // position cursor
      const extraCushion = 15;
      paddingLeft.value = tagsUl.value.clientWidth + extraCushion;
      // scroll to end of tags
      tagsUl.value.scrollTo(tagsUl.value.scrollWidth, 0);
      // emit value on tags change
      emit("update:modelValue", tags.value);
    };
    watch(tags, () => nextTick(onTagsChange), { deep: true });
    onMounted(onTagsChange);

    // options
    const availableOptions = computed(() => {
      if (!props.options) return false;
      return props.options.filter((option) => !tags.value.includes(option));
    });

    return {
      tags,
      newTag,
      addTag,
      removeTag,
      paddingLeft,
      tagsUl,
      availableOptions,
      id,
      duplicate,
    };
  },
};
</script>
<style scoped>
.tag-input {
  position: relative;
}
ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  max-width: 75%;
  overflow-x: auto;
}
.tag {
  background: rgb(229, 231, 235);
  padding: 4px;
  margin-right: 3px;
  border-radius: 5px;
  color: rgb(55, 65, 81);
  white-space: nowrap;
  transition: 0.1s ease background;
}
input {
  width: 100%;
  padding: 10px;
  border-radius: 0.5rem;
  border-color: #d1d5db;
  @media (min-width: 768px) {
    width: 100%;
  }
}
input::placeholder {
  color: rgba(52, 58, 64, .8);
  display: none;
}

.delete {
  color: white;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
}
@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
.tag.duplicate {
  background: rgb(220, 38, 38);
  animation: shake 1s;
}
.count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: block;
  font-size: 0.8rem;
  white-space: nowrap;
}
.count span {
  background: #eee;
  padding: 2px;
  border-radius: 2px;
}
.with-count input {
  padding-right: 60px;
}
.with-count ul {
  max-width: 60%;
}
</style>
