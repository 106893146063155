<template>
  <div
    class="bg-rounded mx-auto max-w-screen-md bg-white px-0 pt-4 pb-4 sm:bg-gray-50 sm:px-0 md:px-4"
  >
    <div class="prose">
      <h1 class="text-center text-xl font-bold">
        Digital Millennium Copyright Act
      </h1>
      <p class="text-center text-lg italic">
        In case something here infringes your copyright
      </p>
      <p>
        The Digital Millennium Copyright Act ("DMCA") helps resolve situations
        where online service providers are asked to remove material that
        allegedly violates someone's copyright.
      </p>
      <p>
        We respect others' intellectual property rights, and we reserve the
        right to delete or disable Content alleged to be infringing, and to
        terminate the accounts of repeat alleged infringers. Scroll down to
        review our complete Copyright Dispute Policy and learn how to report
        potentially infringing content.
      </p>

      <blockquote
        class="mx-24 rounded-xl bg-indigo-50 pt-4 pb-2 text-left font-medium shadow-md"
      >
        Resources from The U.S. Copyright Office:
        <ul class="list-none">
          <li>
            <a
              href="https://www.copyright.gov/legislation/dmca.pdf"
              target="_blank"
              class="underline hover:text-gray-400"
              >Digital Millennium Copyright Act ("DMCA")</a
            >
          </li>
          <li>
            <a
              href="https://www.copyright.gov/what-is-copyright/"
              target="_blank"
              class="italic underline hover:text-gray-400"
              >"What is Copyright?"</a
            >
          </li>
        </ul>
      </blockquote>

      <h3 class="">Copyright Dispute Policy</h3>
      <p class="italic">Last Updated: October 19, 2021</p>
      <p>
        In accordance with the DMCA, Zagnetic ("Company") adopted the policy
        below toward copyright infringement. We reserve the right to (1) block
        access to or remove material that we believe in good faith to be
        copyrighted material that has been illegally copied and distributed by
        any of our advertisers, affiliates, content providers, members or users
        and (2) remove and discontinue service to repeat offenders.
      </p>
      <p>
        Remember that your use of Zagnetic's Services is at all times subject to
        the Terms of Use, which incorporates this Copyright Dispute Policy. Any
        terms we use in this Policy without defining them have the definitions
        given to them in the Terms of Use.
      </p>
      <p>
        (1) Procedure for Reporting Copyright Infringements. If you believe that
        material or content residing on or accessible through the Services
        infringes your copyright (or the copyright of someone whom you are
        authorized to act on behalf of), please send a notice of copyright
        infringement containing the following information to the Zagnetic's
        Designated Agent to Receive Notification of Claimed Infringement (our
        "Designated Agent," whose contact details are listed below):
      </p>
      <ul>
        <li>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of the copyright that has been allegedly
          infringed;
        </li>
        <li>Identification of works or materials being infringed;</li>
        <li>
          Identification of the material that is claimed to be infringing
          including information regarding the location of the infringing
          materials that the copyright owner seeks to have removed, with
          sufficient detail so that Company is capable of finding and verifying
          its existence; Contact information about the notifier including
          address, telephone number and, if available, email address;
        </li>
        <li>
          A statement that the notifier has a good faith belief that the
          material identified in (1)(c) is not authorized by the copyright
          owner, its agent, or the law; and
        </li>
        <li>
          A statement made under penalty of perjury that the information
          provided is accurate and the notifying party is authorized to make the
          complaint on behalf of the copyright owner.
        </li>
      </ul>

      <p>
        (2) Once Proper Bona Fide Infringement Notification is Received by the
        Designated Agent. Upon receipt of a proper notice of copyright
        infringement, we reserve the right to:
      </p>

      <ul>
        <li>remove or disable access to the infringing material;</li>
        <li>
          notify the content provider who is accused of infringement that we
          have removed or disabled access to the applicable material; and
        </li>
        <li>
          terminate such content provider's access to the Services if he or she
          is a repeat offender.
        </li>
      </ul>

      <p>
        (3) Procedure to Supply a Counter-Notice to the Designated Agent. If the
        content provider believes that the material that was removed (or to
        which access was disabled) is not infringing, or the content provider
        believes that it has the right to post and use such material from the
        copyright owner, the copyright owner's agent, or, pursuant to the law,
        the content provider may send us a counter-notice containing the
        following information to the Designated Agent:
      </p>

      <ul>
        <li>A physical or electronic signature of the content provider;</li>
        <li>
          Identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or disabled;
        </li>
        <li>
          A statement that the content provider has a good faith belief that the
          material was removed or disabled as a result of mistake or
          misidentification of the material; and
        </li>
        <li>
          Content provider's name, address, telephone number, and, if available,
          email address, and a statement that such person or entity consents to
          the jurisdiction of the Federal Court for the judicial district in
          which the content provider's address is located, or, if the content
          provider's address is located outside the United States, for any
          judicial district in which Company is located, and that such person or
          entity will accept service of process from the person who provided
          notification of the alleged infringement.
        </li>
      </ul>

      <p>
        If a counter-notice is received by the Designated Agent, Company may, in
        its discretion, send a copy of the counter-notice to the original
        complaining party informing that person that Company may replace the
        removed material or cease disabling it in 10 business days. Unless the
        copyright owner files an action seeking a court order against the
        content provider accused of committing infringement, the removed
        material may be replaced or access to it restored in 10 to 14 business
        days or more after receipt of the counter-notice, at Company's
        discretion
      </p>

      <p class="font-bold">
        Please contact Company's Designated Agent at the following address:
        Zagnetic, Box 91 Mendham, NJ 07945
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<!-- https://www.rexhomes.com/terms-and-privacy/copyright -->
<style></style>
