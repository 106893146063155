<template v-if="fetchedImages.length > 0">
  <div v-if="displayCode == '1'">
    <hr class="my-8" />

    <h2 class="headinggg scroll-mt-12" id="ppphotos">Photos & video</h2>
    <p class="username subheadinggg mb-6">by the reviewer</p>
    <p
      v-if="fetchedImages.length > 0"
      class="mb-6 -mt-3 text-sm font-light italic"
    >
      {{ fetchedImages.length }} images&thinsp;:
    </p>
  </div>

  <div
    v-if="displayCode == '1' || displayCode == '0'"
    class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2"
  >
    <img
      v-for="(img, index) in fetchedImages"
      :src="img.imgPath"
      :key="'image' + index"
      alt="image of the property uploaded by the reviewer"
      class="w-full rounded-sm hover:w-[800px]"
    />
  </div>

  <!-- BEGIN displayCode == '2' (EditReview - to show a "delete" button or X-in-a-circle symbol next to each image) -->
  <div
    v-else-if="displayCode == '2'"
    class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2"
  >
    <img
      v-for="(img, index) in fetchedImages"
      :src="img.imgPath"
      :key="'image' + index"
      alt="image of the property uploaded by the reviewer"
      class="w-full rounded-sm hover:w-[800px]"
    />
  </div>
  <!-- END displayCode == '2' (EditReview - to show a "delete" button or X-in-a-circle symbol next to each image) -->

  <!-- BEGIN displayCode == '2.22' LINE 887 of EditReview (EditReview - to show a single column of images with a "delete" button or X-in-a-circle symbol next to each image) -->
  <div v-else-if="displayCode == '2.22'">
    <div v-if="fetchedImages.length > 0" :key="fetchedImages.length">
      <div
        v-for="(image, index) in fetchedImages"
        :key="'image' + index"
        class="grid grid-cols-12 gap-0 sm:gap-6"
      >
        <div class="col-span-10 pb-12 sm:col-span-10">
          <img
            :src="image.imgPath"
            :key="'image' + index"
            alt="image of the property uploaded by the reviewer"
            class="rounded-lg"
          />
        </div>
        <div class="col-span-2 mx-auto italic sm:col-span-2">
          <button
            class="changebtn !px-0.5 sm:!px-2 sm:!pb-1.5"
            @click="removeImage(image.dbPath)"
          >
            remove
            <fa
              icon="times-circle"
              class="pt-0.5 text-base"
              alt="X icon in a circle"
            />
          </button>
        </div>
      </div>
    </div>
    <!-- 
    <div class="col-span-10">Image will go here</div>
    <div class="col-span-2">button</div> -->

    <!-- <img
      v-for="(img, index) in fetchedImages"
      :src="img"
      :key="'image' + index"
      alt="image of the property uploaded by the reviewer"
      class="w-full rounded-sm hover:w-[800px]"
    /> -->
    <form @submit.prevent="handleImageUpload">
      <div class="mx-20 pt-4 pb-2 sm:mx-40 md:mx-56 lg:mx-60">
        <!-- BEGIN new file input button -->

        <div class="text-center text-sm font-normal text-gray-500">
          <label for="image_uploads" class="mx-auto"
            ><div class="changebtn mx-auto !ml-0 !px-5 !py-2.5 !text-sm">
              Add image(s)
              <fa
                icon="camera-retro"
                size="lg"
                alt="camera icon"
                class="mx-1"
              />
            </div>
          </label>
          <input
            type="file"
            id="image_uploads"
            name="image_uploads"
            accept="image/*"
            @change="handleChange"
            multiple
            class="invisible text-pink-400"
          />
          <div
            class="-mt-2 text-center text-xs font-light text-gray-500 opacity-90"
          >
            PNG, JPG, JPEG, HEIC - up to 10&thinsp;MB
          </div>
          <!-- class="invisible" hides default "browse..." + file name -->
          <!-- <div class="error">{{ fileError }}</div>
          <div class="success text-progreen py-2.5">{{ fileSuccess }}</div> -->

          <ul class="mb-4 list-disc">
            <div v-for="name in imageNames" v-bind:key="name" class="">
              <li class="my-1.5 text-xs italic text-indigo-900">{{ name }}</li>
            </div>
          </ul>
        </div>

        <!-- END new file input button -->
      </div>

      <div class="error text-center">{{ fileError }}</div>
      <div
        class="success animate-fade py-2.5 text-center"
        v-if="isUploadStarted && uploadedCount == 0"
      >
        File upload in progress...
      </div>
      <div
        class="success text-progreen animate-fade py-2.5 text-center"
        v-if="isUploadStarted && uploadedCount > 0"
      >
        Success! &thinsp;{{ uploadedCount }} file<span
          v-if="uploadedCount > 1"
          class="text-progreen"
          >s</span
        >
        uploaded out of {{ totalUpload }}.
      </div>
    </form>
  </div>
  <!-- END displayCode == '2.22' LINE 887 of EditReview (EditReview - to show a single column of images with a "delete" button or X-in-a-circle symbol next to each image) -->
</template>

<script>
import useStorage from "@/composables/useStorage";
import useCollection from "../composables/useCollection";
import useSubCollection from "../composables/useSubCollection";
import { ref, onMounted, watch, watchEffect } from "vue";

import { Agile } from "vue-agile-lite";

export default {
  props: {
    filesPath: {
      type: Array,
    },
    displayCode: { type: String }, // indictes what (if any) text to display from ReviewMedia component ("0" mean show no text at all)
    review_id: String,
    propertyId: String,
  },

  components: {
    agile: Agile,
  },

  setup(props, context) {
    const {
      getImages,
      fetchedImages,
      deleteImage,
      error: storageErr,
      filePath,
      uploadImage,
      bulkUpload,
      isUploadStarted,
      totalUpload,
      uploadedCount,
      getImagesWithPath,
      uploadedFilesArray,
    } = useStorage();
    const {
      error: parentDocErr,
      document: parentDoc,
      getDocById,
      updateDocById,
    } = useCollection("properties");
    const { updateSubDoc, subCollectionError } = useSubCollection(
      "reviews",
      "properties"
    );
    const filesPath = ref(props.filesPath);
    const displayCode = props.displayCode;

    const review_id = ref(props.review_id);
    const propertyId = ref(props.propertyId);

    const imageNames = ref(null);
    const imageFiles = ref(null);
    const selectedImages = ref([]);
    const fileError = ref(null);
    const fileSuccess = ref(null);
    const totalSelectedFiles = ref(0);

    const removeImage = async (imagePath) => {
      // console.log(imagePath);
      const confirmDelete = confirm("Remove this image?");
      if (confirmDelete == false) {
        return false;
      }
      await deleteImage(imagePath);
      if (storageErr.value == null) {
        const getOldImageArray = [...filesPath.value];
        const updatedImageArray = getOldImageArray.filter(
          (item) => item !== imagePath
        );
        const updateItemObject = {};
        updateItemObject["filePath"] = updatedImageArray;
        // console.log(updateItemObject); return false;
        updateImagesInDb(updateItemObject, updatedImageArray, true);
      }
    };

    const updateImagesInDb = async (
      updateItemObject,
      updatedImageArray,
      reloadImages = false
    ) => {
      await updateSubDoc(propertyId.value, review_id.value, updateItemObject);
      if (subCollectionError.value == null) {
        console.log("document updated successfully");
        await getDocById(propertyId.value);
        if (parentDocErr.value == null) {
          console.log("parentDoc--", parentDoc);
          const allReview = [...parentDoc.value.allReviews];
          console.log("old allreviwe-- ", allReview);
          let getExactReviewId = allReview.findIndex(function (element) {
            return element["review_id"] == review_id.value;
          });
          allReview[getExactReviewId]["filePath"] = updatedImageArray;
          const updateReview = {};
          updateReview["allReviews"] = allReview;
          await updateDocById(propertyId.value, updateReview);
        }

        filesPath.value = updatedImageArray;
        fetchedImages.value = [];
        if (reloadImages) {
          getImagesWithPath(filesPath.value);
        }

        // fileSuccess.value = "Success! Images have been updated.";
      }
    };

    const types = ["image/png", "image/jpeg", "image/webp", "image/heic"];
    imageFiles.value = [];
    imageNames.value = [];
    const handleChange = async (e) => {
      let allImages = e.target.files;
      for (let i = 0; i < allImages.length; i++) {
        if (!types.includes(allImages[i].type)) {
          file.value = null;
          fileError.value =
            "Please select an image file (png, heic, webp, jpg or jpeg)";
          return false;
        } else {
          imageNames.value.push(allImages[i].name);
          imageFiles.value.push(allImages[i]);
        }
      }

      if (imageFiles.value.length > 0) {
        // await imageFiles.value.forEach((file) => {
        // uploadImage(file, "review_images", propertyId.value);
        //   selectedImages.value.push(filePath.value);
        // });
        totalSelectedFiles.value = imageFiles.value.length;
        bulkUpload([...imageFiles.value], "review_images", propertyId.value);

        const updatedImageArray = [
          ...filesPath.value,
          ...uploadedFilesArray.value,
        ];
        console.log("updated image array--", updatedImageArray);
        const updateItemObject = {};
        updateItemObject["filePath"] = updatedImageArray;
        updateImagesInDb(updateItemObject, updatedImageArray, false);
        selectedImages.value = [];
        imageFiles.value = [];
        imageNames.value = [];
      }
    };

    onMounted(() => {
      getImagesWithPath(filesPath.value);
      console.log("fetched Images", fetchedImages);
    });

    // watch(filesPath, async(oldVal, newVal) => {
    //   console.log('watch executed');
    //   getImages(filesPath.value);
    //   console.log(fetchedImages);
    // });

    watch(uploadedCount, async (oldVal, newVal) => {
      fetchedImages.value = [];
      // if(newVal == totalSelectedFiles.value) {
      getImagesWithPath(filesPath.value);
      // }
    });

    return {
      fetchedImages,
      // displayCode,
      filesPath,
      removeImage,
      imageNames,
      imageFiles,
      handleChange,
      fileError,
      fileSuccess,
      isUploadStarted,
      totalUpload,
      uploadedCount,
    };
  },
};
</script>

<style scoped></style>
