<template>
  <!-- <div class="bg-red-200 sm:bg-amber-200 md:bg-blue-200 lg:bg-emerald-200 xl:bg-violet-200 2xl:bg-pink-200 pt-4 lg:mx-24 lg:px-8"> -->
  <div id="top" class="scroll-mt-32">
    <div
      class="absolute inset-0 z-10 mt-24 h-[24.5%] w-full bg-slate-900 lg:h-[34.5%]"
    >
      <div class="sm:my-2 lg:my-16 2xl:m-12">
        <img
          src="@/assets/images/logo-for-dark-bg.svg"
          alt="Zagnetic logo"
          class="mx-auto mt-6 w-1/2 pt-6 shadow-sm sm:w-2/5 md:w-1/3 xl:w-1/4"
        />
        <h2
          class="py-8 text-center text-xl font-medium leading-relaxed text-indigo-50 shadow-sm lg:text-2xl"
        >
          for Real Estate Pros
        </h2>
        <p class="py-6 text-center text-lg text-indigo-50">
          (&thinsp;coming soon&thinsp;)
        </p>
      </div>
    </div>
  </div>

  <div class="bg-gray-50 lg:mx-24 lg:px-8">
    <div class="mt-56 py-6 md:mt-64 lg:mt-96 lg:text-2xl"></div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import ShareButtonDD from "@/components/ShareButtonDD.vue";
import Whoops from "@/components/Whoops";

export default {
  components: {
    ShareButtonDD,
    Whoops,
  },

  setup() {
    const router = useRouter();

    const goHome = async () => {
      router.push({ name: "Home" });
    };

    return { goHome };
  },
};
</script>

<style></style>
