<template>
  <div
    class="mx-auto mt-12 mb-36 max-w-screen-sm rounded-lg bg-indigo-50 px-0 pt-4 pb-8 shadow-md ring-8 ring-indigo-300 ring-opacity-80 ring-offset-8 ring-offset-indigo-200 sm:mt-20 sm:px-0 md:px-4"
  >
    <div class="bg-indigo-50 px-2 sm:px-6">
      <p class="my-8 text-center underline underline-offset-4 decoration-1 text-2xl font-semibold">One last thing…</p>
      <p class="mt-8 mb-4 ml-0 sm:ml-2"><span class="font-semibold text-lg">(A)</span> Click the number below to copy it:</p>
      <p class="text-center">
        <button
          class="rounded-full break-all mx-8 sm:mx-4 px-4 py-2 sm:py-1.5 text-center font-semibold bg-white hover:text-slate-500 focus:bg-progreen/20 focus:text-progreen focus:ring focus:ring-progreen active:bg-progreen active:text-white active:ring-progreen"
          @click="toClipboard('K9' + user.uid)"
        >
          K9{{ user.uid }}
        </button>
      </p>

      <p class="mt-12 mb-2 ml-0 sm:ml-2"><span class="font-semibold text-lg">(B)</span> Paste it into the subject line of an email<span class="italic ml-2 pt-0.5 text-base opacity-80">(use your professional email address&thinsp;)</span>
      </p>

      <!-- <p class="mt-12 mb-2 ml-2"><span class="font-semibold text-lg">(B)</span> Paste it into the subject line of an email
      </p>
      <p class="italic ml-9 pb-8 pt-0.5 text-base opacity-80">(&thinsp;use your professional email address&thinsp;)</p> -->

      <p class="mt-16 mb-2 ml-0 sm:ml-2"><span class="font-semibold text-lg">(C)</span> Send the email to:<span class="font-semibold text-indigo-900 ml-2">verify@</span
        ><span class="zagnetic">zagnetic</span
        ><span class="font-semibold">.</span><span class="font-semibold text-indigo-900 ml-0.5">com</span>
      </p>

      <p class="text-sm italic ml-0 sm:ml-2 mt-12 opacity-80"><span class="font-semibold italic text-sm mr-1.5 underline opacity-80">Why?</span>To verify your professional email address</p>
    </div>

    <!-- <img src="@/assets/images/rounded-square-logo.svg" alt="Zagnetic rounded square logo" class="mx-auto w-1/3 md:w-1/4 py-6"> -->
  </div>

  <p class="text-center pb-8"><router-link :to="{ name: 'Home' }"
              ><button class="borderbtn">Done. &thinsp;Go Home <fa
              icon="arrow-right"
              class="ml-0.5"
            /></button></router-link></p>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import getUser from "@/composables/getUser";
import WaitingToLoad from "@/components/WaitingToLoad.vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";

export default {
  components: {
    WaitingToLoad,
  },

  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const { user } = getUser();

    // if(user.value !== null && typeof user.value.uid !== 'undefined') {
    //   router.push({ name: "Home" });
    // }

    return { user, toClipboard };
  },
};
</script>
