<template>
  <div>
    <Navbar />
    <!-- per 2:30 in lesson 141, this is intentionally placed above the <div class-"content"> Now seeing this error: "[vue/no-multiple-template-root] The template root requires exactly one element." Per StackOverflow (https://stackoverflow.com/q/64867504/1459653) it appears to be from the Vetur VSC plugin -->
    <div class="content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/TheNavbar.vue";
import Footer from "./components/TheFooter.vue";
export default {
  components: { Navbar, Footer },
};
</script>

<style>
/* .content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;
  } */
</style>
