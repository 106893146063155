// getDocument.js is a real-time listener to just one single document

import { watchEffect, ref } from "vue";
import { projectFirestore } from "../firebase/config";

//Not sure import below is rght - it's partly taken from getCollection.js
import { doc, onSnapshot } from "firebase/firestore"; // ### new for Firebase 8 to 9 refactoring

const getDocument = (collectionName, id) => {
  let document = ref(null);
  let error = ref(null);

  // register the firestore collection reference
  const docRef = doc(projectFirestore, collectionName, id);

  const unsub = onSnapshot(
    docRef,
    (doc) => {
      // console.log("Current data: ", doc.data());
      if (doc.data()) {
        document.value = { ...doc.data(), id: doc.id };
        error.value = null;
        // console.log("Current data: ", doc.data());
      } else {
        error.value = "that document does not exist";
      }
    },
    (err) => {
      console.log(err.message);
      error.value = "problem fetching the document";
    }
  );

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, document };
};

export default getDocument;
