<template>
  <div>
    <!-- Below, each updateUserInfoVersion number (1, 2, 3, 4 etc.) corresponds to a different pop-up alert button and message (see "BEGIN v-if case 1, 2 or 3" a few dozen lines below) -->

    <!-- BEGIN case 1 (update string - displayName, etc.) -->
    <div v-if="updateUserInfoVersion == 1">
      <div @click="openModal" class="">
        <button class="changebtn ">
          change<fa
            icon="edit"
            class="mx-1 text-sm opacity-80 hover:opacity-100"
          />
        </button>
      </div>
    </div>
    <!-- END case 1 (update string - displayName, etc.) -->

    <!-- BEGIN case 11 (update bool item) -->
    <div v-if="updateUserInfoVersion == 11">
      <div @click="openModal" class="">
        <button class="changebtn">
          change<fa
            icon="edit"
            class="mx-1 text-sm opacity-80 hover:opacity-100"
          />
        </button>
      </div>
    </div>
    <!-- END case 11 (update bool item) -->

    <!-- BEGIN case 2 - update profile photo (profilePhotoUrl) -->
    <div v-if="updateUserInfoVersion == 2">
      <div @click="openModal" class="">
        <button class="changebtn">
          update profile photo<fa
            icon="camera"
            class="mx-1 text-xs opacity-80 hover:opacity-100"
          />
        </button>
      </div>
    </div>
    <!-- END case 2 - update profile photo (profilePhotoUrl) -->

    <!-- BEGIN case 3 - "Delete My Account" -->
    <div v-if="updateUserInfoVersion == 3">
      <div @click="openModal" class="">
        <button class="changebtn">delete account</button>
      </div>
    </div>
    <!-- END case 3 - "Delete My Account" -->

    <!-- BEGIN HeadlessUI Dialogue code -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-1 text-center sm:px-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="via ingigo-50 my-8 inline-block w-full max-w-[18rem] transform overflow-hidden rounded-2xl bg-gradient-to-br from-white to-slate-100 px-3 py-4 text-center align-middle shadow-xl ring-8 ring-slate-300 ring-opacity-80 ring-offset-8 ring-offset-slate-200 transition-all sm:max-w-sm sm:p-6 sm:px-6"
              >
                <!-- BEGIN case 1 (update string, like displayName) -->
                <div v-if="updateUserInfoVersion == 1">
                  <DialogTitle
                    class="text-zagblue text-left text-lg font-medium sm:text-lg"
                    ><span class="mr-0.5 text-lg font-semibold underline"
                      >Update Info</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->
                  <!-- <div
                    class="row-span-1 -ml-1 inline-flex rounded-lg p-2.5 sm:-ml-2 md:-ml-8"
                  >
                    <p
                      class="qatext text-zagblue ml-0 text-left !text-lg !font-semibold !leading-normal md:ml-6"
                    >
                      Update {{ whatToUpdate }}
                    </p>
                  </div> -->

                  <div class="my-4 text-left">
                    <!-- <div v-if="user" class="my-4 text-left"> -->
                    <form
                      class="bg-slate-50/5"
                      @submit.prevent="handleUpdateSubmit"
                    >
                      <p class="mt-10 mb-2">Enter new {{ whatToUpdate }}</p>
                      <input
                        type="text"
                        id="whatToUpdate"
                        class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                        placeholder="Type new info here"
                        v-model="fieldValue"
                      />
                      <span class="error" v-if="updateErr">{{
                        updateErr
                      }}</span>
                      <!-- <hr class="my-8" /> -->

                      <button
                        v-if="!isPending"
                        class="text-zaggray mt-12 mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 1 (update string, like displayName) -->

                <!-- BEGIN case 11 (update bool) -->
                <div v-if="updateUserInfoVersion == 11">
                  <DialogTitle
                    class="text-zagblue text-left text-lg font-medium sm:text-lg"
                    ><span class="mr-0.5 text-lg font-semibold underline"
                      >Update Info</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->
                  <!-- <div
                    class="row-span-1 -ml-1 inline-flex rounded-lg p-2.5 sm:-ml-2 md:-ml-8"
                  >
                    <p
                      class="qatext text-zagblue ml-0 text-left !text-lg !font-semibold !leading-normal md:ml-6"
                    >
                      Update {{ whatToUpdate }}
                    </p>
                  </div> -->

                  <div class="my-4 text-left">
                    <!-- <div v-if="user" class="my-4 text-left"> -->
                    <form
                      class="bg-slate-50/5"
                      @submit.prevent="handleUpdateSubmit"
                    >
                      <p v-if="whatToUpdate == 'displayEmailOnPublicProfile'" class="mt-10 mb-2">Should we display your professional email on your public profile?</p>
                      <p v-else-if="whatToUpdate == 'displayPhoneOnPublicProfile'" class="mt-10 mb-2">Should we display your professional phone number on your public profile?</p>
                       <p v-else class="mt-10 mb-2">Update value for {{ whatToUpdate }}</p>
                      <div class="inline-flex">
                         <div class="ml-20 sm:ml-24 sm:pl-1">
                            <input
                              type="radio"
                              id="whatToUpdate"
                              value="true"
                              name="fieldValue"
                              required
                              v-model="fieldValue"
                            />
                            <label class="ml-2.5 pt-4 mt-3 inline-flex text-sm sm:text-base" for="unverified"
                              >Yes</label
                            >
                          </div>

                          <div class="ml-5">
                            <input
                              type="radio"
                              id="whatToUpdate"
                              value="false"
                              name="fieldValue"
                              required
                              v-model="fieldValue"
                            />
                            <label class="ml-2.5 mt-7 inline-flex text-sm sm:text-base" for="no"
                              >No</label
                            >
                          </div>
                      </div>
                      <!-- <input
                        type="text"
                        id="whatToUpdate"
                        class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                        placeholder="Type new info here"
                        v-model="fieldValue"
                      /> -->
                      <span class="error" v-if="updateErr">{{
                        updateErr
                      }}</span>
                      <!-- <hr class="my-8" /> -->

                      <button
                        v-if="!isPending"
                        class="text-zaggray mt-12 mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 11 (update bool) -->

                <!-- BEGIN case 2 - update profile photo (profilePhotoUrl) -->
                <div v-if="updateUserInfoVersion == 2">
                  <DialogTitle
                    class="text-zagblue text-left text-lg font-medium sm:text-lg"
                    ><span class="mr-0.5 text-lg font-semibold underline"
                      >Update Info</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->

                  <div class="my-4 text-left">
                    <!-- <div v-if="user" class="my-4 text-left"> -->
                    <form
                      class="bg-slate-50/5"
                      @submit.prevent="handleUpdateProfilePhotoSubmit"
                    >
                      <p class="mt-10 mb-2">Enter new {{ whatToUpdate }}</p>
                      <input
                        type="file"
                        name="profilePic"
                        @change="handleProfilePicUpload"
                      />
                      <div class="error" v-if="fileError">{{ fileError }}</div>
                      <button
                        v-if="!isPending"
                        class="text-zaggray mt-12 mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 2 - update profile photo (profilePhotoUrl) -->

                <!-- BEGIN case 3 - "Delete My Account" -->
                <div v-if="updateUserInfoVersion == 3">
                  <DialogTitle
                    class="text-left text-lg font-bold text-black underline sm:text-xl"
                    >Delete Your Account:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->

                  <div class="my-6 text-left">
                    <ul class="space-y-6">
                      <li
                        class="text-conred my-4 rounded-lg bg-amber-200 text-center font-semibold"
                      >
                        WARNING: This can not be undone
                      </li>
                      <li>
                        We believe users have a right to privacy, including the
                        "right to be forgotten."
                      </li>
                      <li>
                        If you delete your account, all of your votes, flags,
                        etc. and any data that connects you or your email or
                        phone to any content you created will be deleted.
                      </li>
                      <li>
                        However, some or all reviews, questions and answers you
                        created may continue to exist, with "ownership"
                        reassigned to our generic "Former user who no longer
                        exists on Zagnetic" account.
                      </li>
                      <li>
                        Note: It is possible some of your data will exist in
                        historical backups and/or customer service
                        communications or blogs or social media you may have
                        subscribed or connected to.
                      </li>
                    </ul>
                  </div>
                  <button
                    v-if="!isPending"
                    class="bg-conred hover:text-conred hover:ring-conred mx-auto mt-6 mb-8 rounded-lg py-1.5 px-6 text-lg text-white hover:bg-white hover:ring-1"
                    type="submit"
                  >
                    Delete my account forever
                  </button>
                  <button v-else disabled>Saving...</button>
                </div>
                <!-- END case 3 - "Delete My Account" -->

                <div class="float-right mt-1">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-sm font-normal text-gray-600 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    cancel
                    <fa
                      :icon="['far', 'times-circle']"
                      class="ml-1.5 text-xl"
                    />
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- END HeadlessUI Dialogue code -->
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useDocument from "@/composables/useDocument";
import getSetUserInfo from "@/composables/getSetUserInfo";
import useStorage from "@/composables/useStorage";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  props: {
    whichItemToUpdate: String,
    userId: String,
    updateUserInfoVersion: [Number, String],
    fieldValue: String,
    itemType: String,
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },

  setup(props, context) {
    const {
      updateErr,
      updateProfileField,
      updateAuthField,
      updateUserPassword,
    } = getSetUserInfo();
    const { filePath, url, uploadImage, getImages, fetchedImages, isUploadDone } =
      useStorage();
    const whatToUpdate = ref(props.whichItemToUpdate);
    const fieldValue = ref(props.fieldValue);
    const itemType = ref(props.itemType);
    const imageFiles = ref([]);
    const selectedImages = ref("");
    const fileError = ref(null);

    const whichAccountToUpdate = ref(props.userId);
    const isOpen = ref(false);
    const isPending = ref(false);

    //const displayName = ref("");

    // BEGIN handleUpdateSubmit
    const handleUpdateSubmit = async () => {
      console.log("hello user");
      if (itemType.value == "auth") {
        const updateItemObject = {};
        updateItemObject[whatToUpdate.value] = fieldValue.value;

        await updateAuthField(updateItemObject);
        if (updateErr.value === null) {
          isOpen.value = false;
        }
      } else if (itemType.value == "profile") {
        const updateItemObject = {};
        updateItemObject[whatToUpdate.value] = fieldValue.value;
        await updateProfileField(whichAccountToUpdate.value, updateItemObject);
        if (updateErr.value === null) {
          isOpen.value = false;
        }
      } else if (itemType.value == "authAndProfile") {
        const updateItemObject = {};
        updateItemObject[whatToUpdate.value] = fieldValue.value;
        await updateAuthField(updateItemObject);
        await updateProfileField(whichAccountToUpdate.value, updateItemObject);
        if (updateErr.value === null) {
          isOpen.value = false;
        }
      } else if (itemType.value == "authPass") {
        await updateUserPassword(fieldValue.value);
        if (updateErr.value === null) {
          isOpen.value = false;
        }
      }
    };

    const handleUpdateProfilePhotoSubmit = async () => {
      uploadImage(
        imageFiles.value[0],
        "profile_pics",
        whichAccountToUpdate.value
      );
      selectedImages.value = filePath.value;
      const updateItemObject = {};
      updateItemObject[whatToUpdate.value] = selectedImages.value;
      await updateProfileField(whichAccountToUpdate.value, updateItemObject);
      if (updateErr.value === null) {
        getImages([selectedImages.value]);

        // setTimeout(() => {
        //   console.log(fetchedImages.value[0]);
        //   if (fetchedImages.value.length > 0) {
        //     fieldValue.value = fetchedImages.value[0];
        //     console.log("fieldValue-", fieldValue.value);
        //   }
        // }, 2000);

        isOpen.value = false;
      }
      // BEGIN Mark attempt to "refresh" Account page after update, so new data appears
      window.location.reload();
      //END Mark attempt to "refresh" Account page after update, so new data appears
    };

    const handleProfilePicUpload = (e) => {
      const types = ["image/png", "image/jpeg", "image/webp", "image/heic"];
      let allImages = e.target.files;
      if (!types.includes(allImages[0].type)) {
        fileError.value =
          "Please select an image file (png, heic, webp, jpg or jpeg)";
        isPending.value = true;
        return false;
      } else {
        // imageNames.value.push(allImages[i].name);
        fileError.value = null;
        isPending.value = false;
        imageFiles.value.push(allImages[0]);
      }
    };

    // END handleUpdateSubmit

    watch(isUploadDone, async(newVal, oldVal) => {
      getImages([selectedImages.value]);
    });

    return {
      handleUpdateSubmit,
      handleProfilePicUpload,
      handleUpdateProfilePhotoSubmit,
      whatToUpdate,
      whichAccountToUpdate,
      fieldValue,
      updateErr,
      itemType,
      isPending,
      fileError,

      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>
