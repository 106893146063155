<template>
  <!-- <p class="my-8 bg-yellow-300">
    This v-if one or more extended_address are known to exist
  </p> -->
  <div v-if="searchedDocs.length < 0">
    <!-- changed by Mark from '> 0' to '< 0' so no scenarios meet this condition -->
    <p class="my-6 text-xl font-semibold text-red-500">
      Which of the following?
    </p>

    <div class="inline-flex">
      <p class="mt-8 mb-12 text-xl font-semibold">
        <span class="break-word mr-6 text-xl md:text-2xl">{{
          mainHeading
        }}</span
        ><button
          @click="goHome"
          class="border-zaggray text-zaggray -mt-2.5 rounded-md border bg-white px-2 py-1 text-xs hover:border hover:border-indigo-500 hover:bg-indigo-900 hover:text-white"
        >
          Change address
        </button>
      </p>
    </div>

    <div class="">
      <ul class="ml-6 space-y-8 font-bold">
        <li
          class="rounded-lg bg-indigo-50 pl-2.5 pb-2 text-xl"
          v-for="property in searchedDocs"
          :key="property.extended_address"
          @change="callMyDocs()"
        >
          {{ property.extended_address }}&emsp;
          <!-- <button class="borderbtn !hover:bg-indigo-200 mx-2">Reviews</button> -->
          <!-- <router-link
            to="/reviews/propertylanding"
            class="borderbtn !hover:bg-indigo-200 mx-2"
            >Reviews</router-link
          > -->
          <!-- <button class="borderbtn !hover:bg-indigo-200 mx-2">Q&A</button> -->
          <!-- <router-link
            to="/reviews/propertylanding#top-of-qna-section"
            class="borderbtn !hover:bg-indigo-200 mx-2"
            >Q&A</router-link
          > -->
          <button
            class="borderbtn !hover:bg-indigo-200 mx-2"
            @click="writeReview(`${property.extended_address}`)"
          >
            Write a Review<fa
              :icon="['fas', 'pencil-alt']"
              class="ml-1.5 text-sm"
            />
          </button>
        </li>

        <li class="rounded-lg bg-amber-100 pl-2 pb-2">
          None of the above
          <button class="borderbtn !hover:bg-indigo-200 mx-2">
            Add a new unit, apt, floor, etc.
          </button>
          <!-- <button @click="goHome" class="borderbtn !hover:bg-indigo-200 mx-2">
            Change address
          </button> -->
        </li>
        <li class="rounded-lg bg-lime-100 pl-2 pb-2">
          All of the above,
          <span class="font-bold italic"
            >combined<button class="mb-2.5 ml-0.5">
              <Whoops whoopsButtonVifTextAndIcon="10" /></button
          ></span>
          as a multi-family property
          <button class="borderbtn !hover:bg-indigo-200 mx-2">Reviews</button>
          <button class="borderbtn !hover:bg-indigo-200 mx-2">Q&A</button>
          <button class="borderbtn !hover:bg-indigo-200 mx-2">
            Write a Review<fa
              :icon="['fas', 'pencil-alt']"
              class="ml-1.5 text-sm"
            />
          </button>
        </li>
        <li class="rounded-lg bg-slate-100 pl-2 pb-2">
          All of the above,
          <span class="font-bold italic"
            >combined<button class="mb-2.5 ml-0.5">
              <Whoops whoopsButtonVifTextAndIcon="10" /></button
          ></span>
          as a commercial property
          <button class="borderbtn !hover:bg-indigo-200 mx-2">Reviews</button>
          <button class="borderbtn !hover:bg-indigo-200 mx-2">Q&A</button>
          <button class="borderbtn !hover:bg-indigo-200 mx-2">
            Write a Review<fa
              :icon="['fas', 'pencil-alt']"
              class="ml-1.5 text-sm"
            />
          </button>
          <br /><span class="text-sm font-normal italic"
            ><sup>*</sup>&thinsp;includes stores, warehouses, etc.</span
          >
        </li>
      </ul>

      <button
        class="my-20 ml-8 text-sm font-medium text-indigo-900 underline hover:text-gray-400"
        @click="goHome"
      >
        return to home page
      </button>
    </div>
  </div>
  <div v-else>
    <p class="mt-8 mb-10 break-words text-2xl font-bold">
      {{ searchStr }}
      <button
        @click="goHome"
        class="border-zaggray text-zaggray -mt-2.5 ml-6 rounded-md border bg-white px-2 py-1 text-xs hover:border hover:border-indigo-500 hover:bg-indigo-900 hover:text-white"
      >
        Change address
      </button>
    </p>

    <!-- <p class="font-base my-10 pl-2 text-lg italic text-slate-600">
      Hmm. We need a bit more info to show you the right property:
    </p> -->

    <form @submit.prevent="writeReviewIfNotFound">
      <!-- BEGIN "What kind of property" as a selection -->
      <div class="my-8 sm:my-16">
        <h3 class="question mb-4 ml-2 !text-lg sm:ml-6">
          What kind of property is it?<!--<span class="required">(required)</span>-->
        </h3>

        <div class="ml-2 space-y-0 sm:ml-6">
          <select
            required
            name="property_type"
            id="property_type"
            v-model="property_type"
            @change="toggleExtendedAddr(), callMyDocs()"
            class="text-zaggray rounded border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400"
          >
            <option value="">Please select</option>
            <option value="1_family">Single family house</option>
            <option value="condo">Condominium</option>
            <option value="coop">Cooperative</option>
            <option value="townhouse">Townhouse</option>
            <option value="rentalapartment">Apartment for rent</option>
            <!--<option value="mobilehome">Mobile home</option>-->
            <!-- was "Mobile home, yurt, etc." >> Removed this for now because it's difficult to separate the mobile home from the (often rented) lot where it's located -->
            <option value="multi_family">Multi-family (whole building)</option>
            <option value="commercial">Commercial</option>
            <option value="raw_land">Raw land</option>
          </select>
        </div>
      </div>
      <!-- END "What kind of property" as a selection -->

      <!-- BEGIN extended_address -->
      <div v-if="toggleExtendedAddrProp">
        <h3 class="question mb-2 sm:mb-4 ml-6 !text-lg">
          Please enter apartment, unit, suite, or floor #
        </h3>
        <p class="-mt-2 mb-2 ml-6 text-sm font-normal italic text-slate-600">
          <span class="animate-fade rounded-lg px-1.5 py-1 text-sm font-normal"
            >Leave blank if none</span
          >
        </p>
        <div>
          <input
            type="text"
            id="extended_address"
            class="focus:border-zagblue focus:ring-zagblue ml-6 w-1/3 rounded-lg border-gray-300 md:w-1/6"
            placeholder=""
            v-model="extended_address"
            @change="callMyDocs()"
          />
          <!-- Mark removed 'required' -->
        </div>
      </div>
      <!-- END extended_address -->

      <div v-if="property_type" class="mt-12 font-medium">
        <div v-if="propertyId && propertyHasReviews.length > 0" class="mt-12 font-medium bg-indigo-100 rounded-xl px-2 py-4">

          <p class="pb-2 sm:pb-4"><span class="animate-fade  text-xl sm:text-xl font-semibold underline decoration-pink-600">Success<fa
              :icon="['fas', 'check-circle']"
              class="ml-1.5 text-xl text-pink-700"
            /></span></p>

          <span class="pb-2 sm:pb-4 text-sm sm:text-base"
            >Click to go to this property's:<br /></span
          >
          <span class="mt-6 pb-2 sm:pb-4 text-sm sm:text-base"
            >
          <router-link
            v-if="propertyId"
            :to="{ name: 'PropertyLanding', params: { id: propertyId } }"
            class="borderbtn !hover:bg-indigo-200 !px-4 !sm:px-6"
            >Reviews</router-link
          >
          <router-link
            v-if="propertyId"
            :to="{
              name: 'PropertyLanding',
              params: { id: propertyId },
              el: '#top-of-qna-section',
            }"
            class="borderbtn !hover:bg-indigo-200 !px-4 mx-2 sm:mx-4"
            >Q&A</router-link
          >
          <button class="borderbtn !hover:bg-indigo-200 !px-4 !sm:px-6" type="submit">
            Write a Review<fa
              :icon="['fas', 'pencil-alt']"
              class="ml-1.5 text-sm"
            />
          </button>
          </span>
        </div>

        <div v-else class="mt-6 sm:mt-12 font-medium animate-fade p-2 rounded-md max-w-sm">
          
          <p class="mb-4 sm:mb-0 pb-0 sm:pb-4 text-lg sm:text-xl font-semibold"
            >No reviews for this property yet.</p
          >
          <p class="mb-4 sm:mb-0 font-semibold text-indigo-900"
            ><fa
                          icon="birthday-cake"
                          class="text-pink-600 text-2xl mr-2"
                          alt="plus sign icon in a circle"
                        /> Be the first to 
          <button class="borderbtn !border-indigo-900 !text-indigo-900 !bg-indigo-50 !hover:bg-indigo-200 mx-2" type="submit">
            Write a Review<fa
              :icon="['fas', 'pencil-alt']"
              class="ml-1.5 text-sm"
            />
          </button></p
          >
        </div>
      </div>
    </form>

    <!-- BEGIN "Nearby" mockup with a map -->
    
    

    <div v-if="property_type && matchingDocs.length">
        
        <hr class="my-6 sm:my-12" />
          <h1 class="pb-4 text-xl sm:text-xl font-semibold"><span class="pb-4 text-xl sm:text-xl font-semibold underline decoration-pink-600">Nearby</span> properties with reviews<span class="mx-0.5">/</span>Q&A:</h1>
          <div class="mx-auto mt-2.5 grid grid-cols-12 justify-between gap-6 pt-2.5 pb-6">
          <!-- BEGIN non-map Nearby content -->
          <!-- BEGIN v-for, display a  <Nearby/> with different info, for each nearby property -->
          <div class="col-span-12 sm:col-span-8 lg:col-span-6">
          <div class="mx-auto mt-2.5 grid grid-cols-12 lg:justify-between gap-6 rounded-md pb-6">
            <Nearby
              v-for="nearByLoc in matchingDocs"
              :singleLocation="nearByLoc"
              :key="nearByLoc.doc_id"
            />
          </div>
          </div>
            <!-- END v-for, display a  <Nearby/> with different info, for each nearby property -->
          <!-- END non-map Nearby content -->
        
          <!-- BEGIN map -->
          <div class="col-span-12 sm:col-span-4 lg:col-span-6 pt-2.5">
          <div class="w-full" v-if="propertyLat && propertyLng">
            <GoogleMap
                mapStyle="2"
                :latNumber="propertyLat"
                :lngNumber="propertyLng"
              />
          </div>
          <!-- END map -->
          </div>
          </div>
      
    </div>

  </div>
</template>

<script>
import useCollection from "@/composables/useCollection";
import getNearbyLocations from "@/composables/getNearbyLocations";
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import Whoops from "@/components/Whoops";
import Nearby from "@/components/Nearby.vue";
import { formatDistanceToNow } from "date-fns";
import GoogleMap from "@/components/GoogleMap.vue";

export default {
  props: ["str"],
  components: {
    Whoops,
    Nearby,
    GoogleMap,
  },

  setup(props, context) {
    const router = useRouter();
    const { getDocError, getDocsByQuery, getDocsByCompoundQuery } = useCollection("properties");
    const { error, getLocations, matchingDocs } =
      getNearbyLocations("properties");
    const searchedDocs = ref([]);
    const mainHeading = ref("");
    const propertyId = ref("");
    const searchStr = props.str;
    const extended_address = ref("");
    const property_type = ref("");
    const toggleExtendedAddrProp = ref(false);
    const propertyHasReviews = ref([]);
    const propertyLat = ref(parseFloat(getLocalStorage("latNumber")));
    const propertyLng = parseFloat(getLocalStorage("lngNumber"));

    clearLocalStorage("exist_prop_addr");
    clearLocalStorage("exist_prop_extended_addr");
    clearLocalStorage("exist_prop_type");
    clearLocalStorage("exist_prop_id");

    // for getting geohash manually
    // const lat =  40.6039345 ;
    // const lng = -74.61421720000001 ;
    // const geohash = geohashForLocation([lat, lng]);
    // console.log("manual geohash", geohash);
    // for getting geohash manually
    
    const callMyDocs = async () => {
      const query = [];
      if(props.str) {
        query.push([
            'address',
            '==',
            props.str
          ]
        );
      }

      if(property_type.value) {
        query.push([
            'property_type',
            '==',
            property_type.value
          ]
        );
      }

      if(extended_address.value) {
        query.push([
            'extended_address',
            '==',
            extended_address.value
          ]
        );
      }


      const myDocs = await getDocsByCompoundQuery(query);
      
      if (getDocError.value == null) {
        mainHeading.value = myDocs[0].address;
        propertyId.value = myDocs[0].id;
        propertyHasReviews.value = myDocs[0].allReviews;
        searchedDocs.value = myDocs;
      } else {
        propertyId.value = "";
        searchedDocs.value = [];
      }
    };

    const getNearbyLoc = async (addressToIgnore) => {
      const lat = parseFloat(getLocalStorage("latNumber"));
      const lng = parseFloat(getLocalStorage("lngNumber"));
      const dist = 2.5; // was set to 4 
      const nearerLoc = await getLocations(lat, lng, dist, addressToIgnore);
    };

    onBeforeMount(() => {
      if (props.str != "") {
        callMyDocs();
        getNearbyLoc(props.str);
        // console.log(myDocs);
      }
    });

    const goHome = async () => {
      router.push({ name: "Home" });
    };

    const writeReview = (param_extendedAddress) => {
      localStorage.setItem("exist_prop_id", propertyId.value);
      localStorage.setItem("exist_prop_addr", mainHeading.value);
      localStorage.setItem("exist_prop_extended_addr", param_extendedAddress);
      localStorage.setItem("exist_prop_type", property_type.value); // added by Mark (in case it causes an error later, we know who to blame!)
      router.push({ name: "CreateReview" });
    };

    const writeReviewIfNotFound = () => {
      // localStorage.setItem('exist_prop_id', propertyId.value);
      localStorage.setItem("exist_prop_addr", searchStr);
      localStorage.setItem("exist_prop_extended_addr", extended_address.value);
      localStorage.setItem("exist_prop_type", property_type.value);
      router.push({ name: "CreateReview" });
    };

    const toggleExtendedAddr = () => {
      const allowedType = [
        "condo",
        "coop",
        "townhouse",
        "rentalapartment",
        "commercial",
        "raw_land",
      ];
      if (allowedType.includes(property_type.value)) {
        toggleExtendedAddrProp.value = true;
      } else {
        toggleExtendedAddrProp.value = false;
        extended_address.value = "";
      }
    };

    function clearLocalStorage(key) {
      localStorage.removeItem(key);
    }

    function getLocalStorage(key) {
      return localStorage.getItem(key);
    }

    return {
      goHome,
      searchedDocs,
      mainHeading,
      propertyId,
      getDocError,
      searchStr,
      extended_address,
      property_type,
      toggleExtendedAddrProp,
      toggleExtendedAddr,
      writeReview,
      writeReviewIfNotFound,
      matchingDocs,
      formatDistanceToNow,
      propertyLat,
      propertyLng,
      callMyDocs,
      propertyHasReviews,
    };
  },

  data() {
    return {};
  },
};
</script>

<style></style>
