<template>
  <div
    class="bg-rounded mx-auto max-w-screen-md bg-white px-0 pt-4 pb-4 sm:bg-gray-50 sm:px-0 md:px-4"
  >
    <div class="prose">
      <h3>Privacy Policy</h3>
      <p><i>Version 1.1</i></p>
      <p><i>Last revised on: May 10, 2022</i></p>
      <p>
        <strong
          >IMPORTANT: &nbsp;This is a <u>temporary</u> privacy policy. Please
          check back here often to learn about significant upcoming
          changes</strong
        >
      </p>

      <p>
        We NEVER send or encourage spam. Should you receive any email supposedly
        from Zagnetic.com asking for any personal information, delete it,
        because it's not from us.
      </p>
      <p>
        We DO actively solicit or collect information from you that is both
        personally identifiable and not personally identifiable.
      </p>
      <p>
        We may use and share with one or more third parties statistical and/or
        other information about how personally anonymous groups or individuals
        have behaved, including but not limited to tracking responses to
        regional advertising. Also, third parties, such as advertising
        intermediaries, may share information they have directly or ondirectly
        gathered on an independent or semi-independent basis.
      </p>

      <p>
        <strong>Cookies </strong> When you visit Zagnetic.com directly, or
        indirectly via embedded Zagnetic content, we may send one or more cookies - a
        small file containing a string of characters - to your computer that
        uniquely identifies your browser. We may use cookies to improve the
        quality of our service by storing user preferences and tracking user
        trends, such as how people search. Most browsers are initially set up to
        accept cookies, but you can reset your browser to refuse all cookies or
        to indicate when a cookie is being sent. However, some features and
        services may not function properly if your cookies are disabled.
      </p>

      <p>
        <strong>Log information</strong> When you visit Zagnetic.com either
        directly or indirectly via embedded content, we may automatically opr
        otherwise record information that your browser sends whenever you visit
        a website. These server logs may include information such as your web
        request, Internet Protocol address, browser type, browser language, the
        date and time of your request and one or more cookies that may uniquely
        identify your browser.
      </p>

      <p>
        <strong>User communications</strong> When you send email or other
        communication to us, we may retain those communications in order to
        process your inquiries, respond to your requests and improve our
        services.
      </p>

      <p>
        <strong>Links</strong> We may present links, particularly paid or
        advertiser links, in a format that enables us or a third party such as
        an advertising intermediary to keep track of whether these links have
        been followed and what actions have been taken, such as whether or not a
        product or service has been purchased and thus a fee or commission
        earned.
      </p>

      <p>
        We do not exercise control over other sites or services. They employ
        their own independent practices and privacy policies and may place their
        own cookies or other files on your computer, collect data or solicit
        personal information from you.
      </p>

      <p>
        We would comply with valid legal process, such as search warrants, court
        orders, or subpoenas seeking personal information of users. These same
        processes apply to all law-abiding companies. As has always been the
        case, the primary protections you have against intrusions by the
        government are the laws that apply to where you live.
      </p>

      <p><strong>We use third party services and software</strong>, all with their own respective terms of service and privacy policies which Zagnetic users may be subject to and affected by. An incomplete list is below:</p>

      <ul>
        <li><a href="https://www.google.com/recaptcha/about/" target="_blank">Google reCAPTCHA</a> - Here are links to their <a href="https://www.google.com/policies/terms/" target="_blank">terms of service</a> and <a href="https://www.google.com/policies/privacy/" target="_blank">privacy policy</a>. <i>Zagnetic has implemented (or intends to implement) reCAPTCHA v3 on Zagnetic. Your use of reCAPTCHA v3 is subject to the Google Privacy Policy and Terms of Use. reCAPTCHA is used on Zagnetic to fight spam and abuse.</i></li>
      </ul>

      <h3>Information sharing</h3>
      <p>
        <strong
          >We share personal information with third party companies or
          individuals in the following limited circumstances:</strong
        >
      </p>

      <ol>
        <li>
          We have your consent. We require opt-in consent for the sharing of any
          sensitive personal information.
        </li>
        <li>
          We provide such information to our subsidiaries, affiliated companies
          or other trusted businesses or persons for the purpose of processing
          personal information on our behalf. We require that these parties
          agree to process such information based on our instructions and in
          compliance with this Policy and any other appropriate confidentiality
          and security measures.
        </li>
        <li>
          We have a good faith belief that access, use, preservation or
          disclosure of such information is reasonably necessary to (a) satisfy
          any applicable law, regulation, legal process or enforceable
          governmental request, (b) enforce applicable terms of service or the
          like, including investigation of potential violations thereof, (c)
          detect, prevent, or otherwise address fraud, security or technical
          issues, or (d) protect against imminent harm to the rights, property
          or safety of Zagnetic.com / Zagnetic LLC, owners, employees,
          affiliates, users or the public as required or permitted by law.
        </li>
      </ol>

      <p>
        <strong>Changes to this policy</strong> Please note that this Privacy
        Policy may change from time to time, without notice.
      </p>
      <p>
        If you have any additional questions or concerns about this Policy,
        please feel free to contact us any time through this web site or email
        at legal //{at] zagnetic//dot//com or reach us by mail at 2 Tempe Wick
        Road, #91 Mendham, New Jersey 07945
      </p>

      <h3>Thanks for visiting Zagnetic.com</h3>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
  },
};
</script>

<style></style>
