<template v-if="review">
  <div>
    <div class="error print:hidden" v-if="error">{{ error }}</div>
    <!-- Temporary code to display WaitingToLoad.vue "spinner" - When done, delete this and the next two lines and reinstate line #8 -->
    <!-- <div v-if="review"><WaitingToLoad /></div> -->
    <div
      v-if="review.reviewPrivacy == 'keepFullySecret'"
      class="bg-highlight mx-6 mt-6 rounded-xl px-6 print:hidden sm:mx-14 lg:mx-20 xl:mx-32"
    >
      <p class="pt-8 text-center text-xl font-semibold">
        Sorry, this is a SECRET review<fa
          :icon="['fas', 'ghost']"
          class="ml-2.5 text-lg"
        />
      </p>

      <p class="pt-8 text-center text-lg font-medium italic">
        SECRET reviews are only visible<button class="my-2.5 ml-0.5">
          <Whoops whoopsButtonVifTextAndIcon="4" />
        </button>
        to the author and people they share it with.
      </p>

      <p class="py-8 text-center text-xl font-medium">
        Return to the
        <router-link
          :to="{ name: 'Home' }"
          class="text-xl font-semibold underline"
          >Home page</router-link
        >
      </p>
    </div>

    <div v-else-if="review">
      <article>
        <div
          class="to-zagblue absolute inset-0 z-0 mt-20 mb-40 h-56 w-full bg-gradient-to-tr from-indigo-900 via-indigo-900 object-cover print:relative print:mt-6 print:mb-6 print:h-auto print:w-auto print:bg-white print:object-none"
        >
          <div>
            <h1
              class="pt-4 text-center text-2xl font-bold text-white print:bg-white print:pt-0 print:text-black sm:pt-8 lg:text-4xl"
            >
              <fa
                icon="map-marker-alt"
                class="mr-2 text-indigo-200 print:hidden"
                size="lg"
              />
              {{ review.address }}
            </h1>

            <div
              v-if="review.extended_address"
              class="pt-4 text-center text-white"
            >
              <span class="mt-4 text-lg font-bold md:text-xl lg:text-2xl">{{
                review.extended_address
              }}</span>
            </div>

            <!-- BEGIN display property type-->
            <div class="my-4 text-center text-white print:text-black">
              <div v-if="review.property_type === '1_family'">
                <p class="text-base print:text-lg sm:text-xl">Single family</p>
              </div>
              <div v-else-if="review.property_type === 'condo'">
                <p class="text-base print:text-lg sm:text-xl">Condominium</p>
              </div>
              <div v-else-if="review.property_type === 'townhouse'">
                <p class="text-base print:text-lg sm:text-xl">Townhouse</p>
              </div>
              <div v-else-if="review.property_type === 'coop'">
                <p class="text-base print:text-lg sm:text-xl">Cooperative</p>
              </div>
              <div v-else-if="review.property_type === 'mobilehome'">
                <p class="text-base print:text-lg sm:text-xl">Mobile home</p>
              </div>
              <!-- was "Mobile home, yurt, etc." -->
              <div v-else-if="review.property_type === 'multi_family'">
                <p class="text-base print:text-lg sm:text-xl">
                  Multi-family (whole building)
                </p>
              </div>
              <div v-else-if="review.property_type === 'commercial'">
                <p class="text-base print:text-lg sm:text-xl">Commercial</p>
              </div>
              <div v-else-if="review.property_type === 'rentalapartment'">
                <p class="text-base print:text-lg sm:text-xl">
                  Apartment for rent
                </p>
              </div>
              <div v-else>
                <p class="text-base print:text-lg sm:text-xl">Raw land</p>
              </div>
            </div>
            <!-- END display property type -->

            <p class="mt-4 pb-12 text-center italic text-white print:hidden">
              <span class="mr-1.5 text-base font-semibold italic text-white"
                >7</span
              >
              people found this review helpful
            </p>
          </div>
        </div>

        <div
          class="mt-64 grid grid-cols-1 gap-12 pt-8 print:col-span-3 print:mt-6 lg:grid-cols-3"
        >
          <!-- BEGIN 3-column grid -->

          <div class="col-span-1 print:col-span-3 lg:col-span-2">
            <p
              v-if="user && review.userId == user.uid"
              class="mx-auto mb-12 text-center sm:mb-8"
            >
              <router-link
                :to="{
                  name: 'EditReview',
                  params: {
                    review_id: review.review_id,
                    propertyId: review.propertyId,
                  },
                }"
              >
                <button
                  class="changebtn !px-4 !text-base ring-2 ring-indigo-100 ring-offset-4 ring-offset-pink-100"
                >
                  Edit your review
                </button></router-link
              >
            </p>
            <!-- BEGIN main (left-hand) column -->

            <!-- BEGIN pros, cons, ratings insert -->

            <!-- BEGIN pros -->
            <div class="grid grid-cols-2 print:col-span-3">
              <div class="headinggg col-span-1">Pros</div>

              <!-- BEGIN "photos" button on right-hand side of "Pros" linking to #ppphotos -->
              <div class="col-span-1 text-right print:hidden">
                <div v-if="review.filePath !== null">
                  <a href="#ppphotos"
                    ><button
                      class="mr-4 rounded-lg border border-indigo-900/20 px-2 py-0 text-sm font-medium text-slate-800/70 hover:border-indigo-900/50 hover:bg-slate-200 hover:text-indigo-900 sm:px-3 sm:py-1 sm:text-sm"
                    >
                      Photos<fa
                        :icon="['fas', 'camera']"
                        class="ml-1 text-base sm:ml-1.5 sm:text-base"
                      /><br /></button
                  ></a>
                </div>
              </div>
              <!-- END "photos" button on right-hand side of "Pros" linking to #ppphotos -->
            </div>
            <!-- <h2 class="headinggg">Pros</h2> -->
            <!-- <div v-if="review.propPros"><fa icon="plus-circle" class="text-pink-500"/> &nbsp;{{ review.propPros }}</div> -->
            <div v-if="review.propPros.length > 0">
              <div v-for="propPro in review.propPros" :key="propPro.id">
                <div
                  class="mb-2 ml-0 lowercase sm:ml-8 md:ml-16 lg:ml-12 xl:ml-20"
                >
                  <fa
                    icon="plus-circle"
                    class="text-progreen"
                    alt="plus sign icon in a circle"
                  />
                  &nbsp;{{ propPro }}
                </div>
              </div>
            </div>
            <div v-else><p class="italic">- none -</p></div>
            <!-- END pros -->

            <hr class="my-8" />

            <!-- BEGIN cons -->
            <h2 class="headinggg">Cons</h2>

            <div v-if="review.propCons.length > 0">
              <div v-for="propCon in review.propCons" :key="propCon.id">
                <div
                  class="mb-2 ml-0 lowercase sm:ml-8 md:ml-16 lg:ml-12 xl:ml-20"
                >
                  <fa
                    icon="minus-circle"
                    class="text-conred"
                    alt="minus sign icon in a circle"
                  />
                  &nbsp;{{ propCon }}
                </div>
              </div>
            </div>
            <div v-else><p class="italic">- none -</p></div>
            <!-- END cons -->

            <hr class="my-8" />

            <!-- BEGIN star ratings -->
            <h2 class="headinggg">
              Ratings<sup
                >&nbsp;<fa
                  :icon="['far', 'question-circle']"
                  size="xs"
                  class="text-gray-500"
                />&nbsp;</sup
              >
            </h2>

            <!-- BEGIN new responsive rating stars grid -->
            <div class="mt-4 grid grid-cols-2 gap-2 sm:grid-cols-3 md:gap-2">
              <!-- BEGIN the main ratings div  -->

              <div
                class="col-span-1 max-w-max justify-self-end whitespace-nowrap sm:px-3"
              >
                <!-- BEGIN 1st column, with text labels, on left -->
                <h4
                  class="text-zagblue mb-5 text-right align-bottom text-base font-bold"
                >
                  Kitchen rating:
                </h4>
                <h4
                  class="text-zagblue mb-5 text-right align-bottom text-base font-bold"
                >
                  Smell & odor rating:
                </h4>
                <h4
                  class="text-zagblue mb-5 text-right align-bottom text-base font-bold"
                >
                  Bathroom<span class="text-xs">(</span>s<span class="text-xs"
                    >)</span
                  >
                  rating:
                </h4>
                <!-- <h4
                class="text-zagblue mb-5 text-right align-bottom text-base font-bold"
              >
                Windows rating:
              </h4> -->
                <h4
                  class="text-zagblue mb-5 text-right align-bottom text-base font-bold"
                >
                  Location rating:
                </h4>
                <h4
                  class="text-zagblue mb-5 inline-flex justify-end pl-20 text-right align-bottom text-base font-bold sm:pl-20 md:pl-20"
                >
                  Setting<Whoops whoopsButtonVifTextAndIcon="14" />
                  &nbsp;rating:
                </h4>
              </div>

              <div
                class="col-span-1 max-w-max justify-self-start whitespace-nowrap"
              >
                <!-- BEGIN 2nd column, with stars, on right -->
                <div class="mb-4 align-top">
                  <div
                    v-if="review.kitchen_rating === '5'"
                    alt="reviewer rated kitchen 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="review.kitchen_rating === '4'"
                    alt="reviewer rated kitchen 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.kitchen_rating === '3'"
                    alt="reviewer rated kitchen 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.kitchen_rating === '2'"
                    alt="reviewer rated kitchen 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.kitchen_rating === '1'"
                    alt="reviewer rated kitchen 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                </div>

                <div class="mb-4 align-top">
                  <div
                    v-if="review.smell_rating === '5'"
                    alt="reviewer rated smells & odors 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="review.smell_rating === '4'"
                    alt="reviewer rated smells & odors 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.smell_rating === '3'"
                    alt="reviewer rated smells & odors 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.smell_rating === '2'"
                    alt="reviewer rated smells & odors 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.smell_rating === '1'"
                    alt="reviewer rated smells & odors 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                </div>

                <div class="mb-4 align-top">
                  <div
                    v-if="review.bath_rating === '5'"
                    alt="reviewer rated bath 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="review.bath_rating === '4'"
                    alt="reviewer rated bath 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.bath_rating === '3'"
                    alt="reviewer rated bath 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.bath_rating === '2'"
                    alt="reviewer rated bath 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.bath_rating === '1'"
                    alt="reviewer rated bath 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                </div>

                <div class="mb-4 align-top">
                  <div
                    v-if="review.location_rating === '5'"
                    alt="reviewer rated location 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="review.location_rating === '4'"
                    alt="reviewer rated location 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.location_rating === '3'"
                    alt="reviewer rated location 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.location_rating === '2'"
                    alt="reviewer rated location 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.location_rating === '1'"
                    alt="reviewer rated location 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                </div>

                <!-- BEGIN "setting" ratings -->
                <div class="mb-4 align-top">
                  <div
                    v-if="review.setting_rating === '5'"
                    alt="reviewer rated setting 5 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                  </div>
                  <div
                    v-else-if="review.setting_rating === '4'"
                    alt="reviewer rated setting 4 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.setting_rating === '3'"
                    alt="reviewer rated setting 3 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.setting_rating === '2'"
                    alt="reviewer rated setting 2 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div
                    v-else-if="review.setting_rating === '1'"
                    alt="reviewer rated setting 1 out of 5 stars"
                  >
                    <fa icon="star" id="rating_star" size="lg" class="mr-1.5" />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                    <fa
                      icon="star"
                      id="non_rating_star"
                      size="lg"
                      class="mr-1.5"
                    />
                  </div>
                  <div v-else><p class="italic">- not rated -</p></div>
                </div>
                <!-- END "setting" ratings -->
              </div>
              <!-- END 2nd column with stars on right-->

              <div class="col-span-0 grow bg-white print:hidden sm:col-span-1">
                <!-- BEGIN "junk" 3rd column, with white background (unsuccessfully tried to make this take all unused space) -->
                <p></p>
              </div>
              <!-- END BEGIN "junk" 3rd column, with white background -->
            </div>
            <!-- END new responsive rating stars grid -->

            <!-- END star ratings -->

            <!-- END pros, cons, ratings insert -->

            <div v-if="review.whatToRepairUpgradeAdd">
              <hr class="my-8" />
              <h2 class="headinggg">How to spend $12,000</h2>
              <div class="mt-4 inline-flex">
                <p class="text-zagblue mr-3 font-bold">Q:</p>
                <p class="text-zagblue pb-6 italic">
                  If you were going to live here and received a
                  <span class="font-bold">$12,000 gift card</span>, good for any
                  product or service, what would you repair, upgrade or add?
                  Why?
                </p>
              </div>
              <div v-if="review.whatToRepairUpgradeAdd">
                <p>
                  <span class="mr-3 whitespace-pre-line font-bold">A:</span
                  >{{ review.whatToRepairUpgradeAdd }}
                </p>
              </div>
              <div v-else>
                <p class="font-bold">
                  A:<span class="ml-3 font-normal italic">- no answer -</span>
                </p>
              </div>
            </div>

            <!-- <div class="tempdevnote">review.filePath = <br /><span v-for="file in review.filePath" :key="file" class="bg-violet-100">{{file}}<br /></span></div> -->

            <div v-if="review.filePath != null" class="print:hidden">
              <ReviewMedia :filesPath="review.filePath" :displayCode="1" />
            </div>

            <hr class="my-8" />

            <div v-if="review.reviewerTextReview">
              <h2 class="headinggg">Details by reviewer</h2>
              <p class="mt-2 whitespace-pre-line">
                <!-- was  <p class="line-clamp-5 hover:line-clamp-none mt-2"> -->
                <i>{{ review.reviewerTextReview }}</i>
              </p>
            </div>
            <!-- to truncate after serain number of lines, Tailwind CSS line-clamp: https://www.youtube.com/watch?v=klh-jMTm5PU -->
            <div v-else>
              <p class="italic">
                - Reviewer did not write a detailed text review -
              </p>
            </div>

            <hr class="my-8" />

            <h2 class="headinggg pb-4">
              Insight
              <span class="ml-1 text-sm font-medium italic text-gray-500"
                >(&thinsp;from the reviewer&thinsp;)</span
              >
            </h2>
            <div>
              <div class="inline-flex">
                <p class="text-zagblue mr-3 font-bold">Q:</p>
                <p class="text-zagblue pb-6 italic">
                  Was anything in the listing misleading or untrue?
                  <span class="ml-1 text-sm font-light"
                    >(&thinsp;including any photos&thinsp;)</span
                  >
                </p>
              </div>
              <div v-if="review.whatInListingIsUntrue">
                <p>
                  <span class="mr-3 font-bold">A:</span
                  >{{ review.whatInListingIsUntrue }}
                </p>
              </div>
              <div v-else>
                <p class="font-bold">
                  A:<span class="ml-4 font-normal italic">- Nothing -</span>
                </p>
              </div>
            </div>

            <!-- <div>
            <div class="mt-11 inline-flex">
              <p class="text-zagblue mr-3 font-bold">Q:</p>
              <p class="text-zagblue pb-6 italic">
                If you were going to live here and received a
                <span class="font-bold">$12,000 gift card</span>, good for any
                product or service, what would you repair, upgrade or add? Why?
              </p>
            </div>
            <div v-if="review.whatToRepairUpgradeAdd">
              <p>
                <span class="mr-3 font-bold">A:</span
                >{{ review.whatToRepairUpgradeAdd }}
              </p>
            </div>
            <div v-else>
              <p class="font-bold">
                A:<span class="ml-3 font-normal italic">- no answer -</span>
              </p>
            </div>
          </div> -->

            <hr class="my-8" />

            <!-- <h2 class="headinggg pb-4">
            Disclosures
            <span class="ml-1 text-sm font-medium italic text-gray-500"
              >(&thinsp;from the reviewer&thinsp;)</span
            >
          </h2>

          <div>
            <div class="inline-flex">
              <p class="text-zagblue mr-3 font-bold">Q:</p>
              <i
                ><p class="text-zagblue pb-6">
                  Does the reviewer have any relationship<button
                    class="mb-2.5 ml-0"
                  >
                    <Whoops whoopsButtonVifTextAndIcon="5.5" />
                  </button>
                  with the seller and/or agent?
                </p></i
              >
            </div>
            <div v-if="review.reviewerAnyRelWithSellerOrAgent === 'solid'">
              <p><span class="mr-3 font-bold">A:</span>Yes, "solid"</p>
            </div>
            <div
              v-else-if="
                review.reviewerAnyRelWithSellerOrAgent === 'acquainted'
              "
            >
              <p><span class="mr-3 font-bold">A:</span>Yes, "acquainted"</p>
            </div>
            <div
              v-else-if="review.reviewerAnyRelWithSellerOrAgent === 'isSeller'"
            >
              <p class="text-semibold bg-pink-100">
                <span class="mr-3 font-bold">A:</span>Reviewer is the seller
              </p>
            </div>
            <div
              v-else-if="
                review.reviewerAnyRelWithSellerOrAgent === 'isSellersAgent'
              "
            >
              <p class="text-semibold bg-pink-100">
                <span class="mr-3 font-bold">A:</span>Reviewer is the seller's
                agent
              </p>
            </div>
            <div v-else>
              <p><span class="mr-3 font-bold">A:</span>No.</p>
            </div>
          </div>

          <div>
            <div class="mt-11 inline-flex">
              <p class="text-zagblue mr-3 font-bold">Q:</p>
              <i
                ><p class="text-zagblue pb-6">
                  When was the reviewer physically at the property?
                </p></i
              >
            </div>
            <div v-if="review.whenReviewerVisitedProp === '0'">
              <p><span class="mr-3 bg-pink-100 font-bold">A:</span>"Never"</p>
            </div>
            <div v-else-if="review.whenReviewerVisitedProp === '30'">
              <p>
                <span class="mr-3 font-bold">A:</span>"<span
                  class="font-semibold"
                  >Within one month</span
                >
                before writing the review"
              </p>
            </div>
            <div v-else-if="review.whenReviewerVisitedProp === '180'">
              <p>
                <span class="mr-3 font-bold">A:</span>"Within 6 months before
                writing the review"
              </p>
            </div>
            <div v-else>
              <p>
                <span class="mr-3 font-bold">A:</span>"More than 6 months before
                writing the review"
              </p>
            </div>
          </div>

          <hr class="my-8" /> -->

            <h2
              class="mb-4 mt-10 text-center text-2xl font-bold print:hidden sm:mb-6 sm:text-left"
            >
              7 people found this review helpful.
              <span class="bg-highlight text-2xl">Do you?</span>
            </h2>

            <p class="text-center print:hidden">
              <button
                v-if="user"
                class="borderbtn px-4 font-bold uppercase tracking-widest hover:bg-indigo-900 hover:text-white sm:hidden"
              >
                Helpful
              </button>
              <button
                v-else
                class="borderbtn px-4 font-bold uppercase tracking-widest hover:bg-indigo-900 hover:text-white sm:hidden"
              >
                <Whoops whoopsButtonVifTextAndIcon="23" />
              </button>
            </p>

            <div class="row print:hidden">
              <div class="flex-nowrap text-center">
                <button
                  v-if="user"
                  class="borderbtn hidden font-bold uppercase tracking-widest sm:inline-block"
                >
                  Helpful
                </button>
                <button
                  v-else
                  class="borderbtn hidden font-bold uppercase tracking-widest sm:inline-block"
                >
                  <Whoops whoopsButtonVifTextAndIcon="23" />
                </button>
                <!-- <span class="hidden font-medium sm:mx-8 sm:inline-block md:mx-10"
                >|</span
              >
              <button v-if="user" class="borderlessbtn">Reply</button>
              <button v-else class="borderlessbtn"><Whoops whoopsButtonVifTextAndIcon="24" /></button> -->
                <span class="mx-4 font-medium sm:mx-8 md:mx-10">|</span>
                <!-- <button class="borderlessbtn sm:mb-6">Report Abuse</button> -->
                <button v-if="user" class="borderlessbtn !px-1 !py-1.5 sm:mb-6">
                  <FlagReportAbuse FlagReportAbuseButtonVifTextAndIcon="1" />
                </button>
                <button v-else class="borderlessbtn !px-1 !py-1.5 sm:mb-6">
                  <Whoops whoopsButtonVifTextAndIcon="16" />
                </button>
              </div>
            </div>
          </div>
          <!-- END main (left-hand) column -->

          <div class="col-span-1 print:col-span-3">
            <!-- BEGIN right-hand column -->

            <!-- BEGIN bookmark this review -->
            <div class="mb-4 print:hidden">
              <button v-if="user" class="rightcolumnbtn mb-2">
                Bookmark this review&nbsp;&nbsp;<fa
                  :icon="['far', 'bookmark']"
                />
              </button>
              <button v-else class="rightcolumnbtn mb-2">
                <Whoops whoopsButtonVifTextAndIcon="21" />
              </button>
            </div>
            <!-- END bookmark this review -->

            <a
              href="https://forms.gle/6VTm9ZtirwtkFhpF9"
              target="_blank"
              title="get custom notifications"
            >
              <button class="rightcolumnbtn mb-6 print:hidden">
                Follow this property&nbsp;&nbsp;<fa :icon="['far', 'bell']" />
              </button>
            </a>
            <!-- <button v-if="user" class="rightcolumnbtn mb-6 print:hidden">
              Follow this property&nbsp;&nbsp;<fa :icon="['far', 'bell']" />
            </button>
            <button v-else class="rightcolumnbtn mb-6 print:hidden">
              <Whoops whoopsButtonVifTextAndIcon="22" />
            </button> -->

            <!-- BEGIN card with buttons -->
            <div
              class="z-10 mt-4 space-y-4 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-4 px-4 drop-shadow-lg print:hidden"
            >
              <ShareButtonDD
                shareButtonDDStyle="5"
                :urlToShare="$route.fullPath"
              />

              <hr />

              <router-link
                :to="{
                  name: 'PropertyLanding',
                  params: { id: review.propertyId },
                }"
                ><button
                  class="mt-4 rounded-lg text-indigo-900 hover:bg-indigo-900 hover:px-1 hover:text-white"
                >
                  <fa icon="comments" />&nbsp;&nbsp;Q&A for this property
                </button>
              </router-link>

              <hr />

              <router-link
                :to="{
                  name: 'PropertyLanding',
                  params: { id: review.propertyId },
                }"
              >
                <button
                  class="mt-4 rounded-lg text-indigo-900 hover:bg-indigo-900 hover:px-1 hover:text-white"
                >
                  <fa icon="plus-circle" />&nbsp;&nbsp;More reviews for this
                  property
                </button>
              </router-link>
            </div>
            <!-- END card with buttons -->

            <div class="my-12 print:hidden">
              <button v-if="user" class="rightcolumnbtn">
                <router-link :to="{ name: 'CreateReview' }"
                  ><span class="text-base lg:text-[0.95rem] xl:text-lg"
                    >Write a review&nbsp;&nbsp;<fa icon="pencil-alt" /></span
                ></router-link>
              </button>
              <button v-else class="rightcolumnbtn">
                <Whoops whoopsButtonVifTextAndIcon="17" />
              </button>
            </div>

            <!-- BEGIN About this review card -->
            <div
              class="z-10 mb-12 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-5 px-4 drop-shadow-lg print:border-none print:drop-shadow-none"
            >
              <!-- <h4 class="question subheadinggg mb-4">About this review:</h4> -->
              <p class="mb-4 text-2xl font-bold">About this review:</p>
              <ul class="my-4 space-y-4 text-base">
                <!-- BEGIN time display -->
                <!-- <li>created {{ computedDateToNow }} ago</li> -->
                <li>
                  created<span class="mx-1"
                    >{{
                      formatDistanceToNow(
                        new Date(review.createdAt.seconds * 1000)
                      )
                    }}&nbsp;ago</span
                  >
                </li>
                <!-- END time display -->
                <li>
                  <!-- BEGIN display reviwer's real name, user name, or "secret" -->
                  <div v-if="review.reviewPrivacy === 'keepFullySecret'">
                    <p class="text-base">
                      reviewer's identity is SECRET<button
                        class="mb-2 ml-0 print:hidden"
                      >
                        <Whoops whoopsButtonVifTextAndIcon="4" /></button
                      >&nbsp;&nbsp;<fa icon="ghost" />
                    </p>
                  </div>
                  <div v-else-if="review.reviewPrivacy === 'postAnonPublic'">
                    <p class="text-base">
                      by
                      <span
                        class="rounded-md text-base font-medium underline hover:bg-indigo-100 hover:px-0.5 hover:opacity-80"
                        >{{ review.userName }}</span
                      >
                      <fa icon="user-secret" class="ml-1 mr-1 print:hidden" />
                    </p>
                    <p class="text-sm text-gray-500 italic mt-4"
                        >reviewer's privacy = incognito<button class="mb-2 ml-0 print:hidden">
                          <Whoops whoopsButtonVifTextAndIcon="4" /></button
                        ></p
                      >
                  </div>
                  <div v-else-if="review.reviewPrivacy === 'postFullPublic'">
                    <router-link
                      :to="{
                        name: 'PublicProfile',
                        params: { id: review.userId },
                      }"
                    >
                      <p class="mb-2 text-base">
                        by&nbsp;<fa
                          icon="user-circle"
                          class="mx-1 print:hidden"
                        /><span class="underline text-base">{{ review.userName }}</span>

                        <!-- ||
                      <span class="bg-amber-200 italic"
                        >need to display reviewer's real full name here</span
                      > -->
                        <!-- <sup class="print:hidden"
                          >&nbsp;<fa icon="check-circle" size="s" />&nbsp;</sup
                        > -->
                      </p>
                    </router-link>
                    <p class="text-sm italic text-gray-500">
                      reviewer's privacy<button
                        class="my-2 ml-0 print:hidden"
                      >
                        <Whoops whoopsButtonVifTextAndIcon="4" />
                      </button>
                    </p>
                  </div>
                  <!-- END display reviwer's real name, user name, or "secret" -->
                </li>
                <li>
                  <!-- BEGIN display if user is real estate agent / verified real estate agent. "isRealEstateAgent" is a temp hard-coded field from 1385 -->
                  <div
                    v-if="review.isRealEstateAgent == 'verified'"
                    class="text-zaggray my-4 text-base"
                  >
                    <span class="text-zagblue text-base font-semibold"
                      >Verified</span
                    ><fa
                      :icon="['fas', 'check-circle']"
                      size="sm"
                      class="text-zagblue ml-1 mb-0.5 print:hidden"
                    />
                    real estate agent<span
                      class="ml-0.5 inline-flex print:hidden"
                      ><Whoops whoopsButtonVifTextAndIcon="11"
                    /></span>
                  </div>

                  <div
                    v-else-if="review.isRealEstateAgent == 'unverified'"
                    class="text-zaggray my-2.5 text-sm italic"
                  >
                    reviewer is a real esate agent, but has
                    <span class="text-zaggray text-sm font-semibold italic"
                      >not</span
                    >
                    been verified<span class="ml-0.5 inline-flex print:hidden"
                      ><Whoops whoopsButtonVifTextAndIcon="11"
                    /></span>
                  </div>
                  <!-- END display if user is real estate agent / verified real estate agent. "isRealEstateAgent" is a temp hard-coded field from 1385 -->
                </li>
              </ul>
            </div>
            <!-- END About this review card -->

            <!-- BEGIN Disclosures card -->
            <div
              class="mb-12 rounded-lg border-2 border-gray-100 border-opacity-50 bg-white py-5 px-4 drop-shadow-lg print:border-none print:drop-shadow-none"
            >
              <p class="mb-4 text-2xl font-bold">
                Disclosures<span
                  class="ml-1.5 text-sm font-medium italic text-gray-500"
                  >(&thinsp;from the reviewer&thinsp;)</span
                >
              </p>
              <ul class="my-4 space-y-4 text-base">
                <li>
                  <div>
                    <div class="inline-flex text-base">
                      <p class="text-zagblue mr-3 text-base font-bold">Q:</p>
                      <p class="text-zagblue pb-2.5 text-base italic">
                        Does the reviewer have any relationship with the seller
                        and/or agent?<button class="mb-2.5 ml-1 print:hidden">
                          <Whoops whoopsButtonVifTextAndIcon="5.5" />
                        </button>
                      </p>
                    </div>
                  </div>

                  <!-- BEGIN when user was at property -->
                  <div class="inline-flex text-base">
                    <p class="mr-3 text-base font-bold">A:</p>
                    <p
                      v-if="review.reviewerAnyRelWithSellerOrAgent === 'solid'"
                      class="pb-2.5 text-base italic"
                    >
                      Yes, "solid"
                    </p>
                    <p
                      v-else-if="
                        review.reviewerAnyRelWithSellerOrAgent === 'acquainted'
                      "
                      class="pb-2.5 text-base italic"
                    >
                      "acquainted"
                    </p>
                    <p
                      v-else-if="
                        review.reviewerAnyRelWithSellerOrAgent === 'isSeller'
                      "
                      class="pb-2.5 text-base italic"
                    >
                      <span class="rounded-md bg-pink-100 px-1.5 text-base"
                        >Reviewer is the owner&thinsp;/&thinsp;seller</span
                      >
                    </p>
                    <p
                      v-else-if="
                        review.reviewerAnyRelWithSellerOrAgent ===
                        'isSellersAgent'
                      "
                      class="pb-2.5 text-base italic"
                    >
                      <span class="rounded-md bg-pink-100 px-1 text-base"
                        >Reviewer is the owner&thinsp;/&thinsp;seller's
                        agent</span
                      >
                    </p>
                    <p v-else class="pb-2.5 text-base italic">"No"</p>
                  </div>
                  <!-- END relationship w/ agent / seller -->

                  <hr class="my-4" />

                  <!-- BEGIN when user was at property -->
                  <div>
                    <div class="mt-2 inline-flex text-base">
                      <p class="text-zagblue mr-3 font-bold">Q:</p>

                      <p class="text-zagblue pb-6 text-base italic">
                        When was the reviewer physically at the property?
                      </p>
                    </div>

                    <!-- BEGIN when user was at property -->
                    <div class="inline-flex text-base">
                      <p class="mr-3 text-base font-bold">A:</p>
                      <p
                        v-if="review.whenReviewerVisitedProp === '0'"
                        class="pb-2.5 text-base italic"
                      >
                        <span class="rounded-md bg-pink-100 px-1.5 text-base"
                          >Never</span
                        >
                      </p>
                      <p
                        v-else-if="review.whenReviewerVisitedProp === '30'"
                        class="pb-2.5 text-base italic"
                      >
                        <span class="text-base font-semibold"
                          >Within one month</span
                        >
                        before writing the review"
                      </p>
                      <p
                        v-else-if="review.whenReviewerVisitedProp === '180'"
                        class="pb-2.5 text-base italic"
                      >
                        "Within 6 months before writing the review"
                      </p>
                      <p v-else class="pb-2.5 text-base italic">
                        "More than 6 months before writing the review"
                      </p>
                    </div>
                  </div>
                  <!-- END when user was at property -->
                </li>
              </ul>
            </div>
            <!-- END Diclosures card -->

            <!-- BEGIN card NOT identifying listing agent + directing to verified agents who answered questions well -->
            <div class="propLandingCard col-span-2 print:hidden md:col-span-1">
              <h5 class="subheadinggg mb-6">
                Read This&ensp;<fa
                  :icon="['fas', 'minus']"
                  size="2x"
                  class="-mb-0.5 -mr-1.5 text-xl"
                /><fa
                  :icon="['fas', 'long-arrow-alt-down']"
                  size="2x"
                  class="-mb-2.5 text-xl"
                />
              </h5>
              <h1 class="headinggg">
                <span class="bg-highlight rounded-sm text-3xl"
                  >IMPORTANT:&nbsp;&nbsp;<fa icon="exclamation-triangle"
                /></span>
              </h1>
              <p class="mb-6 mt-4 text-lg">
                The seller's real estate agent represents the
                <span class="text-lg font-semibold underline">seller</span
                >&thinsp;,
                <span class="bg-highlight text-lg font-bold">not you</span
                >.<span class="inline-flex"
                  ><Whoops whoopsButtonVifTextAndIcon="12" /></span
                >&nbsp;
              </p>
              <p class="mb-6 text-lg">
                Find an experienced agent who only represents
                <span class="text-lg font-semibold underline">you</span
                >&thinsp;.
              </p>

              <p class="mb-8 text-lg">
                <span class="mr-2 text-xl font-bold">How?</span>Look for
                <span class="text-zagblue text-lg font-semibold">verified</span
                ><fa
                  :icon="['fas', 'check-circle']"
                  size="sm"
                  class="text-zagblue ml-1 mb-0.5"
                />
                real estate agents<span class="ml-0 inline-flex"
                  ><Whoops whoopsButtonVifTextAndIcon="11"
                /></span>
                who provide
                <span class="text-lg font-semibold">helpful answers</span> in
                the Q&A section of local properties.
              </p>

              <!-- <p class="text-lg mb-2.5">
              Click names to see profile pages.
            </p> -->

              <router-link
                :to="{
                  name: 'PropertyLanding',
                  params: { id: review.propertyId },
                }"
              >
                <button class="rightcolumnbtn bg-zaggray !text-white">
                  Go to Q&A<fa icon="comments" class="ml-1 mr-2" />for this
                  property
                </button>
              </router-link>

              <p
                class="mt-4 mb-1.5 text-center text-sm font-light italic text-gray-500"
              >
                click names to see profile info
              </p>
            </div>
            <!-- END card NOT identifying listing agent + directing to verified agents who answered questions well -->

            <!-- BEGIN bookmark this review -->
            <div class="mt-8 print:hidden">
              <p class="mb-2 text-center text-lg italic">
                <button v-if="user" class="rightcolumnbtn !border-0">
                  <fa :icon="['far', 'bookmark']" />&nbsp;&nbsp;Bookmark this
                  review
                </button>
                <button v-else class="rightcolumnbtn !border-0">
                  <Whoops whoopsButtonVifTextAndIcon="20" />
                </button>
              </p>
              <p class="text-center text-sm italic text-slate-500">
                7 people bookmarked this review
              </p>
            </div>
            <!-- END bookmark this review -->
          </div>
          <!-- END right-hand column -->
        </div>
        <!-- END 3-column grid -->

        <hr class="my-8" />

        <div class="row print:hidden lg:flex">
          <ShareButtonDD :urlToShare="$route.fullPath" />
          <!-- <ShareButtonDD class="borderlessbtn flex-initial font-zagnetic" /> -->
          <router-link
            :to="{
              name: 'PropertyLanding',
              params: { id: review.propertyId },
            }"
            ><button class="borderlessbtn flex-initial">
              <fa icon="plus-circle" /> &nbsp;See more reviews
            </button></router-link
          >&nbsp;&nbsp;
          <router-link
            :to="{
              name: 'PropertyLanding',
              params: { id: review.propertyId },
            }"
            ><button class="borderlessbtn flex-initial">
              <fa icon="comments" /> &nbsp;Q&A
            </button></router-link
          >&nbsp;&nbsp;
          <button v-if="user" class="borderlessbtn flex-initial">
            <fa :icon="['far', 'bookmark']" /> &nbsp;Bookmark this
          </button>
          <button v-else class="borderlessbtn flex-initial">
            <Whoops whoopsButtonVifTextAndIcon="19" /></button
          >&nbsp;&nbsp;
          <button v-if="user" class="borderlessbtn flex-initial">
            <router-link :to="{ name: 'CreateReview' }"
              ><span class="text-sm"
                ><fa icon="pencil-alt" /> &nbsp;Write a review</span
              ></router-link
            >
          </button>
          <button v-else class="borderlessbtn flex-initial">
            <Whoops whoopsButtonVifTextAndIcon="18" />
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import useStorage from "@/composables/useStorage";
import getSubCollectionDoc from "@/composables/getSubCollectionDoc";
import getUser from "@/composables/getUser";
import { computed, ref, onRenderTriggered, onMounted } from "vue";
import { useRouter } from "vue-router";

import { formatDistanceToNow, format } from "date-fns";

import ShowRatingStars from "@/components/ShowRatingStars.vue";

import ShareButtonDD from "@/components/ShareButtonDD.vue";
import Whoops from "@/components/Whoops";
import WaitingToLoad from "@/components/WaitingToLoad.vue";
import FlagReportAbuse from "@/components/FlagReportAbuse.vue";
import ReviewMedia from "@/components/ReviewMedia";

export default {
  props: ["id"],
  components: {
    ShowRatingStars,
    ShareButtonDD,
    Whoops,
    WaitingToLoad,
    FlagReportAbuse,
    ReviewMedia,
  },
  setup(props, context) {
    // const { error, document: review } = getDocument("reviews", props.id);
    const { user } = getUser();
    const { error, document: review } = getSubCollectionDoc(
      "reviews",
      "review_id",
      props.id,
      "=="
    );
    const { deleteImage, getImages, fetchedImages } = useStorage();
    const router = useRouter();
    let reviewCreatedTime = "";
    const isRenderedTriggered = ref(false);

    const ownership = computed(() => {
      return (
        review.value && user.value && user.value.uid == review.value.userId
      );
    });

    const handleDelete = async () => {
      await deleteDoc();
      await deleteImage(review.value.filePath);
      router.push({ name: "Home" });
    };

    // const blankInReview = "<p><i>Nada</i></p>";

    // BEGIN formatting timestamp
    onRenderTriggered(() => {
      if (!isRenderedTriggered.value) {
        reviewCreatedTime = formatDistanceToNow(
          review.value.createdAt.toDate()
        );
        getImages(review.value.filePath);
      }

      isRenderedTriggered.value = true;
    });

    const computedDateToNow = computed(() => {
      return reviewCreatedTime;
    });

    // END formatting timestamp

    return {
      user,
      error,
      review,
      ownership,
      handleDelete,
      formatDistanceToNow,
      computedDateToNow,
      fetchedImages,
      reviewCreatedTime,
    };
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
