<template>
  <div>
    <!-- Below, each updateCollAndSubCollVersion number (1, 2, 3, 4 etc.) corresponds to a different pop-up alert button and message (see "BEGIN v-if case 1, 2 or 3" a few dozen lines below) -->

    <!-- BEGIN case 1 (update string - displayName, etc.) -->
    <div v-if="updateCollAndSubCollVersion == 1">
      <div @click="openModal" class="">
        <button class="changebtn ">
          edit<fa
            icon="edit"
            class="mx-1 text-sm opacity-70 hover:opacity-100 hidden sm:inline"
          />
        </button>
      </div>
    </div>
    <!-- END case 1 (update string - displayName, etc.) -->



    <!-- BEGIN case 2 - update profile photo (profilePhotoUrl) -->
    <div v-if="updateCollAndSubCollVersion == 2">
      <div @click="openModal" class="">
        <button class="changebtn">
          update<fa
            icon="camera"
            class="mx-1 text-xs opacity-80 hover:opacity-100"
          />
        </button>
      </div>
    </div>
    <!-- END case 2 - update profile photo (profilePhotoUrl) -->

    <!-- BEGIN case 3 - "  " -->
    <div v-if="updateCollAndSubCollVersion == 3">
      <div @click="openModal" class="">
        <!-- <span class="text-xs p-2">testing on UpdateCollAndSubcoll - will be buttons on right of each image, via ReviewMedia.vue lines 51-57</span><br />
        <button class="changebtn !px-0.5 sm:!px-2 sm:!pb-1.5">remove
            <fa
            icon="times-circle"
            class="text-base pt-0.5"
            alt="X icon in a circle"
            />
          </button> -->
      </div>
    </div>
    <!-- END case 3 - "  " -->

    <!-- BEGIN HeadlessUI Dialogue code -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-1 text-center sm:px-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="via ingigo-50 my-8 inline-block w-full max-w-[18rem] transform overflow-hidden rounded-2xl bg-gradient-to-br from-white to-slate-100 px-3 py-4 text-center align-middle shadow-xl ring-8 ring-slate-300 ring-opacity-80 ring-offset-8 ring-offset-slate-200 transition-all sm:max-w-sm sm:p-6 sm:px-6"
              >
                <!-- BEGIN case 1 (update string, like displayName) -->
                <div v-if="updateCollAndSubCollVersion == 1">
                  <DialogTitle
                    class="text-left text-base font-medium sm:text-lg pb-4"
                    ><span class="mr-0.5 text-base font-medium underline"
                      >Update Info</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>

                  <div class="my-4 text-left">
                    <!-- <div v-if="user" class="my-4 text-left"> -->
                    <form
                      class="bg-slate-50/5"
                      @submit.prevent="handleUpdateSubmit"
                    >
                      <div v-if="itemType === 'string'" class="mt-4">
                        <p v-if="whatToUpdate === 'whatInListingIsUntrue'" class="ml-1 mr-0.5 text-zagblue font-semibold mb-8">Anything misleading or untrue in the listing or photos?</p>
                        <p v-if="whatToUpdate === 'address'" class="ml-1 mr-0.5 text-zagblue font-semibold mb-8">What is the new address?</p>
                        <p v-else-if="whatToUpdate === 'whatToRepairUpgradeAdd'" class="ml-1 mr-0.5 text-zagblue font-semibold mb-8">If you were going to live here and received a $12,000 gift card, good for any product or service, what would you repair, upgrade or add? Why?</p>
                        <p v-else-if="whatToUpdate === 'reviewerTextReview'" class="ml-1 mr-0.5 text-zagblue font-semibold mb-8">Anything else?<br /><span class="text-zagblue text-sm font-medium pt-2">Short snippet? Detailed review? </span></p>

                        <div v-if="whatToUpdate === 'address'" class="">
                          <input
                          type="text"
                          id="whatToUpdate"
                          class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                          placeholder="Type new info here"
                          v-model="fieldValToUpdate"
                        />
                        </div>

                        <div v-if="whatToUpdate === 'extended_address'" class="">
                          <input
                          type="text"
                          id="whatToUpdate"
                          class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                          placeholder="Type new info here"
                          v-model="fieldValToUpdate"
                        />
                        </div>

                        <div v-else><textarea
                          rows="5"
                          id="whatToUpdate"
                          class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                          placeholder=""
                          v-model="fieldValToUpdate">
                        </textarea></div>

                      </div>

                      <div v-if="itemType === 'select'">
                        
                        <!-- BEGIN 'property_type' selection -->
                        <div v-if="whatToUpdate === 'property_type'" class="space-y-0">
                          <p class="mt-10 text-center text-zagblue font-semibold mb-2">Enter new property type&thinsp;:</p>
                          <!--
                          <p class="text-sm text-center italic pb-8">(&thinsp;current = 
                              <span v-if="fieldValue === '1_family'" class="text-sm italic">Single family</span>
                              <span v-else-if="fieldValue === 'condo'" class="text-sm italic">Condominium</span>
                              <span v-else-if="fieldValue === 'townhouse'" class="text-sm italic">Townhouse</span>
                              <span v-else-if="fieldValue === 'coop'" class="text-sm italic">Cooperative</span>
                              <span v-else-if="fieldValue === 'mobilehome'" class="text-sm italic">Mobile Home</span>
                              <span v-else-if="fieldValue === 'multi_family'" class="text-sm italic">Multi-family (whole building)</span>
                              <span v-else-if="fieldValue === 'commercial'" class="text-sm italic">Commercial</span>
                              <span v-else-if="fieldValue === 'rentalapartment'" class="text-sm italic">Apartment for rent</span>
                            &thinsp;)</p>
                            -->

                          <select
                            required
                            name="property_type"
                            id="property_type"
                            v-model="fieldValToUpdate" 
                            class="text-zaggray rounded -ml-2.5 sm:ml-6 border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400"
                          >
                            <!-- <option disabled value="">Please select</option> -->
                            <option value="1_family">Single family house</option>
                            <option value="condo">Condominium</option>
                            <option value="coop">Cooperative</option>
                            <option value="townhouse">Townhouse</option>
                            <option value="rentalapartment">Apartment for rent</option>
                            <!--<option value="mobilehome">Mobile home</option>-->
                            <!-- was "Mobile home, yurt, etc." >> Removed this for now because it's difficult to separate the mobile home from the (often rented) lot where it's located -->
                            <option value="multi_family">Multi-family (whole building)</option>
                            <option value="commercial">Commercial</option>
                            <option value="raw_land">Raw land</option>
                          </select>
                        </div>
                        <!-- END 'property_type' selection -->
                      </div>

                      <div v-if="itemType === 'radio'">
                        <!-- <p class="mt-10 text-center mb-2">Enter new {{ cleanWhatToUpdate }}&thinsp;:</p> -->
                        <!-- <p class="text-sm text-center italic">(&thinsp;current = {{fieldValueAsString}}&thinsp;)</p> -->
                        
                        <!-- BEGIN 'whenReviewerVisitedProp' selection -->
                        <div v-if="whatToUpdate === 'whenReviewerVisitedProp'" class="mb-8 mt-4 space-y-4">
                          <p class="mt-10 text-left mb-2 question">When were you physically at the property?</p>
                          <p class="text-sm pb-6 text-left italic">(&thinsp;relative to when you first wrote the review&thinsp;)</p>
                            <div>
                              <input
                                type="radio"
                                id="30"
                                value="30"
                                name="whenReviewerVisitedProp"
                                required
                                v-model="fieldValToUpdate"
                              />
                              <label class="ml-4 inline-flex text-base" for="30"
                                >Within the last month</label
                              >
                            </div>

                            <div>
                              <input
                                type="radio"
                                id="180"
                                value="180"
                                name="whenReviewerVisitedProp"
                                required
                                v-model="fieldValToUpdate"
                              />
                              <label class="ml-4 inline-flex text-base" for="180"
                                >Within the last 6 months</label
                              >
                            </div>

                            <div>
                              <input
                                type="radio"
                                id="181"
                                value="181"
                                name="whenReviewerVisitedProp"
                                required
                                v-model="fieldValToUpdate"
                              />
                              <label class="ml-4 inline-flex text-base" for="181"
                                >More than 6 months ago</label
                              >
                            </div>

                            <div>
                              <input
                                type="radio"
                                id="0"
                                value="0"
                                name="whenReviewerVisitedProp"
                                required
                                v-model="fieldValToUpdate"
                              />
                              <label class="ml-4 inline-flex text-base" for="0"
                                >Never<button class="ml-0">
                                  <Whoops whoopsButtonVifTextAndIcon="9" /></button
                              ></label>
                            </div>
                        </div>
                        <!-- END 'whenReviewerVisitedProp' selection -->

                        <!-- BEGIN 'reviewerAnyRelWithSellerOrAgent' selection -->
                         <div v-if="whatToUpdate === 'reviewerAnyRelWithSellerOrAgent'" class="mb-8 mt-4 space-y-4">
                          <div class="inline-flex pt-6 pb-4">
                            <p class="question mt-0.5">
                              Do you have any relationship
                              <!-- <button class="mb-2 ml-0">
                              <Whoops whoopsButtonVifTextAndIcon="5" />
                            </button> -->
                             with the seller or real estate agent?
                            </p>
                          </div>
                          <!-- <p class="mt-10 text-center mb-2 question">When were you physically at the property?</p>
                          <p class="text-sm pb-6 text-center italic">(&thinsp;relative to when you first wrote the review&thinsp;)</p> -->
                          <div>
                          <input
                            type="radio"
                            id="none"
                            value="none"
                            name="reviewerAnyRelWithSellerOrAgent"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label class="ml-4 inline-flex align-bottom text-base" for="none"
                            ><fa
                              :icon="['far', 'meh-blank']"
                              class="text-zaggray text-xl"
                            />&nbsp;&nbsp;None</label
                          >
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="acquainted"
                            value="acquainted"
                            name="reviewerAnyRelWithSellerOrAgent"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label
                            class="ml-4 inline-flex align-bottom text-base"
                            for="acquainted"
                            ><fa
                              :icon="['far', 'meh']"
                              class="text-zaggray text-xl"
                            />&nbsp;&nbsp;Acquainted</label
                          >
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="solid"
                            value="solid"
                            name="reviewerAnyRelWithSellerOrAgent"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label class="ml-4 inline-flex align-bottom text-base" for="solid"
                            ><fa
                              :icon="['far', 'grin']"
                              class="text-zaggray text-xl"
                            />&nbsp;&nbsp;Solid</label
                          >
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="solid"
                            value="isSeller"
                            name="reviewerAnyRelWithSellerOrAgent"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label class="ml-4 inline-flex align-bottom text-base" for="isSeller"
                            ><fa
                              :icon="['fas', 'house-user']"
                              class="text-zaggray text-opacity-85 text-xl"
                            />&nbsp;&nbsp;I am the owner&thinsp;/&thinsp;seller</label
                          >
                          <!-- also consider 'far', 'user-circle' -->
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="solid"
                            value="isSellersAgent"
                            name="reviewerAnyRelWithSellerOrAgent"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label
                            class="ml-5 inline-flex align-bottom text-base"
                            for="isSellersAgent"
                            ><fa
                              :icon="['far', 'clipboard']"
                              class="text-zaggray text-xl"
                            />&nbsp;&nbsp;I am the owner&thinsp;/&thinsp;seller's agent</label
                          >
                        </div>
                        </div>
                        <!-- END 'reviewerAnyRelWithSellerOrAgent' selection -->
                      
                      <!-- BEGIN 'reviewPrivacy' selection -->
                        <div v-if="whatToUpdate === 'reviewPrivacy'" class="mb-8 mt-4 space-y-4">
                          <div class="inline-flex pt-6">
                            <p class="question mt-0.5">Privacy</p>
                            <button class="mb-2 ml-0">
                              <Whoops whoopsButtonVifTextAndIcon="4" />
                            </button>
                            <div class="inline">
                              <span class="text-zaggray mb-0.5 pl-1 pr-0.5 text-sm font-normal"
                                >choice for this review&thinsp;:</span
                              >
                            </div>
                          </div>
                          <!-- <p class="mt-10 text-center mb-2 question">When were you physically at the property?</p>
                          <p class="text-sm pb-6 text-center italic">(&thinsp;relative to when you first wrote the review&thinsp;)</p> -->
                        <div>
                          <input
                            type="radio"
                            id="postFullPublic"
                            value="postFullPublic"
                            name="reviewPrivacy"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label
                            class="ml-4 inline-flex align-bottom text-base"
                            for="postFullPublic"
                            ><fa
                              icon="user"
                              class="text-zaggray text-xl"
                            />&nbsp;&nbsp;Public</label
                          >
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="postAnonPublic"
                            value="postAnonPublic"
                            name="reviewPrivacy"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label
                            class="ml-4 inline-flex align-bottom text-base"
                            for="postAnonPublic"
                            ><fa
                              icon="user-secret"
                              class="text-zaggray text-xl"
                            />&nbsp;&nbsp;Incognito<span
                              class="ml-2 mt-1 inline-flex align-text-bottom text-xs"
                              >(user name)</span
                            ></label
                          >
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="keepFullySecret"
                            value="keepFullySecret"
                            name="reviewPrivacy"
                            required
                            v-model="fieldValToUpdate"
                          />
                          <label
                            class="ml-4 inline-flex align-bottom text-base"
                            for="keepFullySecret"
                            ><fa icon="ghost" class="text-zaggray text-xl" />&nbsp;&nbsp;Secret
                            <span class="ml-2 mt-1 inline-flex align-text-bottom text-xs"
                              >(your eyes only)</span
                            ></label
                          >
                        </div>
                        <div
                          v-if="reviewPrivacy === 'keepFullySecret'"
                          class="ml-8 inline-flex pt-6 align-text-bottom text-sm italic"
                        >
                          Secret reviews become Incognito after 30+ days
                          <span class="ml-1 mb-2 pb-2 text-xs italic underline"
                            ><Whoops whoopsButtonVifTextAndIcon="4"
                          /></span>
                        </div>
                      </div>
                      <!-- END 'reviewPrivacy' selection -->

                      </div>

                      <div v-if="itemType === 'tagarray'">
                        
                        <p v-if="whatToUpdate === 'propPros'" class="ml-1 mr-0.5 text-zagblue font-semibold pt-4 mb-8"><span class="text-zagblue font-semibold italic">Positive</span> things:</p>
                        <p v-if="whatToUpdate === 'propCons'" class="ml-1 mr-0.5 text-zagblue font-semibold pt-4 mb-8"><span class="text-zagblue font-semibold italic">Negative</span> things:</p>
                        
                        <div class="my-6">
                          <div v-if="fieldValueAsString.length > 0">
                          <div v-for="tag in fieldValueAsString" :key="tag.id">
                            <div class="mb-2 ml-2 lg:ml-6 text-base lowercase">
                              <fa
                                v-if="whatToUpdate === 'propPros'"
                                icon="plus-circle"
                                class="text-sm text-progreen/80"
                                alt="tag icon in a circle"
                              /><fa
                                v-if="whatToUpdate === 'propCons'"
                                icon="minus-circle"
                                class="text-sm text-conred/80"
                                alt="tag icon in a circle"
                              />
                              &nbsp;{{ tag }} 
                              <!-- <button class="delete" @click="removeTag(tag)">
                                <fa icon="times-circle" class="mx-0.5 text-lg text-gray-700" />
                              </button> -->
                            </div>
                          </div>
                        </div>
                        <div v-else><p class="italic">- none -</p></div>
                        
                        </div>
                       

                        <TagInput
                          class="peer focus:border-zagblue focus:ring-zagblue absolute w-full rounded-lg border-gray-300 placeholder-transparent hover:placeholder-transparent focus:placeholder-transparent md:w-full"
                          placeholder="separate , with , commas"
                          alt="add short tags describing positives, separating each with a comma"
                          id="whatToUpdate"
                          v-model="fieldValToUpdate"
                        />
                        <!-- <p class="text-sm italic text-center mt-4">separate , with , commas</p> -->
                        <p class="py-0.5 text-base bg-highlight rounded-lg italic text-center mt-4"><span class="semibold underline">IMPORTANT</span>&thinsp;: &nbsp;Add comma<span class="font-bold text-lg italic mx-0.5">,</span> After new<span class="font-bold text-lg italic mx-0.5">,</span></p>
                      </div>

                      <div v-if="itemType === 'starrating'">
                        <p class="mt-10 text-center font-semibold pb-2">Enter new {{ cleanWhatToUpdate }}:</p>
                       <!-- <p class="text-sm text-center italic">(&thinsp;current = {{fieldValueAsString}}&thinsp;)</p> -->

                        <!-- <input
                          type="text"
                          id="whatToUpdate"
                          class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                          placeholder="Type new info here"
                          v-model="fieldValue"
                        /> --> 

                        <!-- location_rating -->
                        <div class="inline-flex ml-14 sm:ml-16 mt-4 items-end">
                          <fieldset
                            class="rating float-left justify-end text-base sm:text-lg"
                            alt="five empty rating stars in a horizontal line. Select the star that matches your rating. Example, select 3rd star if rating is 3 out of 5"
                          >
                            <input
                              type="radio"
                              id="setting_rating_star5"
                              v-model.number="fieldValToUpdate"
                              value="5"
                            />
                            <label for="setting_rating_star5">5 stars</label>
                            <input
                              type="radio"
                              id="setting_rating_star4"
                              v-model.number="fieldValToUpdate"
                              value="4"
                            />
                            <label for="setting_rating_star4">4 stars</label>
                            <input
                              type="radio"
                              id="setting_rating_star3"
                              v-model.number="fieldValToUpdate"
                              value="3"
                            />
                            <label for="setting_rating_star3">3 stars</label>
                            <input
                              type="radio"
                              id="setting_rating_star2"
                              v-model.number="fieldValToUpdate"
                              value="2"
                            />
                            <label for="setting_rating_star2">2 stars</label>
                            <input
                              type="radio"
                              id="setting_rating_star1"
                              v-model.number="fieldValToUpdate"
                              value="1"
                            />
                            <label for="setting_rating_star1">1 star</label>
                          </fieldset>
                        </div>
                      </div>

                      <div v-if="itemType === 'deleteimage'">
                        <p class="mt-10 text-center mb-2">Enter new {{ cleanWhatToUpdate }}&thinsp;:</p>
                        <!-- <p class="text-sm text-center italic">(&thinsp;current = {{fieldValueAsString}}&thinsp;)</p> -->
                        <input
                          type="text"
                          id="whatToUpdate"
                          class="focus:border-zagblue focus:ring-zagblue mx-auto w-full rounded-lg border-gray-300"
                          placeholder="Type new info here"
                          v-model="fieldValue"
                        />
                      </div>

                      <span class="error" v-if="updateErr">{{
                        updateErr
                      }}</span>
                      <!-- <hr class="my-8" /> -->

                      <button
                        v-if="!isPending"
                        class="text-zaggray mt-12 mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 1 (update string, like displayName) -->


                <!-- BEGIN case 2 - update profile photo (profilePhotoUrl) -->
                <div v-if="updateCollAndSubCollVersion == 2">
                  <DialogTitle
                    class="text-left text-lg font-medium sm:text-lg"
                    ><span class="mr-0.5 text-lg font-medium underline"
                      >Update Info</span
                    >:</DialogTitle
                  >
                  <!-- BEGIN top-right close button -->
                  <div class="float-right -mt-8">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-xs font-normal text-gray-600/50 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      cancel
                      <fa
                        :icon="['far', 'times-circle']"
                        class="ml-1 text-base sm:ml-1.5"
                      />
                    </button>
                  </div>
                  <!-- END top-right close button -->

                  <div class="my-4 text-left">
                    <!-- <div v-if="user" class="my-4 text-left"> -->
                    <form
                      class="bg-slate-50/5"
                      @submit.prevent="handleUpdateProfilePhotoSubmit"
                    >
                      <p class="mt-10 text-center mb-2">Enter new {{ cleanWhatToUpdate }}&thinsp;:</p>
                      <!-- <p class="text-sm text-center italic">(&thinsp;current = {{fieldValueAsString}}&thinsp;)</p> -->
                      <input
                        type="file"
                        name="profilePic"
                        @change="handleProfilePicUpload"
                      />
                      <div class="error" v-if="fileError">{{ fileError }}</div>
                      <button
                        v-if="!isPending"
                        class="text-zaggray mt-12 mb-4 ml-[30%] rounded-md border border-slate-900 bg-white py-1.5 px-6 text-lg hover:bg-slate-900 hover:text-white hover:ring-pink-500"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button v-else disabled>Saving...</button>
                    </form>
                  </div>
                </div>
                <!-- END v-if case 2 - update profile photo (profilePhotoUrl) -->

                <div class="float-right mt-1">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-lg border border-transparent px-1.5 py-1 text-sm font-normal text-gray-600 hover:ring-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    cancel
                    <fa
                      :icon="['far', 'times-circle']"
                      class="ml-1.5 text-xl"
                    />
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- END HeadlessUI Dialogue code -->
  </div>
</template>

<script>
import { ref, computed } from "vue";

import useCollection from "../composables/useCollection";
import useSubCollection from "../composables/useSubCollection";
import getSetUserInfo from "@/composables/getSetUserInfo";
import useStorage from "@/composables/useStorage";
import Whoops from "@/components/Whoops";
import TagInput from "@/components/TagInput.vue";
import WaitingToLoad from "@/components/WaitingToLoad.vue";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  props: {
    whichItemToUpdate: String,
    // userId: String,
    review_id: String,
    propertyId: String,
    updateCollAndSubCollVersion: [Number, String],
    fieldValue: [String, Array],
    itemType: String,
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Whoops,
    TagInput: TagInput,
    WaitingToLoad,
  },

  setup(props, context) {
    const {
      updateErr,
      updateProfileField,
      updateAuthField,
      updateUserPassword,
    } = getSetUserInfo();
    const { filePath, url, uploadImage, getImages, fetchedImages } = useStorage();
    const { error: parentDocErr, document: parentDoc, getDocById, updateDocById} = useCollection("properties");
    const {updateSubDoc, subCollectionError} = useSubCollection('reviews', 'properties');
    const whatToUpdate = ref(props.whichItemToUpdate);
    const fieldValue = ref(props.fieldValue);
    const whatToUpdateAsString = whatToUpdate.value; // need fieldValue as a string, for cleanWhatToUpdate below
    const fieldValueAsString = fieldValue.value; // need fieldValue as a string, for cleanFieldValue below
    const itemType = ref(props.itemType);
    const imageFiles = ref([]);
    const selectedImages = ref("");
    const fileError = ref(null);

    // const removeTag = (index) => {
    //   tags.value.splice(index, 1);
    // };

    let fieldValToUpdate = ref("");
    if(itemType.value == 'string' || itemType.value == 'radio' || itemType.value == 'select' ||  itemType.value == 'starrating') {
      fieldValToUpdate = ref("");
      fieldValToUpdate.value = fieldValue.value;
    } else if(itemType.value == 'tagarray' || itemType.value == 'deleteimage') {
      fieldValToUpdate = ref([]);
      fieldValToUpdate.value = fieldValue.value;
    } else {
      fieldValToUpdate.value = fieldValue.value;
    }

    // const whichAccountToUpdate = ref(props.userId);
    const review_id = ref(props.review_id);
    const propertyId = ref(props.propertyId);
    const isOpen = ref(false);
    const isPending = ref(false);

    // Fields

    // BEGIN computed to remove '_' from starrating items, to display cleaner text to user
    const cleanWhatToUpdate = computed(() => {
        return whatToUpdateAsString.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ');   // per https://stackoverflow.com/a/11090301/1459653
    });
    console.log("cleanWhatToUpdate is ", cleanWhatToUpdate)
    // END computed to remove '_' from starrating items, to display cleaner text to user

    //const displayName = ref("");

    // BEGIN handleUpdateSubmit

    //  ** Taking 'itemType' prop and using it to convey the type of field being edited: string, tagarray, images, select, & starrating **
    
    const handleUpdateSubmit = async () => {
      console.log('in submit-', fieldValToUpdate.value, whatToUpdate.value);

      const updateItemObject = {};
      updateItemObject[whatToUpdate.value] = fieldValToUpdate.value;
      // console.log(updateItemObject); return false;
      await updateSubDoc(propertyId.value, review_id.value, updateItemObject);
      if(subCollectionError.value == null) {
        console.log("document updated successfully");
        await getDocById(propertyId.value);
          if(parentDocErr.value == null) {
            console.log('parentDoc--', parentDoc);
            const allReview = [...parentDoc.value.allReviews];
            console.log('old allreviwe-- ', allReview);
            let getExactReviewId = allReview.findIndex(function(element) {
               return element['review_id'] == review_id.value;
            });
            allReview[getExactReviewId][whatToUpdate.value] = fieldValToUpdate.value;
            const updateReview = {};
            updateReview['allReviews'] = allReview;
            await updateDocById(propertyId.value, updateReview);
          }
        isOpen.value = false;
      }
    };

    const handleUpdateProfilePhotoSubmit = async () => {
      // uploadImage(
      //   imageFiles.value[0],
      //   "profile_pics",
      //   whichAccountToUpdate.value
      // );
      // selectedImages.value = filePath.value;
      // const updateItemObject = {};
      // updateItemObject[whatToUpdate.value] = selectedImages.value;
      // await updateProfileField(whichAccountToUpdate.value, updateItemObject);
      // if (updateErr.value === null) {
      //   getImages([selectedImages.value]);

      //   setTimeout(() => {
      //     console.log(fetchedImages.value[0]);
      //     if (fetchedImages.value.length > 0) {
      //       fieldValue.value = fetchedImages.value[0];
      //       console.log("fieldValue-", fieldValue.value);
      //     }
      //   }, 2000);

      //   isOpen.value = false;
      // }
      // BEGIN Mark attempt to "refresh" Account page after update, so new data appears
      window.location.reload();
      //END Mark attempt to "refresh" Account page after update, so new data appears
    };

    const handleProfilePicUpload = (e) => {
      const types = ["image/png", "image/jpeg", "image/webp", "image/heic"];
      let allImages = e.target.files;
      if (!types.includes(allImages[0].type)) {
        fileError.value =
          "Please select an image file (png, heic, webp, jpg or jpeg)";
        isPending.value = true;
        return false;
      } else {
        // imageNames.value.push(allImages[i].name);
        fileError.value = null;
        isPending.value = false;
        imageFiles.value.push(allImages[0]);
      }
    };

    // END handleUpdateSubmit

    return {
      handleUpdateSubmit,
      handleProfilePicUpload,
      handleUpdateProfilePhotoSubmit,
      whatToUpdate,
      // whichAccountToUpdate,
      fieldValue,
      fieldValToUpdate,
      updateErr,
      itemType,
      isPending,
      fileError,
      review_id,
      propertyId,
      cleanWhatToUpdate,
      fieldValueAsString,

      // removeTag,

      isOpen,
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

<style scoped>
/* BEGIN star rating from https://jsfiddle.net/swfour/fnU5V/4/ */
.rating {
  /* float:left; */
  border: none;
}
.rating:not(:checked) > input {
  position: absolute;
  top: auto; /* was -9999px; */
  clip: rect(0, 0, 0, 0);
}
.rating:not(:checked) > label {
  float: right;
  width: 1.05em; /* was width:.90em; */
  padding: 0 0.1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 200%;
  line-height: 1.2;
  color: rgba(28, 117, 188, 0.43); /* was #868e96; */
}
.rating:not(:checked) > label:before {
  content: "★ "; /* was content:'★ '; */
}
.rating > input:checked ~ label {
  color: #ffc107;
}
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: rgba(28, 117, 188, 0.8);
}
.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffc107;
}
.rating > label:active {
  position: relative;
}
/* END star rating from https://jsfiddle.net/swfour/fnU5V/4/ */

.pac-item {
  cursor: pointer;
  /* color: zagblue; */
}

.pac-item:hover {
  background-color: #dedaee;
}

.pac-item-query {
  font-size: 16px;
}
</style>
