<template>
  <div class="">
    <Confetti />
    <h1
      class="my-16 mx-auto w-full break-words rounded-full bg-sky-300 py-8 px-2 text-center text-4xl lg:text-7xl font-bold sm:w-5/6 md:w-1/2"
    >
      Success!
    </h1>
    <h3
      class="bg-highlight mx-auto mb-16 break-words rounded-full py-6 px-2 text-center text-xl font-semibold sm:text-2xl w-5/6 sm:w-3/6 md:w-1/3"
    >
      Thank you.
    </h3>

    <p class="text-center">

      <button @click="goToReviewDetail" class="animate-pulse-slow hover:animate-none my-1 bg-indigo-900 mx-auto text-center px-8 py-4 justify-center align-center ak rounded-full border-4 text-white border-indigo-200 hover:bg-indigo-600 font-bold uppercase tracking-widest">
        See your review
      </button>
    </p>


    <div class="grid grid-cols-2 my-10 md:my-28">
      <!-- <div class="col-span-2 md:col-span-1 mx-auto mb-12 md:mb-0">
        <button class="borderbtn font-bold uppercase tracking-widest">
        Go to all reviews and Q&A
      </button>
      </div> -->
      <div class="col-span-2 md:col-span-2 mx-auto">
        <button
        class="borderbtn font-bold uppercase tracking-widest"
        @click="goHome"
      >
        home page
      </button>
      </div>

    </div>


  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from "vue-router";
import Confetti from "@/components/Confetti.vue";

export default {
  props: ["id"],
  setup: function (props, context) {
    const router = useRouter();
    const reviewId = ref(props.id);
    
    Confetti.stop
    const goHome = async () => {
      router.push({ name: "Home" });
    };

    const goToReviewDetail = () => {
      router.push({ name: 'ReviewDetails', params: { id: reviewId.value }})
    }
    
    return {
      goHome,
      goToReviewDetail,
    };
  },
  components: {
    Confetti,
  },
  methods: {
    start() {
      this.$confetti.start();
    },

    stop() {
      this.$confetti.stop();
    },
  },
};
</script>

<style></style>
