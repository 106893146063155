<template>
  <div
    class="mx-auto max-w-screen-md rounded-lg bg-slate-50 px-0 pt-4 pb-4 shadow-md ring-8 ring-slate-300 ring-opacity-80 ring-offset-8 ring-offset-slate-200 sm:px-0 md:px-4"
  >
    <h1 class="mt-2.5 text-center text-4xl font-bold">
      Get Verified<sup
        >&nbsp;<i class="fas fa-check-circle text-zagblue"></i
      ></sup>
    </h1>
    <p class="mt-2.5 text-base text-center">Become a <span class="text-zagblue text-base">verified<sup
          >&thinsp;<i class="fas fa-check-circle text-zagblue"></i
        ></sup></span> real esate pro</p>

    <!-- <details
      class="bg-rounded-lg mt-10 mb-6 rounded-lg p-2 text-center open:border open:border-slate-400 open:bg-white open:py-2.5"
    >
      <summary class="text-zagblue mb-4">
        <span class="question bg-highlight rounded-lg px-2 !text-lg">
          Why This Costs $5</span
        >
        <span class="text-zaggray text-xs font-light italic sm:text-sm">
          &thinsp;(click to show)</span
        >
      </summary>
      <p class="my-4 px-6 pt-2 text-left">
        Banks have lots of security measures to prevent identity theft & credit
        card fraud.
      </p>
      <p class="my-4 px-6 text-left">
        This tiny payment lets us piggy-back on all their security, blocking
        <span class="font-semibold">fraudsters</span> &
        <span class="font-semibold">millions of spam-bots</span>.
      </p> -->
      <!-- <p class="font-light italic px-4 text-left text-sm">* max be tax-deductible</p> -->
      <!-- <p class="my-4 px-6 text-left">
        This is part of why verified<sup
          >&thinsp;<i class="fas fa-check-circle text-zagblue"></i
        ></sup>
        real estate agents are
        <span class="font-semibold">more trustworthy</span> (and why our users
        are more likely to hire
        <span class="mr-0.5 font-semibold underline">YOU</span>).
      </p>
    </details> -->

    <!-- <p
      class="-mt-4 mb-4 text-center text-sm font-light italic before:font-semibold before:text-base before:mr-1 before:text-red-500 before:content-['*']"
    >means required
    </p> -->

    <form class="bg-slate-50 px-1 py-4 sm:px-4 mt-10" @submit.prevent="handleSubmit">
      <div class="inline-flex">
        <p class="question mt-0.5">
          What is your professional email address?<span
            ><sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            ><sup
              class="text-conred ml-0.5 text-xs font-normal italic"
              alt="required field"
              >required field</sup
            ></span
          >
        </p>
      </div>
      <div class="relative mx-auto my-8 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="email"
          placeholder="Email"
          id="profEmail"
          v-model.trim="profEmail"
          required
        />
         <label
          for="profEmail"
          class="getverifiedinputlabel"
          >Email</label
        >
      </div>

      <!-- BEGIN display this on your public profile? -->
      <div class="inline-flex">
        <p class="text-sm">Display this email on your public profile?
        <sup class="text-conred -mt-0.5 mr-1 text-base sm:mr-2.5">*</sup></p>

        <input
          type="radio"
          id="true"
          value="true"
          name="displayEmailOnPublicProfile"
          required
          v-model="displayEmailOnPublicProfile"
        />
        <label class="ml-1 mr-4 text-sm sm:ml-2" for="true">Yes</label>

        <input
          type="radio"
          id="false"
          value="false"
          name="displayEmailOnPublicProfile"
          required
          v-model="displayEmailOnPublicProfile"
        />
        <label class="ml-1 text-sm sm:ml-2" for="false">No</label>
      </div>
      <!-- END display this on your public profile? -->

      <hr class="my-10" />

      <div class="inline-flex">
        <p class="question mt-0.5">
          What is your phone number?<span
            ><sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            ><sup
              class="text-conred ml-0.5 text-xs font-normal italic"
              alt="required field"
              >required field</sup
            ></span
          >
        </p>
      </div>
      <div class="relative mx-auto my-8 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="Mobile or land line"
          id="profPhone"
          v-model.trim="profPhone"
          required
        />
         <label
          for="profPhone"
          class="getverifiedinputlabel"
          >Mobile or land line</label
        >
      </div>

      <!-- BEGIN display this on your public profile? -->
      <div class="inline-flex">
        <span class="mr-1 text-sm sm:mr-2.5"
          >Display this phone # on your public profile?<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            ></span
        >

        <input
          type="radio"
          id="true"
          value="true"
          name="displayPhoneOnPublicProfile"
          required
          v-model="displayPhoneOnPublicProfile"
        />
        <label class="ml-1 mr-4 text-sm sm:ml-2" for="true">Yes</label>

        <input
          type="radio"
          id="false"
          value="false"
          name="displayPhoneOnPublicProfile"
          required
          v-model="displayPhoneOnPublicProfile"
        />
        <label class="ml-1 text-sm sm:ml-2" for="false">No</label>
      </div>
      <!-- END display this on your public profile? -->

      <hr class="my-10" />

      <p class="question mt-0.5">
        What is your full name, exactly as printed on your real estate
        license?<span
          ><sup
            class="text-conred mt-1 text-base font-normal"
            alt="required field"
            >*</sup
          ></span
        >
      </p>
      <div class="relative mx-auto my-8 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="First name"
          id="realFirstName"
          v-model.trim="realFirstName" 
          required
        />
        <label
          for="realFirstName"
          class="getverifiedinputlabel"
          >First name (forename)<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
        </label>
      </div>
      <div class="relative mx-auto my-8 text-center">
        <input
          class="peer my-4 w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="Middle name or initial"
          id="realMiddleNameOrInit"
          v-model.trim="realMiddleNameOrInit"
        />
        <label
          for="realMiddleNameOrInit"
          class="peer-placeholder-shown:left-26 text-zaggray/70 absolute -top-2 left-1 pl-0 text-sm transition-all duration-500 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:opacity-80 peer-focus:-top-2 peer-focus:text-sm peer-focus:text-indigo-900/70 sm:left-16 sm:pl-0 sm:peer-placeholder-shown:left-16"
          >Middle name (or initial)</label
        >
      </div>

      <div class="relative mx-auto my-8 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="Last name"
          id="realLastName"
          v-model.trim="realLastName"
          required
        />
        <label
          for="realLastName"
          class="getverifiedinputlabel"
          >Last name (surname)<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            ></label
        >
      </div>

      <hr class="my-10" />

      <p class="question mt-0.5">
        About your
        <span class="question mr-0.5 !italic">primary</span> active real estate
        license:
      </p>

      <!-- <p class="text-sm font-light italic">
        (if you have more than one, you can add others later)
      </p> -->

      <div class="justify-center pt-8">
        <p class="text-base">
          U.S.<span class="px-0.5 text-sm">/</span>Canadian state, province or
          territory:<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
        </p>
        <!-- BEGIN "state, province or territory" as a selection -->
        <div class="flex justify-center space-y-0 pt-4">
          <select
            required
            name="primaryLicenseGovernment"
            id="primaryLicenseGovernment"
            v-model="primaryLicenseGovernment"
            @change="toggleExtendedAddr"
            class="text-zaggray w-full rounded-md border-gray-300 bg-white hover:border-gray-400 hover:placeholder-gray-600 focus:bg-white focus:placeholder-gray-400 sm:w-5/6"
          >
            <option disabled value="">Please select</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
            <option value="">---------------------</option>
            <option value="AS">American Samoa</option>
            <option value="GU">Guam</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="PR">Puerto Rico</option>
            <option value="UM">U.S. Minor Outlying Islands</option>
            <option value="VI">Virgin Islands</option>
            <option value="">---------------------</option>
            <option value="AB">Alberta</option>
            <option value="BC">British Columbia</option>
            <option value="MB">Manitoba</option>
            <option value="NB">New Brunswick</option>
            <option value="NL">Newfoundland and Labrador</option>
            <option value="NS">Nova Scotia</option>
            <option value="ON">Ontario</option>
            <option value="PE">Prince Edward Island</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="NT">Northwest Territories</option>
            <option value="NU">Nunavut</option>
            <option value="YT">Yukon</option>
          </select>
        </div>
      </div>
      <!-- END "state, province or territory" as a selection -->

      <p class="pt-10 text-base">
        Real estate license number?<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="primaryLicenseNumber"
          v-model.trim="primaryLicenseNumber"
          required
        />
        <label
          for="primaryLicenseNumber"
          class="getverifiedinputlabel"
          >type carefully</label
        >
      </div>

      <p class="pt-8 text-base">
        What <span class="text-base italic">type</span> of real estate
        license?<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="primaryLicenseType"
          v-model.trim="primaryLicenseType"
          required
        />
        <label
          for="primaryLicenseType"
          class="getverifiedinputlabel"
          >broker, salesperson, referral, etc.</label
        >
      </div>

      <hr class="my-10" />

      <p class="question mt-0.5">
        What real estate business or brokerage are you affiliated with?
      </p>
      <p class="text-sm font-light italic">
        (business name, address, phone, website & contact person)
      </p>

      <p class="pt-8 text-base">
        Business or brokerage name:<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="businessName"
          v-model.trim="businessName"
        />
        <label
          for="businessName"
          class="getverifiedinputlabel"
          >XYZ Real Estate Company, LLC</label
        >
      </div>

      <p class="pt-8 text-base">
        Full address:<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="full address"
          id="businessAddress"
          v-model.trim="businessAddress"
        />
        <label
          for="businessAddress"
          class="getverifiedinputlabel"
        >12 Main St. 3rd Floor Anytown, MI 12345</label>
      </div>

      <p class="pt-8 text-base">
        Telephone number:<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="businessPhone"
          v-model.trim="businessPhone"
        />
        <label
          for="businessPhone"
          class="getverifiedinputlabel"
          >123-456-7890</label
        >
      </div>

      <p class="pt-8 text-base">
        Website or web page:<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="businessWebsite"
          v-model.trim="businessWebsite"
        />
        <label
          for="businessWebsite"
          class="getverifiedinputlabel"
          >www.yourwebsite.com</label
        >
      </div>

      <p class="pt-8 text-base">
        Licensed person who can verify you <span class="text-sm italic mr-0.5">(we will contact them)</span>:<sup
              class="text-conred mt-1 text-base font-normal"
              alt="required field"
              >*</sup
            >
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="licensedVerifyingPerson"
          v-model.trim="licensedVerifyingPerson"
        />
        <label
          for="licensedVerifyingPerson"
          class="getverifiedinputlabel"
          >name + title</label
        >
      </div>

      <p class="pt-8 text-base">
        Franchise the business is
        affilliated with <span class="mr-0.5 text-sm">(</span>if any<span
          class="ml-0.5 text-sm"
          >)</span
        >:
      </p>
      <div class="relative mx-auto my-4 text-center">
        <input
          class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
          type="text"
          placeholder="broker, salesperson, referral, etc."
          id="businessFranchise"
          v-model.trim="businessFranchise"
        />
        <label
          for="businessFranchise"
          class="getverifiedinputlabel"
          >RE/MAX, Compass, independent, etc.</label
        >
      </div>

      <!-- <div class="relative mx-auto my-8 text-center">
        <textarea
          class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 md:w-full"
          v-model.trim="realEstateAffiliations"
          placeholder="We contact them, as part of your verification"
          rows="4"
        ></textarea>
      </div> -->

      <hr class="my-10" />

      <div class="bg-indigo-50/20 rounded-lg pb-2">
        <p class="font-semibold text-xl text-center underline">Territory where you specialize</p>
        <p class="my-2.5 text-base font-light italic">
          Below, we ask for <span class="underline">one</span> geographic area you specialize in with your real
          estate business, such as a town or neighborhood.
        </p>
        <p class="my-2.5 text-base font-light italic">
          Please only enter a location where you have genuine expertise and truly
          know the market. 
        </p>

        <p class="my-2.5 text-base font-light italic">
          Also, please make sure the location you enter is not too
          large<button class="ml-0">
            <Whoops whoopsButtonVifTextAndIcon="25" /></button
          > ("Los Angeles"), or it will be rejected.
        </p>

        <p class="my-2.5 text-base font-light italic">
          This will be used to help
          <span class="zagnetic">zagnetic</span> and users determine whether
          you're a good fit to help with their home search.
        </p>

        <!-- <p class="my-2.5 text-base font-light italic">
          An exception is if you specialize in a very specific niche, within a huge or populous territory, such as horse ranches over 10,000 acreas in the Rocky Mountains, or brownstones and townhomes in Brooklyn.
        </p>

        <p class="my-2.5 text-base font-light italic">
          Picking a territory you specialize in may seem like it limits you. In a way it does, because there are more total people looking to buy home in all of Manhattan than just Tribeca. However, a clear focus helps customers find agents that truly know the particular area they're searching in. We may expand this in some way, but for now, everyone gets just only area they specialize in.  
        </p> -->

        <p class="my-2.5 text-base font-light italic">
          NOTE: We verify a transaction of yours in the territory you identify below, to help ensure agents have experience in that particular market.
        </p>

        <div class="mt-10 pb-2">
          <p class="question mt-0.5">
            What is a specific location or territory<button class="-ml-0.5">
            <Whoops whoopsButtonVifTextAndIcon="25" /></button
          > where you specialize?
          </p>
          <div class="relative mx-auto my-8 text-center">
            <input
              class="peer w-full rounded-md placeholder-transparent shadow-sm hover:placeholder-transparent focus:placeholder-transparent sm:w-5/6"
              type="text"
              placeholder="location"
              id="primarySpecialtyLocation"
              v-model.trim="primarySpecialtyLocation"
            />
            <label
              for="primarySpecialtyLocation"
              class="getverifiedinputlabel"
              >a specific location</label
            >
          </div>

          <p class="question mt-0.5">
            Are there any details about your business that give context to the location or territory you identify above, such as a particular niche with a large market?
          </p>
         <div class="relative mx-auto my-8 text-center">
            <textarea
              class="focus:border-zagblue focus:ring-zagblue w-full sm:w-5/6 rounded-lg border-gray-300"
              v-model.trim="primarySpecialtyLocationContext"
              placeholder=""
              rows="4"
            ></textarea>
          </div>

          <p class="question mt-0.5">
            Add details of a transaction of yours, during the past 30 months, within the
            geographic location you entered above:
          </p>
          <p class="text-sm font-light italic">
            Please include the addresses and whether you represented the buyer,
            seller or both. (exact address, whether you represented the
            buyer/seller, counter-party representing the other side of the
            transaction, and that counter-party's contact info, so we can
            verify)
          </p>

          <div class="relative mx-auto my-8 text-center">
            <textarea
              class="focus:border-zagblue focus:ring-zagblue w-full sm:w-5/6 rounded-lg border-gray-300"
              v-model.trim="primarySpecialtyLocationDealDetails"
              placeholder="We contact the other party, to verify"
              rows="4"
            ></textarea>
          </div>
        </div>

      </div>

              <hr class="my-10" />

      <!-- BEGIN additional licenses or anything else -->

      <p class="question mt-0.5">
        Please enter additional information you think we need, including details on any
        <span class="question mr-1 !italic underline">other</span>
        <span class="question mr-0.5 !italic">active</span> real estate
        licenses you have:
      </p>
      <!-- <span class="text-sm font-normal italic"
          >(state/province, license number & type of license) </span
        > -->

      <div class="relative mx-auto my-8 text-center">
        <textarea
          class="focus:border-zagblue focus:ring-zagblue w-full rounded-lg border-gray-300 sm:w-5/6"
          v-model.trim="additionalInfoAndLicenses"
          placeholder="Leave blank if none"
          rows="4"
        ></textarea>
      </div>
      <!-- END additional licenses or anything else -->

      <hr class="my-10" />

      <div class="flex justify-center pt-0 pb-0">
        <label class="mx-auto inline-flex items-center">
          <input
            type="checkbox"
            class="-mt-28 rounded-sm sm:-mt-16 sm:mb-1.5 lg:-mt-10"
            required
          />
          <div
            class="ml-2 mt-2 text-sm leading-6 sm:mt-0 sm:text-base sm:leading-normal"
          >
            In my capacity as a licensed real estate professional, I attest
            everything above to be true, complete and correct to the best of my
            knowledge and ability. Further, I submit this in agreement with the
            <span class="text-sm underline sm:text-base"
              ><router-link class="text-sm sm:text-base" :to="{ name: 'Terms' }"
                >terms</router-link
              ></span
            >
            and
            <span class="text-sm underline sm:text-base"
              ><router-link
                class="text-sm sm:text-base"
                :to="{ name: 'Privacy' }"
                >privacy policy.</router-link
              ></span
            >
          </div>
        </label>
      </div>
       <p class="ml-6 pt-2.5 text-sm leading-6 sm:mt-0 sm:text-base sm:leading-normal">I understand users may be able to see some or all of the information above in
        my public profile.</p>

      <!-- <p class="my-12 italic font-medium sm:px-8 text-center text-medium">
        $5- verification with a financial institution after "Submit"<button class="ml-1">
            <Whoops whoopsButtonVifTextAndIcon="28" /></button
          >
      </p> -->
     
      <div v-if="error" class="error">{{ error }}</div>
      <div class="flex justify-center pt-0 pb-4">
        <button v-if="!isPending" class="text-zaggray rounded-md border border-indigo-900 py-1.5 px-5 text-lg hover:bg-indigo-900 hover:text-white hover:ring-pink-500 w-50% mx-auto">
          Submit
        </button>
        <button v-if="isPending" class="borderlessbtn w-50% mx-auto" disabled>
          <!-- Loading -->
          <WaitingToLoad />
        </button>
        <!-- <p v-if="form_submitted_text" class="bg-progreen text-white text-lg py-2 px-4 rounded-lg font-semibold">{{ form_submitted_text }}</p> -->
      </div>
       <p v-if="form_submitted_text" class="bg-progreen text-white text-lg py-2 px-4 rounded-lg text-center my-6 font-semibold">{{ form_submitted_text }}</p>
    </form>

    <div class="my-8 mx-auto text-center text-sm text-gray-500 sm:text-base">
      <span v-if="!form_submitted_text" class="text-sm font-medium sm:text-base">Cancel and go to </span
      ><span class="text-sm underline sm:text-base"
        ><router-link class="text-sm sm:text-base" :to="{ name: 'Home' }"
          >Home page</router-link
        ></span
      >
    </div>

    <!-- <img src="@/assets/images/rounded-square-logo.svg" alt="Zagnetic rounded square logo" class="mx-auto w-1/3 md:w-1/4 py-6"> -->
  </div>
</template>

<script>
// using @ means start at the project src root
import useLogin from "@/composables/useLogin";
import getUser from "@/composables/getUser";
//import useSubCollection from "../../composables/useSubCollection";
import useDocument from "../../composables/useDocument";
import { ref } from "vue";
import { useRouter } from "vue-router";
import Whoops from "@/components/Whoops";
import WaitingToLoad from "@/components/WaitingToLoad.vue";

export default {
  components: {
    Whoops,
    WaitingToLoad,
  },

  setup() {
    const { error, login, isPending } = useLogin();
    const router = useRouter();
    const { user } = getUser();
    // const {subCollectionError, addSubDocWithName } = useSubCollection('public_data', 'users');
    const { error: updateErr, updateMyDoc } = useDocument('users', user.value.uid);

    const profEmail = ref("");
    const displayEmailOnPublicProfile = ref("");
    const profPhone = ref("");
    const displayPhoneOnPublicProfile = ref("");

    const realFirstName = ref("");
    const realMiddleNameOrInit = ref("");
    const realLastName = ref("");

    const primaryLicenseGovernment = ref("");
    const primaryLicenseNumber = ref("");
    const primaryLicenseType = ref("");

    const businessName = ref("");
    const businessAddress = ref("");
    const businessPhone = ref("");
    const businessWebsite = ref("");
    const licensedVerifyingPerson = ref("");
    const businessFranchise = ref("");

    const primarySpecialtyLocation = ref("");
    const primarySpecialtyLocationContext = ref("");
    const primarySpecialtyLocationDealDetails = ref("");

    const additionalInfoAndLicenses = ref("");
    const dateVerified = ref(null); // this will be a timestamp, to track application backlog internally, and once verified, the date the user became verified
    const form_submitted_text = ref(null);
    const handleSubmit = async (event) => {
      // const res = await addSubDocWithName({
      //   profEmail: profEmail.value,
      //   displayEmailOnPublicProfile: displayEmailOnPublicProfile.value,
      //   profPhone: profPhone.value,
      //   displayPhoneOnPublicProfile: displayPhoneOnPublicProfile.value,
      //   realFirstName: realFirstName.value,
      //   realMiddleNameOrInit: realMiddleNameOrInit.value,
      //   realLastName: realLastName.value,
      //   primaryLicenseGovernment: primaryLicenseGovernment.value,
      //   primaryLicenseNumber: primaryLicenseNumber.value,
      //   primaryLicenseType: primaryLicenseType.value,
      //   businessName: businessName.value,
      //   businessAddress: businessAddress.value,
      //   businessPhone: businessPhone.value,
      //   businessWebsite: businessWebsite.value,
      //   licensedVerifyingPerson: licensedVerifyingPerson.value,
      //   businessFranchise: businessFranchise.value,
      //   primarySpecialtyLocation: primarySpecialtyLocation.value,
      //   primarySpecialtyLocationContext: primarySpecialtyLocationContext.value,
      //   primarySpecialtyLocationDealDetails: primarySpecialtyLocationDealDetails.value, 
      //   additionalInfoAndLicenses: additionalInfoAndLicenses.value,
      //   dateVerified: dateVerified.value,
      //   parentDocId: user.value.uid,
      // });

      // if(subCollectionError.value === null) {
      //   form_submitted_text.value = "Your form submitted successfully";
      //   event.target.reset();
      // } else {
      //   form_submitted_text.value = "Error in form submission, please try after some time."
      // }
      const res = await updateMyDoc({
        profEmail: profEmail.value,
        displayEmailOnPublicProfile: displayEmailOnPublicProfile.value,
        profPhone: profPhone.value,
        displayPhoneOnPublicProfile: displayPhoneOnPublicProfile.value,
        realFirstName: realFirstName.value,
        realMiddleNameOrInit: realMiddleNameOrInit.value,
        realLastName: realLastName.value,
        primaryLicenseGovernment: primaryLicenseGovernment.value,
        primaryLicenseNumber: primaryLicenseNumber.value,
        primaryLicenseType: primaryLicenseType.value,
        businessName: businessName.value,
        businessAddress: businessAddress.value,
        businessPhone: businessPhone.value,
        businessWebsite: businessWebsite.value,
        licensedVerifyingPerson: licensedVerifyingPerson.value,
        businessFranchise: businessFranchise.value,
        primarySpecialtyLocation: primarySpecialtyLocation.value,
        primarySpecialtyLocationContext: primarySpecialtyLocationContext.value,
        primarySpecialtyLocationDealDetails: primarySpecialtyLocationDealDetails.value, 
        additionalInfoAndLicenses: additionalInfoAndLicenses.value,
        dateVerified: dateVerified.value,
        parentDocId: user.value.uid,
      });

      if(updateErr.value === null) {
        form_submitted_text.value = "Success! Your form was submitted.";
        event.target.reset();
        router.push({ name: "ThanksAfterGetVerified" });
      } else {
        form_submitted_text.value = "Error in form submission, please try after some time."
      }
    };

    return {
      profEmail,
      displayEmailOnPublicProfile,
      profPhone,
      displayPhoneOnPublicProfile,

      realFirstName,
      realMiddleNameOrInit,
      realLastName,
      primaryLicenseGovernment,
      primaryLicenseNumber,
      primaryLicenseType,
      businessName,
      businessAddress,
      businessPhone,
      businessWebsite,
      licensedVerifyingPerson,
      businessFranchise,

      primarySpecialtyLocation,
      primarySpecialtyLocationContext,
      primarySpecialtyLocationDealDetails,

      additionalInfoAndLicenses,

      handleSubmit,
      error,
      isPending,

      form_submitted_text,
    };
  },
};
</script>
