import { ref, watchEffect } from "vue";
import { projectFirestore } from "../firebase/config";
import { collectionGroup, query, where, onSnapshot, limit } from "firebase/firestore";  

const getSubCollectionDoc = (subCollection, key, value, comparison) => {
  let document = ref(null);
  let error = ref(null);
  
  const q = query(collectionGroup(projectFirestore, subCollection), where(key, comparison, value), limit(1));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const results = [];
    querySnapshot.forEach((doc) => {
      document.value = { ...doc.data(), id: doc.id };
    });
    // console.log("Current value is: ", document.value);
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsubscribe());
  });

  return { error, document };
};

export default getSubCollectionDoc;
