<template>
  <div>
    <h1>{{ itembeingrated }} is the item being rated</h1>
    <br />
    <p>And the rating for that is {{ rating }}</p>
  </div>
</template>

<script>
export default {
  props: ["itembeingrated", "rating"],
};
</script>

<style></style>
