<template>
<div class="my-20 ml-[42%]">
  <div
    class="h-16 w-16 animate-spin-very-slow rounded-full border-l border-dotteddd border-indigo-900/70 bg-gradient-to-r from-pink-500/0 to-violet-500/0 text-transparent"
  > 
    <div
      class="h-10 w-10 animate-spin-slow rounded-full border-t border-b-4 border-dotted border-pink-300 "
    > <div
      class="h-14 w-14 animate-spin-slow rounded-full border-t-4 border border-dotted border-sky-300"
    ><div
      class="h-24 w-24 animate-spin rounded-full border-t-8 border border-dotted border-violet-300/50"
    ></div></div></div>
  </div>
  <p class="mt-24 text-slate-500 font-light animate-bounce-slow">loading<span class="text-slate-500/70 ml-0.5">...</span></p>
  
  </div>
</template>

<script>
export default {};
</script>

<style></style>
