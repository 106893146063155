<template>
  <div
    class="mx-auto max-w-screen-md rounded-md bg-white px-0 pt-4 pb-4 shadow-sm sm:bg-gray-50 sm:px-0 md:px-4"
    id="topofpage"
  >
    <div v-if="loggedInUser">
      <p class="mb-4 text-center text-4xl font-bold sm:mb-6">Account</p>

      <!-- BEGIN quick page nav -->
      <div class="mb-16 text-center">
        <a href="#profile" class="scroll-smooth"
          ><button class="borderbtnsmall mr-2.5">Profile</button>
        </a>

        <span v-if="loggedInUser.isRealEstateAgent != 'no'"
          ><a href="#realestatepro" class="scroll-smooth"
            ><button class="borderbtnsmall mr-2.5">Real Estate Pro</button>
          </a></span
        >

        <a href="#activityandstats" class="scroll-smooth"
          ><button class="borderbtnsmall mr-2.5">Activity & stats</button>
        </a>

        <a href="#settingsandprivacy" class="scroll-smooth"
          ><button class="borderbtnsmall mr-2.5">Settings & privacy</button>
        </a>

        <a href="#notifications" class="scroll-smooth"
          ><button class="borderbtnsmall mr-2.5">Notifications</button>
        </a>

        <a href="#helpandsupport" class="scroll-smooth"
          ><button class="borderbtnsmall mr-2.5">Help & support</button>
        </a>

        <a href="#legal" class="scroll-smooth"
          ><button class="borderbtnsmall">Legal</button>
        </a>
      </div>
      <!-- END quick page nav -->

      <div class="col-span-11 rounded-md sm:col-span-10">
        <div class="mb-8 scroll-mt-12" id="profile">
          <h2 class="mb-6 text-xl font-semibold">
            <fa
              :icon="['fas', 'user-circle']"
              class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
            />Profile for {{ loggedInUser.realFirstName }}&nbsp;{{
              loggedInUser.realMiddleNameOrInit
            }}&nbsp;{{ loggedInUser.realLastName }}
          </h2>

          <ul class="ml-8 space-y-6 italic sm:space-y-5">
            <li class="text-base">
              <span class="text-base underline">Member since</span>: &thinsp;{{
                loggedInUser.createdAt
                  .toDate()
                  .toLocaleString("en-US", { month: "long" })
              }}
              {{ loggedInUser.createdAt.toDate().getUTCFullYear() }}
            </li>
            <!-- <li class="text-base">Last signed in: {{ user.lastLoginAt.toDate().toLocaleString() }}</li> -->

            <li class="text-base" :key="user.displayName">
              <span class="text-base underline">User name</span>:<button
                class="-mt-0"
              >
                &thinsp;<Whoops whoopsButtonVifTextAndIcon="29" />
              </button>
              &thinsp;{{ user.displayName }}
              &nbsp;
              <button>
                <UpdateUserInfo
                  :fieldValue="user.displayName"
                  updateUserInfoVersion="1"
                  whichItemToUpdate="displayName"
                  :userId="user.uid"
                  itemType="authAndProfile"
                />
              </button>
            </li>

            <!-- BEGIN update profile photo -->
            <li v-if="loggedInUser.profilePhotoUrl == null" class="text-base">
              You don't have a profile photo<fa
                :icon="['fa', 'camera']"
                alt="camera icon"
                class="text-md mx-1.5 opacity-80"
              />yet!
              <button>
                <UpdateUserInfo
                  :fieldValue="profilePic"
                  updateUserInfoVersion="2"
                  whichItemToUpdate="profilePhotoUrl"
                  :userId="user.uid"
                  itemType="profilePhotoUrl"
                />
              </button>
            </li>
            <li v-else class="-ml-8 text-base" :key="profilePic">
              <span class="ml-8 text-base underline">Profile photo</span>:
              <!-- {{ profilePic }} -->
              <!-- <button>
                <UpdateUserInfo
                  :fieldValue="profilePic"
                  updateUserInfoVersion="2"
                  whichItemToUpdate="profilePhotoUrl"
                  :userId="user.uid"
                  itemType="profilePhotoUrl"
                />
              </button> -->
              <div class="">
                <div class="mx-auto w-1/2 sm:w-1/3">
                  <img
                    v-if="fetchedImages.length"
                    :src="fetchedImages[0]"
                    alt="user's profile photo"
                    class="mt-2 mb-4 rounded-xl sm:-mt-4"
                  />
                </div>
              </div>
              <div class="mx-auto mb-12 text-center">
                <button>
                  <UpdateUserInfo
                    :fieldValue="profilePic"
                    updateUserInfoVersion="2"
                    whichItemToUpdate="profilePhotoUrl"
                    :userId="user.uid"
                    itemType="profilePhotoUrl"
                  />
                </button>
              </div>
            </li>
            <!-- END update profile photo -->

            <li class="text-base">
              <!-- <p class="tempdevnote"> userId = {{loggedInUser.userId}}</p> -->
              <span class="text-base underline">Your public profile</span>:
              &thinsp;
              <button class="">
                <router-link
                  :to="{ name: 'PublicProfile', params: { id: user.uid } }"
                  class="changebtn"
                  ><span class="pt-0 text-xs">click to view</span
                  ><fa icon="external-link-alt" class="ml-1 text-sm"
                /></router-link>
              </button>
            </li>

            <li v-if="loggedInUser.isRealEstateAgent == 'no'" class="text-base">
              <span class="text-base underline">Status</span>: &thinsp;You are
              not a real estate professional&nbsp;
              <button class="">
                <router-link
                  :to="{ name: 'GetVerified', params: { id: user.uid } }"
                  class="changebtn"
                  ><span class="pt-0 text-xs">change</span
                  ><fa icon="edit" class="ml-1 text-sm"
                /></router-link>
              </button>
            </li>
          </ul>
          <a href="#topofpage"
            ><button class="nonbtn mt-16 mb-2">
              <span class="text-xs"
                >back to top<i class="fa fa-arrow-up ml-2"></i
              ></span></button
          ></a>

          <hr class="mb-8" />
        </div>

        <div v-if="loggedInUser.isRealEstateAgent != 'no'">
          <div class="mb-8 scroll-mt-12" id="realestatepro">
            <h2 class="mb-6 text-xl font-semibold">
              <fa
                :icon="['fas', 'user-shield']"
                class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
              />Real Estate Professional
            </h2>

            <ul class="ml-10 space-y-5 italic">
              <!-- BEGIN whether user is a verified real estate professional -->
              <div v-if="loggedInUser.isRealEstateAgent == 'verified'">
                <div
                  class="bg-highlight rounded-xl px-2 pt-2 pb-1.5 text-base sm:px-1.5 md:px-2"
                >
                  <span class="text-base font-semibold">Congratulations!</span>
                  You are a
                  <span class="text-zagblue inline-flex text-base font-semibold"
                    >verified<sup
                      >&thinsp;<i
                        class="fas fa-check-circle -mb-2"
                      ></i></sup></span
                  >real estate professional&thinsp;<button>
                    <Whoops whoopsButtonVifTextAndIcon="11" />
                  </button>
                </div>

                <li
                  class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                >
                  All of your public reviews, questions & answers show your
                  <span class="text-zagblue inline-flex text-base font-semibold"
                    >verified<sup
                      >&thinsp;<i class="fas fa-check-circle -mb-2"></i></sup
                  ></span>
                  status.
                </li>
                <li
                  class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                >
                  When users visit your public profile, they see your
                  <span class="zagnetic !text-base">zagnetic</span>
                  contributions and all the times they've been upvoted or marked
                  '&thinsp;helpful&thinsp;' by other users, as well as your
                  contact information and one market you specialize in.
                </li>
              </div>

              <div v-else-if="loggedInUser.isRealEstateAgent == 'unverified'">
                <li class="text-base">
                  You're listed as a real estate professional, but
                  <span class="font-semibold underline">not</span> yet
                  <span class="text-zagblue inline-flex font-semibold"
                    >verified&nbsp;<Whoops whoopsButtonVifTextAndIcon="11"
                  /></span>
                  &nbsp;
                </li>

                <li
                  v-if="!loggedInUser.primaryLicenseNumber"
                  class="mx-auto text-center text-base"
                >
                  <!-- 'loggedInUser.primaryLicenseNumber'only exists if user has submitted the GetVerified form -->
                  <button
                    class="border-zaggray text-zaggray mt-6 mr-8 rounded-md border bg-white px-4 py-2 text-sm hover:border hover:border-indigo-500 hover:bg-indigo-900 hover:text-white focus:outline-none focus:ring focus:ring-indigo-300 active:bg-indigo-300"
                  >
                    <router-link :to="{ name: 'GetVerified' }">
                      Get verified<sup
                        >&nbsp;<i class="fas fa-check-circle text-zagblue"></i
                      ></sup>
                      now</router-link
                    >
                  </button>
                </li>
                <li class="mx-auto text-left text-base">
                  <!-- 'loggedInUser.primaryLicenseNumber'only exists if user has submitted the GetVerified form -->
                  <p class="pt-6 text-base">
                    You've submitted the Get Verified<sup
                      >&nbsp;<i class="fas fa-check-circle text-zagblue"></i
                    ></sup>
                    form (thank you!), but the verification process hasn't yet
                    been completed.
                  </p>
                </li>
              </div>
              <!-- END whether user is a verified real estate professional -->

              <div v-if="loggedInUser.isRealEstateAgent != 'no'">
                <p class="pt-6 pb-4 text-base font-medium">
                  <span class="text-base font-medium underline"
                    >Here's your verification info</span
                  >:
                </p>
                <div v-if="loggedInUser.primaryLicenseNumber">
                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Professional email:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.profEmail }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.profEmail"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="profEmail"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </li>
                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Display email on your public profile:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.displayEmailOnPublicProfile }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.displayEmailOnPublicProfile"
                        updateUserInfoVersion="11"
                        whichItemToUpdate="displayEmailOnPublicProfile"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </li>
                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Professional phone:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.profPhone }}</span
                    ><button>
                      <UpdateUserInfo
                        :fieldValue="user.profPhone"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="profPhone"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </li>
                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Display phone on your public profile:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.displayPhoneOnPublicProfile }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.displayPhoneOnPublicProfile"
                        updateUserInfoVersion="11"
                        whichItemToUpdate="displayPhoneOnPublicProfile"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </li>
                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    First name:<span class="ml-2 text-base font-semibold">{{
                      loggedInUser.realFirstName
                    }}</span>
                  </li>
                  <li
                    v-if="loggedInUser.MiddleNameOrInit"
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Middle name or initial:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.MiddleNameOrInit }}</span
                    >
                  </li>
                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Last name:<span class="ml-2 text-base font-semibold">{{
                      loggedInUser.realLastName
                    }}</span>
                  </li>

                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Regarding your your primary active real estate license:
                  </li>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;issuing government:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.primaryLicenseGovernment }}</span
                    >
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;license number:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.primaryLicenseNumber }}</span
                    >
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;license type:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.primaryLicenseType }}</span
                    >
                  </p>

                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Real estate business you're affiliated with:
                  </li>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;business name:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.businessName }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.businessName"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="businessName"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;address:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.businessAddress }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.businessAddress"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="businessAddress"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;phone:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.businessPhone }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.businessPhone"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="businessPhone"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;website:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.businessWebsite }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.businessWebsite"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="businessWebsite"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;licensed person to verify you:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.licensedVerifyingPerson }}</span
                    >
                  </p>
                  <p
                    v-if="loggedInUser.businessFranchise"
                    class="ml-20 mr-4 mt-4 list-disc text-base leading-relaxed"
                  >
                    franchise:<span class="ml-2 text-base font-semibold">{{
                      loggedInUser.businessFranchise
                    }}</span>
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.businessFranchise"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="businessFranchise"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </p>

                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Regarding the location:
                  </li>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;one location where you specialize<button>
                      <Whoops whoopsButtonVifTextAndIcon="25" /></button
                    ><span class="ml-2 text-base font-semibold">{{
                      loggedInUser.primarySpecialtyLocation
                    }}</span>
                  </p>
                  <p
                    v-if="loggedInUser.primarySpecialtyLocationContext"
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;context about your work in this location:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.primarySpecialtyLocationContext }}</span
                    >
                    <button>
                      <UpdateUserInfo
                        :fieldValue="user.primarySpecialtyLocationContext"
                        updateUserInfoVersion="1"
                        whichItemToUpdate="primarySpecialtyLocationContext"
                        :userId="user.uid"
                        itemType="profile"
                      />
                    </button>
                  </p>
                  <p
                    class="ml-12 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-20"
                  >
                    &#8231;&nbsp;deal you've done in this location:<span
                      class="ml-2 text-base font-semibold"
                      >{{
                        loggedInUser.primarySpecialtyLocationDealDetails
                      }}</span
                    >
                  </p>

                  <li
                    class="ml-8 mr-4 mt-4 list-disc text-base leading-relaxed sm:ml-12"
                  >
                    Additional info & licenses:<span
                      class="ml-2 text-base font-semibold"
                      >{{ loggedInUser.additionalInfoAndLicenses }}</span
                    >
                  </li>
                </div>
              </div>
            </ul>
          </div>
          <a href="#topofpage"
            ><button class="nonbtn mt-6 mb-2">
              <span class="text-xs"
                >back to top<i class="fa fa-arrow-up ml-2"></i
              ></span></button
          ></a>

          <hr class="mb-8" />
        </div>

        <div class="mb-8 scroll-mt-12" id="activityandstats">
          <h2 class="mb-6 text-xl font-semibold">
            <fa
              :icon="['fas', 'chart-bar']"
              class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
            />Activity & stats
          </h2>
           <p class="tempdevnote">
            reviewsWrittenKeepPrivate = {{ loggedInUser.reviewsWrittenKeepPrivate }}
          </p>
          <p class="tempdevnote">
            reviewsWrittenFullPublic = {{ loggedInUser.reviewsWrittenFullPublic }}
          </p>
          <p class="tempdevnote">
            reviewsWrittenAnonPublic = {{ loggedInUser.reviewsWrittenAnonPublic }}
          </p>

          <hr class="my-4"/>

          <p class="tempdevnote">
            questionsWrittenKeepPrivate = {{ loggedInUser.questionsWrittenKeepPrivate }}
          </p>
          <p class="tempdevnote">
            questionsWrittenFullPublic = {{ loggedInUser.questionsWrittenFullPublic }}
          </p>
          <p class="tempdevnote">
            questionsWrittenAnonPublic = {{ loggedInUser.questionsWrittenAnonPublic }}
          </p>

          <hr class="my-4"/>

          <p class="tempdevnote">
            answersWrittenKeepPrivate = {{ loggedInUser.answersWrittenKeepPrivate }}
          </p>
          <p class="tempdevnote">
            answersWrittenFullPublic = {{ loggedInUser.answersWrittenFullPublic }}
          </p>
          <p class="tempdevnote">
            answersWrittenAnonPublic = {{ loggedInUser.answersWrittenAnonPublic }}
          </p>

          <hr class="my-4"/>
          
          <ul class="ml-10 space-y-5 italic">
            <li class="text-base">
              You've written 12 reviews
              <span class="ml-2.5 rounded-md bg-red-100 pl-1 pr-2 text-sm"
                >-1 helpful vote</span
              >
            </li>
            <li class="text-base">
              You've asked 0 questions
              <span class="ml-2.5 rounded-md bg-gray-100 pl-1 pr-2 text-sm"
                >0 helpful votes
              </span>
            </li>
            <li class="text-base">
              You've answered 15 questions
              <span class="ml-2.5 rounded-md bg-emerald-100 pl-1 pr-2 text-sm"
                >+17 helpful votes
              </span>
            </li>
            <li class="text-base">You're following 12 properties</li>
            <li class="text-base">You're blocking 6 users</li>
          </ul>
        </div>

        <a href="#topofpage"
          ><button class="nonbtn mt-6 mb-2">
            <span class="text-xs"
              >back to top<i class="fa fa-arrow-up ml-2"></i
            ></span></button
        ></a>
        <hr class="mb-8" />

        <div class="mb-8 scroll-mt-12" id="settingsandprivacy">
          <h2 class="mb-6 text-xl font-semibold">
            <fa
              :icon="['fas', 'cog']"
              class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
            />Settings & privacy
          </h2>
          <ul class="ml-4 space-y-8 italic sm:ml-8 sm:space-y-5">
            <li class="text-base">Sign in & security</li>
            <ul
              class="ml-4 list-inside list-disc space-y-5 sm:ml-10 sm:space-y-2.5"
            >
              <li class="text-sm">
                password &nbsp;

                <button>
                  <UpdateUserInfo
                    fieldValue=""
                    updateUserInfoVersion="1"
                    whichItemToUpdate="password"
                    :userId="user.uid"
                    itemType="authPass"
                  />
                </button>
              </li>
              <li class="text-sm">
                email: &nbsp;{{ user.email }} &nbsp;
                <button>
                  <UpdateUserInfo
                    fieldValue=""
                    updateUserInfoVersion="1"
                    whichItemToUpdate="email"
                    :userId="user.uid"
                  />
                </button>
              </li>
              <div v-if="user.emailVerified === false">
                <li class="text-sm">
                  your email is not verified &nbsp;

                  <button @click="handleEmailVerifClick" class="changebtn">
                    verify
                  </button>
                  <!-- docs to send verification email: https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address -->
                </li>
              </div>
              <div v-else>
                <li class="text-sm">
                  verified email? &nbsp;{{ user.emailVerified }}
                </li>
              </div>
              <li class="text-sm">
                phone &nbsp; {{ loggedInUser.phone }}

                <button>
                  <UpdateUserInfo
                    :fieldValue="loggedInUser.phone"
                    updateUserInfoVersion="1"
                    whichItemToUpdate="phone"
                    :userId="user.uid"
                    itemType="profile"
                  />
                </button>
              </li>
              <li class="text-sm">
                two-factor auth &nbsp;
                <button>
                  <UpdateUserInfo
                    fieldValue=""
                    updateUserInfoVersion="1"
                    whichItemToUpdate="two-factor-auth (not yet an active field)"
                    :userId="user.uid"
                  />
                </button>
              </li>
              <li class="text-sm">
                get alerts about unrecognized logins &nbsp;

                <button>
                  <UpdateUserInfo
                    fieldValue=""
                    updateUserInfoVersion="1"
                    whichItemToUpdate="unrecognized logins (not yet an active field)"
                    :userId="user.uid"
                  />
                </button>
              </li>
            </ul>
            <li class="text-base">Visibility - what others see</li>
            <li class="text-base" id="adpreferences">Ad Preferences</li>
            <li class="text-base">
              Delete your account&nbsp;
              <span class="text-sm italic"> Cannot be undone! </span>
              <button>
                <UpdateUserInfo
                  fieldValue=""
                  updateUserInfoVersion="3"
                  whichItemToUpdate="deleteAccount"
                  :userId="user.uid"
                />
              </button>
            </li>
          </ul>
        </div>

        <a href="#topofpage"
          ><button class="nonbtn mt-6 mb-2">
            <span class="text-xs"
              >back to top<i class="fa fa-arrow-up ml-2"></i
            ></span></button
        ></a>
        <hr class="mb-8" />

        <div class="mb-8 scroll-mt-12" id="notifications">
          <h2 class="mb-6 text-xl font-semibold">
            <fa
              :icon="['fas', 'bell']"
              class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
            />Notifications
          </h2>
          <ul class="ml-4 space-y-5 italic">
            <li class="text-base"></li>
          </ul>
        </div>

        <a href="#topofpage"
          ><button class="nonbtn mt-6 mb-2">
            <span class="text-xs"
              >back to top<i class="fa fa-arrow-up ml-2"></i
            ></span></button
        ></a>

        <hr class="mb-8" />

        <div class="mb-8 scroll-mt-12" id="helpandsupport">
          <h2 class="mb-6 text-xl font-semibold">
            <fa
              :icon="['fas', 'life-ring']"
              class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
            />Help & Support
          </h2>
          <ul class="ml-4 space-y-5 italic">
            <li class="text-base"></li>
          </ul>
        </div>

        <a href="#topofpage"
          ><button class="nonbtn mt-6 mb-2">
            <span class="text-xs"
              >back to top<i class="fa fa-arrow-up ml-2"></i
            ></span></button
        ></a>
        <hr class="mb-8" />

        <div class="scroll-mt-12" id="legal">
          <h2 class="mb-6 text-xl font-semibold">
            <fa
              :icon="['fas', 'balance-scale']"
              class="fa-fw text-md mr-2 text-gray-600 md:text-lg"
            />Legal
          </h2>
          <ul class="ml-10 space-y-5 italic">
            <li>
              <router-link :to="{ name: 'Terms' }"
                ><span class="text-base hover:text-gray-400"
                  >Terms</span
                ></router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Privacy' }"
                ><span class="text-base hover:text-gray-400"
                  >Privacy</span
                ></router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Dmca' }"
                ><span class="text-base hover:text-gray-400"
                  >DMCA</span
                ></router-link
              >
            </li>
          </ul>
          <a href="#topofpage"
            ><button class="nonbtn mt-16 mb-2">
              <span class="text-xs"
                >back to top<i class="fa fa-arrow-up ml-2"></i
              ></span></button
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";
import getSetUserInfo from "@/composables/getSetUserInfo";
import { ref, watch } from "vue";
import { getAuth, sendEmailVerification } from "firebase/auth";
import useStorage from "@/composables/useStorage";
import useDocument from "@/composables/useDocument";
import getDocument from "@/composables/getDocument";
import Whoops from "@/components/Whoops.vue";
import UpdateUserInfo from "@/components/UpdateUserInfo.vue";

export default {
  components: {
    Whoops,
    UpdateUserInfo,
  },
  // props: ["id"],
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const {
      // userErr,
      loggedInUser,
      getLoggedInUserInfo,
      // updateErr,
      // updateProfileField,
    } = getSetUserInfo();

    const { logout } = useLogout();

    const { getImages, fetchedImages, isUploadDone } = useStorage();
    const file = ref(null);
    const fileError = ref(null);
    const isPending = ref(false);
    const profilePic = ref("");

    if (user.value) {
      getLoggedInUserInfo(user.value.uid);
    }

    const auth = getAuth(); // for email verification, per documentation at https://firebase.google.com/docs/auth/web/manage-users#send_a_user_a_verification_email

    const handleClick = async () => {
      await logout();
      console.log("logged out");
      router.push({ name: "Login" });
    };
    
    // code to change all text to uppercase
    

    // BEGIN computed question, answer, etc. counts
    // END computed question, answer, etc. counts

    // BELOW is for email verification, per documentation at https://firebase.google.com/docs/auth/web/manage-users#send_a_user_a_verification_email
    const handleEmailVerifClick = async () => {
      await sendEmailVerification(auth.currentUser).then(() => {
        // Email verification sent!
        // ...
      });
      console.log("email verification sent");
    };
    // ABOVE is for email verification, per documentation at https://firebase.google.com/docs/auth/web/manage-users#send_a_user_a_verification_email

    // const handleClickPassword = async () => {
    //   console.log('handleClickPassword was clicked')
    // }
    // const whichBtnIsActive = profile

    watch(loggedInUser, async (newVal, oldVal) => {
      const profilePic = newVal.profilePhotoUrl;
      getImages([profilePic]);
    });

    return {
      user,
      loggedInUser,
      fetchedImages,
      profilePic,
      handleClick,

      //handleSubmit, fileError, handleChange, isPending,

      // handleClickPassword,
      // handleEmailVerifClick,

      // whichBtnIsActive: "profilebtn",
    };
  },

  data() {
    return {};
  },
};
</script>

<style></style>